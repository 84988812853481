import React, { useState } from "react"
import { Configure } from "react-instantsearch"
import { ReactSortable } from "react-sortablejs"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import HealMeTooltip from "../../components/shared/HealMeTooltip"
import { Box, ProfilePageEmptyStateBox } from "../../components/shared/Layout"
import { UnderlinedLink } from "../../components/shared/Links"
import { EndorsementPill } from "../../components/shared/Pill"
import { AlgoliaSearchProvider } from "../../components/shared/SearchInputs"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { EndorsementsFlyout } from "../ProfileEdit/EndorsementsFlyout"
import ProfileEditProvider from "../ProfileEdit/ProfileEditProvider"

const DELETE_SKILLS = `
  mutation DeleteSkills($skillId: String!) {
    deleteSkills(skillId: $skillId) {
      result
      skills {
        id
        endorsementsCount
        specialty { id name }
      }
      errors
    }
  }
`

const MOVE_SKILLS = `
  mutation MoveSkills($skillArray: [String!]!) {
    moveSkills(skillArray: $skillArray) {
      result
      skills {
        id
        endorsementsCount
        specialty { id name }
      }
      errors
    }
  }
`

const Endorsements = ({ skills, specialtiesByLetter }) => {
  const [skillsState, setSkillsState] = useState(skills)
  const [flyoutVisible, setFlyoutVisible] = useState(false)

  const [, deleteSkills] = useMutation(DELETE_SKILLS)
  const [, moveSkills] = useMutation(MOVE_SKILLS)

  const { showToast } = useToast()

  const removeSkill = (skill) => {
    deleteSkills({ skillId: skill.id.toString() }).then((result) => {
      if (result?.data?.deleteSkills?.result === "success") {
        setSkillsState(result.data.deleteSkills.skills)
        setFlyoutVisible(false)
        showToast("Successfully deleted keyword")
      } else {
        showToast({ type: "error", content: "Failed to delete keyword" })
        console.error(result) // eslint-disable-line no-console
      }
    })

    const index = skillsState.indexOf(skill)
    if (index !== -1) {
      const copySkills = [...skillsState]
      copySkills.splice(index, 1)
      setSkillsState(copySkills)
    }
  }

  return (
    <>
      <div className="w-[600px] md:w-full">
        <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
          <div className="flex flex-col">
            <Typography as="h1" variant="h3">
              Keywords
            </Typography>
            <Typography variant="subtitle">Add or edit your keywords.</Typography>
          </div>
        </div>

        {skillsState.length === 0 ? (
          <ProfilePageEmptyStateBox title="Keywords" onButtonClick={() => setFlyoutVisible(true)}>
            Why do your clients come to see you? List these here.{" "}
            <UnderlinedLink
              href="https://help.heal.me/en/articles/8441399-what-are-endorsements-and-which-should-i-pick"
              target="_blank">
              Learn more
            </UnderlinedLink>
          </ProfilePageEmptyStateBox>
        ) : (
          <>
            <Box data-test-id="endorsements">
              <div className="flex items-center justify-between border-b border-gray-light pb-6 sm:border-none sm:pb-0">
                <Typography variant="capitalHeading">Keywords</Typography>
                <div data-tooltip-id="disabled-tooltip">
                  <Button floatingInMobile={true} disabled={skills.length >= 50} onClick={() => setFlyoutVisible(true)}>
                    Add new
                  </Button>
                </div>
                {skills.length >= 50 && (
                  <HealMeTooltip
                    id="disabled-tooltip"
                    place="bottom"
                    content="Note: You can add a maximum of 50 credentials."
                  />
                )}
              </div>
              <Typography variant="subtitle" className="mt-6 sm:mt-2">
                Drag to re-order each keyword.
              </Typography>
              <ReactSortable
                className="mt-6 flex flex-wrap gap-2 sm:mt-4"
                list={skillsState}
                setList={(newSkills) => {
                  if (JSON.stringify(newSkills) !== JSON.stringify(skillsState)) {
                    const newOrder = newSkills.map((skill, i) => JSON.stringify({ id: skill.id, position: i + 1 }))
                    setSkillsState(newSkills)
                    moveSkills({ skillArray: newOrder }).then((result) => {
                      if (result?.data?.moveSkills?.result !== "success") {
                        console.error(result) // eslint-disable-line no-console
                        showToast({
                          type: "error",
                          content:
                            "There was error reordering your keywords. Please try again later or contact support if the error persists."
                        })
                      }
                    })
                  }
                }}>
                {skillsState.map((skill) => (
                  <EndorsementPill key={skill.id} draggable active onCloseButtonClick={() => removeSkill(skill)}>
                    {skill.specialty.name}
                  </EndorsementPill>
                ))}
              </ReactSortable>
            </Box>
          </>
        )}
      </div>

      <EndorsementsFlyout
        specialtiesByLetter={specialtiesByLetter}
        skills={skillsState}
        setSkills={setSkillsState}
        visible={flyoutVisible}
        closeFlyout={() => setFlyoutVisible(false)}
      />
    </>
  )
}

export default function ProReviewsEndorsementsPage({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <AlgoliaSearchProvider indexName="Specialty">
        <Configure hitsPerPage={50} filters="NOT ordered_kind:1" />
        <Endorsements {...props} />
      </AlgoliaSearchProvider>
    </ProfileEditProvider>
  )
}

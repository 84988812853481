import algoliasearch from "algoliasearch"
import React from "react"
import { InstantSearch, Configure, Index } from "react-instantsearch-dom"

import SearchCategory from "./SearchCategory"

const SearchBar = ({
  envVars,
  query,
  setQuery,
  searchLocation,
  setSearchLocation,
  latlng,
  setLatlng,
  isVirtual,
  setIsVirtual,
  shouldRedirect,
  onSearchFocus,
  onLocationFocus,
  activeFilter
}) => {
  const searchClient = algoliasearch(envVars.algoliaAppId, envVars.algoliaApiKey)

  return (
    <div className="searchbar-container">
      <InstantSearch searchClient={searchClient} indexName="Specialty">
        <Configure hitsPerPage={10} />
        <SearchCategory
          setQuery={setQuery}
          setSearchLocation={setSearchLocation}
          setLatlng={setLatlng}
          setIsVirtual={setIsVirtual}
          query={query}
          latlng={latlng}
          searchLocation={searchLocation}
          isVirtual={isVirtual}
          shouldRedirect={shouldRedirect}
          onSearchFocus={onSearchFocus}
          onLocationFocus={onLocationFocus}
          activeFilter={activeFilter}
        />
        <Index indexName="Specialty" />
        <Index indexName={`Practice_${process.env.NODE_ENV}`} />
      </InstantSearch>
    </div>
  )
}

export default SearchBar

import dayjs from "dayjs"
import React from "react"

const CalendarEvent = ({ calendarEvent }) => (
  <div className="grid grid-cols-[auto,1fr] gap-4">
    <div className="font-bold">When</div>
    <div>
      {dayjs(calendarEvent.start).format("dddd, MMM Do, YYYY [at] h:mm A")} -{" "}
      {dayjs(calendarEvent.end).format("h:mm A z")}
    </div>
    {calendarEvent.extendedProps.location && (
      <>
        <div className="font-bold">Where</div>
        <div>{calendarEvent.extendedProps.location}</div>
      </>
    )}
    <div className="font-bold">Calendar</div>
    <div>{calendarEvent.extendedProps.calendarName}</div>
    {calendarEvent.extendedProps.about && (
      <>
        <div className="font-bold">About</div>
        <div>{calendarEvent.extendedProps.about}</div>
      </>
    )}
  </div>
)

export default CalendarEvent

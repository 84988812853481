import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import { CheckBox, Input } from "../../components/shared/Inputs"

const GiftCardForm = ({ giftCard, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    amountCents: giftCard?.amountCents || "",
    description: giftCard?.description || "",
    active: giftCard?.active ?? true,
    public: giftCard?.public ?? true
  })
  const [errors, setErrors] = useState({})

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const validate = () => {
    const newErrors = {}
    if (!formData.amountCents) {
      newErrors.amountCents = "Amount is required"
    } else if (isNaN(parseFloat(formData.amountCents)) || parseFloat(formData.amountCents) <= 0) {
      newErrors.amountCents = "Amount must be a positive number"
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (!validate()) return
    onSave(formData)
  }

  return (
    <div className="flex items-center gap-4 rounded-lg bg-blue/10 p-4">
      <div className="w-32">
        <div className="relative flex items-center">
          <span className="pointer-events-none absolute left-3 text-gray-dark">$</span>
          <Input
            type="number"
            min="0"
            step="1"
            className="pl-7"
            value={Math.round(Number(formData.amountCents) / 100) || ""}
            onChange={(e) => handleChange("amountCents", parseInt(e.target.value * 100))}
          />
        </div>
        {errors.amountCents && <span className="mt-1 text-sm text-red">{errors.amountCents}</span>}
      </div>
      <Input
        type="text"
        className="flex-1"
        placeholder="Description (optional)..."
        value={formData.description}
        onChange={(e) => handleChange("description", e.target.value)}
      />
      <div className="flex items-center gap-4">
        <CheckBox
          label="Active"
          className="mb-0"
          checked={formData.active}
          onChange={(e) => handleChange("active", e.target.checked)}
        />
        <CheckBox
          label="Online"
          className="mb-0"
          checked={formData.public}
          onChange={(e) => handleChange("public", e.target.checked)}
        />
      </div>
      <div className="flex gap-2">
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default GiftCardForm

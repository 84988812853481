import React from "react"

import { Divider } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

import SeoSettings from "./SeoSettings"
import WebsiteDomain from "./WebsiteDomain"

const WebsiteSettingsComponent = ({
  shortLink,
  customDomain,
  customDomainEnabled,
  website,
  websiteMetadata,
  isImpersonating
}) => (
  <>
    <div>
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          Website Settings
        </Typography>
      </div>
    </div>
    <WebsiteDomain
      shortLink={shortLink}
      customDomain={customDomain}
      customDomainEnabled={customDomainEnabled}
      website={website}
    />
    {isImpersonating && (
      <>
        <Divider className="my-10" />
        <SeoSettings websiteMetadata={websiteMetadata} />
      </>
    )}
  </>
)

export default function WebsiteSettings({ ...props }) {
  return (
    <ToastProvider>
      <GraphQLProvider>
        <WebsiteSettingsComponent {...props} />
      </GraphQLProvider>
    </ToastProvider>
  )
}

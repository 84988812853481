import dayjs from "dayjs"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

const SEND_INTAKE_FORM_MUTATION = `
  mutation SendIntakeForm($clientId: ID!) {
    sendIntakeForm(clientId: $clientId) {
      result
      errors
    }
  }
`

const SendIntakeFormButton = ({ clientId, intakeLastSentAt }) => {
  const [{ fetching }, sendIntakeForm] = useMutation(SEND_INTAKE_FORM_MUTATION)
  const [sentForm, setSentForm] = useState(false)

  const { showToast } = useToast()

  return (
    <div className="flex flex-col gap-1">
      <Button
        className="w-fit"
        disabled={fetching}
        onClick={() => {
          sendIntakeForm({ clientId }).then((result) => {
            if (result.error) {
              console.error(result.error) // eslint-disable-line no-console
              showToast({ content: "Error sending intake form", type: "error" })
            } else if (result.data.sendIntakeForm.result === "error") {
              showToast({ content: result.data.sendIntakeForm.errors.join(", "), type: "error" })
            } else if (result.data.sendIntakeForm.result === "success") {
              showToast("Intake form sent 🎉")
              setSentForm(true)
            }
          })
        }}>
        Send intake form
      </Button>
      {(intakeLastSentAt || sentForm) && (
        <Typography variant="micro">Last sent {dayjs(sentForm ? new Date() : intakeLastSentAt).fromNow()}</Typography>
      )}
    </div>
  )
}

export default SendIntakeFormButton

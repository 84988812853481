import { ChevronRightIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"
import React from "react"

import { Link } from "./Links"
import Typography from "./Typography"

const Breadcrumbs = ({ links, activePage }) => (
  <div className="mb-8 flex flex-row items-center sm:mb-4">
    {links.map((link, index) => (
      <React.Fragment key={link.href}>
        <Link href={link.href} className="text-sm font-bold">
          {link.text}
        </Link>
        {index < links.length - 1 && <ChevronRightIcon className="mx-2 h-5 w-5 text-gray" />}
      </React.Fragment>
    ))}

    {activePage && (
      <>
        <ChevronRightIcon className="ml-2 h-5 w-5 text-gray" />
        <Typography variant="small" className="ml-2 text-gray-dark">
          {activePage}
        </Typography>
      </>
    )}
  </div>
)

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired
}

export default Breadcrumbs

import dayjs from "dayjs"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import DatePicker from "../../components/shared/DatePicker"
import { InputWithLabel, TextArea, Select, Label } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

const IMPORT_REVIEW_MUTATION = `
  mutation ProImportReview($rating: Int!, $review: String, $firstName: String!, $phone: String, $createdAt: String) {
    proImportReview(rating: $rating, review: $review, firstName: $firstName, phone: $phone, createdAt: $createdAt) {
      result
      errors
    }
  }
`

export default function ProImportReviews() {
  const { showToast } = useToast()

  const [{ fetching }, importReview] = useMutation(IMPORT_REVIEW_MUTATION)

  const reviewDefaults = {
    rating: 5,
    review: "",
    firstName: "",
    phone: "",
    createdAt: dayjs()
  }

  const [newReview, setNewReview] = useState(reviewDefaults)

  const saveEnabled = !!(newReview.rating && newReview.firstName && newReview.createdAt && newReview.phone)

  const onMutationComplete = (result) => {
    setNewReview(reviewDefaults)

    if (result?.data?.proImportReview?.result === "success") {
      showToast(`We sent ${newReview.firstName} a request to confirm this review.`)
    } else {
      console.error(result)
      let errorMessage = "There was an error adding this review."
      if (result.data?.proImportReview?.errors) errorMessage += ` ${result.data.proImportReview.errors}`
      showToast({
        type: "error",
        content: errorMessage
      })
    }
  }

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return (
    <div className="mt-8 flex flex-row sm:flex-col">
      <div className="min_md:mr-8 min_md:w-[572px]">
        <div className="rounded-lg border border-gray-light p-8 sm:w-full">
          <Label className="mb-2">Review text</Label>
          <TextArea
            placeholder=""
            rows="3"
            onChange={(e) => setNewReview({ ...newReview, review: e.target.value })}
            value={newReview.review}
          />
          <Typography variant="small" className="text-gray-dark">
            Copy & paste or type in the text your client wrote for you.
          </Typography>

          <div className="grid grid-cols-2 gap-2 md:grid-cols-1">
            <div>
              <Label className="mb-2 mt-4">Date</Label>
              <DatePicker
                id="date-picker"
                selected={dayjs(newReview.createdAt).toDate()}
                onChange={(date) => setNewReview({ ...newReview, createdAt: date })}
                maxDate={today}
              />
            </div>
            <div>
              <Label className="mb-2 mt-4">Rating</Label>
              <Select
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 }
                ]}
                onChange={(e) => setNewReview({ ...newReview, rating: parseInt(e.target.value) })}
                value={newReview.rating}
              />
            </div>
            <InputWithLabel
              label="First name"
              value={newReview.firstName}
              onChange={(e) => setNewReview({ ...newReview, firstName: e.target.value })}
              className="mt-2"
            />
            <InputWithLabel
              label="Phone"
              type="phone"
              value={newReview.phone}
              onChange={(e) => setNewReview({ ...newReview, phone: e.target.value })}
              className="mt-2"
            />
          </div>

          <div className="mt-8 block w-full text-right">
            <Button
              type="primary"
              disabled={!saveEnabled || fetching}
              onClick={() => {
                importReview(newReview).then(onMutationComplete)
              }}>
              Send confirmation
            </Button>
          </div>
        </div>
      </div>
      <div className="w-[360px] md:mt-8 sm:w-full">
        <div className="rounded-lg border border-gray-light p-8">
          <Typography variant="body" className="font-semibold">
            Do you have already have reviews?
          </Typography>
          <Typography variant="subtitle" className="mt-2">
            If you’ve gotten some on your website, Google, etc., fill this out and we’ll send a request for your clients
            to confirm their previous review.
          </Typography>
        </div>
      </div>
    </div>
  )
}

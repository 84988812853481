export const customDomainConfig = (token) => {
  const isDev = window.App.environment === "development"
  const isStaging = window.App.environment === "staging"
  const url = isDev ? "https://heal.me" : `https://${window.location.hostname}`
  const appId = isDev || isStaging ? "heal.me_dev" : "heal.me"
  return {
    applicationId: appId,
    token: token,
    power: true,
    dnsRecords: {
      domain: [
        {
          type: "A",
          host: "@",
          value: "{ENTRI_SERVERS}", // This will be automatically replaced for the Entri servers IPs
          ttl: 300,
          applicationUrl: url,
          powerRootPathAccess: ["packs/", "assets/"]
        },
        {
          type: "CNAME",
          host: "www",
          value: "{CNAME_TARGET}", // This will automatically use the CNAME target entered in the dashboard
          ttl: 300,
          applicationUrl: url,
          powerRootPathAccess: ["packs/", "assets/"]
        }
      ],
      subDomain: [
        {
          type: "CNAME",
          host: "{SUBDOMAIN}", // This will use the user inputted subdomain
          value: "{CNAME_TARGET}", // This will automatically use the CNAME target entered in the dashboard
          ttl: 300,
          applicationUrl: url,
          powerRootPathAccess: ["packs/", "assets/"]
        }
      ]
    }
  }
}

export const showCustomDomainModal = (config) => {
  if (window.entri) {
    window.entri.showEntri(config)
  } else {
    console.warn("entri is not defined") // eslint-disable-line no-console
  }
}

export const showPurchaseDomainModal = (config) => {
  if (window.entri) {
    window.entri.purchaseDomain(config)
  } else {
    console.warn("entri is not defined") // eslint-disable-line no-console
  }
}

export const handleCustomDomainModalStepChange = ({ event, upsertCustomDomain, showToast, updateState }) => {
  if (
    event.detail &&
    (event.detail.step === "FINISHED_SUCCESSFULLY" || event.detail.step === "FINISHED_SUCCESSFULLY_MANUAL")
  ) {
    const domain = event.detail.domain

    upsertCustomDomain({ domain }).then((result) => {
      if (result?.data?.upsertCustomDomain?.result === "success") {
        showToast("Custom domain created successfully")
        updateState(domain)
      } else {
        showToast({
          type: "error",
          content: "Error creating custom domain"
        })
      }
    })
  }
}

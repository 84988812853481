import { useModal } from "@ebay/nice-modal-react"
import React, { useState } from "react"
import { useMutation } from "urql"

import { ConfirmCancelModal } from "../../components/shared/Modal"

const CLIENT_CANCEL_MUTATION = `
  mutation($appointmentId: ID!) {
    clientCancel(appointmentId: $appointmentId) {
      result
    }
  }
`

const CancelAppointmentButton = ({ appointmentId, onCancel, onError }) => {
  const [cancelButtonLoading, setCancelButtonLoading] = useState(false)

  const [, cancelAppointment] = useMutation(CLIENT_CANCEL_MUTATION)

  const confirmCancelModal = useModal(ConfirmCancelModal)

  return (
    <button
      className="hover:text-gray-dark"
      onClick={() => {
        confirmCancelModal.show({ loading: cancelButtonLoading }).then(() => {
          setCancelButtonLoading(true)
          cancelAppointment({ appointmentId }).then((res) => {
            res.error ? onError(res.error) : onCancel()
            setCancelButtonLoading(false)
            confirmCancelModal.hide()
          })
        })
      }}>
      Cancel appointment
    </button>
  )
}

export default CancelAppointmentButton

import dayjs from "dayjs"
import React from "react"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { usePractice } from "../../../../contexts/PracticeContext"
import { useToast } from "../../../../contexts/ToastContext"
import { formatClientPhonePlusOne, isClientValid } from "../../../../utils/utils"
import { Button } from "../../../shared/Buttons"

const AppointmentActions = ({
  fetching,
  onDecline,
  removeAppointmentFromCalendar,
  onAccept,
  onAppointmentChanged,
  onCancel,
  createManualAppointment,
  editManualAppointment,
  onRecurringAppointmentsChanged,
  resetBookingState,
  setShowPackageConfirmModal,
  setShowPastAppointmentModal,
  setPendingAction
}) => {
  const { showToast } = useToast()

  const {
    appointment,
    services,
    client,
    locationId,
    startsAt,
    endsAt,
    recurringStartTimes,
    recurrencePattern,
    createRecurringAppointments,
    recurringAppointmentError,
    setAppointmentErrors,
    initialAppointmentValues
  } = useManualBooking()

  const { practicePhone, practiceEmail } = usePractice()

  const pending = appointment?.state === "pending"
  const packageData =
    client?.openPackages?.find((p) => p.serviceId === services?.[0]?.service?.id) || appointment?.package
  const clientPhone = formatClientPhonePlusOne(client?.phone)

  const hasChangesInAppointment = () => {
    if (!appointment || !initialAppointmentValues) return true

    return (
      initialAppointmentValues.locationId !== locationId ||
      initialAppointmentValues.startsAt !== startsAt ||
      initialAppointmentValues.endsAt !== endsAt ||
      JSON.stringify(initialAppointmentValues.services) !== JSON.stringify(services) ||
      JSON.stringify(initialAppointmentValues.client) !== JSON.stringify(client)
    )
  }

  const isDisabled =
    (recurrencePattern && recurringStartTimes?.length === 0) ||
    !!recurringAppointmentError ||
    fetching ||
    !services?.[0]?.service ||
    !locationId ||
    !client ||
    !isClientValid(client, clientPhone, practicePhone, practiceEmail) ||
    (appointment && !hasChangesInAppointment())

  const isCreatingNewPackage = services?.[0]?.service?.package && !packageData

  const isPastAppointment = () => dayjs(startsAt).isBefore(dayjs())

  const handleSave = () => {
    // Remove the possible empty object from services
    const validServices = services?.filter((service) => service.service)

    if (validServices?.length > 0 && validServices?.every((service) => service.service?.addOn)) {
      setAppointmentErrors("You can't create an appointment with only add-on services")
      return
    }

    if (isPastAppointment() && !appointment) {
      setShowPastAppointmentModal(true)
      setPendingAction(() => executeSave)
      return
    }

    executeSave()
  }

  const executeSave = () => {
    if (appointment) {
      editManualAppointment()
    } else {
      if (recurringStartTimes && recurrencePattern) {
        if (services?.[0]?.service?.package && !packageData) {
          setShowPackageConfirmModal(true)
        } else {
          createRecurringAppointments((appointments) => {
            resetBookingState()
            onRecurringAppointmentsChanged(appointments)
            setShowPackageConfirmModal(false)
          })
        }
      } else {
        if (isCreatingNewPackage) {
          setShowPackageConfirmModal(true)
        } else {
          createManualAppointment()
          setShowPackageConfirmModal(false)
        }
      }
    }
  }

  return (
    <div className="mt-8 flex w-full justify-between border-t border-gray-light py-4">
      {pending ? (
        <>
          <Button
            type="warning"
            className="sm:w-full"
            onClick={() => {
              onDecline().then((result) => {
                if (result.data?.declineAppointment?.result === "success") {
                  removeAppointmentFromCalendar([appointment.id])
                  showToast("Appointment declined")
                } else {
                  showToast({
                    type: "error",
                    content: "Unexpected error declining appointment. Please contact support if this persists."
                  })
                }
              })
            }}>
            Decline
          </Button>
          <Button
            className="sm:w-full"
            type="primary"
            onClick={() => {
              onAccept().then((result) => {
                if (result.data.acceptAppointment?.result === "success") {
                  onAppointmentChanged(result.data.acceptAppointment.appointment)
                  showToast("Appointment accepted")
                } else {
                  showToast({
                    type: "error",
                    content: "Unexpected error accepting appointment. Please contact support if this persists."
                  })
                }
              })
            }}>
            Accept
          </Button>
        </>
      ) : (
        <>
          {appointment && (
            <Button type="warning" className="mr-4 sm:w-full" onClick={onCancel}>
              Cancel appt.
            </Button>
          )}
          <Button type="primary" className="sm:w-full" onClick={handleSave} disabled={isDisabled}>
            Save
          </Button>
        </>
      )}
    </div>
  )
}

export default AppointmentActions

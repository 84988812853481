import React, { useState } from "react"
import { useHits, useSearchBox } from "react-instantsearch"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { Label, SearchInput } from "../../components/shared/Inputs"
import { Flyout } from "../../components/shared/Layout"
import { EndorsementPill } from "../../components/shared/Pill"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"
import { useToast } from "../../contexts/ToastContext"

const UPDATE_SKILLS = `
  mutation UpdateSkills($skills: [String!]) {
    updateSkills(skills: $skills) {
      result
      skills {
        id
        endorsementsCount
        specialty { id name }
      }
      errors
    }
  }
`

const EndorsementButton = ({ specialty, active, onClick, onCloseButtonClick }) => (
  <EndorsementPill
    value={specialty.id.toString()}
    active={active}
    className="mb-0 block"
    onClick={onClick}
    onCloseButtonClick={onCloseButtonClick}>
    {specialty.name}
  </EndorsementPill>
)

export const EndorsementsFlyout = ({ specialtiesByLetter, skills, setSkills, visible, closeFlyout }) => {
  const { syncProfileStatus } = useProfileStatus()
  const [searchValue, setSearchValue] = useState("")
  const [selectedSpecialties, setSelectedSpecialties] = useState(skills.map((skill) => skill.specialty.id.toString()))

  const [{ fetching }, updateSkills] = useMutation(UPDATE_SKILLS)

  const { showToast } = useToast()
  const { refine } = useSearchBox()
  const { hits } = useHits()

  const setQuery = (newQuery) => {
    setSearchValue(newQuery)
    refine(newQuery)
  }

  const onSpecialtyClick = (e) => {
    setSelectedSpecialties((specialties) => {
      const newSpecialties = [...specialties]
      const specialtyId = e.target.value

      if (newSpecialties.includes(specialtyId)) {
        const index = newSpecialties.indexOf(specialtyId)
        newSpecialties.splice(index, 1)
      } else {
        newSpecialties.push(specialtyId)
      }
      return newSpecialties
    })
  }

  const onSave = () => {
    updateSkills({ skills: Array.from(selectedSpecialties) }).then((result) => {
      if (result?.data?.updateSkills?.result === "success") {
        setSkills(result.data.updateSkills.skills)
        closeFlyout()
        syncProfileStatus()
        showToast("Successfully updated keywords")
      } else {
        showToast({ type: "error", content: "Failed to update keywords" })
        console.error(result)
      }
    })
  }

  return (
    <Flyout
      visible={visible}
      closeFlyout={closeFlyout}
      header="Add keywords"
      footer={
        <div className="flex items-center justify-end gap-4">
          <Button type="tertiary" onClick={closeFlyout} disabled={fetching}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSave} disabled={fetching}>
            Save
          </Button>
        </div>
      }>
      <div className="flex flex-col gap-4">
        <div>
          <Label htmlFor="endorsements-search">Search for topics</Label>
          <SearchInput
            id="endorsements-search"
            containerClassName="w-full"
            value={searchValue}
            explanatorySubtext={
              <div className="flex justify-between">
                <span>Ex: Anxiety, Back pain, etc.</span>
                <span className="font-bold">{selectedSpecialties.length}/50 selected</span>
              </div>
            }
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {searchValue === "" ? (
          <>
            {specialtiesByLetter.map(({ letter, specialties }) => (
              <div key={letter}>
                <div className="mb-2 text-sm font-bold uppercase text-gray-dark">{letter}</div>
                <div className="flex flex-wrap gap-2">
                  {specialties.map((specialty) => (
                    <EndorsementButton
                      specialty={specialty}
                      key={specialty.id}
                      active={selectedSpecialties.includes(specialty.id.toString())}
                      onClick={onSpecialtyClick}
                    />
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="flex flex-wrap gap-2">
            {hits.map((hit) => {
              const specialty = { id: hit.objectID, name: hit.name }
              return (
                <EndorsementButton
                  key={specialty.id}
                  specialty={specialty}
                  active={selectedSpecialties.includes(specialty.id)}
                  onClick={onSpecialtyClick}
                />
              )
            })}
            {hits.length === 0 && <div>No results found</div>}
          </div>
        )}
      </div>
    </Flyout>
  )
}

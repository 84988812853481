import React from "react"

import Breadcrumbs from "../../../components/shared/Breadcrumbs"
import { Button } from "../../../components/shared/Buttons"
import { EditableValue } from "../../../components/shared/Inputs"
import { Banner } from "../../../components/shared/Layout"
import Typography from "../../../components/shared/Typography"
import { ToastProvider, useToast } from "../../../contexts/ToastContext"
import GraphQLProvider from "../../../providers/GraphQLProvider"

const SquareSettings = ({ squareInfo }) => {
  const { showToast } = useToast()

  if (squareInfo.message) {
    showToast(squareInfo.message)
  }
  if (squareInfo.error) {
    showToast({
      content: squareInfo.error,
      type: "error",
      duration: 5000
    })
  }

  return (
    <div>
      <Breadcrumbs
        links={[
          { href: "/portal/settings", text: "Settings" },
          { href: "/portal/settings/banking", text: "Payments" }
        ]}
        activePage="Square"
      />
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          Square
        </Typography>
      </div>
      <div className="w-1/2 rounded-xl border border-gray-light p-8 sm:w-full">
        {squareInfo.erroredAt && (
          <Banner type="error" className="mb-6">
            Heal.me no longer has access to your Square account. Please reconnect to continue using the Square
            integration.
          </Banner>
        )}
        <div className="flex justify-between">
          <div className="flex flex-col">
            <EditableValue
              value="Client payments will be made to this account."
              editable={false}
              name={squareInfo.displayName}
            />
          </div>
          <a href="/portal/settings/banking/square/authorize">
            <Button type={squareInfo.squareConnected ? "tertiary" : "primary"} className="w-fit">
              {squareInfo.squareConnected ? "Manage" : "Connect"}
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default function SquarePaymentsSettings(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <SquareSettings {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

import React, { useState } from "react"

import PaymentProviderCardForm from "../../components/payments/PaymentProviderCardForm"
import { usePaymentProvider } from "../../components/payments/PaymentProviderWrapper"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

const AddCreditCardModal = ({ headerLabel, open, closeModal, practice, onSave }) => {
  const [loading, setLoading] = useState(false)
  const { showToast } = useToast()

  const { handleCardSavedOnProvider } = usePaymentProvider()

  const handleCardSaved = async () => {
    const showErrorAndClose = (message) => {
      showToast({ content: `There was an error: ${message}`, type: "error" })
      setLoading(false)
      closeModal()
    }

    setLoading(true)

    const paymentProviderResult = await handleCardSavedOnProvider()
    if (paymentProviderResult.error) {
      showErrorAndClose(paymentProviderResult.error || "An error occurred")
      return
    }

    setLoading(false)
    showToast({ content: "Card saved successfully", type: "success" })
    closeModal()
    onSave()
  }

  return (
    <AnimatedModal
      visible={open}
      hideModal={closeModal}
      header={headerLabel}
      showFooter={true}
      onSave={handleCardSaved}
      saveDisabled={loading}>
      <div className="mt-4">
        <Typography variant="h5" className="mb-4">
          New Credit card
        </Typography>
        <PaymentProviderCardForm practice={practice} />
      </div>
    </AnimatedModal>
  )
}

export default AddCreditCardModal

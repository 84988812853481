import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../../components/shared/Buttons"
import { Select, CheckBox } from "../../../components/shared/Inputs"
import Typography from "../../../components/shared/Typography"
import { useToast } from "../../../contexts/ToastContext"
import { commIntervals } from "../../../utils/utils"

import ProCommunicationSettingsProvider from "./ProCommunicationSettingsProvider"
import ProCommunicationSettingsView from "./ProCommunicationSettingsView"

const UPDATE_COMMUNICATION_SETTINGS = `
  mutation UpdateCommunicationSettings(
    $appointmentReminderEmailEnabled: Boolean,
    $appointmentReminderTextEnabled: Boolean,
    $appointmentEmailReminderMinutes: Int,
    $appointmentTextReminderMinutes: Int,
    $paymentPastDueEmailEnabled: Boolean,
    $paymentPastDueTextEnabled: Boolean,
    $paymentPastDueEmailMinutes: Int,
    $paymentPastDueTextMinutes: Int,
  ) {
    updateCommunicationSettings(
      appointmentReminderEmailEnabled: $appointmentReminderEmailEnabled,
      appointmentReminderTextEnabled: $appointmentReminderTextEnabled,
      appointmentEmailReminderMinutes: $appointmentEmailReminderMinutes,
      appointmentTextReminderMinutes: $appointmentTextReminderMinutes,
      paymentPastDueEmailEnabled: $paymentPastDueEmailEnabled,
      paymentPastDueTextEnabled: $paymentPastDueTextEnabled,
      paymentPastDueEmailMinutes: $paymentPastDueEmailMinutes,
      paymentPastDueTextMinutes: $paymentPastDueTextMinutes,
    ) {
      result
      error
    }
  }
`

const CommunicationSettings = ({ communicationSettings, path }) => {
  const [, updateCommunicationSettings] = useMutation(UPDATE_COMMUNICATION_SETTINGS)

  const [settings, setSettings] = useState(communicationSettings)
  const [originalSettings, setOriginalSettings] = useState(communicationSettings)
  const { showToast } = useToast()

  const handleUpdate = (key) => (newValue) => {
    let update = newValue
    if (newValue.value) {
      update = newValue.value
    }
    setSettings((prevSettings) => ({ ...prevSettings, [key]: update }))
  }

  const handleSave = () => {
    updateCommunicationSettings(settings)
      .then((result) => {
        if (result.data?.updateCommunicationSettings?.result) {
          showToast({ content: "Settings updated successfully.", timeout: 3000 })
          setOriginalSettings(settings)
        } else {
          const error =
            result.error?.message?.replace("[GraphQL]", "") ||
            result.data?.updateCommunicationSettings?.error ||
            "Please try again later."

          showToast({ type: "error", content: error })
        }
      })
      .catch((error) => {
        showToast({ type: "error", content: error.message })
      })
  }

  if (Object.keys(settings).length === 0) {
    return null
  }

  const settingsChanged = JSON.stringify(settings) !== JSON.stringify(originalSettings)

  return (
    <ProCommunicationSettingsView path={path}>
      <div className="relative grid w-full grid-cols-2 gap-8 sm:grid-cols-1 sm:pb-[80px]">
        <div className="absolute -top-28 z-10 flex w-full justify-end sm:fixed sm:bottom-[68px] sm:left-0 sm:top-[auto] sm:border-t sm:border-gray-light sm:bg-white sm:p-6">
          <Button
            className="mr-4"
            label="Cancel"
            type="tertiary"
            onClick={() => {
              setSettings(originalSettings)
              showToast({ content: "Your changes have been discarded.", timeout: 3000 })
            }}>
            Cancel
          </Button>
          <Button type="primary" disabled={!settingsChanged} onClick={handleSave}>
            Save
          </Button>
        </div>
        <div className="rounded-xl border border-gray-light">
          <div className="p-8">
            <Typography variant="h5" as="h3">
              Appointment reminders
            </Typography>
            <Typography variant="subtitle" className="mt-6">
              Heal.me will remind your clients before their next appointment with you.
            </Typography>
          </div>
          <div className="rounded-b-xl bg-gray-ultralight p-8">
            <div className="mb-2 flex flex-row flex-wrap items-center">
              <CheckBox
                label="Email"
                className="flex-none font-manrope text-[16px] font-normal leading-6"
                id="appt-email-reminder"
                onChange={() =>
                  handleUpdate("appointmentReminderEmailEnabled")(!settings.appointmentReminderEmailEnabled)
                }
                checked={settings.appointmentReminderEmailEnabled}
              />
              <div className="mb-2 ml-2 flex-none bg-white">
                <Select
                  options={commIntervals}
                  value={settings.appointmentEmailReminderMinutes}
                  onChange={(e) =>
                    setSettings({ ...settings, appointmentEmailReminderMinutes: Number(e.target.value) })
                  }
                />
              </div>
              <p className="mb-2 ml-2 flex-none">before</p>
            </div>
            <div className="flex flex-row flex-wrap items-center">
              <CheckBox
                label="Text"
                className="flex-none font-manrope text-[16px] font-normal leading-6"
                id="appt-text-reminder"
                onChange={() =>
                  handleUpdate("appointmentReminderTextEnabled")(!settings.appointmentReminderTextEnabled)
                }
                checked={settings.appointmentReminderTextEnabled}
              />
              <div className="mb-2 ml-2 flex-none bg-white">
                <Select
                  options={commIntervals}
                  value={settings.appointmentTextReminderMinutes}
                  onChange={(e) => setSettings({ ...settings, appointmentTextReminderMinutes: Number(e.target.value) })}
                />
              </div>
              <p className="mb-2 ml-2 flex-none">before</p>
            </div>
          </div>
        </div>

        <div className="rounded-xl border border-gray-light">
          <div className="p-8">
            <Typography variant="h5" as="h3">
              Payment reminders
            </Typography>
            <Typography variant="subtitle" className="mt-6">
              Heal.me will remind your clients if they haven’t paid for an appointment.
            </Typography>
          </div>
          <div className="rounded-b-xl bg-gray-ultralight p-8">
            <div className="mb-2 flex flex-row flex-wrap items-center">
              <CheckBox
                label="Email"
                className="flex-none font-manrope text-[16px] font-normal leading-6"
                id="payment-email-reminder"
                onChange={() => handleUpdate("paymentPastDueEmailEnabled")(!settings.paymentPastDueEmailEnabled)}
                checked={settings.paymentPastDueEmailEnabled}
              />
              <div className="mb-2 ml-2 flex-none bg-white">
                <Select
                  options={commIntervals}
                  value={settings.paymentPastDueEmailMinutes}
                  onChange={(e) => setSettings({ ...settings, paymentPastDueEmailMinutes: Number(e.target.value) })}
                />
              </div>
              <p className="mb-2 ml-2 flex-none">after</p>
            </div>
            <div className="flex flex-row flex-wrap items-center">
              <CheckBox
                label="Text"
                className="flex-none font-manrope text-[16px] font-normal leading-6"
                id="payment-text-reminder"
                onChange={() => handleUpdate("paymentPastDueTextEnabled")(!settings.paymentPastDueTextEnabled)}
                checked={settings.paymentPastDueTextEnabled}
              />
              <div className="mb-2 ml-2 flex-none bg-white">
                <Select
                  options={commIntervals}
                  value={settings.paymentPastDueTextMinutes}
                  onChange={(e) => setSettings({ ...settings, paymentPastDueTextMinutes: Number(e.target.value) })}
                />
              </div>
              <p className="mb-2 ml-2 flex-none">after</p>
            </div>
          </div>
        </div>
      </div>
    </ProCommunicationSettingsView>
  )
}

export default function ProCommunicationSettings(props) {
  return (
    <ProCommunicationSettingsProvider>
      <CommunicationSettings {...props} />
    </ProCommunicationSettingsProvider>
  )
}

export const PAYMENT_STATUS_OPTIONS = {
  FREE: "Free",
  PAID: "Paid",
  PAST_DUE: "Past due",
  FAILED: "Failed",
  CARD_NEEDED: "Card needed",
  CARD_NEEDED_PAST_DUE: "Card needed past due",
  PENDING: "Pending",
  CANCELLED: "Cancelled",
  UNPAID: "Unpaid"
}

export const paymentProviderName = (appointment) => {
  switch (appointment?.paymentMethod) {
    case "stripe":
      return "Stripe"
    case "square":
      return "Square"
    case "paypal":
      return "PayPal"
    case "venmo":
      return "Venmo"
    default:
      return null
  }
}

export const isPaidOnProvider = (appointment) => appointment?.paymentIntentId !== null

import React from "react"

import Typography from "../../components/shared/Typography"

import PersonalInformationPractice from "./PersonalInformationPractice"
import PersonalInformationUser from "./PersonalInformationUser"
import ProfileEditProvider from "./ProfileEditProvider"

export default function CombinedInformationPage({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
        <div className="flex flex-col">
          <Typography variant="h3" as="h1">
            Details
          </Typography>
          <Typography variant="subtitle">Edit information about you and your practice</Typography>
        </div>
      </div>

      <div className="space-y-8">
        <section>
          <PersonalInformationUser {...props} />
          <PersonalInformationPractice {...props} />
        </section>
      </div>
    </ProfileEditProvider>
  )
}

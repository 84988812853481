import React, { useEffect, useState } from "react"
import Autosuggest from "react-autosuggest"
import { Highlight, connectAutoComplete } from "react-instantsearch-dom"

import { practitionerWebsiteUrl } from "../../@core/practitioner/practitioner.utils"
import { sendAmplitudeData } from "../../support/amplitude-react"

import LocationSearch from "./LocationSearch"

const ProRow = ({ hit }) => (
  <div className="pro-row-suggestion">
    <div className="pro-row-suggestion-img">
      <img src={hit.user.photo} />
    </div>
    <div>
      <div className="pro-row-suggestion-name">{hit.user.name}</div>
      <div className="pro-row-suggestion-info">
        <i className="icon-star-filled" />
        <span className="pro-row-suggestion-rating">{hit.reviews_average}</span>
        <span className="pro-row-suggestion-reviews">&nbsp;({hit.reviews_count})</span>
      </div>
    </div>
  </div>
)

const SearchCategory = ({
  currentRefinement,
  hits,
  refine,
  setQuery,
  setSearchLocation,
  setLatlng,
  setIsVirtual,
  query,
  searchLocation,
  latlng,
  isVirtual,
  shouldRedirect,
  onSearchFocus,
  onLocationFocus,
  activeFilter
}) => {
  const [value, setValue] = useState(currentRefinement || query)

  useEffect(() => {
    setValue(query)
  }, [query])

  const submit = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      setQuery(value)
      sendAmplitudeData("top search submitted", { search: value })
      Turbolinks.visit(
        `/practitioner-search?query=${encodeURIComponent(
          value
        )}&searched_location=${searchLocation}&aroundLatLng[lat]=${latlng[0]}&aroundLatLng[lng]=${latlng[1]}${
          isVirtual && "&virtual=true"
        }${activeFilter && "&activeFilter=" + activeFilter}`
      )
    }
  }

  const onSuggestionSelected = (e, result) => {
    if (result.suggestion.user) {
      setQuery("")
      setValue(result.suggestion.user.name)
      sendAmplitudeData("pro selected from top search", { pro: result.suggestion.user.slug })
      window.location.href = practitionerWebsiteUrl(result.suggestion.user, null)
    } else {
      setQuery(result.suggestion.name)
      setValue(result.suggestion.name)
      if (shouldRedirect) {
        sendAmplitudeData("category selected from top search", { therapy: result.suggestion.name })
        Turbolinks.visit(
          `/practitioner-search?query=${encodeURIComponent(
            result.suggestion.name
          )}&searched_location=${searchLocation}&aroundLatLng[lat]=${latlng[0]}&aroundLatLng[lng]=${latlng[1]}${
            isVirtual && "&virtual=true"
          }`
        )
      } else if (onLocationFocus) {
        onLocationFocus()
      }
    }
  }

  useEffect(() => {
    setQuery(query)
    setValue(query)
  }, [query])

  const onSuggestionCleared = () => {
    setQuery("")
  }

  const onChange = (e) => {
    setQuery(e.target.value)
    setValue(e.target.value)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    refine(value)
  }

  const onSuggestionsClearRequested = () => {
    refine()
  }

  const getSuggestionValue = (hit) => hit.name

  const renderSuggestion = (hit) => {
    if (hit.user) {
      return <ProRow hit={hit} />
    } else {
      return <Highlight attribute="name" hit={hit} tagName="mark" />
    }
  }

  const renderSectionTitle = (section) => (section.index == "Specialty" ? "Therapies" : "Practitioners")

  const getSectionSuggestions = (section) => section.hits

  const inputProps = {
    placeholder: 'Try "Massage" or "Back Pain"',
    onChange: onChange,
    value,
    name: "search",
    onFocus: onSearchFocus,
    onKeyUp: submit
  }

  return (
    <div className="flex" id="category-pro-search-form">
      <Autosuggest
        suggestions={hits}
        multiSection={true}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        focusInputOnSuggestionClick={false}
      />
      <i className="icon-search" />
      {value !== "" && (
        <i
          className="icon-close autosuggest-clear"
          onClick={() => {
            if (onLocationFocus) {
              onLocationFocus()
            }
            onSuggestionCleared()
            setValue("")
          }}
        />
      )}
      <LocationSearch
        setSearchLocation={setSearchLocation}
        searchLocation={searchLocation}
        setLatlng={setLatlng}
        setIsVirtual={setIsVirtual}
        query={query}
        isVirtual={isVirtual}
        onLocationFocus={onLocationFocus}
        shouldRedirect={shouldRedirect}
      />
    </div>
  )
}

export default connectAutoComplete(SearchCategory)

import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import React from "react"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { usePractice } from "../../../../contexts/PracticeContext"
import { formatPhone } from "../../../../utils/utils"
import ClientNotes from "../../../shared/ClientNotes"
import Typography from "../../../shared/Typography"
import ClientSelector from "../ClientSelector"

const ClientInfo = () => {
  const { client, setClient, appointment, services, setServices, updateEndsAtWithServicesDuration } = useManualBooking()
  const { practice, practiceServices } = usePractice()

  const packageData =
    client?.openPackages?.find((p) => p.serviceId === services?.[0]?.service?.serviceId) || appointment?.package

  const handleClientSelect = (selectedClient) => {
    setClient(selectedClient)
    if (selectedClient.openPackages && selectedClient.openPackages.length > 0) {
      const selectedService = practiceServices.find(
        (service) => service.id === selectedClient.openPackages[0].serviceId
      )
      updateStateOnClientSelect(selectedService, selectedClient)
    } else if (selectedClient.lastBookedService) {
      const selectedService = practiceServices.find((service) => service.id === selectedClient.lastBookedService.id)
      updateStateOnClientSelect(selectedService, selectedClient)
    }
  }

  const updateStateOnClientSelect = (selectedService, selectedClient) => {
    if (selectedService) {
      const serviceObj = buildServiceObj(selectedService, selectedClient)
      setServices([serviceObj])
      updateEndsAtWithServicesDuration([serviceObj])
    }
  }

  const buildServiceObj = (selectedService, selectedClient) => {
    const selectedVariation = selectedService.variations[0]

    const serviceObj = {
      amountCents: selectedVariation.amountCents,
      service: {
        ...selectedService,
        id: selectedVariation.id,
        serviceId: selectedService.id,
        timeLength: selectedVariation.timeLength
      }
    }

    const customPrice = selectedClient.customPrices?.find((cp) => cp.serviceId === selectedVariation.id)
    if (customPrice) {
      const originalPrice = selectedVariation.amountCents
      return {
        ...serviceObj,
        amountCents: customPrice.amountCents,
        priceChanged: originalPrice !== customPrice.amountCents,
        saveCustomPrice: originalPrice !== customPrice.amountCents
      }
    }

    return serviceObj
  }

  return (
    <div className="mb-6">
      {client && client.id ? (
        <div className="relative rounded-xl border border-gray-light px-4 py-2">
          <a href={`/portal/clients/${client.id}`}>
            <Typography variant="h5">
              {client.firstName} {client.lastName}
            </Typography>
            <Typography variant="small">
              {client.email && `${client.email}`} {formatPhone(client.phone) && `${formatPhone(client.phone)}`}{" "}
            </Typography>
            {appointment && (
              <button className="absolute right-4 top-4">
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            )}
          </a>

          {packageData && (
            <div className="-mx-4 -mb-2 mt-2 box-content bg-gray-ultralight px-4 py-2">
              <Typography variant="small">
                <span className="font-bold">
                  {appointment?.packageSessionNumber
                    ? `Session ${appointment.packageSessionNumber} of ${packageData.numberOfSessions}`
                    : `${packageData.sessionsRemaining} sessions left`}
                </span>
                : They purchased the &apos;{packageData.name}&apos; on{" "}
                {dayjs(packageData.createdAt).format("MM/DD/YYYY")}.
              </Typography>
            </div>
          )}
          {practice.clientNotesEnabled && (
            <div className="-mx-4 -mb-2 mt-2 box-content rounded-b-xl px-4 py-2">
              <ClientNotes client={client} />
            </div>
          )}
          {!appointment && (
            <button className="absolute right-4 top-4" onClick={() => setClient(null)}>
              <XMarkIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      ) : practiceServices ? (
        <ClientSelector defaultClient={client} onSelect={handleClientSelect} />
      ) : null}
    </div>
  )
}

export default ClientInfo

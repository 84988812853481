export const UPSERT_PERSONAL_EVENT = `
  mutation UpsertPersonalEvent ($id: ID, $startsAt: String!, $endsAt: String!, $name: String, $allDay: Boolean, $timeZone: String) {
    upsertPersonalEvent (id: $id, startsAt: $startsAt, endsAt: $endsAt, name: $name, allDay: $allDay, timeZone: $timeZone) {
      result
      errors
      personalEvent {
        id name startsAt endsAt allDay
      }
    }
  }
`

export const CHARGE_CARD_MUTATION = `
  mutation($appointmentId: ID!) {
    chargeCard(appointmentId: $appointmentId) {
      result
      errors
      appointment {
        id
        clientPaymentStatus
      }
    }
  }
`

export const SQUARE_ADD_CARD_TO_CUSTOMER_MUTATION = `
  mutation SquareAddCardToCustomerMutation($clientId: ID, $practiceId: ID!, $sourceId: String!, $userId: ID) {
    squareAddCardToCustomer(clientId: $clientId, practiceId: $practiceId, sourceId: $sourceId, userId: $userId) {
      result
      errors
    }
  }
`

export const DEFAULT_PAYMENT_METHOD_QUERY = `
  query($clientId: ID, $userId: ID, $practiceId: ID!) {
    defaultPaymentMethodPractice(clientId: $clientId, userId: $userId, practiceId: $practiceId) {
      brand
      last4
      expMonth
      expYear
    }
  }
`

export const UPDATE_PRACTICE_DETAILS_MUTATION = `
  mutation UpdatePracticeDetails(
    $locationId: String,
    $languageIds: [ID!], 
    $ageWorkWithIds: [ID!], 
    $workWithIds: [ID!], 
    $website: String, 
    $facebook: String, 
    $instagram: String, 
    $twitter: String, 
    $linkedin: String, 
    $tiktok: String,
    $youtube: String
  ) {
    updatePracticeDetails(
      locationId: $locationId,
      languageIds: $languageIds, 
      ageWorkWithIds: $ageWorkWithIds, 
      workWithIds: $workWithIds, 
      website: $website, 
      facebook: $facebook, 
      instagram: $instagram, 
      twitter: $twitter, 
      linkedin: $linkedin, 
      tiktok: $tiktok,
      youtube: $youtube
    ) {
      result
      practice {
        id
        location
        languages { id name }
        ageWorkWiths { id name }
        workWiths { id name }
        website
        facebook
        instagram
        twitter
        linkedin
        tiktok
        youtube
      }
    }
  }
`

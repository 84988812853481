import React, { useState, useRef, useEffect } from "react"
import { useMutation } from "urql"

import PaymentProviderCardForm from "../../components/payments/PaymentProviderCardForm"
import { usePaymentProvider } from "../../components/payments/PaymentProviderWrapper"
import CurrencyInput from "../../components/shared/CurrencyInput"
import { Banner } from "../../components/shared/Layout"
import { AnimatedModal } from "../../components/shared/Modal"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import Typography from "../../components/shared/Typography"
import { useAppointment } from "../../contexts/AppointmentContext"
import { useToast } from "../../contexts/ToastContext"
import useBrowserInfo from "../../hooks/useBrowserInfo"
import { capitalize, stringPriceToInteger } from "../../utils/utils"

import { ADD_TIP_TO_APPOINTMENT_MUTATION, CHARGE_TIP_ONLY_MUTATION } from "./mutations"
import PaypalPaymentButton from "./PaypalPaymentButton"

const PAYMENT_METHODS = {
  SAVED_CARD: "saved_card",
  NEW_CARD: "new_card",
  PAYPAL: "paypal",
  VENMO: "venmo"
}

const PAYMENT_METHOD_DESCRIPTIONS = {
  [PAYMENT_METHODS.PAYPAL]:
    'After clicking "Pay with PayPal", you will be redirected to PayPal to complete your purchase securely.',
  [PAYMENT_METHODS.VENMO]:
    'After clicking "Pay with Venmo", you will be redirected to Venmo to complete your purchase securely.'
}

const isFundingEnabled = (fundingSource) => {
  if (!window.paypal) return false
  return window.paypal.isFundingEligible(fundingSource)
}

const AddPaymentModal = ({
  headerLabel,
  open,
  closeModal,
  onSave,
  tipOnly = false,
  actionButtonCopy,
  onPaypalSuccess
}) => {
  const [loading, setLoading] = useState(false)
  const [tipOption, setTipOption] = useState("")
  const [tipValue, setTipValue] = useState(0)
  const { appointment } = useAppointment()
  const { showToast } = useToast()
  const browserInfo = useBrowserInfo()
  const customTipInputRef = useRef(null)

  const acceptsTips = appointment?.practice?.acceptsTips
  const showPaypal = appointment.practice.hasPaypalPaymentsEnabled
  const showVenmo = appointment.practice.hasVenmoPaymentsEnabled && isFundingEnabled("venmo")
  const isVenmoIncompatible = browserInfo?.isMobile && !browserInfo?.isCompatibleBrowser

  const [, addTipToAppointment] = useMutation(ADD_TIP_TO_APPOINTMENT_MUTATION)
  const [, chargeTipOnly] = useMutation(CHARGE_TIP_ONLY_MUTATION)

  const { handleCardSavedOnProvider } = usePaymentProvider()

  const handleCardSaved = async () => {
    if (acceptsTips && tipOption === "") {
      showToast({ content: "Please select a tip option.", type: "error", timeout: 4000 })
      return
    }

    const showErrorAndClose = (message) => {
      showToast({ content: `There was an error: ${message}`, type: "error" })
      setLoading(false)
      closeModal()
    }

    setLoading(true)

    if (paymentMethod === PAYMENT_METHODS.NEW_CARD) {
      const paymentProviderResult = await handleCardSavedOnProvider()
      if (paymentProviderResult.error) {
        showErrorAndClose(paymentProviderResult.error || "An error occurred")
        return
      }
    }

    if (!tipOnly && acceptsTips && tipValue !== appointment.tipAmountCents) {
      const res = await addTipToAppointment({ appointmentId: appointment.id, tipAmount: tipValue })
      if (res.data?.addTipToAppointment?.errors) {
        showErrorAndClose(res.data.addTipToAppointment.errors.join(", ") || "An error occurred")
        return
      }
    }

    if (tipOnly) {
      const res = await chargeTipOnly({ appointmentId: appointment.id, tipAmount: tipValue })
      if (res.data?.chargeTipOnly?.errors) {
        showErrorAndClose(res.data.chargeTipOnly.errors.join(", ") || "An error occurred")
        return
      }
    }

    setLoading(false)
    onSave(tipValue)
    closeModal()
  }

  const defaultPaymentMethod = appointment?.user?.defaultPaymentMethod || appointment?.client?.defaultPaymentMethod
  const [paymentMethod, setPaymentMethod] = useState(
    defaultPaymentMethod ? PAYMENT_METHODS.SAVED_CARD : PAYMENT_METHODS.NEW_CARD
  )

  const getDefaultPaymentMethodText = (paymentMethod) => {
    if (!paymentMethod) return "No payment method selected"
    return `${capitalize(paymentMethod.brand)} ending in ${paymentMethod.last4} • Expires ${paymentMethod.expMonth}/${
      paymentMethod.expYear
    }`
  }

  const TIPPING_OPTIONS = [
    ...(!tipOnly ? [{ value: "not-now", label: "Not now" }] : []),
    { value: "10%", label: `10% • $${((0.1 * appointment.amountCents) / 100).toFixed(2)}` },
    { value: "15%", label: `15% • $${((0.15 * appointment.amountCents) / 100).toFixed(2)}` },
    { value: "20%", label: `20% • $${((0.2 * appointment.amountCents) / 100).toFixed(2)}` },
    { value: "custom-tip", label: "Custom Tip" }
  ]

  useEffect(() => {
    if (tipOption === "custom-tip" && customTipInputRef.current) {
      customTipInputRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [tipOption])

  return (
    <AnimatedModal
      visible={open}
      hideModal={closeModal}
      header={headerLabel}
      showFooter={paymentMethod !== PAYMENT_METHODS.PAYPAL && paymentMethod !== PAYMENT_METHODS.VENMO}
      onSave={handleCardSaved}
      actionButtonCopy={actionButtonCopy}
      saveDisabled={loading}>
      <div className="mt-4">
        <Typography variant="h5" className="mb-4">
          Payment method
        </Typography>
        <div className="flex flex-col gap-2">
          {/* Credit card buttons */}
          {defaultPaymentMethod && (
            <RadioWithLabel
              value={PAYMENT_METHODS.SAVED_CARD}
              name="payment-method"
              label={getDefaultPaymentMethodText(defaultPaymentMethod)}
              checked={paymentMethod === PAYMENT_METHODS.SAVED_CARD}
              onChange={() => setPaymentMethod(PAYMENT_METHODS.SAVED_CARD)}
            />
          )}
          <RadioWithLabel
            value={PAYMENT_METHODS.NEW_CARD}
            name="payment-method"
            label="New Credit card"
            checked={paymentMethod === PAYMENT_METHODS.NEW_CARD}
            onChange={() => setPaymentMethod(PAYMENT_METHODS.NEW_CARD)}
          />
          {paymentMethod === PAYMENT_METHODS.NEW_CARD && <PaymentProviderCardForm practice={appointment.practice} />}

          {/* PayPal / Venmo buttons */}
          {showPaypal && (
            <>
              <RadioWithLabel
                value={PAYMENT_METHODS.PAYPAL}
                name="payment-method"
                label="PayPal"
                checked={paymentMethod === PAYMENT_METHODS.PAYPAL}
                onChange={() => setPaymentMethod(PAYMENT_METHODS.PAYPAL)}
              />
              {paymentMethod === PAYMENT_METHODS.PAYPAL && (
                <div className="ml-6 text-sm">{PAYMENT_METHOD_DESCRIPTIONS[PAYMENT_METHODS.PAYPAL]}</div>
              )}
            </>
          )}
          {showVenmo && (
            <>
              <RadioWithLabel
                value={PAYMENT_METHODS.VENMO}
                name="payment-method"
                label="Venmo"
                checked={paymentMethod === PAYMENT_METHODS.VENMO}
                onChange={() => setPaymentMethod(PAYMENT_METHODS.VENMO)}
                disabled={isVenmoIncompatible}
              />
              {paymentMethod === PAYMENT_METHODS.VENMO && (
                <div className="ml-6 text-sm">{PAYMENT_METHOD_DESCRIPTIONS[PAYMENT_METHODS.VENMO]}</div>
              )}
              {isVenmoIncompatible && (
                <Banner type="warning" className="text-sm">
                  {browserInfo?.isIOS && "Please use Safari browser on iOS to pay with Venmo."}
                  {browserInfo?.isAndroid && "Please use Chrome browser on Android to pay with Venmo."}
                </Banner>
              )}
            </>
          )}
        </div>
      </div>
      {acceptsTips && (
        <div className="mt-4">
          <Typography variant="h5" className="mb-4">
            Add a tip
          </Typography>
          <div className="flex flex-col gap-2">
            {TIPPING_OPTIONS.map((option) => (
              <RadioWithLabel
                key={option.value}
                value={option.value}
                name="tip"
                label={option.label}
                checked={tipOption === option.value}
                onChange={() => {
                  setTipOption(option.value)
                  if (option.value === "not-now") {
                    setTipValue(0)
                    return
                  }
                  if (option.value === "custom-tip") {
                    setTipValue(parseInt(appointment.amountCents) * 0.1)
                    return
                  }
                  setTipValue((parseInt(appointment.amountCents) * parseInt(option.value)) / 100)
                }}
              />
            ))}
          </div>
          {tipOption === "custom-tip" && (
            <div ref={customTipInputRef}>
              <CurrencyInput
                label="Custom tip"
                value={tipValue / 100}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setTipValue(0)
                    return
                  }
                  setTipValue(stringPriceToInteger(e.target.value))
                }}
                className="mt-4"
                autoFocus
              />
            </div>
          )}
        </div>
      )}
      {(paymentMethod === PAYMENT_METHODS.PAYPAL || paymentMethod === PAYMENT_METHODS.VENMO) && (
        <div className="mt-4">
          <PaypalPaymentButton
            appointment={appointment}
            tipAmountCents={tipValue}
            tipOnly={tipOnly}
            fundingSource={paymentMethod === PAYMENT_METHODS.VENMO ? "venmo" : "paypal"}
            onSuccess={(appointmentResponse) => {
              onPaypalSuccess(appointmentResponse)
              closeModal()
            }}
            onError={(error) => {
              showToast({ content: `Payment failed: ${error}`, type: "error" })
            }}
          />
        </div>
      )}
    </AnimatedModal>
  )
}

export default AddPaymentModal

import React from "react"

import Typography from "../../components/shared/Typography"
import { PracticeProvider } from "../../contexts/PracticeContext"
import DefaultProvider from "../../providers/DefaultProvider"

import ReviewRequestForm from "./ReviewRequestForm"

const Reviews = ({ requestReviewLink, clientRequests }) => (
  <>
    <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
      <div className="flex flex-col">
        <Typography as="h1" variant="h3">
          Request Reviews
        </Typography>
        <Typography variant="subtitle">Send review request to your clients.</Typography>
      </div>
    </div>

    <ReviewRequestForm existingRequests={clientRequests.length} requestReviewLink={requestReviewLink} defaultRows={1} />
  </>
)

export default function ProReviewsRequestPage(props) {
  return (
    <DefaultProvider>
      <PracticeProvider>
        <Reviews {...props} />
      </PracticeProvider>
    </DefaultProvider>
  )
}

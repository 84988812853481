import { ArrowTopRightOnSquareIcon, CreditCardIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { useMutation, useQuery } from "urql"

import PaymentProviderWrapper from "../../components/payments/PaymentProviderWrapper"
import { Button } from "../../components/shared/Buttons"
import { Flyout } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { AnimatedModal } from "../../components/shared/Modal"
import { usePractice } from "../../contexts/PracticeContext"

import BookableEventPaymentModal from "./BookableEventPaymentModal"
import { REMOVE_CLIENT_FROM_BOOKABLE_EVENT_MUTATION } from "./mutations"
import { BOOKABLE_EVENT_REGISTRATION_QUERY } from "./queries"

export default function ClientDetailsFlyout({ visible, closeFlyout, client, bookableEventId, onClientRemoved }) {
  const [removeError, setRemoveError] = useState(null)
  const [{ fetching: removeFetching }, removeClientFromEvent] = useMutation(REMOVE_CLIENT_FROM_BOOKABLE_EVENT_MUTATION)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const { practice } = usePractice()

  const [{ data: registrationData, fetching: registrationFetching, error: registrationError }, reexecuteQuery] =
    useQuery({
      query: BOOKABLE_EVENT_REGISTRATION_QUERY,
      variables: {
        bookableEventId,
        clientId: client?.id
      },
      pause: !visible || !client?.id
    })

  const registration = registrationData?.bookableEventRegistration

  const formatCurrency = (amountCents) => `$${(amountCents / 100).toFixed(2)}`

  const getPaymentStatusDisplay = () => {
    if (!registration) return { label: "Unknown", color: "gray-dark" }

    switch (registration.paymentStatus) {
      case "free":
        return { label: "Free Event", color: "green" }
      case "paid":
        return { label: "Paid", color: "green" }
      case "upcoming":
        return { label: "Upcoming", color: "orange" }
      case "unpaid":
        return { label: "Payment Pending", color: "orange" }
      case "failed":
        return { label: "Payment Failed", color: "red" }
      default:
        return { label: "No Payment Required", color: "gray-dark" }
    }
  }

  const canProcessPayment = () => {
    if (!registration) return false
    if (registration.amountCents === 0) return false // Free event
    if (registration.paymentStatus === "paid") return false // Already paid

    // Can process if payment is pending, failed, or none
    return ["unpaid", "failed", "none"].includes(registration.paymentStatus)
  }

  const paymentStatus = getPaymentStatusDisplay()

  const handleShowRemoveConfirmation = () => {
    setShowRemoveConfirmation(true)
  }

  const handleCancelRemove = () => {
    setShowRemoveConfirmation(false)
    setRemoveError(null)
  }

  const handleRemoveClient = async () => {
    if (!client) return

    setRemoveError(null)

    try {
      const result = await removeClientFromEvent({
        bookableEventId: bookableEventId,
        clientId: client.id
      })

      if (result.data?.removeClientFromBookableEvent?.result === "success") {
        setShowRemoveConfirmation(false)
        onClientRemoved()
        closeFlyout()
      } else {
        const errorMessage =
          result.data?.removeClientFromBookableEvent?.errors?.[0] || "Failed to remove client from event"
        setRemoveError(errorMessage)
      }
    } catch (err) {
      console.error("Error removing client:", err)
      setRemoveError(err.message || "An unexpected error occurred")
    }
  }

  const handleShowPaymentModal = () => {
    setShowPaymentModal(true)
  }

  const handlePaymentSuccess = () => {
    reexecuteQuery({ requestPolicy: "network-only" })
  }

  return (
    <>
      <Flyout header="Client Details" visible={visible} closeFlyout={closeFlyout}>
        <div className="space-y-6">
          <div>
            <h3 className="mb-2 font-semibold">Client Information</h3>
            <div className="rounded-lg border border-gray-light p-4">
              <div className="mb-2 flex items-center">
                <span className="font-medium">Name:</span>
                <span className="mx-1">
                  {client?.firstName} {client?.lastName}
                </span>
                <a
                  href={`/portal/clients/${client?.id}`}
                  className="ml-1 inline-flex items-center text-teal hover:text-teal-dark"
                  title="View client profile"
                  target="_blank"
                  rel="noopener noreferrer">
                  <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </a>
              </div>
              <div className="mb-2">
                <span className="font-medium">Email:</span> {client?.email || "N/A"}
              </div>
              <div>
                <span className="font-medium">Phone:</span> {client?.phone || "N/A"}
              </div>
            </div>
          </div>

          <div>
            <h3 className="mb-2 font-semibold">Payment Status</h3>
            {registrationFetching ? (
              <div className="flex justify-center py-4">
                <LoadingSpinner />
              </div>
            ) : registrationError ? (
              <div className="rounded-lg border border-red p-4 text-red">Error loading payment information</div>
            ) : (
              <div className="rounded-lg border border-gray-light p-4">
                {!registration ? (
                  <div className="text-center italic text-gray-dark">Registration information not found</div>
                ) : (
                  <>
                    <div className="mb-2">
                      <span className="font-medium">Status:</span>
                      <span
                        className={`ml-2 inline-block rounded-full px-2 py-0.5`}
                        style={{ color: paymentStatus.color }}>
                        {paymentStatus.label}
                      </span>
                    </div>
                    <div className="mb-2">
                      <span className="font-medium">Registration Date:</span>{" "}
                      {registration.createdAt ? new Date(registration.createdAt).toLocaleDateString() : "N/A"}
                    </div>
                    {registration.amountCents > 0 && (
                      <div className="mb-2">
                        <span className="font-medium">Amount:</span> {formatCurrency(registration.amountCents)}
                      </div>
                    )}
                    {registration.discount && (
                      <>
                        <div className="mb-2">
                          <span className="font-medium">Discount Code:</span> {registration.discount.code}
                        </div>
                        <div className="mb-2">
                          <span className="font-medium">Discount Amount:</span>{" "}
                          {formatCurrency(registration.discount.amountCents)}
                        </div>
                      </>
                    )}

                    {canProcessPayment() && practice.hasPaymentProvider && (
                      <div className="mt-4">
                        <Button
                          type="secondary"
                          className="flex w-full items-center justify-center"
                          onClick={handleShowPaymentModal}>
                          <CreditCardIcon className="mr-2 h-5 w-5" />
                          Process Payment
                        </Button>
                      </div>
                    )}

                    {canProcessPayment() && !practice.hasPaymentProvider && (
                      <div className="mt-4 rounded bg-gray-ultralight p-3">
                        <p className="text-sm text-gray-dark">
                          To process payments, you need to connect a payment provider.{" "}
                          <a href="/portal/settings/banking" className="text-teal underline">
                            Connect payment provider
                          </a>
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="pt-4">
            <Button type="destructive" onClick={handleShowRemoveConfirmation} className="w-full">
              Remove Client from Event
            </Button>
          </div>
        </div>
      </Flyout>

      {/* Confirmation Modal */}
      <AnimatedModal
        visible={showRemoveConfirmation}
        hideModal={handleCancelRemove}
        header="Remove Client from Event"
        showFooter={true}
        cancelButtonCopy="Cancel"
        cancelButtonType="tertiary"
        actionButtonCopy={removeFetching ? "Removing..." : "Yes, Remove Client"}
        actionButtonType="destructive"
        saveDisabled={removeFetching}
        onSave={handleRemoveClient}>
        <div className="py-4">
          <p className="mb-4">
            Are you sure you want to remove{" "}
            <strong>
              {client?.firstName} {client?.lastName}
            </strong>{" "}
            from this event?
          </p>
          <p className="mb-4">This action cannot be undone.</p>
          {removeError && <div className="mt-4 rounded border border-red p-3 text-red">{removeError}</div>}
        </div>
      </AnimatedModal>

      {/* Payment Modal */}
      {client && registration && (
        <PaymentProviderWrapper practice={practice} client={client}>
          <BookableEventPaymentModal
            visible={showPaymentModal}
            hideModal={() => setShowPaymentModal(false)}
            client={client}
            registration={registration}
            onPaymentSuccess={handlePaymentSuccess}
          />
        </PaymentProviderWrapper>
      )}
    </>
  )
}

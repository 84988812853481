import dayjs from "dayjs"
import React from "react"
import { useQuery } from "urql"

import { Label } from "../../shared/Inputs"
import { Link } from "../../shared/Links"
import Typography from "../../shared/Typography"

import AppointmentSummaryRow from "./AppointmentSummaryRow"

const PAST_APPOINTMENTS_QUERY = `
  query PastAppointments($clientId: ID, $userId: ID){
    pastAppointments(clientId: $clientId, userId: $userId) {
      id
      isVirtual
      startsAt
      endsAt
      amountCents
      timeZone
      instantActionToken
      isReviewed
      state
      hasEndorsements
      hasTextReview
      fullTotal
      user {
        id
        name
        phone
        email
      }
      client {
        id
        firstName
        userId
        email
        phone
      }
      practice {
        id
        isStripeApproved
        user {
          id
          name
          profilePhotos {
            xLarge {
              jpeg
              webp
            }
            large {
              jpeg
              webp
            }
            medium {
              jpeg
              webp
            }
            small {
              jpeg
              webp
            }
            original {
              jpeg
              webp
            }
          }
        }
      }
      service {
        id
        name
        timeLength
        description
        photoUrl
        parent {
          id
          name
          description
          photoUrl
          timeLength
        }
        variations {
          id
          timeLength
          amountCents
        }
      }
      location {
        id
        name
        latitude
        longitude
        singleLine
        shortSingleLine
        virtualLink
        virtualInstructions
      }
      review {
        id
        review
        rating
        endorsedSkillIds
        videoUrl
        skillsForEndorsement {
          id
          name
        }
      }
    }
  }
`

export default function AppointmentHistory({ client, appointment, limit }) {
  const [{ data, fetching }] = useQuery({
    query: PAST_APPOINTMENTS_QUERY,
    variables: { clientId: client?.id },
    requestPolicy: "cache-and-network"
  })

  let pastAppointments = data?.pastAppointments
  if (pastAppointments && limit) {
    pastAppointments = pastAppointments.slice(0, limit)
  }

  return (
    <div>
      <Label>Past appointments</Label>
      {fetching ? (
        <img className="loading" src="/loading-spinner.png" width="40px" />
      ) : (
        <div>
          {pastAppointments && pastAppointments.length > 0 ? (
            <div>
              {pastAppointments.map((pastAppointment, index) => {
                const isLastItem = index === pastAppointments.length - 1
                if (!appointment || pastAppointment.id !== appointment?.id) {
                  return (
                    <div key={index} className="pt-0">
                      <p>{dayjs(pastAppointment.startsAt).format("dddd, MMMM DD, h:mm a")}</p>
                      <AppointmentSummaryRow appointment={pastAppointment} hideDivider={isLastItem} />
                    </div>
                  )
                }
                return null
              })}
              {limit && data.pastAppointments.length > limit && (
                <div className="mt-2 flex justify-end">
                  <Link href={`/portal/clients/${client.id}`}>
                    View all {data.pastAppointments.length} past appointments
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <Typography variant="body">None</Typography>
          )}
        </div>
      )}
    </div>
  )
}

import {
  CalendarIcon as CalendarIconOutline,
  UserIcon as UserIconOutline,
  BanknotesIcon as BanknotesIconOutline,
  Cog8ToothIcon as Cog8ToothIconOutline,
  ChatBubbleLeftIcon as ChatBubbleLeftIconOutline,
  NewspaperIcon as NewspaperIconOutline,
  GlobeAltIcon as GlobeAltIconOutline,
  CurrencyDollarIcon as CurrencyDollarIconOutline,
  QuestionMarkCircleIcon as QuestionMarkCircleIconOutline
} from "@heroicons/react/24/outline"
import {
  CalendarIcon as CalendarIconSolid,
  UserIcon as UserIconSolid,
  BanknotesIcon as BanknotesIconSolid,
  Cog8ToothIcon as Cog8ToothIconSolid,
  ChatBubbleLeftIcon as ChatBubbleLeftIconSolid,
  NewspaperIcon as NewspaperIconSolid,
  GlobeAltIcon as GlobeAltIconSolid,
  CurrencyDollarIcon as CurrencyDollarIconSolid,
  QuestionMarkCircleIcon as QuestionMarkCircleIconSolid
} from "@heroicons/react/24/solid"
import React from "react"

import { useFeatureFlags } from "../contexts/FeatureFlagContext"

const navItems = [
  {
    name: "Calendar",
    href: "/portal/calendar",
    solidIcon: <CalendarIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <CalendarIconOutline className=" h-6 w-6 text-gray-dark" />
  },
  {
    name: "Clients",
    href: "/portal/clients",
    solidIcon: <UserIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <UserIconOutline className=" h-6 w-6 text-gray-dark" />
  },
  {
    name: "Payments",
    href: "/portal/payments",
    solidIcon: <BanknotesIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <BanknotesIconOutline className=" h-6 w-6 text-gray-dark" />
  },
  {
    name: "My Practice",
    href: "/portal/services",
    solidIcon: <NewspaperIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <NewspaperIconOutline className=" h-6 w-6 text-gray-dark" />,
    children: [
      {
        name: "Services",
        href: "/portal/services",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Locations & Hours",
        href: "/portal/services/locations",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Details",
        href: "/portal/profile/combined",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Credentials",
        href: "/portal/profile/credential",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Intake Form",
        href: "/portal/settings/intake_forms",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Keywords",
        href: "/portal/reviews/keywords",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Gift Cards",
        href: "/portal/gift_card_templates",
        solidIcon: null,
        outlineIcon: null
      }
    ]
  },
  {
    name: "My Website",
    href: "/portal/website/settings",
    solidIcon: <GlobeAltIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <GlobeAltIconOutline className=" h-6 w-6 text-gray-dark" />,
    children: [
      {
        name: "Content",
        href: "/portal/website/builder",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Settings",
        href: "/portal/website/settings",
        solidIcon: null,
        outlineIcon: null
      }
    ]
  },
  {
    name: "Reviews",
    href: "/portal/reviews/request",
    solidIcon: <ChatBubbleLeftIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <ChatBubbleLeftIconOutline className=" h-6 w-6 text-gray-dark" />,
    children: [
      {
        name: "Request",
        href: "/portal/reviews/request",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Import",
        href: "/portal/reviews/import",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Completed",
        href: "/portal/reviews/completed",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Pending",
        href: "/portal/reviews/pending",
        solidIcon: null,
        outlineIcon: null
      }
    ]
  },
  {
    name: "Settings",
    href: "/portal/settings",
    solidIcon: <Cog8ToothIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <Cog8ToothIconOutline className=" h-6 w-6 text-gray-dark" />,
    children: [
      {
        name: "Account",
        href: "/portal/settings/personal",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Calendar",
        href: "/portal/settings/calendar",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Video Conferencing",
        href: "/portal/settings/conferencing",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Payments",
        href: "/portal/settings/banking",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Communication",
        href: "/portal/settings/communication",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Booking",
        href: "/portal/settings/booking",
        solidIcon: null,
        outlineIcon: null
      },
      {
        name: "Subscription",
        href: "/portal/subscriptions",
        solidIcon: null,
        outlineIcon: null
      }
    ]
  },
  {
    name: "Earn $50",
    href: "/portal/referral_program",
    solidIcon: <CurrencyDollarIconSolid className=" h-6 w-6 text-black" />,
    outlineIcon: <CurrencyDollarIconOutline className=" h-6 w-6 text-gray-dark" />
  },
  {
    name: "Help",
    href: "#",
    solidIcon: <QuestionMarkCircleIconSolid className="h-6 w-6 text-black" />,
    outlineIcon: <QuestionMarkCircleIconOutline className="h-6 w-6 text-gray-dark" />,
    id: "Intercom"
  }
]

export function getSideNavigationItems({ websitePlan }) {
  const { giftCardsEnabled, conferencingEnabled } = useFeatureFlags()

  let navigationItems = [...navItems]

  navigationItems = !websitePlan ? navigationItems : navigationItems.filter((item) => item.name !== "My Website")

  // Filter both top level and sub nav items for gift cards and video conferencing
  navigationItems = navigationItems.map((item) => {
    if (item.children) {
      return {
        ...item,
        children: item.children.filter((child) => {
          if (!giftCardsEnabled && child.name === "Gift Cards") return false
          if (!conferencingEnabled && child.name === "Video Conferencing") return false
          return true
        })
      }
    }
    return item
  })

  return navigationItems
}

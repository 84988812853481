import NiceModal from "@ebay/nice-modal-react"
import React from "react"

import { PracticeProvider } from "../../contexts/PracticeContext"
import { ProfileStatusProvider } from "../../contexts/ProfileStatusContext"
import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

const ProfileEditProvider = ({ children, profileStatusData }) => (
  <ToastProvider>
    <NiceModal.Provider>
      <PracticeProvider>
        <ProfileStatusProvider data={profileStatusData}>
          <GraphQLProvider>{children}</GraphQLProvider>
        </ProfileStatusProvider>
      </PracticeProvider>
    </NiceModal.Provider>
  </ToastProvider>
)

export default ProfileEditProvider

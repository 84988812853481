import React from "react"

export default function OfflinePaymentMethods({ appointment }) {
  if (!appointment) return null
  if (!appointment.practice) return null
  return (
    <div className="mt-4 flex flex-col">
      <h3 className="mb-2 pb-2 text-center font-semibold tracking-wide">
        You can {appointment.practice.isStripeApproved && "also"} pay with:
      </h3>
      <div className="flex flex-row flex-wrap items-center justify-evenly">
        {appointment.practice.acceptsCash && (
          <div className="mb-4 mr-4 flex flex-row items-center">
            <img src="/images/payments-icon-cash.png" className="mr-2" width="32px" height="32px" />
            <p className="font-semibold">Cash</p>
          </div>
        )}
        {appointment.practice.acceptsOfflineCreditCard && (
          <div className="mb-4 mr-4 flex flex-row items-center">
            <img src="/images/payments-icon-credit-card.png" className="mr-2" width="32px" height="32px" />
            <p className="font-semibold">Credit Card</p>
          </div>
        )}
        {appointment.practice.acceptsPaypal && (
          <div className="mb-4 mr-4 flex flex-row items-center">
            <img src="/images/payments-icon-paypal.png" className="mr-2" width="24px" height="24px" />
            <p className="font-semibold">PayPal</p>
          </div>
        )}
        {appointment.practice.acceptsVenmo && (
          <div className="mb-4 mr-4 flex flex-row items-center">
            <img src="/images/payments-icon-venmo.png" className="mr-2" width="24px" height="24px" />
            <p className="font-semibold">Venmo</p>
          </div>
        )}
        {appointment.practice.acceptsZelle && (
          <div className="mb-4 mr-4 flex flex-row items-center">
            <img src="/images/payments-icon-zelle.png" className="mr-2" width="24px" height="24px" />
            <p className="font-semibold">Zelle</p>
          </div>
        )}
      </div>
    </div>
  )
}

import React from "react"
import * as SurveyCreatorCore from "survey-creator-core"
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react"
import "survey-core/survey.i18n.js"
import "survey-creator-core/survey-creator-core.i18n.js"
import "survey-core/defaultV2.css"
import "survey-creator-core/survey-creator-core.css"
import "./IntakeFormsPage.sass"

import Breadcrumbs from "../components/shared/Breadcrumbs"
import Typography from "../components/shared/Typography"
import GraphQLProvider from "../providers/GraphQLProvider"
import { urqlClient } from "../utils/utils"

const questionTypes = [
  "boolean",
  "checkbox",
  "comment",
  "dropdown",
  "tagbox",
  // "expression",
  // "file",
  // "html",
  "image",
  // "imagepicker",
  "matrix",
  "matrixdropdown",
  "matrixdynamic",
  "multipletext",
  "panel",
  "paneldynamic",
  "radiogroup",
  "rating",
  "ranking",
  "signaturepad",
  "text"
]

const UPSERT_INTAKE_FORM_MUTATION = `
  mutation($data: String!, $saveNo: Int!) {
    upsertIntakeForm(data: $data, saveNo: $saveNo) {
      result
    }
  }
`

const UPSERT_INTAKE_FORM_THEME_MUTATION = `
  mutation($data: String!) {
    upsertIntakeFormTheme(data: $data) {
      result
    }
  }
`

const enLocale = SurveyCreatorCore.localization.getLocale("en")
enLocale.ed.surveyTypeName = "Outline"

function IntakeForms({ formJson, theme }) {
  const options = {
    showLogicTab: false,
    showJSONEditorTab: false,
    showThemeTab: true,
    questionTypes
  }
  const creator = new SurveyCreator(options)

  const whiteList = [
    "title",
    "description",
    "isRequired",
    "showCommentArea",
    "commentText",
    "commentPlaceholder",
    "labelTrue",
    "labelFalse",
    "swapOrder",
    "choices",
    "choicesFromQuestion",
    "choicesFromQuestionMode",
    "choiceValuesFromQuestion",
    "choiceTextsFromQuestion",
    "choicesOrder"
  ]

  creator.onShowingProperty.add(function (_, options) {
    if (options.obj.constructor.name !== "ThemeModel" && options.obj.constructor.name !== "HeaderModel") {
      options.canShow = whiteList.indexOf(options.property.name) > -1
    }
  })

  creator.isAutoSave = true
  creator.showState = true
  creator.JSON = JSON.parse(formJson)
  creator.theme = JSON.parse(theme)
  creator.toolbox.forceCompact = false
  creator.saveSurveyFunc = (saveNo, callback) => {
    urqlClient
      .mutation(UPSERT_INTAKE_FORM_MUTATION, { data: JSON.stringify(creator.JSON), saveNo })
      .toPromise()
      .then((result) => {
        if (result.error) {
          console.error(result)
          callback(saveNo, false)
        } else {
          callback(saveNo, true)
        }
      })
  }

  creator.saveThemeFunc = (saveNo, callback) => {
    urqlClient
      .mutation(UPSERT_INTAKE_FORM_THEME_MUTATION, { data: JSON.stringify(creator.theme), saveNo })
      .toPromise()
      .then((result) => {
        if (result.error) {
          console.error(result)
          callback(saveNo, false)
        } else {
          callback(saveNo, true)
        }
      })
  }

  return (
    <div className="h-[calc(100vh-156px)]">
      <div className="flex flex-col">
        <Breadcrumbs links={[{ href: "/portal/settings", text: "Settings" }]} activePage="Intake Form" />
        <Typography as="h1" variant="h3">
          Intake Form
        </Typography>
        <Typography variant="subtitle">Customize the intake form that you can send to your clients</Typography>
      </div>
      <SurveyCreatorComponent creator={creator} />
    </div>
  )
}

export default function IntakeFormsPage(props) {
  return (
    <GraphQLProvider>
      <IntakeForms {...props} />
    </GraphQLProvider>
  )
}

import React from "react"
import { useQuery } from "urql"

import LoadingSpinner from "../../shared/LoadingSpinner"
import { AnimatedModal } from "../../shared/Modal"

const DEFAULT_PAYMENT_METHOD_QUERY = `
  query($clientId: ID, $userId: ID, $practiceId: ID!) {
    defaultPaymentMethodPractice(clientId: $clientId, userId: $userId, practiceId: $practiceId) {
      brand
      last4
      expMonth
      expYear
    }
  }
`

const ConfirmPackageModal = ({ showPackageConfirmModal, hideModal, onSave, client, amount, disabled, practice }) => {
  const clientId = client?.__typename === "Client" ? client?.id : null
  const userId = client?.__typename === "User" ? client?.id : null

  const [{ data, fetching, error }] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: { clientId, userId, practiceId: practice?.id },
    pause: !client?.id
  })

  if (error) console.error(error)

  const cardOnFile = data?.defaultPaymentMethodPractice
  const clientName = client?.firstName || "This client"

  return (
    <AnimatedModal
      visible={showPackageConfirmModal}
      hideModal={hideModal}
      header={cardOnFile ? "Charge card on file now" : "No card on file"}
      showFooter={true}
      actionButtonCopy={cardOnFile ? `Charge ${amount} now` : "Continue & book"}
      saveDisabled={disabled}
      onSave={onSave}>
      {fetching ? (
        <LoadingSpinner />
      ) : cardOnFile ? (
        <p>
          Booking this package will charge {clientName}&apos;s card on file now ({cardOnFile.brand} ending in{" "}
          {cardOnFile.last4}) for {amount}. Click cancel to go back.
        </p>
      ) : (
        <p>{clientName} doesn&apos;t have a card on file, but you can still book this package and add a card later.</p>
      )}
    </AnimatedModal>
  )
}

export default ConfirmPackageModal

import NiceModal from "@ebay/nice-modal-react"
import dayjs from "dayjs"
import React, { useState } from "react"

import Breadcrumbs from "../../components/shared/Breadcrumbs"
import { Button } from "../../components/shared/Buttons"
import EditClientModal from "../../components/shared/EditClientModal"
import { Label } from "../../components/shared/Inputs"
import { Link, TabNavLink } from "../../components/shared/Links"
import ListBox from "../../components/shared/ListBox"
import Typography from "../../components/shared/Typography"
import { ToastProvider } from "../../contexts/ToastContext"
import { clientName, formatPhone } from "../../utils/utils"

const ClientPageView = ({ path, client: clientProp, upcomingAppointments, children, clientNotesEnabled }) => {
  const [client, setClient] = useState(clientProp)
  const navItems = [
    { title: "Overview", path: `/portal/clients/${client.id}` },
    { title: "Billing", path: `/portal/clients/${client.id}/billing` }
  ]

  if (clientNotesEnabled) {
    navItems.push({ title: "Intake form", path: `/portal/clients/${client.id}/intake_form` })
  }

  const selectItems = navItems.map((item) => ({ value: item.path, label: `${item.title}` }))
  const name = clientName(client)

  return (
    <ToastProvider>
      <NiceModal.Provider>
        <div className="w-full">
          <div className="mx-auto max-w-[1140px]">
            <Breadcrumbs
              links={[{ href: "/portal/clients", text: "Client directory" }]}
              activePage={`${name}'s profile`}
            />
            <div className="flex w-full items-start gap-8">
              <div className="flex-1">
                <Typography variant="h3" as="h1">
                  {name}
                </Typography>
                <div className="mt-4 flex gap-8 border-b border-gray-light sm:hidden">
                  {navItems.map((item) => (
                    <TabNavLink key={item.title} href={item.path} active={path === item.path}>
                      {item.title}
                    </TabNavLink>
                  ))}
                </div>
                <div className="mt-2 hidden sm:block">
                  <ListBox
                    value={selectItems.find((item) => item.value === path)}
                    onChange={(item) => {
                      if (typeof window !== "undefined") window.location = item.value
                    }}
                    options={selectItems}
                    buttonHint="(tap to navigate)"
                  />
                </div>
                <div className="mt-4 w-full sm:mt-2">{children}</div>
              </div>
              <div className="flex w-[278px] flex-col gap-4">
                <div className="rounded-xl border border-gray p-8" data-test-id="client-details">
                  <div className="mb-2 flex items-center justify-between">
                    <div className="font-bold">Client details</div>
                    <Button type="link" onClick={() => NiceModal.show("edit-client-flyout")}>
                      Edit
                    </Button>
                  </div>
                  <div className="flex flex-col gap-4">
                    {formatPhone(client.phone) && (
                      <div>
                        <Label>Phone</Label>
                        <Typography variant="micro" className="truncate" as="div">
                          {formatPhone(client.phone)}
                        </Typography>
                      </div>
                    )}
                    {client.email && (
                      <div>
                        <Label>Email</Label>
                        <Typography variant="micro" className="truncate" as="div">
                          {client.email}
                        </Typography>
                      </div>
                    )}
                    {client.fullAddress && (
                      <div>
                        <Label>Address</Label>
                        <Typography variant="micro" className="whitespace-pre-wrap" as="div">
                          {client.fullAddress}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className="rounded-xl border border-gray p-8">
                  <div className="mb-2 font-bold">Upcoming appointments</div>
                  {upcomingAppointments.length === 0 && <div className="text-sm">No upcoming appointments</div>}
                  {upcomingAppointments.map((appointment, index) => (
                    <div key={index}>
                      <Link className="text-sm" href={`/portal/calendar#${appointment.instantActionToken}`}>
                        {dayjs(appointment.startsAt).format("MMM D, YYYY")}
                      </Link>{" "}
                      <span className="text-sm text-gray-dark">{dayjs(appointment.startsAt).format("h:mm A")}</span>
                    </div>
                  ))}
                  <Link href={`/portal/calendar?clientId=${client.id}#create-appointment`}>+ New appointment</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditClientModal
          client={client}
          modalId="edit-client-flyout"
          deleteModalId="delete-client-flyout"
          onSuccess={(newClient) => {
            newClient.fullAddress = [
              newClient.address,
              newClient.addressTwo,
              newClient.city,
              newClient.state,
              newClient.postalCode,
              newClient.country
            ]
              .filter(Boolean)
              .join(", ")
            setClient((prevClient) => ({ ...prevClient, ...newClient }))
          }}
          key={client.id}
        />
      </NiceModal.Provider>
    </ToastProvider>
  )
}

export default ClientPageView

import { Listbox, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import React, { Fragment } from "react"
import { twMerge } from "tailwind-merge"

import { BASE_INPUT_CLASSNAMES, BASE_LABEL_CLASSNAMES, DROPDOWN_CLASSNAMES, DROPDOWN_OPTION_CLASSNAMES } from "./Inputs"

const ListBox = ({
  label,
  labelClassNames,
  className,
  options,
  value,
  onChange,
  disabled,
  buttonHint = null,
  placeholder = "Select an option"
}) => {
  if (typeof value === "string") value = options.find((option) => option.value === value)

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className={twMerge(BASE_LABEL_CLASSNAMES, labelClassNames)}>{label}</Listbox.Label>}
          <div className="relative">
            <Listbox.Button
              className={twMerge(
                BASE_INPUT_CLASSNAMES,
                disabled ? "pointer-events-none opacity-50" : "relative cursor-pointer",
                className
              )}>
              <div className="flex items-center justify-between gap-2">
                {value ? (
                  <div>{buttonHint ? `${value.label} ${buttonHint}` : value.label}</div>
                ) : (
                  <div className="text-gray-dark">{placeholder}</div>
                )}
                <div>
                  <ChevronDownIcon className="h-5 w-5 text-gray-dark" aria-hidden="true" />
                </div>
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className={"absolute " + DROPDOWN_CLASSNAMES}>
                {options.map((option) => (
                  <Listbox.Option key={option.value} className={DROPDOWN_OPTION_CLASSNAMES} value={option}>
                    <div className="flex items-center">
                      <span className="block">{option.label}</span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default ListBox

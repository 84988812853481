import React, { useCallback, useEffect, useState } from "react"
import { Model } from "survey-core"
import { Survey } from "survey-react-ui"
import { useMutation } from "urql"

import "survey-core/defaultV2.min.css"
import GraphQLProvider from "../providers/GraphQLProvider"

const SAVE_INTAKE_FORM_RESPONSE_MUTATION = `
  mutation($uniqueId: ID!, $formData: String!) {
    saveIntakeFormResponse(uniqueId: $uniqueId, formData: $formData) {
      result
      errors
    }
  }
`

const IntakeFormPage = ({ data, uniqueId, theme, alreadyCompleted, redirectUrl }) => {
  const [countdown, setCountdown] = useState(null)
  const [, saveIntakeFormResponse] = useMutation(SAVE_INTAKE_FORM_RESPONSE_MUTATION)

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
      return () => clearTimeout(timer)
    } else if (countdown === 0) {
      window.location.href = redirectUrl
    }
  }, [countdown])

  useEffect(() => {
    if (alreadyCompleted) {
      setCountdown(5)
    }
  }, [alreadyCompleted])

  const survey = new Model(data)
  survey.applyTheme(theme)
  const surveyComplete = useCallback((survey) => {
    saveIntakeFormResponse({ uniqueId, formData: JSON.stringify(survey.data) }).then((result) => {
      if (result.error) {
        console.error(result.error)
      } else if (result.data.saveIntakeFormResponse.result === "success") {
        setCountdown(5)
        console.log("success")
      } else {
        console.error(result)
      }
    })
  }, [])

  survey.onComplete.add(surveyComplete)

  return (
    <div className="h-screen">
      {countdown || alreadyCompleted ? (
        <div className="flex h-full items-center justify-center">
          <div className="text-center">
            <h1 className="text-2xl">
              {alreadyCompleted
                ? "This form has already been completed."
                : "Thank you for completing the intake form. Your response has been saved."}
            </h1>
            <p>You will be redirected to your practitioner&apos;s website in {countdown} seconds</p>
          </div>
        </div>
      ) : (
        <Survey model={survey} />
      )}
    </div>
  )
}

export default function IntakeForm(props) {
  return (
    <GraphQLProvider>
      <IntakeFormPage {...props} />
    </GraphQLProvider>
  )
}

import React, { createContext, useContext, useState } from "react"

import { createUrqlClient } from "../utils/utils"

import { useToast } from "./ToastContext"

const urqlClient = createUrqlClient({ requestPolicy: "network-only" })

const PROFILE_STATUS_QUERY = `
  query ProfileStatusData {
    profileStatusData {
      status
      completionSteps { name completed link }
      verificationSteps { name completed }
      practitionerFirstName
      profileUrl
      showCompletedModal
      showTermsModal
    }
  }
`

export const ProfileStatusContext = createContext()

export const ProfileStatusProvider = ({ children, data }) => {
  const [profileStatusData, setProfileStatusData] = useState(data)
  const { showToast } = useToast()

  const syncProfileStatus = () => {
    urqlClient
      .query(PROFILE_STATUS_QUERY)
      .toPromise()
      .then((res) => {
        if (res.data?.profileStatusData) {
          setProfileStatusData(res.data.profileStatusData)
        } else {
          showToast({
            type: "error",
            content: "There was an error syncing your profile status. Please refresh the page."
          })
          console.error("Error fetching profile status data")
        }
      })
  }

  return (
    <ProfileStatusContext.Provider value={{ profileStatusData, setProfileStatusData, syncProfileStatus }}>
      {children}
    </ProfileStatusContext.Provider>
  )
}

export const useProfileStatus = () => useContext(ProfileStatusContext)

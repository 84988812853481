import { gql } from "@urql/core"
import React, { useState, useEffect } from "react"
import { useMutation } from "urql"

import Breadcrumbs from "../../../components/shared/Breadcrumbs"
import { Button } from "../../../components/shared/Buttons"
import { Toggle } from "../../../components/shared/Inputs"
import LoadingSpinner from "../../../components/shared/LoadingSpinner"
import { AnimatedModal } from "../../../components/shared/Modal"
import Typography from "../../../components/shared/Typography"
import { ToastProvider, useToast } from "../../../contexts/ToastContext"
import GraphQLProvider from "../../../providers/GraphQLProvider"

const DISCONNECT_PAYPAL_MUTATION = gql`
  mutation DisconnectPaypalAccount {
    disconnectPaypalAccount {
      result
      errors
    }
  }
`

const UPDATE_PAYPAL_SETTINGS_MUTATION = gql`
  mutation UpdatePaypalSettings($paypalActive: Boolean!, $venmoActive: Boolean!) {
    updatePaypalSettings(paypalActive: $paypalActive, venmoActive: $venmoActive) {
      result
      errors
    }
  }
`

const CHECK_PAYPAL_SELLER_STATUS = gql`
  mutation CheckPaypalSellerStatus {
    checkPaypalSellerStatus {
      result
      errors
      status {
        isOnboardingComplete
        paymentsReceivable
        primaryEmailConfirmed
      }
    }
  }
`

const PaypalSettingsComponent = ({ paypalInfo }) => {
  const { showToast } = useToast()

  const [paypalConnected, setPaypalConnected] = useState(paypalInfo.paypalConnected)
  const [showDisconnectModal, setShowDisconnectModal] = useState(false)
  const [paypalActive, setPaypalActive] = useState(paypalInfo.paypalActive)
  const [venmoActive, setVenmoActive] = useState(paypalInfo.venmoActive)
  const [sellerStatus, setSellerStatus] = useState(paypalInfo.sellerStatus)

  const [, disconnectPaypal] = useMutation(DISCONNECT_PAYPAL_MUTATION)
  const [, updatePaypalSettings] = useMutation(UPDATE_PAYPAL_SETTINGS_MUTATION)
  const [{ fetching: fetchingCheckStatus }, checkStatus] = useMutation(CHECK_PAYPAL_SELLER_STATUS)

  const handleUpdateSettings = async (newPaypalActive, newVenmoActive) => {
    const { data } = await updatePaypalSettings({
      paypalActive: newPaypalActive,
      venmoActive: newVenmoActive
    })

    if (data?.updatePaypalSettings?.result === "success") {
      showToast("Settings updated successfully", "success")
      setPaypalActive(newPaypalActive)
      setVenmoActive(newVenmoActive)
    } else {
      showToast(data?.updatePaypalSettings?.errors?.[0] || "Failed to update settings", "error")
    }
  }

  const handleDisconnect = () => {
    disconnectPaypal().then((res) => {
      if (res.data?.disconnectPaypalAccount?.result === "success") {
        showToast("PayPal account disconnected successfully", "success")
        setPaypalConnected(false)
      } else {
        showToast(res.data?.disconnectPaypalAccount?.errors[0], "error")
      }
    })
    setShowDisconnectModal(false)
  }

  const checkSellerStatus = async () => {
    const { data } = await checkStatus()

    if (data?.checkPaypalSellerStatus?.result === "success") {
      setSellerStatus(data.checkPaypalSellerStatus.status)
    } else {
      showToast(data?.checkPaypalSellerStatus?.errors?.[0] || "Failed to check status", "error")
    }
  }

  useEffect(() => {
    if (paypalConnected && !sellerStatus?.isOnboardingComplete) {
      checkSellerStatus()
    }
  }, [paypalConnected])

  const renderOnboardingStatus = () => {
    if (!sellerStatus) return null

    return (
      <div className="mt-4 flex flex-col gap-4">
        {!sellerStatus.paymentsReceivable && (
          <div className="rounded-lg border border-red bg-red-light p-4">
            <Typography variant="body" className="text-red">
              Attention: You currently cannot receive payments due to restriction on your PayPal account. Please reach
              out to PayPal Customer Support or connect to{" "}
              <a target="_blank" rel="noopener noreferrer" className="underline" href="https://www.paypal.com">
                https://www.paypal.com
              </a>{" "}
              for more information.
            </Typography>
          </div>
        )}

        {!sellerStatus.primaryEmailConfirmed && (
          <div className="rounded-lg border border-red bg-red-light p-4">
            <Typography variant="body" className="text-red">
              Attention: Please confirm your email address on{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
                href="https://www.paypal.com/businessprofile/settings">
                https://www.paypal.com/businessprofile/settings
              </a>{" "}
              in order to receive payments! You currently cannot receive payments.
            </Typography>
          </div>
        )}

        <div className="flex flex-row items-center gap-2">
          <Button type="secondary" onClick={checkSellerStatus} disabled={fetchingCheckStatus} className="w-fit">
            Check Onboarding Status
          </Button>
          {fetchingCheckStatus && <LoadingSpinner className="ml-2 inline py-0" spinnerClassName="h-4 w-4" />}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Breadcrumbs
        links={[
          { href: "/portal/settings", text: "Settings" },
          { href: "/portal/settings/banking", text: "Payments" }
        ]}
        activePage="PayPal"
      />
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          PayPal
        </Typography>
      </div>
      <div className="flex w-1/2 flex-col gap-4 sm:w-full">
        <div className="flex flex-row justify-between rounded-xl border border-gray-light p-8">
          <div className="flex flex-col">
            <Typography variant="h5">{paypalConnected ? "PayPal Connected" : "Not Connected"}</Typography>
            <div className="flex flex-col gap-1">
              {paypalConnected ? (
                <div className="flex flex-col">
                  <Typography variant="body">Merchant ID: {paypalInfo.merchantId}</Typography>
                  <Typography variant="body">Clients will be able to pay using PayPal.</Typography>
                </div>
              ) : (
                <Typography variant="body">Connect your PayPal account to start accepting payments.</Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <a href="/portal/settings/banking/paypal/link">
              <Button type={paypalConnected ? "tertiary" : "primary"} className="w-full">
                {paypalConnected ? "Manage" : "Connect"}
              </Button>
            </a>
            {paypalConnected && (
              <Button type="warning" onClick={() => setShowDisconnectModal(true)}>
                Disconnect
              </Button>
            )}
          </div>
        </div>

        {paypalConnected && !sellerStatus?.isOnboardingComplete && renderOnboardingStatus()}

        {paypalConnected && sellerStatus?.isOnboardingComplete && (
          <>
            <div className="flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
              <div className="flex flex-col">
                <Typography variant="h5">Accept PayPal Payments</Typography>
                <Typography variant="body" className="text-gray-dark">
                  Allow clients to pay using PayPal
                </Typography>
                <Typography variant="body" className="mt-2 text-sm italic text-gray-dark">
                  This option only controls if it appears as an option for your clients at Heal.me, but you need to have
                  it enabled in your PayPal account.
                </Typography>
              </div>
              <Toggle checked={paypalActive} onChange={() => handleUpdateSettings(!paypalActive, venmoActive)} />
            </div>

            <div className="flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
              <div className="flex flex-col">
                <Typography variant="h5">Accept Venmo Payments</Typography>
                <Typography variant="body" className="text-gray-dark">
                  Allow clients to pay using Venmo
                </Typography>
                <Typography variant="body" className="mt-2 text-sm italic text-gray-dark">
                  This option only controls if it appears as an option for your clients at Heal.me, but you need to have
                  it enabled in your PayPal account.
                </Typography>
              </div>
              <Toggle checked={venmoActive} onChange={() => handleUpdateSettings(paypalActive, !venmoActive)} />
            </div>
          </>
        )}
      </div>

      <AnimatedModal
        visible={showDisconnectModal}
        hideModal={() => setShowDisconnectModal(false)}
        header="Disconnect PayPal"
        showFooter
        actionButtonCopy="Disconnect"
        actionButtonType="warning"
        onSave={handleDisconnect}>
        <div className="flex flex-col gap-4">
          <Typography variant="body">
            Disconnecting your PayPal account will prevent you from offering PayPal services and products on your
            website. Do you wish to continue?
          </Typography>
        </div>
      </AnimatedModal>
    </div>
  )
}

export default function PaypalSettings(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <PaypalSettingsComponent {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

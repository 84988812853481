import NiceModal from "@ebay/nice-modal-react"
import { DocumentTextIcon } from "@heroicons/react/24/outline"
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { Button, CloseButton } from "../../components/shared/Buttons"
import HealMeTooltip from "../../components/shared/HealMeTooltip"
import { Box, ProfilePageEmptyStateBox } from "../../components/shared/Layout"
import { UnderlinedLink } from "../../components/shared/Links"
import { NewModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { capitalize } from "../../utils/utils"

import CredentialsModal, { modalId } from "./CredentialsModal"
import ProfileEditProvider from "./ProfileEditProvider"

const REMOVE_CREDENTIAL_MUTATION = `
  mutation RemoveCredential($credentialId: ID!) {
    removeCredential(credentialId: $credentialId) {
      result
      errors
    }
  }
`

const REORDER_CREDENTIALS_MUTATION = `
  mutation ReorderCredentials($credentialArray: [String!]!) {
    reorderCredentials(credentialArray: $credentialArray) {
      result
      errors
    }
  }
`

const CredentialRow = ({ proCredential, deleteCredential }) => {
  const fallbackFilename = proCredential.createdAt
    ? `file_upload_${proCredential.createdAt.split("T")[0].replace(/-/g, "_")}`
    : "file_upload"
  const suffix =
    proCredential.state === "pending" ? <span className="flex-none text-gray-dark"> (Pending review)</span> : null

  return (
    <>
      <div className="flex items-center justify-between font-bold">
        <div className="truncate">
          {proCredential.credential?.name || proCredential.filestackDocumentName || fallbackFilename}
        </div>
        <CloseButton className="flex-none" onClick={() => deleteCredential(proCredential)} />
      </div>
      <Typography variant="small" as="div" className="w-11/12">
        {proCredential.licenseNumber ? (
          <>
            {proCredential.licenseNumber}
            {proCredential.stateIssued ? ` • ${capitalize(proCredential.stateIssued)}` : ""}
            {suffix}
          </>
        ) : (
          <div className="flex items-center gap-1">
            <DocumentTextIcon className="h-4 w-4 flex-none" />
            <span className="flex-1 truncate">{proCredential.filestackDocumentName || fallbackFilename}</span>
            {suffix}
          </div>
        )}
      </Typography>
    </>
  )
}

const Credentials = ({ proCredentials: proCredentialsProp }) => {
  const [proCredentials, setProCredentials] = useState(proCredentialsProp)
  const [credentialToDelete, setCredentialToDelete] = useState(null)

  const [, removeCredential] = useMutation(REMOVE_CREDENTIAL_MUTATION)
  const [, reorderCredentials] = useMutation(REORDER_CREDENTIALS_MUTATION)

  const { showToast } = useToast()

  const approvedCredentials = proCredentials.filter((proCredential) => proCredential.state === "approved")
  const pendingCredentials = proCredentials.filter((proCredential) => proCredential.state === "pending")
  const deleteCredentialModalId = "delete-credential-modal"

  const deleteCredential = (credential) => {
    showToast({
      type: "info",
      content: "Credential was deleted successfully."
    })
    setProCredentials((credentials) => credentials.filter((c) => c.id !== credential.id))
    NiceModal.remove(deleteCredentialModalId)

    removeCredential({ credentialId: credential.id }).then((result) => {
      if (result?.data?.removeCredential?.result !== "success") {
        console.error(result) // eslint-disable-line no-console
        showToast({
          type: "error",
          content:
            "There was an error removing your credential. Please try again or contact support if the error persists."
        })
      }
    })
  }

  const showDeleteModal = (proCredential) => {
    setCredentialToDelete(proCredential)
    NiceModal.show(deleteCredentialModalId)
  }

  return (
    <>
      <div className="w-[600px] md:w-full">
        <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
          <div className="flex flex-col">
            <Typography variant="h3" as="h1">
              Credentials
            </Typography>
            <Typography variant="subtitle">Add or edit your credentials.</Typography>
          </div>
        </div>
        {proCredentials.length === 0 ? (
          <ProfilePageEmptyStateBox title="Credentials" onButtonClick={() => NiceModal.show(modalId)}>
            Add any licenses, degrees, or certifications.{" "}
            <UnderlinedLink
              href="https://help.heal.me/en/articles/8440296-what-credentials-should-i-add-to-my-profile"
              target="_blank">
              Learn more
            </UnderlinedLink>
          </ProfilePageEmptyStateBox>
        ) : (
          <div data-test-id="credentials">
            <Box>
              <div className="flex items-center justify-between border-b border-gray-light pb-6 sm:border-none sm:pb-4">
                <Typography variant="capitalHeading">Approved</Typography>
                <div data-tooltip-id="disabled-tooltip">
                  <Button
                    floatingInMobile={true}
                    disabled={proCredentials.length >= 5}
                    onClick={() => NiceModal.show(modalId)}>
                    Add new
                  </Button>
                </div>
                {proCredentials.length >= 5 && (
                  <HealMeTooltip
                    id="disabled-tooltip"
                    place="bottom"
                    content="Note: You can add a maximum of 5 credentials."
                  />
                )}
              </div>
              <div className="pt-6 sm:pt-0">
                {approvedCredentials.length === 0 ? (
                  <Typography variant="subtitle">No approved credentials yet.</Typography>
                ) : (
                  <ReactSortable
                    list={approvedCredentials}
                    setList={(newPositions) => {
                      const newCredentials = newPositions.concat(pendingCredentials)
                      if (JSON.stringify(newCredentials) !== JSON.stringify(proCredentials)) {
                        const newOrder = newCredentials.map((proCredential, i) =>
                          JSON.stringify({ id: proCredential.id, position: i + 1 })
                        )
                        reorderCredentials({ credentialArray: newOrder }).then((result) => {
                          if (result?.data?.reorderCredentials?.result !== "success") {
                            console.error(result) // eslint-disable-line no-console
                            showToast({
                              type: "error",
                              content:
                                "There was error reordering your credentials. Please try again later or contact support if the error persists."
                            })
                          }
                        })
                        setProCredentials(newCredentials)
                      }
                    }}>
                    {approvedCredentials.map((proCredential, i) => (
                      <React.Fragment key={proCredential.id}>
                        <div className="flex cursor-grab items-center">
                          <div
                            className={twMerge(
                              "py-4",
                              i === approvedCredentials.length - 1 ? "pb-0" : i === 0 ? "pt-0" : ""
                            )}>
                            <EllipsisVerticalIcon className="mr-4 h-6 w-6 text-gray-dark" />
                          </div>
                          <div
                            className={twMerge(
                              "w-full border-b border-gray-light py-4",
                              i === approvedCredentials.length - 1 ? "border-none pb-0" : i === 0 ? "pt-0" : ""
                            )}>
                            <CredentialRow proCredential={proCredential} deleteCredential={showDeleteModal} />
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </ReactSortable>
                )}
              </div>
            </Box>
            {pendingCredentials.length > 0 && (
              <Box className="mt-8 flex flex-col gap-4">
                <Typography variant="capitalHeading">Pending</Typography>
                {pendingCredentials.map((proCredential) => (
                  <div key={proCredential.id} className="flex items-center">
                    <div className="w-full">
                      <CredentialRow proCredential={proCredential} deleteCredential={showDeleteModal} />
                    </div>
                  </div>
                ))}
              </Box>
            )}
          </div>
        )}
        <CredentialsModal setProCredentials={setProCredentials} />
        <NewModal
          id={deleteCredentialModalId}
          header="Delete credential"
          showFooter={true}
          onSave={() => {
            deleteCredential(credentialToDelete)
            setCredentialToDelete(null)
          }}
          cancelButtonCopy="No, don't delete"
          actionButtonType="destructive"
          actionButtonCopy="Yes, delete">
          <p>
            This will remove the credential <b>{credentialToDelete?.credential?.name}</b> from your profile.
          </p>
        </NewModal>
      </div>
    </>
  )
}

export default function ProfileEditCredentialsV2({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <Credentials {...props} />
    </ProfileEditProvider>
  )
}

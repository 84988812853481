import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../../components/shared/Buttons"
import { TextArea } from "../../../components/shared/Inputs"
import Typography from "../../../components/shared/Typography"
import { useToast } from "../../../contexts/ToastContext"
import { copyToClipboard } from "../../../utils/utils"

import ProCommunicationSettingsProvider from "./ProCommunicationSettingsProvider"
import ProCommunicationSettingsView from "./ProCommunicationSettingsView"

const UPDATE_COMMUNICATION_SETTINGS = `
  mutation UpdateCommunicationSettings(
    $emailTemplates: [EmailTemplateInput!]!
  ) {
    updateCommunicationSettings(
      emailTemplates: $emailTemplates
    ) {
      result
      error
    }
  }
`

const VariableDivider = () => <div className="mt-2 w-full border border-gray-light" />

const VariableRow = ({ name, exampleValue }) => {
  const { showToast } = useToast()

  const handleCopy = async (text) => {
    await copyToClipboard(text, showToast, "Copied")
  }

  return (
    <div className="mt-2 flex flex-row items-center justify-between">
      <div>
        <p className="font-normal text-gray-dark">{name}</p>
        <p className="mt-1 font-bold">{exampleValue}</p>
      </div>
      <DocumentDuplicateIcon
        className="h-7 w-7 flex-none cursor-pointer text-gray-dark"
        onClick={() => handleCopy(name)}
      />
    </div>
  )
}

const CommunicationTemplates = ({ emailTemplates, defaultEmailContent, practice, path }) => {
  const [, updateCommunicationSettings] = useMutation(UPDATE_COMMUNICATION_SETTINGS)
  const { showToast } = useToast()

  const initializeEmailTemplates = () =>
    defaultEmailContent.map((defaultEmailTemplate) => {
      const customEmailTemplate = emailTemplates.find((template) => template.name === defaultEmailTemplate.name)

      const content = customEmailTemplate?.customContent ?? defaultEmailTemplate.defaultContent

      return {
        name: defaultEmailTemplate.name,
        allowedVariables: defaultEmailTemplate.allowedVariables,
        content
      }
    })

  const originalEmailTemplates = initializeEmailTemplates()
  const [currentEmailTemplates, setCurrentEmailTemplates] = useState(originalEmailTemplates)

  const handleEmailTemplateUpdate = (emailName, content) => {
    setCurrentEmailTemplates((prevEmailTemplates) =>
      prevEmailTemplates.map((emailTemplate) => {
        if (emailTemplate.name === emailName) {
          return { ...emailTemplate, content }
        }
        return emailTemplate
      })
    )
  }

  const updatedEmailTemplates = currentEmailTemplates.filter((template) => {
    const originalTemplate = originalEmailTemplates.find((original) => original.name === template.name)
    return originalTemplate.content !== template.content
  })

  const emailTemplatesChanged = updatedEmailTemplates.length > 0

  const handleSave = () => {
    updateCommunicationSettings({ emailTemplates: updatedEmailTemplates })
      .then((result) => {
        if (result.data?.updateCommunicationSettings?.result) {
          showToast({ content: "Email template updated successfully.", timeout: 3000 })
        } else {
          const error =
            result.error?.message?.replace("[GraphQL]", "") ||
            result.data?.updateCommunicationSettings?.error ||
            "Please try again later."

          showToast({ type: "error", content: error })
        }
      })
      .catch((error) => {
        showToast({ type: "error", content: error.message })
      })
  }

  const handleCancel = () => {
    setCurrentEmailTemplates(originalEmailTemplates)
    showToast({ content: "Your changes have been discarded.", timeout: 3000 })
  }

  const confirmationEmail = currentEmailTemplates.find((template) => template.name === "appointment_confirmed")
  const reminderEmail = currentEmailTemplates.find((template) => template.name === "appointment_reminder")

  return (
    <ProCommunicationSettingsView path={path}>
      <div className="relative sm:pb-[64px]">
        <div className="absolute -top-28 z-10 flex w-full justify-end sm:fixed sm:bottom-[68px] sm:left-0 sm:top-[auto] sm:border-t sm:border-gray-light sm:bg-white sm:p-6">
          <Button className="mr-4" label="Cancel" type="tertiary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" disabled={!emailTemplatesChanged} onClick={handleSave}>
            Save
          </Button>
        </div>
        <div className="mb-5">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 ">
            <div className="flex flex-col">
              <div className="rounded-lg border border-gray p-8">
                <Typography variant="h5" as="h3">
                  Confirmations
                </Typography>
                <Typography variant="subtitle" className="mt-4">
                  You can add a custom message to the emails that are sent to clients when they book appointments.
                </Typography>
                <TextArea
                  className="mt-4 text-sm"
                  value={confirmationEmail.content}
                  key={confirmationEmail.name}
                  id="custom-email-content"
                  placeholder="Type in your message here..."
                  onChange={(event) => {
                    handleEmailTemplateUpdate(confirmationEmail.name, event.target.value)
                  }}
                />
              </div>
              <div className="mt-8 rounded-lg border border-gray p-8">
                <Typography variant="h5" as="h3">
                  Reminders
                </Typography>
                <Typography variant="subtitle" className="mt-4">
                  You can add a custom message to the emails that are sent to clients before their appointments.
                </Typography>
                <TextArea
                  className="mt-4 text-sm"
                  value={reminderEmail.content}
                  key={reminderEmail.name}
                  placeholder="Type in your message here..."
                  onChange={(event) => {
                    handleEmailTemplateUpdate(reminderEmail.name, event.target.value)
                  }}
                />
              </div>
            </div>
            <div className="rounded-lg bg-gray-ultralight p-8">
              <div className="grid grid-cols-2 gap-6 2xl:grid-cols-1">
                <div>
                  <p className="font-manrope text-[12px] font-bold uppercase tracking-wider text-gray-dark">
                    Client Variables
                  </p>
                  <VariableDivider />
                  <VariableRow exampleValue="Stove" name="{{client.first_name}}" />
                  <VariableDivider />
                  <VariableRow exampleValue="Doran" name="{{client.last_name}}" />
                  <VariableDivider />
                  <VariableRow exampleValue="Steve Doran" name="{{client.display_name}}" />
                  <VariableDivider />
                  <VariableRow exampleValue="the.stove_pipe@gmail.com" name="{{client.email}}" />
                  <VariableDivider />
                  <VariableRow exampleValue="(703) 123-4567" name="{{client.phone}}" />
                  <p className="mt-6 font-manrope text-[12px] font-bold uppercase tracking-wider text-gray-dark">
                    Appt. Variables
                  </p>
                  <VariableDivider />
                  <VariableRow exampleValue="August 10, 2023 @ 3:00pm" name="{{appointment.date_and_time}}" />
                  <VariableDivider />
                  <VariableRow exampleValue="Reiki for beginners" name="{{appointment.service_name}}" />
                </div>
                <div>
                  <p className="font-manrope text-[12px] font-bold uppercase tracking-wider text-gray-dark">
                    Practice Variables
                  </p>
                  <VariableDivider />
                  <VariableRow exampleValue={practice.firstName} name="{{practitioner.first_name}}" />
                  <VariableDivider />
                  <VariableRow exampleValue={practice.lastName} name="{{practitioner.last_name}}" />
                  <VariableDivider />
                  <VariableRow
                    exampleValue={practice.firstName + " " + practice.lastName}
                    name="{{practitioner.full_name}}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProCommunicationSettingsView>
  )
}

export default function ProCommunicationTemplates(props) {
  return (
    <ProCommunicationSettingsProvider>
      <CommunicationTemplates {...props} />
    </ProCommunicationSettingsProvider>
  )
}

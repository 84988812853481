import { TrashIcon } from "@heroicons/react/24/outline"
import { CalendarIcon } from "@heroicons/react/24/solid"
import capitalize from "lodash/capitalize"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import Breadcrumbs from "../../components/shared/Breadcrumbs"
import { InputWithLabel } from "../../components/shared/Inputs"
import { Box, Divider, Flyout } from "../../components/shared/Layout"
import { ButtonLink } from "../../components/shared/Links"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { ToastProvider, useToast } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

import CalIcon from "./ProCalendarSettings/CalIcon"
import ReadCalendarSettings from "./ProCalendarSettings/ReadCalendarSettings"
import WriteCalendarSettings from "./ProCalendarSettings/WriteCalendarSettings"

const REMOVE_CRONOFY_PROFILE_MUTATION = `
  mutation RemoveCronofyProfile($profileId: ID!) {
    removeCronofyProfile(profileId: $profileId) {
      result
      errors
    }
  }
`

const CREATE_CRONOFY_FEED_MUTATION = `
  mutation CreateCronofyFeed($feedUrl: String!) {
    createCronofyFeed(feedUrl: $feedUrl) {
      result
      errors
      feed {
        id providerName profileName profileId
        calendars { id providerName profileName calendarName active }
     }
    }
  }
`

const CalendarContainer = ({ imgSrc, name, copy, children, className }) => (
  <div className={twMerge("flex flex-col rounded-xl border border-gray-light p-8", className)}>
    <div className="mb-8 w-fit rounded-xl bg-gray-ultralight p-4">
      {imgSrc ? <img src={imgSrc} width="48px" /> : <CalendarIcon className="h-12 w-12" />}
    </div>
    <Typography variant="h5" className="mb-2">
      {name}
    </Typography>
    <Typography variant="body" className="mb-8 text-gray-dark">
      {copy}
    </Typography>
    {children}
  </div>
)

const ConfigurationButton = ({
  header,
  items,
  onClick,
  provider,
  fallbackCopy = "Do not check this calendar for conflicts"
}) => (
  <button
    className="flex w-full justify-between rounded-lg border border-gray p-4 text-sm hover:border-teal"
    onClick={onClick}>
    <div className="flex gap-4">
      <CalIcon provider={provider} />
      <div className="text-left">
        <div className="text-gray-dark">{header}</div>
        {items?.length > 0 ? (
          <ul>
            {items.map((item) => (
              <li key={item}>&bull; {item}</li>
            ))}
          </ul>
        ) : (
          <div>{fallbackCopy}</div>
        )}
      </div>
    </div>
    <div className="flex-none font-bold text-teal">Edit</div>
  </button>
)

const CalendarSettings = ({ authorizationUrl, connectedCalendars: calendars, writeCalendar: writeCal }) => {
  const [connectedCalendars, setConnectedCalendars] = useState(calendars)
  const [writeCalendar, setWriteCalendar] = useState(writeCal)
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)
  const [providerId, setProviderId] = useState(null)
  const [profileToDelete, setProfileToDelete] = useState(null)
  const [feedModalVisible, setFeedModalVisible] = useState(false)
  const [feedUrl, setFeedUrl] = useState("")
  const [feedSaving, setFeedSaving] = useState(false)

  const [, removeCronofyProfile] = useMutation(REMOVE_CRONOFY_PROFILE_MUTATION)
  const [, createCronofyFeed] = useMutation(CREATE_CRONOFY_FEED_MUTATION)
  const { showToast } = useToast()

  const selectedProvider =
    providerId === "write"
      ? null
      : connectedCalendars && connectedCalendars.find((provider) => provider.id === providerId)

  return (
    <div>
      <Breadcrumbs links={[{ href: "/portal/settings", text: "Settings" }]} activePage="Calendar Integrations" />
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          Calendar Integrations
        </Typography>
        <Typography variant="body" className="text-gray-dark sm:hidden">
          Sync your calendar across apps so wherever you work from, it happens here.
        </Typography>
      </div>
      <div>
        <>
          {connectedCalendars.length > 0 ? (
            <div className="w-full max-w-[750px]">
              <Box>
                <div className="mb-4 flex items-center justify-between text-gray-dark">
                  <h2 className="text-lg font-bold">My Calendar Accounts</h2>
                  <ButtonLink href={authorizationUrl} target="_blank">
                    Connect another calendar
                  </ButtonLink>
                </div>
                <Box className="p-4">
                  {connectedCalendars.map((provider, i) => (
                    <React.Fragment key={`provider-${provider.id}`}>
                      <div className="flex items-center justify-between gap-4">
                        <CalIcon
                          provider={provider.providerName}
                          className="h-10 w-10 flex-none border-none"
                          iconClassName="h-6 w-6"
                        />
                        <div className="w-full">
                          <h3 className="font-bold">{capitalize(provider.providerName)}</h3>
                          <div className="text-gray-dark">{provider.profileName}</div>
                        </div>
                        <button
                          className="flex h-8 w-8 flex-none items-center justify-center rounded text-gray-dark hover:bg-gray-light focus:outline-none focus:ring-2 focus:ring-blue active:bg-gray"
                          onClick={() => setProfileToDelete(provider.id)}>
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </div>
                      {i < connectedCalendars.length - 1 && <Divider className="-mx-4 my-4 box-content px-4" />}
                    </React.Fragment>
                  ))}
                </Box>
                <div className="mt-4 flex justify-end">
                  <button className="text-sm text-gray-dark" onClick={() => setFeedModalVisible(true)}>
                    Connect an iCal feed
                  </button>
                </div>
                <AnimatedModal
                  visible={feedModalVisible}
                  hideModal={() => setFeedModalVisible(false)}
                  header="Connect an iCal feed"
                  showFooter={true}
                  saveDisabled={feedSaving}
                  onSave={() => {
                    setFeedSaving(true)
                    createCronofyFeed({ feedUrl }).then((result) => {
                      if (result.data?.createCronofyFeed?.result === "success") {
                        showToast("Your feed has been added successfully")

                        if (
                          !connectedCalendars
                            .map((cal) => cal.id)
                            .includes(result.data.createCronofyFeed.feed.profileId)
                        ) {
                          setConnectedCalendars((prevCalendars) => [
                            ...prevCalendars,
                            result.data.createCronofyFeed.feed
                          ])
                        }
                      } else {
                        console.error(result)
                        showToast({
                          content:
                            "There was an error adding your feed. Likely the URL you used in not a valid iCal feed URL.",
                          type: "error"
                        })
                      }

                      setFeedSaving(false)
                      setFeedModalVisible(false)
                    })
                  }}>
                  <InputWithLabel
                    label="Feed URL"
                    value={feedUrl}
                    onChange={(e) => setFeedUrl(e.target.value)}
                    placeholder="Paste your iCal feed URL"
                  />
                </AnimatedModal>
              </Box>
              <Box className="mt-8">
                <h2 className="text-lg font-bold">Configuration</h2>
                <Divider />
                <div className="flex gap-4">
                  <div className="flex-none">
                    <CalendarIcon className="h-10 w-10" />
                  </div>
                  <div className="w-1/3">
                    <div className="font-bold">Check for conflicts</div>
                    <div className="text-sm text-gray-dark">
                      Set the calendar(s) to check for conflicts to prevent double bookings
                    </div>
                  </div>
                  <div className="flex w-2/3 flex-col gap-4">
                    {connectedCalendars.map((provider) => (
                      <ConfigurationButton
                        key={`check-button-${provider.id}`}
                        provider={provider.providerName}
                        className="w-full rounded-lg border border-gray p-4 text-sm hover:border-teal"
                        header={`Check ${provider.profileName}`}
                        items={provider.calendars.filter((cal) => cal.active).map((cal) => cal.calendarName)}
                        onClick={() => {
                          setProviderId(provider.id)
                          setIsFlyoutOpen(true)
                        }}
                      />
                    ))}
                  </div>
                </div>
                <Divider />
                <div className="flex gap-4">
                  <div className="flex-none">
                    <CalendarIcon className="h-10 w-10" />
                  </div>
                  <div className="w-1/3">
                    <div className="font-bold">Add to calendar</div>
                    <div className="text-sm text-gray-dark">
                      Set the calendar you would like to add new events to as they’re scheduled.
                    </div>
                  </div>
                  <div className="flex w-2/3 flex-col gap-4">
                    <ConfigurationButton
                      provider={writeCalendar?.providerName}
                      header={writeCalendar ? `Add to ${writeCalendar.profileName}` : "CALENDAR NOT SELECTED"}
                      fallbackCopy="Do not add new events to any calendar"
                      items={writeCalendar ? [writeCalendar.calendarName] : null}
                      onClick={() => {
                        setProviderId("write")
                        setIsFlyoutOpen(true)
                      }}
                    />
                  </div>
                </div>
              </Box>
              <Flyout
                header={
                  providerId === "write"
                    ? "Which calendar should we add new events to?"
                    : "How should we check for conflicts?"
                }
                visible={isFlyoutOpen}
                closeFlyout={() => setIsFlyoutOpen(false)}>
                {selectedProvider && (
                  <ReadCalendarSettings
                    selectedProvider={selectedProvider}
                    key={selectedProvider?.id}
                    setConnectedCalendars={setConnectedCalendars}
                    onSave={(newCalendars) => {
                      setConnectedCalendars((prevCalendars) =>
                        prevCalendars.map((provider) =>
                          provider.id === selectedProvider.id ? { ...provider, calendars: newCalendars } : provider
                        )
                      )
                      setIsFlyoutOpen(false)
                    }}
                  />
                )}
                {providerId === "write" && (
                  <WriteCalendarSettings
                    connectedCalendars={connectedCalendars}
                    writeCalendar={writeCalendar}
                    setWriteCalendar={setWriteCalendar}
                    onSave={() => setIsFlyoutOpen(false)}
                  />
                )}
              </Flyout>
              <AnimatedModal
                header="Disconnect calendar"
                visible={!!profileToDelete}
                hideModal={() => setProfileToDelete(null)}
                actionButtonCopy="Yes, disconnect"
                actionButtonType="destructive"
                showFooter={true}
                onSave={() => {
                  removeCronofyProfile({ profileId: profileToDelete }).then((result) => {
                    if (result.data?.removeCronofyProfile?.result === "success") {
                      showToast("Your calendar has been removed successfully")
                      setConnectedCalendars((prevCalendars) =>
                        prevCalendars.filter((cal) => cal.id !== profileToDelete)
                      )
                    } else {
                      console.error(result) // eslint-disable-line no-console
                      showToast({ content: "There was an error removing your calendar", type: "error" })
                    }
                    setProfileToDelete(null)
                  })
                }}>
                <p>Are you sure you want to disconnect this calendar?</p>
              </AnimatedModal>
            </div>
          ) : (
            <CalendarContainer
              className="w-fit"
              name="Sync Heal.me with your existing calendar"
              copy="Supports Google, iCloud, Outlook, Office 365, and Exchange">
              <ButtonLink href={authorizationUrl} target="_blank">
                Connect calendar
              </ButtonLink>
            </CalendarContainer>
          )}
        </>
      </div>
    </div>
  )
}

export default function ProCalendarSettings(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <CalendarSettings {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

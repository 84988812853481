import React, { useState } from "react"

export default function CopyPaste({ field, reviewInput, errorMessage }) {
  const [localField, setLocalField] = useState(field)
  const invitationType = "copy_paste"

  const handleChange = (e) => {
    setLocalField(e.target.value)
  }

  return (
    <div className="columns is-centered">
      <div className="column is-four-fifths">
        <div className="copy-paste-headline">Copy and paste contact information</div>
        <div>
          {localField.length ? (
            <div className="copy-paste-placeholder">
              Email &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;First Name&emsp;Last Name&emsp;Phone
              Number&#10;
            </div>
          ) : (
            <div className="copy-paste-placeholder">
              Paste the contact information into this field (comma separated)
            </div>
          )}
        </div>
        <div>
          <textarea
            type="textarea"
            rows="10"
            className="textarea"
            value={localField}
            onChange={(e) => handleChange(e)}
            placeholder="Email &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;First Name&emsp;Last Name&emsp;Phone Number&#10;email@example.com&emsp;&emsp;John&emsp;&emsp;&emsp;&emsp;Doe&emsp;&emsp;&emsp;&emsp;1234567890&#10;bloggs_j@email.com&emsp;&emsp;Jane&emsp;&emsp;&emsp;&emsp;Bloggs&emsp;&emsp;&emsp;0987654321"></textarea>
        </div>
        <button
          className="button btn-primary"
          style={
            localField.length
              ? { marginTop: "32px", width: "100%" }
              : { pointerEvents: "none", opacity: "0.5", marginTop: "32px", width: "100%" }
          }
          onClick={() => reviewInput(localField, invitationType)}>
          Continue
        </button>
        <div className="has-text-centered" style={{ color: "red", marginTop: "12px" }}>
          {errorMessage}
        </div>
      </div>
    </div>
  )
}

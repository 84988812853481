import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid"
import dayjs from "dayjs"
import React from "react"

import { Button } from "../../components/shared/Buttons"
import DatePicker from "../../components/shared/DatePicker"
import { CheckBox, Label, Select, TimePicker } from "../../components/shared/Inputs"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useCalendarFlyout } from "../../contexts/CalendarFlyoutContext"
import { useToast } from "../../contexts/ToastContext"
import { useBookableEventTypes } from "../BookableEventTypes/BookableEventTypesContext"

import { useBookableEvents } from "./BookableEventsContext"

const BookableEventForm = ({ onBookableEventChanged, onDeleteBookableEvent }) => {
  const {
    bookableEvent,
    setBookableEvent,
    getDefaultNewEvent,
    fetching,
    deleteBookableEvent,
    deleteModalVisible,
    setDeleteModalVisible,
    upsertBookableEvent
  } = useBookableEvents()
  const { bookableEventTypes, fetching: fetchingEventTypes } = useBookableEventTypes()
  const { showToast } = useToast()
  const { setShowFlyout } = useCalendarFlyout()

  const onSave = () => {
    upsertBookableEvent({ ...bookableEvent }).then((result) => {
      if (result?.data?.upsertBookableEvent?.result === "success") {
        const newEvent = result.data.upsertBookableEvent.bookableEvent
        onBookableEventChanged(newEvent)
        setBookableEvent(newEvent)
        setShowFlyout(false)
        showToast(`Your event was saved successfully`)
      } else {
        console.error(result)
        let errorMessage = "There was an error saving your event"
        if (result.data?.upsertBookableEvent?.errors) errorMessage += `: ${result.data.upsertBookableEvent.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  const handleEventTypeChange = (eventTypeId) => {
    if (eventTypeId) {
      setBookableEvent((prev) => ({
        ...prev,
        bookableEventTypeId: eventTypeId
      }))
    } else {
      setBookableEvent((prev) => ({ ...prev, bookableEventTypeId: null }))
    }
  }

  const editingExistingEvent = !!bookableEvent.id
  const selectedEventType = bookableEventTypes?.find(
    (et) => et.id.toString() === bookableEvent.bookableEventTypeId?.toString()
  )
  const saveEnabled = !fetching && bookableEvent.bookableEventTypeId
  const isLoading = fetching || fetchingEventTypes

  console.log("selectedEventType", selectedEventType)

  if (isLoading) {
    return <LoadingSpinner className="py-12" spinnerClassName="text-teal h-12 w-12" />
  }

  return (
    <>
      <div className="mb-4 flex flex-col">
        {bookableEventTypes && bookableEventTypes.length > 0 ? (
          <div className="mb-4">
            <Label htmlFor="bookable-event-type">Event Type</Label>
            <Select
              id="bookable-event-type"
              disabled={!!bookableEvent.id}
              value={bookableEvent.bookableEventTypeId || ""}
              onChange={(e) => handleEventTypeChange(e.target.value)}>
              <option value="">Select an event type</option>
              {bookableEventTypes.map((eventType) => (
                <option key={eventType.id} value={eventType.id}>
                  {eventType.name}
                </option>
              ))}
            </Select>
          </div>
        ) : (
          <Typography variant="body">
            No event types available. Please{" "}
            <a href="/portal/services#events" className="text-teal hover:underline">
              create an event type in the Services Page
            </a>
            .
          </Typography>
        )}

        {selectedEventType && (
          <>
            <div className="mt-4">
              <Typography variant="body" className="font-medium">
                Event Details
              </Typography>
              <div className="mt-2 rounded bg-gray-light p-3">
                <Typography variant="body">
                  <span className="font-medium">Name:</span> {selectedEventType.name}
                </Typography>
                {selectedEventType.description && (
                  <Typography variant="body" className="mt-1">
                    <span className="font-medium">Description:</span> {selectedEventType.description}
                  </Typography>
                )}
                <Typography variant="body" className="mt-1">
                  <span className="font-medium">Location:</span> {selectedEventType.location || "Virtual"}
                </Typography>
                {selectedEventType.amountCents > 0 && (
                  <Typography variant="body" className="mt-1">
                    <span className="font-medium">Price:</span> ${(selectedEventType.amountCents / 100).toFixed(2)}
                  </Typography>
                )}
                {selectedEventType.capacity && (
                  <Typography variant="body" className="mt-1">
                    <span className="font-medium">Capacity:</span> {selectedEventType.capacity}
                  </Typography>
                )}
                {selectedEventType.url && (
                  <Typography variant="body" className="mt-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Link to book:</span>{" "}
                      <a href={selectedEventType.url} target="_blank" rel="noopener noreferrer">
                        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                      </a>
                    </div>
                  </Typography>
                )}
              </div>
            </div>

            <div className="mt-2">
              <CheckBox
                id="all-day-checkbox"
                label="All-Day"
                name="All-Day"
                checked={bookableEvent.allDay}
                onChange={() => {
                  if (!bookableEvent.allDay) {
                    const startsAt = dayjs(bookableEvent.startsAt)
                      .tz(bookableEvent.timeZone)
                      .startOf("day")
                      .toISOString()
                    const endsAt = dayjs(bookableEvent.endsAt).tz(bookableEvent.timeZone).endOf("day").toISOString()
                    setBookableEvent((event) => ({ ...event, allDay: !event.allDay, startsAt, endsAt }))
                  } else {
                    setBookableEvent((event) => ({
                      ...event,
                      allDay: !event.allDay,
                      startsAt: getDefaultNewEvent().startsAt,
                      endsAt: getDefaultNewEvent().endsAt
                    }))
                  }
                }}
              />
            </div>
          </>
        )}
      </div>

      {selectedEventType && bookableEvent.timeZone && (
        <>
          <div className="flex flex-row justify-between gap-4">
            <div className="mb-4 flex w-1/2 flex-col">
              <Label>Start date</Label>
              <DatePicker
                className="w-full"
                selected={new Date(dayjs(bookableEvent.startsAt).tz(bookableEvent.timeZone))}
                onChange={(date) =>
                  setBookableEvent((event) => {
                    const day = dayjs(date)
                    const endsAt = day.add(1, "hour").toISOString()
                    return { ...event, startsAt: date, endsAt }
                  })
                }
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <Label>End date</Label>
              <DatePicker
                className="w-full"
                selected={new Date(dayjs(bookableEvent.endsAt).tz(bookableEvent.timeZone))}
                onChange={(date) => setBookableEvent((event) => ({ ...event, endsAt: date }))}
              />
            </div>
          </div>
          {!bookableEvent.allDay && (
            <div className="flex flex-row justify-between gap-4">
              <div className="w-1/2">
                <Label htmlFor="bookable-event-start-time">Start time</Label>
                <TimePicker
                  id="bookable-event-start-time"
                  value={dayjs(bookableEvent.startsAt).tz(bookableEvent.timeZone).format("HH:mm")}
                  valueFormat="HH:mm"
                  showFifteenIncrements={true}
                  onChange={(e) => {
                    const [hour, minute] = e.target.value.split(":")
                    const duration = dayjs(bookableEvent.endsAt).diff(dayjs(bookableEvent.startsAt), "minute")
                    setBookableEvent((event) => ({
                      ...event,
                      startsAt: dayjs(event.startsAt).set("hour", hour).set("minute", minute).toDate(),
                      endsAt: dayjs(event.startsAt)
                        .set("hour", hour)
                        .set("minute", minute)
                        .add(duration, "minute")
                        .toDate()
                    }))
                  }}
                />
              </div>
              <div className="w-1/2">
                <Label htmlFor="bookable-event-end-time">End time</Label>
                <TimePicker
                  id="bookable-event-end-time"
                  value={dayjs(bookableEvent.endsAt).tz(bookableEvent.timeZone).format("HH:mm")}
                  valueFormat="HH:mm"
                  showFifteenIncrements={true}
                  onChange={(e) => {
                    const [hour, minute] = e.target.value.split(":")
                    setBookableEvent((event) => ({
                      ...event,
                      endsAt: dayjs(event.endsAt).set("hour", hour).set("minute", minute).toDate()
                    }))
                  }}
                />
              </div>
            </div>
          )}
          <div className="mt-4">
            <Label htmlFor="time-zone">Time zone</Label>
            <Select
              value={bookableEvent.timeZone}
              onChange={(e) =>
                setBookableEvent((event) => {
                  const startsAt = dayjs(event.startsAt)
                    .tz(e.target.value)
                    .set("hour", dayjs(event.startsAt).tz(event.timeZone).get("hour"))
                    .set("minute", dayjs(event.startsAt).tz(event.timeZone).get("minute"))
                    .toISOString()
                  const endsAt = dayjs(event.endsAt)
                    .tz(e.target.value)
                    .set("hour", dayjs(event.endsAt).tz(event.timeZone).get("hour"))
                    .set("minute", dayjs(event.endsAt).tz(event.timeZone).get("minute"))
                    .toISOString()
                  return {
                    ...event,
                    timeZone: e.target.value,
                    startsAt,
                    endsAt
                  }
                })
              }
              id="time-zone">
              {Intl.supportedValuesOf("timeZone").map((tz) => (
                <option key={tz} value={tz}>
                  {tz}
                </option>
              ))}
            </Select>
          </div>
        </>
      )}

      <div className="mt-8 flex justify-between">
        {editingExistingEvent && (
          <Button onClick={() => setDeleteModalVisible(true)} type="warning" size="small">
            Delete
          </Button>
        )}
        <Button disabled={!saveEnabled} onClick={onSave} type="primary" size="small">
          Save
        </Button>
      </div>

      {/* Delete Modal */}
      <AnimatedModal
        header="Delete event"
        visible={deleteModalVisible}
        hideModal={() => setDeleteModalVisible(false)}
        actionButtonCopy="Yes, delete"
        actionButtonType="destructive"
        saveDisabled={fetching}
        onSave={() => {
          deleteBookableEvent({ id: bookableEvent.id }).then((result) => {
            if (result?.data?.deleteBookableEvent?.result === "success") {
              if (onDeleteBookableEvent) onDeleteBookableEvent(bookableEvent)

              setDeleteModalVisible(false)
              setShowFlyout(false)
              showToast(`Your event was deleted successfully`)
            } else {
              console.error(result)
              let errorMessage = "There was an error deleting your event"
              if (result.data?.deleteBookableEvent?.errors)
                errorMessage += `: ${result.data.deleteBookableEvent.errors}`
              showToast({ type: "error", content: errorMessage })
            }
          })
        }}
        showFooter={true}>
        <p>Are you sure you want to delete this event?</p>
      </AnimatedModal>
    </>
  )
}

export default BookableEventForm

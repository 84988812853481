import React, { createContext, useContext, useState } from "react"

export const CalendarFlyoutContext = createContext()

// Used on ProCalendar, PaymentDirectory, BookableEvents
export const CalendarFlyoutProvider = ({ children }) => {
  const [showFlyout, setShowFlyout] = useState(false)
  const [eventType, setEventType] = useState("client")

  const value = {
    showFlyout,
    setShowFlyout,
    eventType,
    setEventType
  }

  return <CalendarFlyoutContext.Provider value={value}>{children}</CalendarFlyoutContext.Provider>
}

export const useCalendarFlyout = () => useContext(CalendarFlyoutContext)

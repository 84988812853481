import React from "react"
import { useMutation } from "urql"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { useToast } from "../../../../contexts/ToastContext"
import { Button } from "../../../shared/Buttons"
import EditAppointmentDateTime from "../EditAppointmentDateTime"
import EditAppointmentService from "../EditAppointmentService"

import LocationSelect from "./LocationSelect"

const RESEND_PRO_APPOINTMENT_CONFIRMATION = `
  mutation ResendProAppointmentConfirmation($appointmentId: ID!, $sendTo: String!) {
    resendProAppointmentConfirmation(appointmentId: $appointmentId, sendTo: $sendTo) {
      result
      errors
    }
  }
`

const AppointmentDetails = () => {
  const { appointment } = useManualBooking()
  const { showToast } = useToast()

  const [, resendProAppointmentConfirmation] = useMutation(RESEND_PRO_APPOINTMENT_CONFIRMATION)
  const resendProAppointmentConfirmationHandler = ({ appointmentId, sendTo }) => {
    resendProAppointmentConfirmation({ appointmentId, sendTo }).then((result) => {
      if (result.data.resendProAppointmentConfirmation.result === "success") {
        showToast("Confirmation resent!")
      } else {
        showToast({
          content: `Error resending confirmation: ${result.data.resendProAppointmentConfirmation.errors.join()}`,
          type: "error"
        })
      }
    })
  }
  const upcoming = new Date(appointment?.startsAt) >= new Date()

  return (
    <>
      <EditAppointmentDateTime />

      <LocationSelect />

      <EditAppointmentService />

      {appointment && upcoming && (
        <>
          <div className="mt-8 flex gap-2">
            <Button
              type="secondary"
              className="w-full"
              onClick={() =>
                resendProAppointmentConfirmationHandler({
                  appointmentId: appointment.id,
                  sendTo: "email"
                })
              }>
              Resend Email
            </Button>
            <Button
              type="secondary"
              className="w-full"
              onClick={() =>
                resendProAppointmentConfirmationHandler({
                  appointmentId: appointment.id,
                  sendTo: "sms"
                })
              }>
              Resend Text
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default AppointmentDetails

import NiceModal from "@ebay/nice-modal-react"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { TextArea } from "../../components/shared/Inputs"
import { NewModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import ReviewProvider from "../../providers/ReviewProvider"

import { ReviewLayout } from "./ReviewLayout"

const REVIEW_TEXT_REVIEW_MUTATION = `
  mutation ReviewAddTextReview($reviewId: ID!, $text: String!) {
    reviewAddTextReview(reviewId: $reviewId, text: $text) {
      result
      errors
    }
  }
`

const FEATURE_REQUEST_MUTATION = `
  mutation FeatureRequest($name: String!, $userId: ID, $clientId: ID) {
    featureRequest(name: $name, userId: $userId, clientId: $clientId) {
      result
      errors
    }
  }
`

const TextReview = ({ review }) => {
  const { showToast } = useToast()
  const [textReview, setTextReview] = useState(review?.review || "")
  const [showVideo, setShowVideo] = useState(true)
  const [, addTextReview] = useMutation(REVIEW_TEXT_REVIEW_MUTATION)
  const [, featureRequest] = useMutation(FEATURE_REQUEST_MUTATION)

  const handleAddTextReview = () => {
    addTextReview({ reviewId: review.token, text: textReview }).then((res) => {
      if (res.data.reviewAddTextReview.result === "success") {
        window.location.href = `/i/r/book_next/${review.token}`
      } else {
        showToast({ content: `Error creating client: ${res.data.reviewAddTextReview.errors.join(" ")}`, type: "error" })
      }
    })
  }

  const isFormValid = textReview?.length > 2

  return (
    <ReviewLayout
      percentDone="90%"
      proPic={review.practitioner.filestackPhoto}
      onContinue={handleAddTextReview}
      continueDisabled={!isFormValid}
      finalStep={true}>
      <Typography variant="h3">
        {review.rating > 3 && "Wonderful!"} Do you want to leave a text or video review now?
      </Typography>
      <Typography variant="subtitle">Share a few words about working with {review.practitioner.firstName}.</Typography>
      <TextArea
        placeholder="Enter your review"
        rows="3"
        className="mt-6"
        onChange={(e) => setTextReview(e.target.value)}
        value={textReview ? textReview : ""}
      />

      {showVideo && (
        <Button
          className="mt-6 w-full"
          type="tertiary"
          onClick={() => {
            NiceModal.show("videoReviewModal")
            featureRequest({ name: "VideoReviews", clientId: review.clientId }).then((res) => {
              if (res.data.featureRequest.result === "success") {
                console.log("Feature requested successfully")
              } else {
                console.error(`Could not save feature request: ${res.data.featureRequest.errors.join(" ")}`)
              }
            })
          }}>
          I&apos;d rather leave a video review
        </Button>
      )}
      <NewModal
        id="videoReviewModal"
        header=""
        showFooter={false}
        hideModal={() => {
          setShowVideo(false)
          NiceModal.hide("videoReviewModal")
        }}>
        <div className="p-6 text-center">
          <Typography variant="h2" as="p">
            🚀
          </Typography>
          <Typography variant="h3" className="mt-2">
            Thank you for your interest!
          </Typography>
          <Typography variant="subtitle" className="mx-auto mt-2 max-w-xs">
            We’re not quite ready yet for video reviews, but we’re excited for it.
          </Typography>
          <Button
            type="primary"
            size="large"
            className="mt-4"
            onClick={() => {
              setShowVideo(false)
              NiceModal.hide("videoReviewModal")
            }}>
            Got it, hide this
          </Button>
        </div>
      </NewModal>
    </ReviewLayout>
  )
}

export default function ReviewTextReview(props) {
  return (
    <ReviewProvider>
      <TextReview {...props} />
    </ReviewProvider>
  )
}

import React, { useState } from "react"
import { useMutation } from "urql"

import { EditableValue, ShortUrlInput } from "../../components/shared/Inputs"
import { Divider } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import CustomDomainSection from "../ProfileEdit/CustomDomainSection"

const UPDATE_SHORT_LINK_MUTATION = `
  mutation UpdateShortLink($shortLink: String!) {
    updateShortLink(shortLink: $shortLink) {
      result
      errors
    }
  }
`

const WebsiteDomain = ({ shortLink, customDomain, customDomainEnabled, website }) => {
  const [shortLinkValue, setShortLinkValue] = useState(shortLink)
  const [lastSavedShortLink, setLastSavedShortLink] = useState(shortLink)
  const [customDomainValue, setCustomDomainValue] = useState(customDomain)

  const [{ fetching }, updateShortLink] = useMutation(UPDATE_SHORT_LINK_MUTATION)

  const { showToast } = useToast()

  const onSave = () => {
    updateShortLink({ shortLink: shortLinkValue }).then((result) => {
      if (result?.data?.updateShortLink?.result === "success") {
        setLastSavedShortLink(shortLinkValue)
        showToast("Short link updated successfully")
      } else {
        console.error(result) // eslint-disable-line no-console
        setShortLinkValue(lastSavedShortLink)
        const errorMessage = result?.data?.updateShortLink?.errors?.join(" ") || "An error occurred"
        showToast({
          content: errorMessage,
          type: "error"
        })
      }
    })
  }

  return (
    <>
      <Typography as="h2" variant="h4" className="mb-4">
        Shortlink and custom domain
      </Typography>
      <div className="flex w-max flex-col items-start justify-start gap-6 rounded-xl border border-gray-light p-8 md:w-full">
        <EditableValue
          name="Heal.me domain (heal.me/link)"
          hint="Set your short profile link so it's easy to share your profile."
          value={shortLinkValue ? `https://heal.me/${shortLinkValue}` : null}
          saveDisabled={shortLinkValue?.length < 1 || shortLinkValue === lastSavedShortLink}
          disabled={fetching}
          onSave={onSave}
          onCancel={() => setShortLinkValue(lastSavedShortLink)}
          className={"w-full"}>
          <>
            <ShortUrlInput
              name="Short link"
              value={shortLinkValue || ""}
              onChange={(e) => {
                const newValue = e.target.value.toLowerCase().replace(/[^a-z0-9_-]/g, "")
                setShortLinkValue(newValue)
              }}
            />
            <Typography variant="micro">Only lowercase letters, numbers, hyphen and underscore allowed</Typography>
          </>
        </EditableValue>

        {customDomainEnabled && (
          <>
            <Divider className="my-2" />
            <CustomDomainSection
              customDomain={customDomainValue}
              setDetailsValues={setCustomDomainValue}
              website={website}
            />
          </>
        )}
      </div>
    </>
  )
}

export default WebsiteDomain

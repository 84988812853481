import NiceModal from "@ebay/nice-modal-react"
import React, { useState } from "react"
import { useMutation } from "urql"

import { FilePickerDropArea } from "../../components/shared/FileStackWidgets"
import { InputWithLabel, Label, Select } from "../../components/shared/Inputs"
import { UnderlinedLink } from "../../components/shared/Links"
import { NewModal } from "../../components/shared/Modal"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import { AlgoliaSearchBox } from "../../components/shared/SearchInputs"
import Typography from "../../components/shared/Typography"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"
import { useToast } from "../../contexts/ToastContext"

const ADD_CREDENTIAL_MUTATION = `
  mutation CreateProCredential($credentialId: ID, $issuerId: ID, $stateIssued: String, $licenseNumber: String, $filestackDocument: String, $filestackDocumentName: String) {
    createProCredential(credentialId: $credentialId, issuerId: $issuerId, stateIssued: $stateIssued, licenseNumber: $licenseNumber, filestackDocument: $filestackDocument, filestackDocumentName: $filestackDocumentName) {
      result
      proCredential { id kind stateIssued licenseNumber filestackDocument filestackDocumentName state credential { id name } }
      errors
    }
  }
`

export const modalId = "credentials-modal"

export default function CredentialsModal({ setProCredentials }) {
  const { syncProfileStatus } = useProfileStatus()
  const [selectedCredentialType, setSelectedCredentialType] = useState("license")
  const [selectedCredential, setSelectedCredential] = useState(null)
  const [selectedIssuer, setSelectedIssuer] = useState(null)
  const [licenseNumber, setLicenseNumber] = useState("")
  const [issuingState, setIssuingState] = useState("")

  const { showToast } = useToast()
  const [, addCredential] = useMutation(ADD_CREDENTIAL_MUTATION)

  const saveEnabled = !!(selectedCredential && selectedIssuer && licenseNumber && issuingState)

  const handleCredentialChange = (e) => {
    setSelectedCredentialType(e.target.value)
  }

  const onMutationComplete = (result) => {
    if (result?.data?.createProCredential?.result === "success") {
      setProCredentials((credentials) => [...credentials, result.data.createProCredential.proCredential])
      showToast({
        content: (
          <>
            <b>Nice work!</b> We’ll email you after our review, but let’s keep going now.
          </>
        )
      })
      syncProfileStatus()
      setSelectedCredentialType("license")
      setSelectedCredential(null)
      setSelectedIssuer(null)
      setLicenseNumber("")
      setIssuingState("")
      NiceModal.remove(modalId)
    } else {
      console.error(result)
      let errorMessage = "There was an error submitting your credential. Please try again later or contact support."
      if (result.data?.createProCredential?.errors) errorMessage += ` ${result.data.createProCredential.errors}`
      showToast({
        type: "error",
        content: errorMessage
      })
    }
  }

  return (
    <NewModal
      id={modalId}
      header="Add credential"
      showFooter={true}
      fullHeightInMobile={true}
      saveDisabled={!saveEnabled}
      hideSaveButton={selectedCredentialType === "certification"}
      onSave={() => {
        addCredential({
          credentialId: selectedCredential.id,
          issuerId: selectedIssuer?.id,
          licenseNumber: licenseNumber,
          stateIssued: issuingState
        }).then(onMutationComplete)
      }}>
      <div className="flex flex-col gap-4">
        <Typography variant="subtitle">
          <b>Reminder:</b> You only get 5 credentials, so we suggest using the best ones that potential clients will
          care about.{" "}
          <UnderlinedLink
            href="https://help.heal.me/en/articles/8440296-what-credentials-should-i-add-to-my-profile"
            target="_blank">
            Learn more
          </UnderlinedLink>
        </Typography>
        <div className="flex gap-4">
          <RadioWithLabel
            id="license-radio"
            value="license"
            name="credential"
            label="License"
            checked={selectedCredentialType === "license"}
            onChange={handleCredentialChange}
          />
          <RadioWithLabel
            id="certification-radio"
            value="certification"
            name="credential"
            label="Degree/Certification"
            checked={selectedCredentialType === "certification"}
            onChange={handleCredentialChange}
          />
        </div>
        <div>
          {selectedCredentialType === "license" ? (
            <>
              <AlgoliaSearchBox
                indexName="Credential"
                label="Name"
                filters="kind:license"
                explanatorySubtext="Ex: Licensed Massage Therapist"
                placeholder="Start typing credential name"
                onChange={(hit) => setSelectedCredential({ id: hit.objectID, name: hit.name, kind: hit.kind })}
              />
              {selectedCredential && (
                <div className="mt-2">
                  <AlgoliaSearchBox
                    indexName="Issuer"
                    label="Issuer"
                    filters="kind:license"
                    placeholder="Ex: Department of Regulatory Agencies"
                    onChange={(hit) => setSelectedIssuer({ id: hit.objectID, name: hit.name, kind: hit.kind })}
                  />
                  <div className="flex gap-4 py-4">
                    <InputWithLabel
                      className="w-1/2"
                      id="license-number"
                      label="License number"
                      value={licenseNumber}
                      onChange={(e) => setLicenseNumber(e.target.value)}
                    />
                    <div className="w-1/2">
                      <Label htmlFor="issuing-state">Issuing state</Label>
                      <Select
                        defaultOption="Select state"
                        id="issuing-state"
                        value={issuingState}
                        onChange={(e) => setIssuingState(e.target.value)}>
                        <optgroup label="U.S. States">
                          <option value="alabama">Alabama</option>
                          <option value="alaska">Alaska</option>
                          <option value="arizona">Arizona</option>
                          <option value="arkansas">Arkansas</option>
                          <option value="california">California</option>
                          <option value="colorado">Colorado</option>
                          <option value="connecticut">Connecticut</option>
                          <option value="delaware">Delaware</option>
                          <option value="district_of_columbia">District of Columbia</option>
                          <option value="florida">Florida</option>
                          <option value="georgia">Georgia</option>
                          <option value="hawaii">Hawaii</option>
                          <option value="idaho">Idaho</option>
                          <option value="illinois">Illinois</option>
                          <option value="indiana">Indiana</option>
                          <option value="iowa">Iowa</option>
                          <option value="kansas">Kansas</option>
                          <option value="kentucky">Kentucky</option>
                          <option value="louisiana">Louisiana</option>
                          <option value="maine">Maine</option>
                          <option value="maryland">Maryland</option>
                          <option value="massachusetts">Massachusetts</option>
                          <option value="michigan">Michigan</option>
                          <option value="minnesota">Minnesota</option>
                          <option value="mississippi">Mississippi</option>
                          <option value="missouri">Missouri</option>
                          <option value="montana">Montana</option>
                          <option value="nebraska">Nebraska</option>
                          <option value="nevada">Nevada</option>
                          <option value="new_hampshire">New Hampshire</option>
                          <option value="new_jersey">New Jersey</option>
                          <option value="new_mexico">New Mexico</option>
                          <option value="new_york">New York</option>
                          <option value="north_carolina">North Carolina</option>
                          <option value="north_dakota">North Dakota</option>
                          <option value="ohio">Ohio</option>
                          <option value="oklahoma">Oklahoma</option>
                          <option value="oregon">Oregon</option>
                          <option value="pennsylvania">Pennsylvania</option>
                          <option value="rhode_island">Rhode Island</option>
                          <option value="south_carolina">South Carolina</option>
                          <option value="south_dakota">South Dakota</option>
                          <option value="tennessee">Tennessee</option>
                          <option value="texas">Texas</option>
                          <option value="utah">Utah</option>
                          <option value="vermont">Vermont</option>
                          <option value="virginia">Virginia</option>
                          <option value="washington">Washington</option>
                          <option value="west_virginia">West Virginia</option>
                          <option value="wisconsin">Wisconsin</option>
                          <option value="wyoming">Wyoming</option>
                        </optgroup>
                        <optgroup label="Canadian Provinces and Territories">
                          <option value="alberta">Alberta</option>
                          <option value="british_columbia">British Columbia</option>
                          <option value="manitoba">Manitoba</option>
                          <option value="new_brunswick">New Brunswick</option>
                          <option value="newfoundland_and_labrador">Newfoundland and Labrador</option>
                          <option value="northwest_territories">Northwest Territories</option>
                          <option value="nova_scotia">Nova Scotia</option>
                          <option value="nunavut">Nunavut</option>
                          <option value="ontario">Ontario</option>
                          <option value="prince_edward_island">Prince Edward Island</option>
                          <option value="quebec">Quebec</option>
                          <option value="saskatchewan">Saskatchewan</option>
                          <option value="yukon">Yukon</option>
                        </optgroup>
                      </Select>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="pb-4">
              <div className="mb-4 rounded border border-orange bg-orange-light p-4">
                Please upload only PDFs or images of your degrees and certifications that we can verify.
              </div>
              <FilePickerDropArea
                onUploadDone={(res) => {
                  addCredential({
                    filestackDocument: res.filesUploaded[0].url,
                    filestackDocumentName: res.filesUploaded[0].filename
                  }).then(onMutationComplete)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </NewModal>
  )
}

import React from "react"
import { twMerge } from "tailwind-merge"

import { FilePicker } from "../../components/shared/FileStackWidgets"
import { BASE_INPUT_CLASSNAMES, Label } from "../../components/shared/Inputs"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { useToast } from "../../contexts/ToastContext"

import { useBookableEventTypes } from "./BookableEventTypesContext"

const PhotoUpload = () => {
  const {
    bookableEventType,
    setBookableEventType,
    fetching,
    saveEventType,
    updatePhoto,
    removePhoto,
    updateBookableEventTypes
  } = useBookableEventTypes()

  const { showToast } = useToast()

  const onUploadDone = (res) => {
    if (bookableEventType.id) {
      uploadPhoto(res)
    } else {
      saveEventType().then((result) => {
        if (result?.data?.upsertBookableEventType?.result === "success") {
          const newEventType = result.data.upsertBookableEventType.bookableEventType
          newEventType.photoUrl = null
          setBookableEventType(newEventType)
          updateBookableEventTypes(newEventType)
          uploadPhoto(res, newEventType.id)
        } else {
          console.error(result)
          let errorMessage = "There was an error saving your event type"
          if (result.data?.upsertBookableEventType?.errors)
            errorMessage += `: ${result.data.upsertBookableEventType.errors}`
          showToast({ type: "error", content: errorMessage })
        }
      })
    }
  }

  const onPhotoDelete = () => {
    removePhoto({ id: bookableEventType.id }).then((result) => {
      if (result?.data?.removeBookableEventTypePhoto?.result === "success") {
        const newEventType = { ...bookableEventType, photoUrl: null }
        setBookableEventType(newEventType)
        updateBookableEventTypes(newEventType)
        showToast("Your photo has been removed.")
      } else {
        console.error(result)
        let errorMessage =
          "There was an error removing your event type photo. Please try again later or contact support."
        if (result.data?.removeBookableEventTypePhoto?.errors)
          errorMessage += ` ${result.data.removeBookableEventTypePhoto.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const uploadPhoto = (res, id) => {
    updatePhoto({
      handle: res.filesUploaded[0].handle,
      id: bookableEventType.id || id
    }).then((result) => {
      if (result?.data?.updateBookableEventTypePhoto?.result === "success") {
        const newEventType = {
          ...bookableEventType,
          photoUrl: result.data.updateBookableEventTypePhoto.photoUrl,
          id: bookableEventType.id || id
        }
        setBookableEventType((eventType) => ({
          ...eventType,
          photoUrl: newEventType.photoUrl
        }))
        updateBookableEventTypes(newEventType)
        showToast("Your photo has been saved.")
      } else {
        console.error(result)
        let errorMessage = "There was an error saving your event type photo. Please try again later or contact support."
        if (result.data?.updateBookableEventTypePhoto?.errors)
          errorMessage += ` ${result.data.updateBookableEventTypePhoto.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  return (
    <>
      <div className="flex justify-between">
        <Label htmlFor="photo">Photo</Label>
        {!bookableEventType.photoUrl && <div className="text-sm text-gray-dark">Optional</div>}
      </div>
      {fetching ? (
        <LoadingSpinner className="py-0" />
      ) : bookableEventType.photoUrl ? (
        <div className="flex items-center gap-4">
          <img src={bookableEventType.photoUrl} alt="Event type photo" className="h-16 w-16 rounded-lg" />
          <div>
            <FilePicker
              as="button"
              className="font-bold text-teal"
              onUploadDone={onUploadDone}
              disabled={fetching}
              transformations={{}}>
              Edit photo
            </FilePicker>
          </div>
          <button className="font-bold text-red" onClick={onPhotoDelete}>
            Remove
          </button>
        </div>
      ) : (
        <FilePicker
          as="button"
          onUploadDone={onUploadDone}
          disabled={fetching}
          transformations={{}}
          className={twMerge(BASE_INPUT_CLASSNAMES, "text-teal")}>
          + Upload photo
        </FilePicker>
      )}
    </>
  )
}

export default PhotoUpload

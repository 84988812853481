import React, { useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "urql"

import { sanitize } from "../../utils/utils"

import { Button } from "./Buttons"
import { TextArea } from "./Inputs"

const APPOINTMENT_NOTES_QUERY = `
  query AppointmentNote($id: ID!) {
    appointment(id: $id) {
      id
      appointmentNote { text time }
    }
  }
`

const ADD_NOTE_MUTATION = `
  mutation($text: String!, $clientId: ID!, $noteType: String, $appointmentId: ID) {
    addNote(text: $text, clientId: $clientId, noteType: $noteType, appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

const AppointmentNotes = ({ appointment, openAppointmentToken }) => {
  const [text, setText] = useState("")
  const [editing, setEditing] = useState(false)
  const [{ data, fetching }, refetchQuery] = useQuery({
    query: APPOINTMENT_NOTES_QUERY,
    variables: { id: appointment.id },
    requestPolicy: "network-only"
  })

  const [{ fetching: addNoteFetching }, addNote] = useMutation(ADD_NOTE_MUTATION)
  const ref = useRef(null)

  useEffect(() => {
    if (openAppointmentToken === appointment.instantActionToken) {
      setEditing(true)
    }
  }, [openAppointmentToken])

  useEffect(() => {
    if (editing && ref.current) ref.current.focus()
  }, [editing])

  const onSave = () => {
    addNote({ text, appointmentId: appointment.id, noteType: "appointment", clientId: appointment.client.id }).then(
      (result) => {
        if (result.data?.addNote?.result === "success") {
          refetchQuery()
        } else {
          console.error(result) // eslint-disable-line no-console
        }
      }
    )
  }

  const appointmentNoteText = data?.appointment?.appointmentNote?.text || ""
  const saveDisabled = fetching || addNoteFetching || text.length < 1
  const disabled = fetching || addNoteFetching

  return (
    <>
      <div className={disabled ? "opacity-50" : ""}>
        {editing ? (
          <div>
            <div className="mb-2">
              <div className="mb-1 font-bold">Appointment notes</div>
            </div>
            <TextArea value={text} onChange={(e) => setText(e.target.value)} ref={ref} />
            <div className="mt-4 flex justify-end gap-4">
              <Button
                type="tertiary"
                size="small"
                onClick={() => {
                  setEditing(false)
                  setText(appointmentNoteText)
                }}>
                Cancel
              </Button>
              <Button
                disabled={saveDisabled}
                size="small"
                onClick={() => {
                  if (saveDisabled) return
                  setEditing(false)
                  onSave()
                }}>
                Save
              </Button>
            </div>
          </div>
        ) : appointmentNoteText ? (
          <div>
            <div className="text-sm font-bold">Notes</div>
            <div dangerouslySetInnerHTML={{ __html: sanitize(appointmentNoteText.replaceAll("\n", "<br />")) }} />
            <Button
              type="link"
              className="no-underline"
              onClick={() => {
                if (disabled) return
                setEditing(true)
                setText(appointmentNoteText)
              }}>
              Edit note
            </Button>
          </div>
        ) : (
          <div>
            <Button
              type="link"
              className="no-underline"
              onClick={() => {
                if (disabled) return
                setEditing(true)
                setText(appointmentNoteText)
              }}>
              + Add note
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default AppointmentNotes

import React, { useState } from "react"
import { useMutation } from "urql"

import { Button, CopyLinkButton } from "../../components/shared/Buttons"
import { InputWithLabel } from "../../components/shared/Inputs"
import { Divider } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { copyToClipboard } from "../../utils/utils"

const SEND_BULK_REVIEW_REQUESTS = `
  mutation SendBulkReviewRequests($clients: [ClientInput!]!) {
    sendBulkReviewRequests (clients: $clients) {
      result
      erroredClients {
        email
        errors
      }
    }
  }
`

export default function ReviewRequestForm({ existingRequests, requestReviewLink, defaultRows = 1 }) {
  const [clients, setClients] = useState(Array(defaultRows).fill({ firstName: "", phone: "", clientType: "invited" }))
  const { showToast } = useToast()

  const [, sendBulkReviewRequests] = useMutation(SEND_BULK_REVIEW_REQUESTS)

  const formIsValid = () => clients.some((client) => client.firstName.trim() !== "" && client.phone.trim().length > 12)

  const handleClientChange = (index, field, value) => {
    const updatedClients = [...clients]
    updatedClients[index] = { ...updatedClients[index], [field]: value }
    setClients(updatedClients)
  }

  const addClient = () => {
    setClients([...clients, { firstName: "", phone: "", clientType: "invited" }])
  }

  const handleInvitesSent = () => {
    showToast("Awesome stuff! We’ll email you when your clients leave reviews for you.")
    setTimeout(() => window.location.reload(), 1500)
  }

  const handleSubmit = async () => {
    const validClients = clients.filter((client) => client.firstName || client.phone)

    const formattedClients = validClients.map((client) => ({
      firstName: client.firstName,
      phone: client.phone,
      clientType: client.clientType
    }))

    try {
      const response = await sendBulkReviewRequests({ clients: formattedClients })
      if (response.data.sendBulkReviewRequests.erroredClients.length > 0) {
        showToast({
          content: `Error requesting reviews: ${response.data.sendBulkReviewRequests.erroredClients
            .map((client) => client.errors)
            .join(" ")}`,
          type: "error"
        })
      } else {
        handleInvitesSent()
      }
    } catch (error) {
      showToast({ content: `An error occurred: ${error.message}`, type: "error" })
    }
  }

  const handleCopy = async (text) => {
    await copyToClipboard(text, showToast)
  }

  return (
    <div className="mt-8 flex flex-row sm:flex-col">
      <div className="min_md:mr-8 min_md:w-[572px]">
        <div className="rounded-lg border border-gray-light p-8 sm:w-full">
          {clients.map((client, index) => (
            <div key={index}>
              <div className="mb-4 grid grid-cols-2 gap-6 sm:grid-cols-1">
                <InputWithLabel
                  label="First name"
                  value={client.firstName}
                  onChange={(e) => handleClientChange(index, "firstName", e.target.value)}
                />

                <InputWithLabel
                  id="phone"
                  type="phone"
                  label="Mobile phone"
                  value={client.phone}
                  onChange={(e) => handleClientChange(index, "phone", e.target.value)}
                />
              </div>

              {index !== clients.length - 1 && <Divider />}
            </div>
          ))}
          {existingRequests + clients.length < 10 && (
            <Button type="link" onClick={addClient}>
              Add another client
            </Button>
          )}
          <Divider />
          <div className="mt-8 block w-full text-right md:mb-8">
            <Button type="primary" size="large" className="" disabled={!formIsValid()} onClick={handleSubmit}>
              Send request{clients.length > 1 ? "s" : ""}
            </Button>
          </div>
        </div>
        <div className="mt-8 rounded-lg border border-gray-light p-8 md:mb-8">
          <Typography variant="body" className="mb-4 font-bold">
            Want to share your link instead?
          </Typography>
          <CopyLinkButton
            value={requestReviewLink}
            onClick={() => {
              handleCopy(requestReviewLink)
            }}
          />
        </div>
      </div>
      <div className="w-[360px] sm:w-full">
        <div className="rounded-lg border border-gray-light p-8">
          <Typography variant="body" className="font-semibold">
            Why do reviews matter?
          </Typography>
          <Typography variant="subtitle" className="mt-2">
            When shopping online for goods and services, clients trust reviews from other clients more than anything
            else. We know getting 10+ reviews builds trust for potential clients.
          </Typography>
        </div>
      </div>
    </div>
  )
}

import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import React, { useState } from "react"

import PaymentProviderWrapper from "../../components/payments/PaymentProviderWrapper"
import { Button } from "../../components/shared/Buttons"
import { useAppointment } from "../../contexts/AppointmentContext"

import AddPaymentModal from "./AddPaymentModal"

const AddPaymentButton = ({
  label,
  modalHeaderLabel,
  onSave,
  loading,
  tipOnly = false,
  modalActionButtonCopy,
  onPaypalSuccess
}) => {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false)
  const { appointment } = useAppointment()

  const onClick = () => {
    setPaymentModalOpen(true)
  }

  const renderModalContent = () => {
    if (appointment.practice.hasPaypalAccount) {
      return (
        <PayPalScriptProvider
          options={{
            clientId: window.App.paypal_client_id,
            merchantId: window.App.paypal_merchant_id,
            dataPartnerAttributionId: window.App.paypal_partner_attribution_id,
            currency: appointment.amountCurrency,
            intent: "capture",
            enableFunding: "venmo",
            components: "buttons,marks,funding-eligibility"
            // buyerCountry: "US" // Add this in development to test Venmo
          }}>
          <AddPaymentModal
            headerLabel={modalHeaderLabel}
            open={paymentModalOpen}
            closeModal={() => setPaymentModalOpen(false)}
            onSave={onSave}
            tipOnly={tipOnly}
            actionButtonCopy={modalActionButtonCopy}
            onPaypalSuccess={onPaypalSuccess}
          />
        </PayPalScriptProvider>
      )
    }

    return (
      <AddPaymentModal
        headerLabel={modalHeaderLabel}
        open={paymentModalOpen}
        closeModal={() => setPaymentModalOpen(false)}
        onSave={onSave}
        tipOnly={tipOnly}
        actionButtonCopy={modalActionButtonCopy}
        onPaypalSuccess={onPaypalSuccess}
      />
    )
  }

  return (
    <>
      <Button disabled={loading} onClick={onClick}>
        {label || "Add credit card"}
      </Button>

      {paymentModalOpen && (
        <PaymentProviderWrapper practice={appointment.practice} appointment={appointment}>
          {renderModalContent()}
        </PaymentProviderWrapper>
      )}
    </>
  )
}

export default AddPaymentButton

import { InformationCircleIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import DatePicker from "../../components/shared/DatePicker"
import { Input, InputWithLabel, Label, Select, CheckBox, ValidationError, Toggle } from "../../components/shared/Inputs"
import { Divider, Flyout } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

import { defaultDiscountCode } from "./DiscountCodesTable"
import ServiceSelector from "./ServiceSelector"

const UPSERT_DISCOUNT_CODE = `
  mutation UpsertDiscountCode($input: UpsertDiscountCodeInput!) {
    upsertDiscountCode(input: $input) {
      discountCode {
        id
        code
        kind
        amountCents
        percent
        expiresAt
        maxRedemptions
        redemptionCount
        serviceIds
        applyToSpecificServices
        editable
      }
      errors
      result
    }
  }
`

const DiscountCodeFlyout = ({ services, setDiscountCodes, visible, setVisible, editingCode, setEditingCode }) => {
  const [formData, setFormData] = useState(defaultDiscountCode)

  const [errors, setErrors] = useState({})
  const [, upsertDiscountCode] = useMutation(UPSERT_DISCOUNT_CODE)
  const { showToast } = useToast()

  useEffect(() => {
    if (editingCode) {
      setFormData({
        code: editingCode.code || "",
        kind: editingCode.kind || "fixed",
        amountCents: editingCode.amountCents || null,
        percent: editingCode.percent || null,
        expiresAt: editingCode.expiresAt ? new Date(editingCode.expiresAt) : null,
        maxRedemptions: editingCode.maxRedemptions || null,
        combinationLimit: editingCode.combinationLimit || 1,
        hasExpiration: !!editingCode.expiresAt,
        hasMaxRedemptions: !!editingCode.maxRedemptions,
        applyToSpecificServices: editingCode.applyToSpecificServices,
        serviceIds: editingCode.serviceIds || []
      })
    } else {
      setFormData(defaultDiscountCode)
    }
  }, [editingCode])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = {}

    if (formData.code && (formData.code.length < 8 || !/^[A-Z0-9]+$/.test(formData.code))) {
      newErrors.code = "Code must be at least 8 characters and contain only uppercase letters and numbers"
    }

    if (formData.kind === "fixed" && !formData.amountCents) {
      newErrors.amountCents = "Please enter an amount for fixed discount"
    }

    if (formData.kind === "percentage" && !formData.percent) {
      newErrors.percent = "Please enter a percentage for percentage discount"
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    // Clear errors before submitting
    setErrors({})
    const result = await upsertDiscountCode({
      input: {
        id: editingCode?.id,
        code: formData.code,
        kind: formData.kind,
        amountCents: formData.amountCents ? parseInt(formData.amountCents) : null,
        percent: formData.percent ? parseInt(formData.percent) : null,
        expiresAt: formData.expiresAt ? dayjs(formData.expiresAt).format("YYYY-MM-DDTHH:mm:ssZ") : null,
        maxRedemptions: formData.maxRedemptions ? parseInt(formData.maxRedemptions) : null,
        applyToSpecificServices: formData.applyToSpecificServices,
        serviceIds: formData.serviceIds
      }
    })

    if (result.data?.upsertDiscountCode?.result === "success") {
      const newCode = result.data?.upsertDiscountCode?.discountCode
      newCode.id = Number(newCode.id)
      newCode.serviceIds = newCode.serviceIds.map(Number)
      setDiscountCodes((codes) => {
        const codeIndex = codes.findIndex((c) => c.id === newCode.id)
        if (codeIndex >= 0) {
          return codes.map((c) => (c.id === newCode.id ? newCode : c))
        } else {
          return [newCode, ...codes]
        }
      })
      showToast({ content: "Coupon saved successfully", type: "success" })
    } else {
      showToast({
        content: `Error: ${result.data?.upsertDiscountCode?.errors?.join(", ") || result.error}`,
        type: "error"
      })
      return
    }

    setEditingCode(null)
    setVisible(false)
  }

  return (
    <Flyout
      visible={visible}
      closeFlyout={() => setVisible(false)}
      header={`${editingCode.id ? "Edit" : "New"} Coupon`}
      footer={
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-4">
            <Button type="tertiary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      }>
      <form className="flex flex-col gap-4">
        <div>
          <InputWithLabel
            label="Code"
            value={formData.code}
            disabled={!editingCode.editable}
            onChange={(e) =>
              setFormData({
                ...formData,
                code: e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "")
              })
            }
            validationError={errors.code}
          />
          <Typography variant="micro">Leave blank to generate a random code (recommended).</Typography>
        </div>

        <div className="flex gap-4">
          <div className="w-1/2">
            <Label>Type</Label>
            <Select
              value={formData.kind}
              onChange={(e) => {
                setFormData({ ...formData, kind: e.target.value, amountCents: null, percent: null })
              }}
              disabled={!editingCode.editable}
              options={[
                { label: "Fixed Amount", value: "fixed" },
                { label: "Percentage", value: "percentage" }
              ]}
              required
            />
          </div>

          {formData.kind === "fixed" ? (
            <div className="w-1/2">
              <Label htmlFor="amountCents">Amount</Label>
              <div className="relative flex w-20 items-center">
                <span className="pointer-events-none absolute left-3 text-gray-dark">$</span>
                <Input
                  id="amountCents"
                  type="number"
                  min="1"
                  step="1"
                  className="pl-7"
                  disabled={!editingCode.editable}
                  value={Math.round(Number(formData.amountCents) / 100) || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, amountCents: parseInt(e.target.value * 100) })
                  }}
                  validationError={!!errors.amountCents}
                />
              </div>
              {errors.amountCents && <ValidationError>{errors.amountCents}</ValidationError>}
            </div>
          ) : (
            <div className="w-1/2">
              <Label htmlFor="percent">Percentage</Label>
              <div className="relative flex w-20 items-center">
                <Input
                  id="percent"
                  type="number"
                  min="0"
                  step="1"
                  max="100"
                  className="pr-7"
                  disabled={!editingCode.editable}
                  value={Number(formData.percent) || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, percent: Math.floor(Number(e.target.value)) })
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "." || e.key === ",") {
                      e.preventDefault()
                    }
                  }}
                  validationError={!!errors.percent}
                />
                <span className="pointer-events-none absolute right-3 text-gray-dark">%</span>
              </div>
              {errors.percent && <ValidationError>{errors.percent}</ValidationError>}
            </div>
          )}
        </div>
        <div>
          <div className="mt-4 flex items-center gap-2">
            <Toggle
              id="applyToSpecificServices"
              disabled={!editingCode.editable}
              checked={formData.applyToSpecificServices || false}
              onChange={() => setFormData({ ...formData, applyToSpecificServices: !formData.applyToSpecificServices })}
            />
            <Label htmlFor="applyToSpecificServices">Apply to specific services</Label>
          </div>
          {formData.applyToSpecificServices && (
            <ServiceSelector
              disabled={!editingCode.editable}
              className="mt-4"
              services={services}
              selectedServiceIds={formData.serviceIds}
              onChange={(newIds) => setFormData({ ...formData, serviceIds: newIds })}
            />
          )}
        </div>
        {!editingCode.editable && (
          <div className="mt-4 flex items-center gap-2">
            <InformationCircleIcon className="h-5 w-5 text-gray-dark" />
            <Typography variant="micro" className="text-gray-dark">
              Coupons cannot be edited once they have been redeemed.
            </Typography>
          </div>
        )}
        <Divider className="my-4" />
        <div>
          <div>
            <div>
              <CheckBox
                label="Add expiration date"
                checked={formData.hasExpiration}
                onChange={() =>
                  setFormData({
                    ...formData,
                    hasExpiration: !formData.hasExpiration,
                    expiresAt: formData.hasExpiration ? null : formData.expiresAt
                  })
                }
              />
            </div>
            {formData.hasExpiration && (
              <div className="mb-6 ml-8">
                <Label>Redeem by</Label>
                <DatePicker
                  selected={formData.expiresAt}
                  onChange={(date) => setFormData({ ...formData, expiresAt: date })}
                />
              </div>
            )}
          </div>
          <div>
            <div>
              <CheckBox
                label="Add redemption limit"
                checked={formData.hasMaxRedemptions}
                onChange={() =>
                  setFormData({
                    ...formData,
                    hasMaxRedemptions: !formData.hasMaxRedemptions,
                    maxRedemptions: formData.hasMaxRedemptions ? null : formData.maxRedemptions
                  })
                }
              />
            </div>
            {formData.hasMaxRedemptions && (
              <div className="ml-8">
                <InputWithLabel
                  className="w-32"
                  label="Max redemptions"
                  type="number"
                  value={formData.maxRedemptions || ""}
                  onChange={(e) => setFormData({ ...formData, maxRedemptions: e.target.value })}
                />
                <Typography variant="micro">
                  This limit applies across customers so it won&apos;t prevent a single customer from redeeming multiple
                  times.
                </Typography>
              </div>
            )}
          </div>
        </div>
      </form>
    </Flyout>
  )
}

export default DiscountCodeFlyout

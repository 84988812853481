import React from "react"

import Row from "./Row"

export default function Review({ clients, submitContacts, editContacts }) {
  return (
    <div className="columns is-centered">
      <div className="column is-four-fifths">
        <div className="copy-paste-headline">
          Review & Finalize
          <i className="icon-edit edit-contacts" onClick={() => editContacts()}></i>
        </div>
        <div className="scrollable-area">
          <table className="client-list table">
            <thead>
              <tr>
                <th></th>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((row, index) => (
                <Row
                  index={index + 1}
                  email={row.email}
                  firstName={row.firstName}
                  lastName={row.lastName}
                  phone={row.phone}
                  key={index}
                />
              ))}
            </tbody>
          </table>
        </div>
        <button
          className="button btn-primary"
          style={{ marginTop: "32px", width: "100%" }}
          onClick={() => submitContacts()}>
          Finalize import
        </button>
      </div>
    </div>
  )
}

import React from "react"
import { twMerge } from "tailwind-merge"

const ProgressBar = ({ className, percentComplete }) => (
  <div className={twMerge("h-1 w-full rounded-full bg-gray-light", className)}>
    <div className="h-[6px] rounded-full bg-teal" style={{ width: percentComplete }}></div>
  </div>
)

export default ProgressBar

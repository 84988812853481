import React from "react"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { usePractice } from "../../../../contexts/PracticeContext"
import { formatClientPhonePlusOne } from "../../../../utils/utils"
import { Banner } from "../../../shared/Layout"

const ErrorBanner = ({ appointmentErrors }) => {
  const { client } = useManualBooking()
  const { practicePhone, practiceEmail } = usePractice()
  const clientPhone = formatClientPhonePlusOne(client?.phone)
  const clientEmail = client?.email

  return (
    <>
      {appointmentErrors && (
        <Banner type="error" className="mt-6">
          {appointmentErrors}
        </Banner>
      )}

      {clientPhone === practicePhone && !!practicePhone && (
        <Banner className="mt-4" type="error">
          Cannot use your phone number for a client.
        </Banner>
      )}
      {clientEmail?.trim() === practiceEmail && (
        <Banner className="mt-4" type="error">
          Cannot use your email for a client.
        </Banner>
      )}
    </>
  )
}

export default ErrorBanner

import React from "react"

import { sendAmplitudeData } from "../support/amplitude-react"
export default function ListPracticeCTA({ classname, amplitudeData, styling }) {
  const sendAnalytics = () => {
    sendAmplitudeData(amplitudeData)
    fbq("trackCustom", "typeform_opened")
  }

  const ctaUrl = "/pro"

  return (
    <div onClick={sendAnalytics} style={{ display: "flex" }}>
      <a href={ctaUrl} className={classname} style={styling}>
        Join as a practitioner
      </a>
    </div>
  )
}

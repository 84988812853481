import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { Button, IconButton } from "../../components/shared/Buttons"
import { CheckBox } from "../../components/shared/Inputs"
import { Box } from "../../components/shared/Layout"
import { TabNavLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import ProfileEditProvider from "../ProfileEdit/ProfileEditProvider"

import GiftCardForm from "./GiftCardForm"

const UPSERT_GIFT_CARD_TEMPLATE_MUTATION = `
  mutation UpsertGiftCardTemplate($id: ID, $amountCents: Int!, $description: String, $active: Boolean!, $public: Boolean!) {
    upsertGiftCardTemplate(id: $id, amountCents: $amountCents, description: $description, active: $active, public: $public) {
      result
      giftCardTemplate {
        id
        amountCents
        description
        active
        public
      }
      errors
    }
  }
`

const GiftCardTemplates = ({ giftCardTemplates: giftCardTemplatesProp }) => {
  const navItems = ["Gift Cards", "Activity"]

  const [giftCardTemplates, setGiftCardTemplates] = useState(giftCardTemplatesProp)
  const [view, setView] = useState(navItems[0])
  const { showToast } = useToast()
  const [showNewForm, setShowNewForm] = useState(giftCardTemplates.length === 0)
  const [editingTemplate, setEditingTemplate] = useState(null)
  const [, upsertGiftCardTemplate] = useMutation(UPSERT_GIFT_CARD_TEMPLATE_MUTATION)

  const handleSave = async (formData) => {
    try {
      const result = await upsertGiftCardTemplate({ ...formData, id: editingTemplate?.id })

      if (result.data?.upsertGiftCardTemplate?.result === "success") {
        const savedTemplate = result.data.upsertGiftCardTemplate.giftCardTemplate
        if (editingTemplate) {
          setGiftCardTemplates((templates) => templates.map((t) => (t.id === savedTemplate.id ? savedTemplate : t)))
        } else {
          setGiftCardTemplates((prev) => [...prev, savedTemplate])
        }
        showToast({ content: "Gift card template saved successfully", type: "success" })
        setEditingTemplate(null)
        setShowNewForm(false)
      } else {
        const error = result.data?.upsertGiftCardTemplate?.errors?.[0] || "Failed to save gift card template"
        showToast({ content: error, type: "error" })
      }
    } catch (error) {
      console.error("Error saving gift card template:", error)
      showToast({ content: "Failed to save gift card template", type: "error" })
    }
  }

  return (
    <div>
      <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
        <div className="flex flex-col">
          <Typography variant="h3" as="h1">
            Gift Cards
          </Typography>
          <Typography variant="subtitle">Add or edit your gift cards.</Typography>
        </div>
        <Button variant="primary" onClick={() => setShowNewForm(true)} className="sm:mt-4">
          Create New
        </Button>
      </div>

      <Box data-test-id="gift-card-templates">
        <div className={twMerge("mt-4 flex gap-8 border-b border-gray-light sm:mb-4")}>
          {navItems.map((item) => (
            <TabNavLink key={item} active={view === item} onClick={() => setView(item)}>
              {item}
            </TabNavLink>
          ))}
        </div>
        <div className="flex flex-row sm:hidden" data-tooltip-id="disabled-tooltip">
          <Button floatingInMobile={true} onClick={() => {}} className="hidden sm:block">
            Add new
          </Button>
        </div>

        {view === "Gift Cards" && (
          <>
            <div className="mb-4">
              <Typography variant="subtitle">
                Create a gift certificate for any dollar amount and with any description
              </Typography>
            </div>

            <div className="flex flex-col gap-4">
              {showNewForm && <GiftCardForm onSave={handleSave} onCancel={() => setShowNewForm(false)} />}

              {giftCardTemplates.map((template, index) => (
                <div key={index} className="flex items-center gap-4 rounded-lg bg-gray-ultralight p-4">
                  {editingTemplate?.id === template.id ? (
                    <GiftCardForm giftCard={template} onSave={handleSave} onCancel={() => setEditingTemplate(null)} />
                  ) : (
                    <>
                      <div className="w-32">${template.amountCents / 100}</div>
                      <div className="flex-1">{template.description || `$${template.amountCents / 100} Gift Card`}</div>
                      <div className="flex items-center gap-4">
                        <CheckBox label="Active" className="mb-0" checked={template.active} disabled />
                        <CheckBox label="Online" className="mb-0" checked={template.public} disabled />
                      </div>
                      <div className="flex gap-2">
                        <IconButton Icon={PencilIcon} onClick={() => setEditingTemplate(template)} />
                        <IconButton Icon={TrashIcon} onClick={() => {}} />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </Box>
    </div>
  )
}

export default function PortalGiftCardTemplatesPage({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <GiftCardTemplates {...props} />
    </ProfileEditProvider>
  )
}

import React from "react"

import { Divider } from "../../shared/Layout"
import Typography from "../../shared/Typography"

export default function AppointmentSummaryRow({ appointment, hideDivider }) {
  const startsAtDate = new Date(appointment.startsAt)
  const endsAtDate = new Date(appointment.endsAt)
  const duration = (endsAtDate - startsAtDate) / 60000

  return (
    <div className="py-1">
      <div className="flex flex-row sm:flex-col">
        <div className="flex w-full flex-col">
          <Typography variant="small" className="mb-1 text-gray-dark">
            {appointment.service.name}
          </Typography>
          <Typography variant="small" className="mb-1 text-gray-dark">
            <span>{duration} minutes</span> <span>·</span> <span>{appointment.location.shortSingleLine}</span>
          </Typography>
          {!hideDivider && <Divider className="mt-6" />}
        </div>
      </div>
    </div>
  )
}

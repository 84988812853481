import { ClipboardIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import React from "react"

import Badge from "../components/shared/Badge"
import { Box, Divider } from "../components/shared/Layout"
import Typography from "../components/shared/Typography"
import { ToastProvider, useToast } from "../contexts/ToastContext"
import GraphQLProvider from "../providers/GraphQLProvider"
import { formatPrice } from "../utils/utils"

const ReferralProgram = ({ practitionerReferral, referralCreditWithdrawals, isLifer }) => {
  const { showToast } = useToast()

  const discountCode = practitionerReferral.discountCode

  const copyToClipboard = () => {
    navigator.clipboard.writeText(discountCode)
    showToast("Discount code copied to clipboard!")
  }

  // const socialMediaUrls = {
  //   facebook: `https://www.facebook.com/sharer/sharer.php?u=&quote=Check out this discount code: ${discountCode}`,
  //   twitter: `https://twitter.com/intent/tweet?text=Check out this discount code: ${discountCode}`,
  //   linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=&summary=Check out this discount code: ${discountCode}`
  // }

  return (
    <div className="w-[600px] md:w-full">
      <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
        <div className="flex flex-col">
          <Typography variant="h3" as="h1">
            Referral Program: Give $50, Get $50
          </Typography>
          <Typography variant="subtitle">
            Share your discount code with other practitioners.
            <br />
            They&apos;ll get $50 off, and you&apos;ll earn $50 when they join!
          </Typography>
        </div>
      </div>

      <Box>
        <Typography variant="capitalHeading" as="h3" className="mb-4">
          Copy your discount code
        </Typography>
        <button className="flex items-center gap-2 hover:text-teal" onClick={copyToClipboard}>
          <span className="rounded-lg bg-gray-ultralight px-4 py-1">Discount code: {discountCode}</span>
          <ClipboardIcon className="h-5 w-5" />
        </button>

        {/* <Divider /> */}
        {/* <Typography variant="capitalHeading" as="h3" className="mb-4"> */}
        {/*   Share on social media */}
        {/* </Typography> */}
        {/* <div className="flex gap-4"> */}
        {/*   <a href={socialMediaUrls.facebook} target="_blank" rel="noopener noreferrer"> */}
        {/*     <FacebookIcon height={32} width={24} /> */}
        {/*   </a> */}
        {/*   <a href={socialMediaUrls.twitter} target="_blank" rel="noopener noreferrer"> */}
        {/*     <XTwitterIcon height={32} width={21} /> */}
        {/*   </a> */}
        {/*   <a href={socialMediaUrls.linkedin} target="_blank" rel="noopener noreferrer"> */}
        {/*     <LinkedinIcon height={32} width={22} /> */}
        {/*   </a> */}
        {/* </div> */}
      </Box>

      <Box className="mt-8">
        <Typography variant="capitalHeading" as="h3" className="mb-4">
          Referral History
        </Typography>
        {practitionerReferral.referralUses.length === 0 ? (
          <div className="text-gray-dark">When people use your referral code, they will show up here</div>
        ) : (
          <>
            {practitionerReferral.referralUses.map((referral) => (
              <div key={referral.id} className="flex items-center justify-between gap-4">
                <div className="font-bold">{dayjs(referral.useDate).format("MM/DD/YYYY")}</div>
                <div className="text-gray-dark">{formatPrice(practitionerReferral.amountCents)}</div>
              </div>
            ))}
            <Divider />
            <div className="flex items-center justify-between gap-4">
              <div className="font-bold">Total</div>
              <div className="text-gray-dark">
                {formatPrice(practitionerReferral.referralUses.length * practitionerReferral.amountCents)}
              </div>
            </div>
          </>
        )}
      </Box>

      {isLifer && (
        <Box className="mt-8">
          <Typography variant="capitalHeading" as="h3" className="mb-4">
            Referral Credit Withdrawals
          </Typography>
          {referralCreditWithdrawals.length === 0 ? (
            <div className="text-gray-dark">When you withdraw referral credit, it will show up here</div>
          ) : (
            <>
              {referralCreditWithdrawals.map((withdrawal) => (
                <div key={withdrawal.id} className="flex items-center justify-between gap-4 space-y-2">
                  <div className="font-bold">{dayjs(withdrawal.withdrawalDate).format("MM/DD/YYYY")}</div>
                  <div className="flex items-center gap-2">
                    <div className="w-24 text-right text-gray-dark">{formatPrice(withdrawal.amountCents)}</div>
                    <Badge type={withdrawal.status === "pending" ? "warning" : "success"} className="w-20 text-center">
                      {withdrawal.status}
                    </Badge>
                  </div>
                </div>
              ))}
            </>
          )}
        </Box>
      )}
    </div>
  )
}

export default function ReferralProgramPage(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <ReferralProgram {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

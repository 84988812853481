import React, { useState } from "react"

import PaymentDirectory from "../../components/pro_portal/payments/PaymentDirectory"
import GraphQLProvider from "../../providers/GraphQLProvider"

import ClientPageView from "./ClientPageView"
import ManageCreditCard from "./ManageCreditCard"

const Billing = ({ client: clientProp, upcomingAppointments, communicationSettings, practice, clientNotesEnabled }) => {
  const [client] = useState(clientProp)

  return (
    <ClientPageView
      client={client}
      practice={practice}
      upcomingAppointments={upcomingAppointments}
      clientNotesEnabled={clientNotesEnabled}
      path={`/portal/clients/${client.id}/billing`}>
      <div className="mb-6 w-full rounded-xl border border-gray p-8">
        <div className="mb-2 font-bold">Payment Method</div>
        <ManageCreditCard client={client} practice={practice} />
      </div>
      <PaymentDirectory communicationSettings={communicationSettings} clientId={client.id} />
    </ClientPageView>
  )
}

export default function ClientBillingPage(props) {
  return (
    <GraphQLProvider>
      <Billing {...props} />
    </GraphQLProvider>
  )
}

import React, { useState } from "react"
import { useMutation } from "urql"

import { useToast } from "../../../contexts/ToastContext"
import { commIntervals } from "../../../utils/utils"
import { Button } from "../../shared/Buttons"
import { Select, CheckBox } from "../../shared/Inputs"
import { Flyout } from "../../shared/Layout"
import { Divider } from "../../shared/Layout"
import { UnderlinedLink } from "../../shared/Links"
import { RadioWithLabel } from "../../shared/RadioButtons"
import Typography from "../../shared/Typography"

const UPDATE_PAYMENT_SETTINGS = `
  mutation UpdatePaymentSettings(
    $requireClientCcToBook: Boolean,
    $chargeClientCcAtBooking: Boolean,
    $paymentPastDueEmailEnabled: Boolean,
    $paymentPastDueTextEnabled: Boolean,
    $paymentPastDueEmailMinutes: Int,
    $paymentPastDueTextMinutes: Int,
  ) {
    updatePaymentSettings(
      requireClientCcToBook: $requireClientCcToBook,
      chargeClientCcAtBooking: $chargeClientCcAtBooking,
      paymentPastDueEmailEnabled: $paymentPastDueEmailEnabled,
      paymentPastDueTextEnabled: $paymentPastDueTextEnabled,
      paymentPastDueEmailMinutes: $paymentPastDueEmailMinutes,
      paymentPastDueTextMinutes: $paymentPastDueTextMinutes,
    ) {
      result
      error
    }
  }
`

export default function PaymentSettingsFlyout({
  hasPaymentProvider,
  hasOffline,
  communicationSettings,
  visible,
  closeFlyout
}) {
  const [{ fetching }, updatePaymentSettings] = useMutation(UPDATE_PAYMENT_SETTINGS)

  const [settings, setSettings] = useState(communicationSettings)
  const [originalSettings, setOriginalSettings] = useState(communicationSettings)

  const { showToast } = useToast()

  const handleUpdate = (key) => (newValue) => {
    let update = newValue
    if (newValue.value) {
      update = newValue.value
    }
    setSettings((prevSettings) => ({ ...prevSettings, [key]: update }))
  }

  const settingsChanged = JSON.stringify(settings) !== JSON.stringify(originalSettings)

  const handleSettingsUpdate = () => {
    updatePaymentSettings(settings)
      .then((result) => {
        if (result.data?.updatePaymentSettings?.result) {
          showToast({ content: "Settings updated successfully.", timeout: 3000 })
          setOriginalSettings(settings)
          closeFlyout()
        } else {
          const error =
            result.error?.message?.replace("[GraphQL]", "") ||
            result.data?.updatePaymentSettings?.error ||
            "Please try again later."

          showToast({ type: "error", content: error })
        }
      })
      .catch((error) => {
        showToast({ type: "error", content: error.message })
      })
  }

  return (
    <Flyout
      header="Payment Settings"
      visible={visible}
      closeFlyout={closeFlyout}
      footer={
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-4">
            <Button type="tertiary" onClick={closeFlyout}>
              Close
            </Button>

            <Button type="primary" onClick={handleSettingsUpdate} disabled={fetching || !settingsChanged}>
              Save
            </Button>
          </div>
        </div>
      }>
      <div className="relative">
        <Typography variant="h5" className="mb-2">
          Require credit card when a client books:
        </Typography>
        <Typography variant="subtitle" className="mb-4">
          Booking free services will not require a credit card.
        </Typography>
        {hasPaymentProvider ? (
          <>
            <div className="mb-4 flex flex-row gap-4">
              <RadioWithLabel
                id="require-cc-yes"
                value={true}
                name="require-cc"
                label="Yes"
                checked={settings.requireClientCcToBook}
                onChange={() => {
                  handleUpdate("requireClientCcToBook")(true)
                  handleUpdate("chargeClientCcAtBooking")(false)
                }}
              />
              <RadioWithLabel
                value={false}
                id="require-cc-no"
                name="require-cc"
                label="No (recommended)"
                checked={!settings.requireClientCcToBook}
                onChange={() => {
                  handleUpdate("requireClientCcToBook")(false)
                  handleUpdate("chargeClientCcAtBooking")(false)
                }}
              />
            </div>
            {hasOffline && (
              <Typography variant="subtitle" className="mb-4">
                Your clients will not have the option to pay with another method.
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="subtitle" className="mb-4 text-red">
            Requires a connected Payment Provider account.{" "}
            <UnderlinedLink className="ml-2" href="/portal/settings/banking">
              Connect Now
            </UnderlinedLink>
          </Typography>
        )}

        <Divider />
        {settings.requireClientCcToBook && (
          <>
            <Typography variant="h5" className="mb-4">
              Charge credit card when client books:
            </Typography>
            <div className="mb-4 flex flex-row gap-4">
              <RadioWithLabel
                id="charge-cc-yes"
                value={true}
                name="charge-cc"
                label="Yes"
                checked={settings.chargeClientCcAtBooking}
                onChange={() => handleUpdate("chargeClientCcAtBooking")(true)}
              />
              <RadioWithLabel
                id="charge-cc-no"
                value={false}
                name="charge-cc"
                label="No (recommended)"
                checked={!settings.chargeClientCcAtBooking}
                onChange={() => handleUpdate("chargeClientCcAtBooking")(false)}
              />
            </div>
            <Divider />
          </>
        )}

        <Typography variant="h5" as="h3">
          Send payment reminders:
        </Typography>
        <Typography variant="subtitle" className="mb-4 mt-1">
          Heal.me will remind your clients if they haven’t paid for an appointment.
        </Typography>
        <div className="mb-2 flex flex-row flex-wrap items-center">
          <CheckBox
            label="Email"
            className="flex-none font-manrope text-[16px] font-normal leading-6 text-gray-dark"
            id="payment-email-reminder"
            onChange={() => handleUpdate("paymentPastDueEmailEnabled")(!settings.paymentPastDueEmailEnabled)}
            checked={settings.paymentPastDueEmailEnabled}
          />
          <div className="mb-2 ml-2 flex-none bg-white">
            <Select
              options={commIntervals}
              value={settings.paymentPastDueEmailMinutes}
              onChange={(e) => setSettings({ ...settings, paymentPastDueEmailMinutes: Number(e.target.value) })}
              name="emailInterval"
            />
          </div>
          <p className="mb-2 ml-2 flex-none text-gray-dark">after</p>
        </div>
        <div className="flex flex-row flex-wrap items-center">
          <CheckBox
            label="Text or app notification"
            className="flex-none font-manrope text-[16px] font-normal leading-6 text-gray-dark"
            id="payment-text-reminder"
            onChange={() => handleUpdate("paymentPastDueTextEnabled")(!settings.paymentPastDueTextEnabled)}
            checked={settings.paymentPastDueTextEnabled}
          />
          <div className="mb-2 ml-2 flex-none bg-white">
            <Select
              options={commIntervals}
              value={settings.paymentPastDueTextMinutes}
              onChange={(e) => setSettings({ ...settings, paymentPastDueTextMinutes: Number(e.target.value) })}
              name="textInterval"
            />
          </div>
          <p className="mb-2 ml-2 flex-none text-gray-dark">after</p>
        </div>
      </div>
    </Flyout>
  )
}

export const UPSERT_BOOKABLE_EVENT_MUTATION = `
  mutation UpsertBookableEvent(
    $id: ID,
    $startsAt: String!,
    $endsAt: String!,
    $allDay: Boolean,
    $timeZone: String!,
    $bookableEventTypeId: ID!
  ) {
    upsertBookableEvent(
      id: $id,
      startsAt: $startsAt,
      endsAt: $endsAt,
      allDay: $allDay,
      timeZone: $timeZone,
      bookableEventTypeId: $bookableEventTypeId
    ) {
      result
      errors
      bookableEvent {
        id startsAt endsAt allDay timeZone bookableEventTypeId name description instructions capacity amountCents amountCurrency location kind
      }
    }
  }
`

export const UPDATE_BOOKABLE_EVENT_PHOTO_MUTATION = `
  mutation UpdateBookableEventPhoto($handle: String!, $id: ID!) {
    updateBookableEventPhoto(handle: $handle, id: $id) { result errors photoUrl }
  }
`

export const REMOVE_BOOKABLE_EVENT_PHOTO_MUTATION = `
  mutation RemoveBookableEventPhoto($id: ID!) {
    removeBookableEventPhoto(id: $id) { result errors }
  }
`

export const DELETE_BOOKABLE_EVENT_MUTATION = `
  mutation DeleteBookableEvent($id: ID!) {
    deleteBookableEvent(id: $id) { result errors }
  }
`

export const ADD_CLIENT_TO_BOOKABLE_EVENT_MUTATION = `
  mutation AddClientToBookableEvent($bookableEventId: ID!, $clientId: ID, $clientParams: ClientInput) {
    addClientToBookableEvent(bookableEventId: $bookableEventId, clientId: $clientId, clientParams: $clientParams) {
      result
      errors
      registrationsCount
    }
  }
`

export const REMOVE_CLIENT_FROM_BOOKABLE_EVENT_MUTATION = `
  mutation RemoveClientFromBookableEvent($bookableEventId: ID!, $clientId: ID!) {
    removeClientFromBookableEvent(bookableEventId: $bookableEventId, clientId: $clientId) {
      result
      errors
      registrationsCount
    }
  }
`

export const PROCESS_REGISTRATION_PAYMENT_MUTATION = `
  mutation ProcessRegistrationPayment($registrationId: ID!) {
    processRegistrationPayment(registrationId: $registrationId) {
      result
      errors
    }
  }
`

import React from "react"

export default function Row({ index, email, firstName, lastName, phone }) {
  return (
    <tr>
      <td>{index}</td>
      <td>{email}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{phone}</td>
    </tr>
  )
}

import React from "react"

import Breadcrumbs from "../../../components/shared/Breadcrumbs"
import { TabNavLink } from "../../../components/shared/Links"
import ListBox from "../../../components/shared/ListBox"
import Typography from "../../../components/shared/Typography"

const NAV_ITEMS = [
  { title: "Reminders", path: "/portal/settings/communication" },
  { title: "Custom content", path: "/portal/settings/communication/templates" }
]

const SELECT_ITEMS = NAV_ITEMS.map((item) => ({ value: item.path, label: `${item.title}` }))

const ProCommunicationSettingsView = ({ path, children }) => (
  <div>
    <Breadcrumbs links={[{ href: "/portal/settings", text: "Settings" }]} activePage="Communications" />
    <Typography variant="h3" as="h1">
      Communications
    </Typography>
    <Typography variant="subtitle" className="sm:hidden">
      Manage the timing, type, and content of your client reminders
    </Typography>
    <div className="mt-4 flex gap-8 border-b border-gray-light sm:hidden">
      {NAV_ITEMS.map((item) => (
        <TabNavLink key={item.title} href={item.path} active={path === item.path}>
          {item.title}
        </TabNavLink>
      ))}
    </div>
    <div className="mt-2 hidden sm:block">
      <ListBox
        value={SELECT_ITEMS.find((item) => item.value === path)}
        onChange={(item) => {
          if (typeof window !== "undefined") window.location = item.value
        }}
        options={SELECT_ITEMS}
        buttonHint="(tap to navigate)"
      />
    </div>
    <div className="mt-4 flex gap-4 pt-4 sm:mt-2">{children}</div>
  </div>
)

export default ProCommunicationSettingsView

import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useQuery } from "urql"

import AppointmentNotes from "../../components/shared/AppointmentNotes"
import Badge from "../../components/shared/Badge"
import ClientNotes from "../../components/shared/ClientNotes"
import LoadingSpinner from "../../components/shared/LoadingSpinner"

const PAST_APPOINTMENTS_QUERY = `
  query PastAppointments($clientId: ID) {
    pastAppointments(clientId: $clientId) {
      id
      startsAt
      endsAt
      amountCents
      timeZone
      instantActionToken
      state
      fullTotal
      client { id }
      service { id name }
      location { id name }
    }
  }
`

export default function ClientChronology({ client, clientNotesEnabled }) {
  const [openAppointmentToken, setOpenAppointmentToken] = useState(null)
  const [{ data, fetching }] = useQuery({
    query: PAST_APPOINTMENTS_QUERY,
    variables: { clientId: client.id },
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (data && location.hash) setOpenAppointmentToken(location.hash.substring(1))
  }, [data])

  const pastAppointments = data?.pastAppointments

  return (
    <div>
      {fetching ? (
        <LoadingSpinner />
      ) : (
        <div>
          {clientNotesEnabled && (
            <div className="mb-6 rounded-xl bg-orange-light p-6">
              <ClientNotes client={client} className="bg-orange-light" />
            </div>
          )}
          {pastAppointments && pastAppointments.length > 0 ? (
            <div>
              {pastAppointments.map((appointment) => (
                <div key={appointment.instantActionToken} className="mb-4 flex w-full items-start gap-4">
                  <Badge type="info" className="w-20 shrink-0 text-center">
                    {dayjs(appointment.startsAt).format("MMM D")}
                  </Badge>
                  <div className="flex w-full justify-between gap-8 border-b border-gray-light">
                    <div className="flex w-full flex-col gap-2 pb-4">
                      <div className="font-bold">{appointment.service.name}</div>
                      <div className="text-sm">{appointment.location.name}</div>
                      {clientNotesEnabled && (
                        <AppointmentNotes appointment={appointment} openAppointmentToken={openAppointmentToken} />
                      )}
                    </div>
                    <div className="flex-none text-sm text-gray-dark">
                      {dayjs(appointment.startsAt).format("h:mm")} - {dayjs(appointment.endsAt).format("h:mm A")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-sm">No past appointments</div>
          )}
        </div>
      )}
    </div>
  )
}

import React, { useState } from "react"
import { useQuery } from "urql"

import LoadingSpinner from "../../components/shared/LoadingSpinner"
import GraphQLProvider from "../../providers/GraphQLProvider"
import SendIntakeFormButton from "../IntakeForms/SendIntakeFormButton"

import ClientPageView from "./ClientPageView"

const CLIENT_INTAKE_FORM_QUERY = `
  query IntakeForm($clientId: ID!) {
    client(clientId: $clientId) {
      id
      intakeForm { text time }
      intakeLastSentAt
    }
  }
`

const IntakeForm = ({ client: clientProp, upcomingAppointments, clientNotesEnabled }) => {
  const [client] = useState(clientProp)
  const [{ data, fetching }] = useQuery({
    query: CLIENT_INTAKE_FORM_QUERY,
    variables: { clientId: client.id },
    requestPolicy: "network-only"
  })

  const rawJson = data?.client?.intakeForm?.text
  const parsedJson = rawJson && JSON.parse(rawJson)

  return (
    <ClientPageView
      client={client}
      upcomingAppointments={upcomingAppointments}
      clientNotesEnabled={clientNotesEnabled}
      path={`/portal/clients/${client.id}/intake_form`}>
      {fetching ? (
        <LoadingSpinner />
      ) : parsedJson ? (
        <div className="mb-6">
          {Object.entries(parsedJson).map((row) => (
            <div key={row[0]} className="mb-2 flex flex-col">
              <span className="font-bold">{row[0].replace("-Comment", " (Comment)")}</span>
              {Array.isArray(row[1]) ? (
                <ul className="list-inside list-disc">
                  {row[1].map((item, index) => (
                    <>
                      {typeof item === "object" ? (
                        <li key={index}>
                          Row {index + 1}:
                          <ul className="ml-4 list-inside list-disc">
                            {Object.entries(item).map(([subKey, subValue]) => (
                              <li key={subKey}>
                                {subKey}: {subValue}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : (
                        <li key={index}>{item}</li>
                      )}
                    </>
                  ))}
                </ul>
              ) : typeof row[1] === "object" ? (
                <div>
                  {Object.entries(row[1]).map(([key, value]) => (
                    <li key={key}>
                      {key}:{" "}
                      {typeof value === "object" ? (
                        <ul className="ml-4 list-inside list-disc">
                          {Object.entries(value).map(([subKey, subValue]) => (
                            <li key={subKey}>
                              {subKey}: {subValue}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        value
                      )}
                    </li>
                  ))}
                </div>
              ) : typeof row[1] === "string" && row[1].startsWith("data:image") ? (
                <img src={row[1]} alt="intake form image" className="max-h-[200px] max-w-[200px]" />
              ) : (
                <span>{row[1] === true ? "Yes" : row[1] === false ? "No" : row[1]}</span>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p className="mb-4">
            {data?.client?.intakeLastSentAt
              ? "This client has not submitted their intake form."
              : "This client has not been sent an intake form."}
          </p>
          <SendIntakeFormButton clientId={client.id} intakeLastSentAt={data?.client?.intakeLastSentAt} />
        </div>
      )}
    </ClientPageView>
  )
}

export default function ClientIntakeForm(props) {
  return (
    <GraphQLProvider>
      <IntakeForm {...props} />
    </GraphQLProvider>
  )
}

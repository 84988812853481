import dayjs from "dayjs"
import React from "react"

import { Button } from "../../components/shared/Buttons"
import Typography from "../../components/shared/Typography"

const BookableEventCard = ({ event, onClick }) => (
  <a
    className="group relative max-w-64 rounded-xl border border-gray-light text-center"
    href="#"
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}>
    {event.photoUrl ? (
      <img src={event.photoUrl} alt={event.name} className="box-content max-h-48 w-full rounded-t-xl object-cover" />
    ) : (
      <div className="aspect-square w-full rounded-t-xl bg-gray-light" />
    )}
    <div className="absolute left-0 top-0 rounded-tl-xl bg-black p-2 text-center text-white">
      <div className="text-sm uppercase">{dayjs(event.startsAt).format("MMM")}</div>
      <div className="text-4xl">{dayjs(event.startsAt).format("D")}</div>
    </div>
    <div className="flex flex-col gap-4 p-8">
      <Typography variant="h3" className="group-hover:underline">
        {event.name}
      </Typography>
      <div>
        {dayjs(event.startsAt).format("h:mm A")} - {dayjs(event.endsAt).format("h:mm A z")}
      </div>
      <div className="truncate">{event.description}</div>
      <Button>Edit event</Button>
    </div>
  </a>
)

export default BookableEventCard

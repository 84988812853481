import NiceModal from "@ebay/nice-modal-react"
import React from "react"

import AcceptTermsModal from "../../components/shared/AcceptTermsModal"
import { Button } from "../../components/shared/Buttons"
import { TabNavLink } from "../../components/shared/Links"
import ListBox from "../../components/shared/ListBox"
import { NewModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"

import ProfileChecklist from "./ProfileChecklist"

const NAV_ITEMS = [
  { title: "Details", path: "/portal/profile" },
  { title: "Credentials", path: "/portal/profile/credentials" },
  { title: "Endorsements", path: "/portal/profile/endorsements" },
  { title: "Photos", path: "/portal/profile/photos" },
  { title: "Locations & Hours", path: "/portal/profile/locations" },
  { title: "Services", path: "/portal/profile/services" },
  { title: "Misc", path: "/portal/profile/audience" }
]

const SELECT_ITEMS = NAV_ITEMS.map((item) => ({ value: item.path, label: `${item.title}` }))

const ProfileEditView = ({ path, children }) => {
  const { profileStatusData } = useProfileStatus()
  const profileVerified = profileStatusData.status === "Complete"
  const notVerified = profileStatusData.status === "Not Published"
  const modalId = "verification-modal"

  return (
    <>
      <div>
        <Typography variant="h3" as="h1">
          Profile
        </Typography>
        <Typography variant="subtitle" className="sm:hidden">
          Edit or add to your profile here.
        </Typography>
        {profileVerified && (
          <Typography variant="subtitle" className={notVerified ? "hidden text-red sm:block" : "hidden sm:block"}>
            Your profile is not {notVerified ? "verified" : "published"}.{" "}
          </Typography>
        )}
        <Button type="link" onClick={() => NiceModal.show(modalId)}>
          Learn more
        </Button>
        <div className="mt-4 flex gap-8 border-b border-gray-light sm:hidden">
          {NAV_ITEMS.map((item) => (
            <TabNavLink key={item.title} href={item.path} active={path === item.path}>
              {item.title}
            </TabNavLink>
          ))}
        </div>
        <div className="mt-2 hidden sm:block">
          <ListBox
            value={SELECT_ITEMS.find((item) => item.value === path)}
            onChange={(item) => {
              if (typeof window !== "undefined") window.location = item.value
            }}
            options={SELECT_ITEMS}
            buttonHint="(tap to navigate)"
          />
        </div>
        <div className="mt-4 flex items-start gap-8 pt-4 sm:mt-2">
          <div className="w-[572px] sm:w-full">{children}</div>
          <ProfileChecklist className="w-[360px] sm:hidden" />
        </div>
      </div>
      <NewModal id={modalId}>
        <ProfileChecklist className="border-none p-0" />
      </NewModal>
      <AcceptTermsModal showModal={profileStatusData?.showTermsModal} />
    </>
  )
}

export default ProfileEditView

import React, { useEffect, useState, useRef } from "react"
import Geocode from "react-geocode"
import Turbolinks from "turbolinks"

import useMenuOutsideAlerter from "../../hooks/useOutsideAlerter"
import { sendAmplitudeData } from "../../support/amplitude-react"

const LocationSearchBox = ({ query, onClick, setShow }) => {
  const locationSearchboxRef = useRef()
  useMenuOutsideAlerter(locationSearchboxRef, setShow)
  return (
    <div className="location-search-box" ref={locationSearchboxRef}>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}>
        <div className="location-search-row" onClick={onClick}>
          <div className="location-search-icon">
            <i className="icon-current-location" />
          </div>
          <div className="location-search-text">Use my current location</div>
        </div>
      </a>
      <a href={`/practitioner-search?query=${query}&virtual=true`}>
        <div className="location-search-row">
          <div className="location-search-icon">
            <i className="icon-virtual-filled" />
          </div>
          <div className="location-search-text">Browse virtual services</div>
        </div>
      </a>
    </div>
  )
}

const LocationSearch = ({
  setSearchLocation,
  searchLocation,
  setLatlng,
  setIsVirtual,
  query,
  isVirtual,
  onLocationFocus,
  shouldRedirect
}) => {
  const [value, setValue] = useState(isVirtual ? "" : searchLocation)
  const [show, setShow] = useState(false)
  const autoCompleteRef = useRef()
  const inputRef = useRef()
  const options = {
    types: ["locality", "sublocality", "neighborhood"]
  }

  const setValuesFromPlace = (place) => {
    if (!place?.geometry) return null

    const location = place.geometry.location
    const lat = typeof location.lat === "function" ? location.lat() : location.lat
    const lng = typeof location.lng === "function" ? location.lng() : location.lng

    setLatlng([`${lat}`, `${lng}`])
    setSearchLocation(place.formatted_address)
    setValue(place.formatted_address)
    sessionStorage.setItem("latlng", [`${lat}`, `${lng}`])
    sessionStorage.setItem("cityState", place.formatted_address)
    setIsVirtual(false)
    if (shouldRedirect) {
      sendAmplitudeData("location search submitted", { location: place.formatted_address })
      Turbolinks.visit(
        `/practitioner-search?query=${encodeURIComponent(
          $(".react-autosuggest__input").val()
        )}&searched_location=${encodeURIComponent(
          place.formatted_address
        )}&aroundLatLng[lat]=${lat}&aroundLatLng[lng]=${lng}`
      )
    }
  }

  const submit = (e, clicked) => {
    if (e.key === "Enter" || e.keyCode === 13 || clicked) {
      Geocode.fromAddress(value)
        .then((response) => {
          const place = response.results[0]
          setValuesFromPlace(place)
        })
        .catch((e) => {
          console.error(`Error geocoding address ${value}: ${e.message}`) // eslint-disable-line
        })
    }
  }

  const findMyLocation = () => {
    const geoLocation = window.navigator && window.navigator.geolocation
    if (geoLocation) {
      geoLocation.getCurrentPosition((position) => {
        setLatlng([`${position.coords.latitude}`, `${position.coords.longitude}`])
        sessionStorage.setItem("latlng", [`${position.coords.latitude}`, `${position.coords.longitude}`])
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then((response) => {
          const formattedAddressArray = response.results[0].formatted_address.split(", ")
          const lat = response.results[0].geometry.location.lat
          const lng = response.results[0].geometry.location.lng
          const city = formattedAddressArray[1]
          let state = formattedAddressArray[2]
          state = state.split(" ")[0]
          const cityState = `${city}, ${state}`
          setSearchLocation(cityState)
          setValue(cityState)
          sessionStorage.setItem("cityState", cityState)
          Turbolinks.visit(
            `/practitioner-search?query=${encodeURIComponent(query)}&searched_location=${encodeURIComponent(
              cityState
            )}&aroundLatLng[lat]=${lat}&aroundLatLng[lng]=${lng}`
          )
        })
      })
    }
  }

  useEffect(() => {
    const addPlaceChangedListener = async () => {
      while (!window.google) await new Promise((resolve) => setTimeout(resolve, 1000))

      autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options)

      autoCompleteRef.current.addListener("place_changed", async function () {
        try {
          const place = await autoCompleteRef.current.getPlace()
          setValuesFromPlace(place)
        } catch (e) {
          console.error(`Error updating place from Google autocomplete: ${e}`) // eslint-disable-line
        }
      })
    }
    addPlaceChangedListener()
  }, [])

  useEffect(() => {
    setValue(searchLocation)
  }, [searchLocation])

  useEffect(() => {
    if (sessionStorage.getItem("latlng")) {
      setLatlng(sessionStorage.getItem("latlng").split(","))
      if (sessionStorage.getItem("cityState")) {
        setSearchLocation(sessionStorage.getItem("cityState"))
        setValue(sessionStorage.getItem("cityState"))
      }
    } else {
      findMyLocation()
    }
  }, [])

  return (
    <div className="location-search-container">
      <input
        ref={inputRef}
        className="location-search-input"
        type="search"
        placeholder={"Location"}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onFocus={() => {
          setShow(true)
          if (onLocationFocus) {
            onLocationFocus()
          }
        }}
        onKeyUp={(e) => submit(e)}
      />
      <i className="icon-map-pin2" />
      <button className="button button-tertiary" onClick={(e) => submit(e, true)}>
        Search
      </button>
      {show && <LocationSearchBox query={query} onClick={findMyLocation} setShow={setShow} />}
    </div>
  )
}

export default LocationSearch

import { CurrencyDollarIcon, SparklesIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import PaymentIntegrations from "../../components/pro_portal/payments/PaymentIntegrations.jsx"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import { Toggle, Select } from "../../components/shared/Inputs.jsx"
import { UnderlinedLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"
import { useToast, ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

const UPDATE_PRACTICE_OFFLINE_OPTIONS = `
  mutation UpdatePracticeOfflineOptions($acceptsPaypal: Boolean, $acceptsVenmo: Boolean, $acceptsZelle: Boolean, $acceptsCash: Boolean, $acceptsCreditCard: Boolean) {
    updatePracticeOfflineOptions(acceptsPaypal: $acceptsPaypal, acceptsVenmo: $acceptsVenmo, acceptsZelle: $acceptsZelle, acceptsCash: $acceptsCash, acceptsCreditCard: $acceptsCreditCard) {
      result
      error
    }
  }
`

const UPDATE_PRACTICE_CURRENCY = `
  mutation UpdatePracticeCurrency($currency: String!) {
    updatePracticeCurrency(currency: $currency) {
      result
      error
    }
  }
`

const UPDATE_TIPPING_OPTION = `
  mutation UpdatePracticeAcceptsTipsMutation($acceptsTips: Boolean!) {
    updatePracticeAcceptsTips(acceptsTips: $acceptsTips) {
      result
      error
    }
  }
`

const currencyOptions = [
  { value: "USD", label: "USD" },
  { value: "CAD", label: "CAD" }
]

const PaymentSettings = ({
  stripeConnected,
  stripeDisplayName,
  acceptsCash,
  acceptsPaypal,
  acceptsZelle,
  acceptsVenmo,
  acceptsCreditCard,
  currencyEnabled,
  currency,
  acceptsTips,
  squareConnected,
  squareDisplayName,
  paymentProvider,
  paypalConnected,
  paypalEnabled
}) => {
  const [, updateOfflineOptions] = useMutation(UPDATE_PRACTICE_OFFLINE_OPTIONS)
  const [, updateCurrency] = useMutation(UPDATE_PRACTICE_CURRENCY)
  const [, updateTippingOption] = useMutation(UPDATE_TIPPING_OPTION)
  const { showToast } = useToast()

  const [editableCurrency, setEditableCurrency] = useState(currency)
  const [editableAcceptsCash, setEditableAcceptsCash] = useState(acceptsCash)
  const [editableAcceptsPaypal, setEditableAcceptsPaypal] = useState(acceptsPaypal)
  const [editableAcceptsZelle, setEditableAcceptsZelle] = useState(acceptsZelle)
  const [editableAcceptsVenmo, setEditableAcceptsVenmo] = useState(acceptsVenmo)
  const [editableAcceptsCreditCard, setEditableAcceptsCreditCard] = useState(acceptsCreditCard)

  const [editableAcceptsTips, setEditableAcceptsTips] = useState(acceptsTips)

  const paymentMethods = [
    {
      name: "Cash",
      state: editableAcceptsCash,
      setState: setEditableAcceptsCash,
      id: "acceptsCash",
      icon: "/images/payments-icon-cash.png"
    },
    {
      name: "Credit Card",
      state: editableAcceptsCreditCard,
      setState: setEditableAcceptsCreditCard,
      id: "acceptsCreditCard",
      icon: "/images/payments-icon-credit-card.png"
    },
    {
      name: "PayPal",
      state: editableAcceptsPaypal,
      setState: setEditableAcceptsPaypal,
      id: "acceptsPaypal",
      icon: "/images/payments-icon-paypal.png"
    },
    {
      name: "Venmo",
      state: editableAcceptsVenmo,
      setState: setEditableAcceptsVenmo,
      id: "acceptsVenmo",
      icon: "/images/payments-icon-venmo.png"
    },
    {
      name: "Zelle",
      state: editableAcceptsZelle,
      setState: setEditableAcceptsZelle,
      id: "acceptsZelle",
      icon: "/images/payments-icon-zelle.png"
    }
  ]

  const onChange = (methodId, newValue) => {
    const variables = paymentMethods.reduce(
      (acc, { id, state }) => ({
        ...acc,
        [id]: id === methodId ? newValue : state
      }),
      {}
    )

    updateOfflineOptions(variables)
      .then((result) => {
        if (result.data?.updatePracticeOfflineOptions?.result) {
          showToast("Settings updated successfully.")
        } else {
          const error =
            result.error?.message?.replace("[GraphQL]", "") ||
            result.data?.updatePracticeOfflineOptions?.error ||
            "Please try later."
          showToast({ type: "error", content: error })
        }
      })
      .catch((error) => {
        showToast({ type: "error", content: error.message })
      })
  }

  const onChangeAcceptsTips = (newValue) => {
    updateTippingOption({ acceptsTips: newValue })
      .then((result) => {
        if (result.data?.updatePracticeAcceptsTips?.result) {
          showToast("Settings updated successfully.")
          setEditableAcceptsTips(newValue)
        } else {
          const error =
            result.error?.message?.replace("[GraphQL]", "") ||
            result.data?.updatePracticeAcceptsTips?.error ||
            "Please try later."
          showToast({ type: "error", content: error })
          setEditableAcceptsTips(!newValue)
        }
      })
      .catch((error) => {
        showToast({ type: "error", content: error.message })
        setEditableAcceptsTips(!newValue)
      })
  }

  return (
    <div>
      <Breadcrumbs links={[{ href: "/portal/settings", text: "Settings" }]} activePage="Payments" />
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          Payments
        </Typography>
        <Typography variant="body" className="text-gray-dark sm:hidden">
          Accept online payments with Heal.me so you get paid on time, every time.
        </Typography>
      </div>
      <div className="grid grid-cols-2 gap-8 sm:grid-cols-1">
        <div className="w-full">
          <PaymentIntegrations
            stripeConnected={stripeConnected}
            stripeDisplayName={stripeDisplayName}
            squareConnected={squareConnected}
            squareDisplayName={squareDisplayName}
            paymentProvider={paymentProvider}
            paypalConnected={paypalConnected}
            paypalEnabled={paypalEnabled}
          />
          {currencyEnabled && (
            <>
              <Typography as="h2" variant="h5" className="mb-2 mt-9">
                Currency
              </Typography>
              <Typography variant="body" className="mb-4 text-gray-dark sm:hidden">
                You can currently list and accept payments in USD or CAD.
              </Typography>
              <div className="mb-4 flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
                <div className="flex flex-row items-center">
                  <div className="mr-4 flex w-fit items-center justify-center rounded-xl bg-gray-ultralight p-4">
                    <CurrencyDollarIcon className="h-7 w-7" />
                  </div>
                  <Typography variant="h5">Selected Currency</Typography>
                </div>
                <Select
                  options={currencyOptions}
                  value={editableCurrency}
                  id="currency-select"
                  onChange={(e) => {
                    setEditableCurrency(e.target.value)
                    updateCurrency({ currency: e.target.value })
                      .then((result) => {
                        if (result.data?.updatePracticeCurrency?.result) {
                          showToast("Currency updated successfully.")
                        } else {
                          const error =
                            result.error?.message?.replace("[GraphQL]", "") ||
                            result.data?.updateCurrency?.error ||
                            "Please try later."
                          showToast({ type: "error", content: error })
                        }
                      })
                      .catch((error) => {
                        showToast({ type: "error", content: error.message })
                      })
                  }}
                />
              </div>
            </>
          )}
          <Typography as="h2" variant="h5" className="mb-2 mt-9">
            Offline Options
          </Typography>
          {paymentMethods.map((method, index) => (
            <div
              key={index}
              className="mb-4 flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
              <div className="flex flex-row items-center">
                <div className="mr-4 flex w-fit items-center justify-center rounded-xl bg-gray-ultralight p-4">
                  <img src={method.icon} width="32px" alt={`${method.name} icon`} />
                </div>
                <Typography variant="h5" className="mb-2">
                  {method.name}
                </Typography>
              </div>
              <Toggle
                id={`toggle-${method.id}`}
                checked={method.state}
                onChange={() => {
                  const newValue = !method.state
                  method.setState(newValue)
                  onChange(method.id, newValue)
                }}
              />
            </div>
          ))}

          <Typography as="h2" variant="h5" className="mb-2 mt-9">
            Tipping
          </Typography>
          <div className="mb-4 flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
            <div className="flex flex-row items-center">
              <div className="mr-4 flex w-fit items-center justify-center rounded-xl bg-gray-ultralight p-4">
                <SparklesIcon className="h-7 w-7" />
              </div>
              <div className="flex flex-col">
                <Typography variant="h5" className="mb-2">
                  Enable Tipping
                </Typography>
                <Typography variant="body" className="text-gray-dark">
                  Customers will have the option to add a tip to their payment.
                </Typography>
              </div>
            </div>
            <Toggle
              checked={editableAcceptsTips}
              onChange={() => {
                setEditableAcceptsTips(!editableAcceptsTips)
                onChangeAcceptsTips(!editableAcceptsTips)
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="mb-4 ml-8 mt-8 rounded-xl border border-gray-light p-8">
            <Typography as="h2" variant="h5" className="mb-2">
              How do offline options work?
            </Typography>
            <Typography variant="body" className="mb-2 text-gray-dark">
              When you add information for PayPal, Cash, Zelle, or Venmo, you&apos;re giving the option for clients to
              pay
              <span className="font-bold"> off of the Heal.me platform </span>
              and pay you directly.
            </Typography>
            <Typography variant="body" className="text-gray-dark">
              Because payments will be handled off of the Heal.me platform, you must ensure you&apos;ve collected and
              recorded payments and transactions in your{" "}
              <UnderlinedLink href="/portal/payments">Payments page</UnderlinedLink>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ProPaymentSettings(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <PaymentSettings {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

import dayjs from "dayjs"
import React, { useState } from "react"
import { useMutation } from "urql"

import { isPaidOnProvider, paymentProviderName } from "../../@core/appointment/appointment.model"
import { useManualBooking } from "../../contexts/ManualBookingContext"
import { useToast } from "../../contexts/ToastContext"
import { formatPrice } from "../../utils/utils"

import DatePicker from "./DatePicker"
import { Label, CurrencyInput, TextArea } from "./Inputs"
import { Banner } from "./Layout"
import { AnimatedModal } from "./Modal"

const CREATE_ADJUSTMENT = `
  mutation CreateAdjustment($appointmentId: ID, $adjustedAt: String!, $amountCents: Int, $note: String, ) {
    createAdjustment(appointmentId: $appointmentId, adjustedAt: $adjustedAt, amountCents: $amountCents, note: $note ) {
      result
      errors
      adjustment {
        id
        adjustedAt
        amountCents
        note
      }
    }
  }
`

export default function AdjustmentModal({ showAdjustmentModal, hideModal }) {
  const { appointment, setAppointment } = useManualBooking()
  const [note, setNote] = useState()
  const [adjustmentDate, setAdjustmentDate] = useState(new Date())
  const [adjustmentAmount, setAdjustmentAmount] = useState(appointment?.fullTotal)
  const [loading, setLoading] = useState(false)

  const [, addAdjustment] = useMutation(CREATE_ADJUSTMENT)
  const { showToast } = useToast()

  const handleAmountChange = (amountCents) => {
    if (amountCents <= appointment.fullTotal) {
      setAdjustmentAmount(amountCents)
    } else {
      showToast({
        content: `Adjustment amount cannot exceed the refundable amount (${formatPrice(appointment.fullTotal)})`,
        type: "error"
      })
    }
  }

  return (
    <AnimatedModal
      visible={showAdjustmentModal}
      className="overflow-visible"
      hideModal={hideModal}
      header="Add refund or adjustment"
      actionButtonCopy={loading ? "Adding..." : "Add"}
      saveDisabled={loading}
      showFooter={true}
      overflowVisible={true}
      onSave={() => {
        setLoading(true)
        addAdjustment({
          appointmentId: appointment.id,
          adjustedAt: dayjs(adjustmentDate).format("YYYY-MM-DDTHH:mm:ssZ"),
          amountCents: adjustmentAmount,
          note
        }).then((result) => {
          setLoading(false)
          if (result.data?.createAdjustment?.result === "success") {
            const newAdjustment = result.data.createAdjustment.adjustment
            const updatedAdjustments = [...(appointment.adjustments || []), newAdjustment].filter(
              (adj) => adj !== undefined
            )
            setAppointment({ ...appointment, adjustments: updatedAdjustments })
            showToast("Adjustment added ")
          } else {
            showToast({
              content: `We weren't able to add this adjustment: ${result.data.createAdjustment.errors.join()}`,
              type: "error"
            })
          }
          hideModal()
        })
      }}>
      <p className="mb-4">You can note any refunds or adjustments to this payment here for your records.</p>
      {isPaidOnProvider(appointment) && (
        <Banner type="warning" className="mb-4">
          This payment was made via {paymentProviderName(appointment)}. This will issue a true refund.
        </Banner>
      )}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <Label>Refund amount</Label>
          <CurrencyInput
            placeholder="Amount"
            id="adjustment-input"
            value={adjustmentAmount}
            onChange={(amountCents) => handleAmountChange(amountCents)}
          />
        </div>
        <div className="flex flex-col">
          <Label>Adjustment date</Label>
          <DatePicker className="w-full" selected={adjustmentDate} onChange={(date) => setAdjustmentDate(date)} />
        </div>
      </div>
      <TextArea placeholder="Add a note here" rows="2" onChange={(e) => setNote(e.target.value)} value={note} />
    </AnimatedModal>
  )
}

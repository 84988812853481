import { PaymentElement } from "@stripe/react-stripe-js"
import React from "react"

import SquarePaymentForm from "./SquarePaymentsForm"

export default function PaymentProviderCardForm({ practice, options }) {
  switch (practice.paymentProvider) {
    case "stripe":
      return <PaymentElement options={options} />
    case "square":
      return <SquarePaymentForm />
  }
}

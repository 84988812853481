import { PencilIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import React from "react"

import { Button } from "../../components/shared/Buttons"
import { ProfilePageEmptyStateBox } from "../../components/shared/Layout"
import { formatPrice } from "../../utils/utils"

import { useBookableEventTypes } from "./BookableEventTypesContext"

const EventTypeRow = ({ eventType, onEdit }) => (
  <tr className="border-b border-gray">
    <td className="px-2 py-4 align-middle">{eventType.name}</td>
    <td className="px-2 py-4 align-middle">
      {eventType.amountCents > 0 ? formatPrice(eventType.amountCents) : "Free"}
    </td>
    <td className="px-2 py-4 align-middle">{eventType.capacity || "Unlimited"}</td>
    <td className="px-2 py-4 align-middle">{eventType.kind === "in_person" ? "In-person" : "Virtual"}</td>
    <td className="px-2 py-4 text-right align-middle">
      <div className="flex justify-end gap-2">
        {eventType.url && (
          <a
            href={eventType.url}
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full bg-gray-light p-2 text-xs text-gray-dark transition hover:bg-gray"
            title="Open event page">
            <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          </a>
        )}
        <button
          className="rounded-full bg-gray-light p-2 text-xs text-gray-dark transition hover:bg-gray"
          onClick={() => onEdit(eventType)}>
          <PencilIcon className="h-4 w-4" />
        </button>
      </div>
    </td>
  </tr>
)

const BookableEventTypesTable = () => {
  const { bookableEventTypes, addEventType, editEventType } = useBookableEventTypes()

  if (bookableEventTypes.length === 0) {
    return (
      <ProfilePageEmptyStateBox onButtonClick={addEventType}>
        You don&apos;t have any event types yet.
      </ProfilePageEmptyStateBox>
    )
  }

  return (
    <div className="mt-6 w-full sm:mt-0">
      <div className="overflow-hidden rounded-lg border bg-white">
        <table className="w-full table-auto">
          <thead className="bg-gray-light">
            <tr>
              <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-dark">Name</th>
              <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-dark">Price</th>
              <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-dark">
                Capacity
              </th>
              <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-dark">
                Location
              </th>
              <th className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-dark">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {bookableEventTypes.map((eventType) => (
              <EventTypeRow key={eventType.id} eventType={eventType} onEdit={editEventType} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-end">
        <Button onClick={addEventType}>Add New Event Type</Button>
      </div>
    </div>
  )
}

export default BookableEventTypesTable

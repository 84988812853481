export const UPSERT_BOOKABLE_EVENT_TYPE_MUTATION = `
  mutation UpsertBookableEventType(
    $id: ID,
    $name: String!,
    $description: String,
    $instructions: String,
    $capacity: Int,
    $amountCents: Int,
    $location: String!,
    $kind: String!,
    $slug: String!
  ) {
    upsertBookableEventType(
      id: $id,
      name: $name,
      description: $description,
      instructions: $instructions,
      capacity: $capacity,
      amountCents: $amountCents,
      location: $location,
      kind: $kind,
      slug: $slug
    ) {
      result
      errors
      bookableEventType {
        id name description instructions capacity amountCents location kind slug photoUrl url
      }
    }
  }
`

export const UPDATE_BOOKABLE_EVENT_TYPE_PHOTO_MUTATION = `
  mutation UpdateBookableEventTypePhoto($handle: String!, $id: ID!) {
    updateBookableEventTypePhoto(handle: $handle, id: $id) { result errors photoUrl }
  }
`

export const REMOVE_BOOKABLE_EVENT_TYPE_PHOTO_MUTATION = `
  mutation RemoveBookableEventTypePhoto($id: ID!) {
    removeBookableEventTypePhoto(id: $id) { result errors }
  }
`

export const DELETE_BOOKABLE_EVENT_TYPE_MUTATION = `
  mutation DeleteBookableEventType($id: ID!) {
    deleteBookableEventType(id: $id) { result errors }
  }
`

import dayjs from "dayjs"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useMutation } from "urql"

import {
  DELETE_BOOKABLE_EVENT_MUTATION,
  REMOVE_BOOKABLE_EVENT_PHOTO_MUTATION,
  UPDATE_BOOKABLE_EVENT_PHOTO_MUTATION,
  UPSERT_BOOKABLE_EVENT_MUTATION
} from "./mutations"

export const defaultNewEvent = {
  startsAt: dayjs()
    .set("hour", dayjs().get("hour") + 1)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .toISOString(),
  endsAt: dayjs()
    .set("hour", dayjs().get("hour") + 2)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .toISOString(),
  allDay: false,
  timeZone: null,
  bookableEventTypeId: null
}

export const BookableEventsContext = createContext()

export const BookableEventsProvider = ({ children, bookableEvents: bookableEventsProp, previousLocations }) => {
  const [showFlyout, setShowFlyout] = useState(false)
  const [bookableEvents, setBookableEvents] = useState(bookableEventsProp)
  const [googlePlacesLoaded, setGooglePlacesLoaded] = useState(false)
  const [bookableEvent, setBookableEvent] = useState(defaultNewEvent)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [rescheduleModalVisible, setRescheduleModalVisible] = useState(false)
  const [bookableEventTab, setBookableEventTab] = useState("details") // details or registrants

  const [{ fetching: upsertMutationFetching }, upsertBookableEvent] = useMutation(UPSERT_BOOKABLE_EVENT_MUTATION)
  const [{ fetching: photoMutationFetching }, updatePhoto] = useMutation(UPDATE_BOOKABLE_EVENT_PHOTO_MUTATION)
  const [{ fetching: deleteMutationFetching }, deleteBookableEvent] = useMutation(DELETE_BOOKABLE_EVENT_MUTATION)
  const [{ fetching: removePhotoMutationFetching }, removePhoto] = useMutation(REMOVE_BOOKABLE_EVENT_PHOTO_MUTATION)

  const fetching =
    upsertMutationFetching || photoMutationFetching || deleteMutationFetching || removePhotoMutationFetching

  const getDefaultNewEvent = () => ({
    ...defaultNewEvent,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  useEffect(() => {
    // do this in an effect to avoid SSR rehydration issues
    setBookableEvent(getDefaultNewEvent())

    async function initPlaces() {
      await window.google.maps.importLibrary("places")
      setGooglePlacesLoaded(true)
    }

    initPlaces()
  }, [])

  // TODO: consider removing this, as it's only used in BookableEventsPage
  const updateBookableEvents = (newEvent) => {
    if (!bookableEvents) return

    setBookableEvents((prevEvents) => {
      const newEvents = [...prevEvents]
      const index = newEvents.findIndex((s) => s.id === newEvent.id)
      if (index !== -1) {
        newEvents[index] = newEvent
      } else {
        newEvents.push(newEvent)
      }

      return newEvents
    })
  }

  const resetBookableEventState = () => {
    setBookableEvent(getDefaultNewEvent())
    setBookableEventTab("details")
  }

  const value = {
    bookableEvents,
    setBookableEvents,
    showFlyout,
    setShowFlyout,
    bookableEvent,
    setBookableEvent,
    getDefaultNewEvent,
    previousLocations,
    googlePlacesLoaded,
    fetching,
    upsertBookableEvent,
    updatePhoto,
    deleteBookableEvent,
    removePhoto,
    updateBookableEvents,
    deleteModalVisible,
    setDeleteModalVisible,
    rescheduleModalVisible,
    setRescheduleModalVisible,
    bookableEventTab,
    setBookableEventTab,
    resetBookableEventState
  }

  return <BookableEventsContext.Provider value={value}>{children}</BookableEventsContext.Provider>
}

export const useBookableEvents = () => useContext(BookableEventsContext)

import { PencilIcon, TrashIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import { IconButton } from "../../components/shared/Buttons"
import { useToast } from "../../contexts/ToastContext"

import DiscountCodeFlyout from "./DiscountCodeFlyout"

const DELETE_DISCOUNT_CODE = `
  mutation DeleteDiscountCode($id: ID!) {
    deleteDiscountCode(id: $id) {
      result
    }
  }
`

export const defaultDiscountCode = {
  code: "",
  kind: "fixed",
  amountCents: null,
  percent: null,
  expiresAt: null,
  maxRedemptions: null,
  combinationLimit: 1,
  hasExpiration: false,
  hasMaxRedemptions: false,
  applyToSpecificServices: false,
  serviceIds: []
}

const DiscountCodesTable = ({
  discountCodes: discountCodesProp,
  services,
  editingCode,
  setEditingCode,
  discountCodesFlyoutVisible,
  setDiscountCodesFlyoutVisible
}) => {
  const [discountCodes, setDiscountCodes] = useState(discountCodesProp)
  const [, deleteDiscountCode] = useMutation(DELETE_DISCOUNT_CODE)

  const { showToast } = useToast()

  const handleDelete = async (code) => {
    if (!window.confirm("Are you sure you want to delete this coupon?")) return

    const result = await deleteDiscountCode({ id: code.id })

    if (result.data?.deleteDiscountCode?.result === "success") {
      setDiscountCodes((codes) => codes.filter((c) => c.id !== code.id))
      showToast({ content: "Coupon deleted successfully", type: "success" })
    } else {
      showToast({
        content: result.error || "Error deleting coupon",
        type: "error"
      })
    }
  }

  return (
    <div className="p-4">
      <table className="w-full">
        <thead>
          <tr className="border-b border-gray-light">
            <th className="px-6 py-4 text-left font-semibold">Code</th>
            <th className="px-6 py-4 text-left font-semibold">Type</th>
            <th className="px-6 py-4 text-left font-semibold">Amount</th>
            <th className="px-6 py-4 text-left font-semibold">Expires</th>
            <th className="px-6 py-4 text-left font-semibold">Usage</th>
            <th className="px-6 py-4 text-left font-semibold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {discountCodes.map((code) => (
            <tr key={code.id} className="border-b border-gray-light transition-colors hover:bg-gray-ultralight">
              <td className="flex items-center gap-1 px-6 py-3">
                <span>{code.code}</span>
                <IconButton
                  Icon={DocumentDuplicateIcon}
                  onClick={() => navigator.clipboard.writeText(code.code)}
                  tooltip="Copy code"
                />
              </td>
              <td className="px-6 py-4">{code.kind}</td>
              <td className="px-6 py-4">{code.amountCents ? `$${code.amountCents / 100}` : `${code.percent}%`}</td>
              <td className="px-6 py-4">
                {code.expiresAt ? new Date(code.expiresAt).toLocaleDateString() : "No expiration"}
              </td>
              <td className="px-6 py-4">
                {code.redemptionCount} / {code.maxRedemptions || "∞"}
              </td>
              <td className="px-6 py-3">
                <div className="flex gap-2">
                  <IconButton
                    Icon={PencilIcon}
                    onClick={() => {
                      setEditingCode(code)
                      setDiscountCodesFlyoutVisible(true)
                    }}
                    tooltip="Edit"
                  />
                  <IconButton
                    Icon={TrashIcon}
                    onClick={() => handleDelete(code)}
                    className="text-red"
                    tooltip="Delete"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingCode && (
        <DiscountCodeFlyout
          services={services}
          setDiscountCodes={setDiscountCodes}
          visible={discountCodesFlyoutVisible}
          setVisible={setDiscountCodesFlyoutVisible}
          editingCode={editingCode}
          setEditingCode={setEditingCode}
        />
      )}
    </div>
  )
}

export default DiscountCodesTable

import { gql } from "@urql/core"

export const UPSERT_WEBSITE_METADATA_MUTATION = gql`
  mutation UpsertWebsiteMetadata($input: UpsertWebsiteMetadataInput!) {
    upsertWebsiteMetadata(input: $input) {
      result
      errors
      websiteMetadata {
        id
        title
        description
        ogSiteName
        primaryModality
      }
    }
  }
`

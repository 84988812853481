import { CSVBoxButton } from "@csvbox/react"
import React, { useState } from "react"
import styled from "styled-components"
import { useMutation } from "urql"

import { sendAmplitudeData } from "../../support/amplitude-react"
import { Button } from "../shared/Buttons"

import DesktopH5 from "./DesktopH5"
import Modal from "./Modal"
import Toast from "./Toast"

const InviteClientsMutation = `
  mutation InviteClients($clients: [ClientInput!]!) {
    inviteClients (clients: $clients) {
      erroredClients {
        email
      }
    }
  }
`

const InviteBox = styled.div`
  border: 1px solid #c8d6df;
  padding: 24px;
  margin-bottom: 16px;

  i {
    margin-right: 9px;
    font-size: 18px;
  }

  .content-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  h4 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #0b3954;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export default function ClientUpload({ practice, checkImport, className }) {
  const [, inviteClients] = useMutation(InviteClientsMutation)
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [launchImport, setLaunchImport] = useState(false)
  const [importSuccess, setImportSuccess] = useState(false)

  const handleClientImport = (result, data) => {
    const clientsForUpload = data.rows.map((uploadedClient) => ({
      email: uploadedClient["email"],
      firstName: uploadedClient["first_name"],
      lastName: uploadedClient["last_name"],
      phone: uploadedClient["phone"],
      notes: uploadedClient["notes"],
      clientType: "invited",
      invitedFrom: "csv_import"
    }))

    if (result) {
      inviteClients({ clients: clientsForUpload }).then((res) => {
        const inviteErrors = res.data.inviteClients.erroredClients.length

        if (res.error) {
          setShowAlert(true)
          setAlertContent(`Error importing clients: ${res.error} Please check your data and try again.`)
          setShowModal(false)
        } else {
          sendAmplitudeData("import clients submitted", {
            "Total Rows": data.rows.length,
            "Errored Invites": inviteErrors
          })
          setShowAlert(true)
          setImportSuccess(true)
          setShowModal(false)
          setAlertContent(
            `${data.row_success - inviteErrors} clients invited successfully
            ${inviteErrors > 0 ? `\n Could not invite ${inviteErrors} clients` : ""}.`
          )
        }
      })
    } else {
      console.log("Upload fail")
    }
  }

  return (
    <div className={className}>
      {checkImport ? (
        <>
          <Button type="primary" size="small" onClick={() => setShowModal(true)}>
            Get started
          </Button>
          {launchImport ? (
            <CSVBoxButton
              licenseKey="XSwq17w1MiVmHnvStM7RmbA4JQvcTp"
              user={{
                user_id: practice.id
              }}
              onImport={handleClientImport}
              render={(launch) => {
                setShowModal(false)
                launch()
              }}>
              Import
            </CSVBoxButton>
          ) : (
            <>
              <Modal
                open={showModal}
                closeModal={() => {
                  setShowModal(false)
                }}
                header={<DesktopH5 content="Invite your clients" />}
                footer={<></>}
                size="small">
                <a onClick={() => setLaunchImport(true)}>
                  <InviteBox>
                    <div className="content-row">
                      <i className="icon-invite-clients" />
                      <h4>Invite clients</h4>
                    </div>
                    Got a client list? You can upload it before booking your first appointment.
                  </InviteBox>
                </a>
                <a onClick={() => (window.location.href = "/portal/calendar#create-appointment")}>
                  <InviteBox>
                    <h4>No thanks, I&apos;m ready to book.</h4>
                  </InviteBox>
                </a>
              </Modal>
            </>
          )}
        </>
      ) : (
        <CSVBoxButton
          licenseKey="XSwq17w1MiVmHnvStM7RmbA4JQvcTp"
          user={{
            user_id: practice.id
          }}
          onImport={handleClientImport}
          render={(launch) => (
            <Button type="tertiary" size="small" onClick={launch}>
              Invite clients
            </Button>
          )}>
          Import
        </CSVBoxButton>
      )}
      <Toast visible={showAlert} setVisible={setShowAlert}>
        {alertContent} {importSuccess && <a onClick={() => (window.location.href = "/portal/clients")}>View</a>}
      </Toast>
    </div>
  )
}

import React from "react"

import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

import ContentTab from "./ContentTab"
import { WebsiteBuilderProvider } from "./WebsiteBuilderContext"
import WebsitePreviewView from "./WebsitePreviewView"

import "../../components/shared/wysiwyg.sass"

export default function WebsiteBuilderPage(props) {
  return (
    <ToastProvider>
      <GraphQLProvider>
        <WebsiteBuilderProvider {...props}>
          <WebsitePreviewView>
            <div className="p-8 pt-6">
              <ContentTab />
            </div>
          </WebsitePreviewView>
        </WebsiteBuilderProvider>
      </GraphQLProvider>
    </ToastProvider>
  )
}

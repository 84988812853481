import { CalendarIcon } from "@heroicons/react/24/solid"
import React from "react"
import { twMerge } from "tailwind-merge"

const calendarImages = {
  google: "/images/calendar-icon-google.png",
  apple: "/images/calendar-icon-icloud.png",
  live_connect: "/images/calendar-icon-outlook.png"
}

const CalIcon = ({ className, iconClassName, provider }) => (
  <div
    className={twMerge(
      "flex h-7 w-7 flex-none items-center justify-center rounded-full border border-gray",
      className
    )}>
    {calendarImages[provider] ? (
      <img alt={`${provider} icon`} src={calendarImages[provider]} className={twMerge("h-4 w-4", iconClassName)} />
    ) : (
      <CalendarIcon className={twMerge("h-4 w-4", iconClassName)} />
    )}
  </div>
)

export default CalIcon

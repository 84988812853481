import React, { useMemo } from "react"
import ReactSelect from "react-select"
import { twMerge } from "tailwind-merge"

import { selectStyling } from "../../utils/utils"

const ServiceSelector = ({ services, selectedServiceIds, onChange, className, disabled }) => {
  const serviceOptions = useMemo(
    () =>
      services.map((service) => ({
        value: service.id,
        label: service.name
      })),
    [services]
  )

  return (
    <div className={twMerge(className, disabled && "pointer-events-none opacity-50")}>
      <ReactSelect
        isMulti
        options={serviceOptions}
        value={serviceOptions.filter((option) => selectedServiceIds.includes(option.value))}
        onChange={(selected) => !disabled && onChange(selected ? selected.map((s) => s.value) : [])}
        styles={selectStyling()}
        placeholder="Select services..."
        disabled={disabled}
      />
    </div>
  )
}

export default ServiceSelector

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { Bars3Icon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid"
import React, { Fragment, useState } from "react"
import { twMerge } from "tailwind-merge"

import ProInbox from "../components/pro_portal/ProInbox"
import { getSideNavigationItems } from "../utils/sideNavigationUtils"

import { Button } from "./shared/Buttons"
import { Divider, Flyout } from "./shared/Layout"

const PortalHeader = ({
  proUrl,
  proName,
  proEmail,
  photoUrl,
  eventsEnabled,
  intakeFormsEnabled,
  customDomainEnabled
}) => {
  const mobileMenuProps = {
    proName,
    proEmail,
    proUrl,
    photoUrl,
    eventsEnabled,
    intakeFormsEnabled,
    customDomainEnabled
  }

  return (
    <div className="fixed right-0 top-0 z-20 w-full border-b border-gray-light bg-white text-black">
      <div className="flex items-center justify-between">
        <a className="p-4 min_md:invisible" href="/">
          <img src="/images/healme_logo.svg" alt="Logo" />
        </a>
        <div className="flex items-center justify-end p-4 sm:py-2">
          <div className="flex items-center">
            <a href={proUrl} className="mr-4 sm:hidden" target="_blank" rel="noreferrer">
              <Button type="tertiary">Preview website</Button>
            </a>
            <ProInbox />
            <div className="md:hidden">
              <UserDropdown photoUrl={photoUrl} proName={proName} proEmail={proEmail} proUrl={proUrl} />
            </div>
            <div className="pl-4 min_md:hidden">
              <MobileMenuHambuger mobileMenuProps={mobileMenuProps} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HealMenuItem = ({ className, children, ...rest }) => (
  <MenuItem className={twMerge("mb-0 block px-6 py-2", className)} {...rest}>
    {children}
  </MenuItem>
)

const UserDropdown = ({ photoUrl, proName, proEmail, proUrl }) => (
  <Menu as="div" className="relative inline-block text-left">
    {({ open }) => (
      <>
        <div>
          <MenuButton className="inline w-full justify-center rounded-full">
            <img className="inline h-10 w-10 rounded-full" src={photoUrl} />
          </MenuButton>
        </div>

        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <MenuItems
            static
            className="absolute right-0 mt-2 w-[180px] origin-top-right rounded-xl border border-gray-light bg-white py-2 shadow-lg">
            <HealMenuItem onClick={(e) => e.preventDefault()}>
              <span className="truncate font-bold">{proName}</span>
            </HealMenuItem>
            <HealMenuItem className="pt-0" onClick={(e) => e.preventDefault()}>
              <span className="truncate text-sm text-gray-dark">{proEmail}</span>
            </HealMenuItem>
            <Divider className="my-2" />
            <HealMenuItem className="min_md:hidden" as="div">
              <a
                className="block w-full rounded border border-gray bg-white py-1.5 text-center font-bold focus:outline-none focus:ring-2 focus:ring-blue active:bg-gray-light"
                target="_blank"
                href={proUrl}
                rel="noreferrer">
                Preview website
              </a>
            </HealMenuItem>
            <HealMenuItem className="hover:bg-gray-ultralight">
              <a href="/portal/settings/personal">My Account</a>
            </HealMenuItem>
            <HealMenuItem className="hover:bg-gray-ultralight md:hidden">
              <a href="/portal/clients">Invite Clients</a>
            </HealMenuItem>
            <Divider className="my-2" />
            <HealMenuItem className="hover:bg-gray-ultralight">
              <a href="/logout" className="block text-gray-dark">
                Log out
              </a>
            </HealMenuItem>
          </MenuItems>
        </Transition>
      </>
    )}
  </Menu>
)

const MobileMenuHambuger = ({ mobileMenuProps }) => {
  const [showFlyout, setShowFlyout] = useState(false)

  return (
    <>
      <a className="relative" onClick={() => setShowFlyout(true)}>
        <Bars3Icon className="h-8 w-8 text-gray-dark" />
      </a>
      <MobileMenuFlyout
        visible={showFlyout}
        closeFlyout={() => setShowFlyout(false)}
        mobileMenuProps={mobileMenuProps}
      />
    </>
  )
}

const MobileMenuFlyout = ({ visible, closeFlyout, mobileMenuProps }) => {
  const {
    proName,
    proEmail,
    proUrl,
    photoUrl,
    eventsEnabled,
    intakeFormsEnabled,
    customDomainEnabled,
    websitePlan,
    websiteBuilderEnabled
  } = mobileMenuProps

  const [openItems, setOpenItems] = useState({})

  const toggleItem = (itemName) => {
    setOpenItems((prev) => ({
      ...prev,
      [itemName]: !prev[itemName]
    }))
  }

  const navigationItems = getSideNavigationItems({
    eventsEnabled,
    intakeFormsEnabled,
    customDomainEnabled,
    websitePlan,
    websiteBuilderEnabled
  })

  return (
    <Flyout header="Menu" visible={visible} closeFlyout={closeFlyout}>
      <div className="mb-4 flex items-center">
        <img className="h-10 w-10 rounded-full" src={photoUrl} />
        <div className="ml-4">
          <div className="font-bold">{proName}</div>
          <div className="text-sm text-gray-dark">{proEmail}</div>
        </div>
      </div>
      <a
        className="block w-full rounded border border-gray bg-white py-1.5 text-center font-bold focus:outline-none focus:ring-2 focus:ring-blue active:bg-gray-light"
        target="_blank"
        href={proUrl}
        rel="noreferrer">
        Preview website
      </a>
      <Divider className="my-2" />
      {navigationItems.map((item) => (
        <div key={item.name} className="my-4">
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() => item.children && toggleItem(item.name)}>
            <a href={item.children ? "#" : item.href} className="font-bold" {...(item.id ? { id: item.id } : {})}>
              {item.name}
            </a>
            {item.children && (
              <span>
                {openItems[item.name] ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
              </span>
            )}
          </div>

          {item.children && openItems[item.name] && (
            <div className="ml-4 mt-2">
              {item.children.map((child) => (
                <a key={child.name} href={child.href} className="flex px-4 py-2 text-gray-dark">
                  {child.name}
                </a>
              ))}
            </div>
          )}
        </div>
      ))}
      <Divider className="my-4" />
      <div>
        <a href="/logout" className="flex text-gray-dark">
          Log out
        </a>
      </div>
    </Flyout>
  )
}

export default PortalHeader

import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { EditableValue, Input, TextArea } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { GENERATE_IMAGE_ALT_TEXT_MUTATION } from "../../graphql/mutations/generateImageAltText"
import { GENERATE_SITE_METADATA_MUTATION } from "../../graphql/mutations/generateSiteMetadata"
import { UPSERT_WEBSITE_METADATA_MUTATION } from "../../graphql/mutations/upsertWebsiteMetadata"

const SeoSettings = ({ websiteMetadata: initialWebsiteMetadata }) => {
  const [websiteMetadata, setWebsiteMetadata] = useState(initialWebsiteMetadata)
  const [lastSavedValues, setLastSavedValues] = useState(initialWebsiteMetadata)

  const [{ fetching }, upsertMetadata] = useMutation(UPSERT_WEBSITE_METADATA_MUTATION)
  const { showToast } = useToast()
  const [{ fetching: generating }, generateMetadata] = useMutation(GENERATE_SITE_METADATA_MUTATION)
  const [{ fetching: generatingAlt }, generateAltText] = useMutation(GENERATE_IMAGE_ALT_TEXT_MUTATION)

  const handleSave = (field, value) => {
    upsertMetadata({
      input: {
        [field]: value
      }
    }).then((result) => {
      if (result?.data?.upsertWebsiteMetadata?.result === "success") {
        setLastSavedValues((prev) => ({ ...prev, [field]: value }))
        showToast("SEO settings updated successfully")
      } else {
        const errorMessage = result?.data?.upsertWebsiteMetadata?.errors?.join(" ") || "An error occurred"
        showToast({
          content: errorMessage,
          type: "error"
        })
      }
    })
  }

  const handleGenerate = () => {
    generateMetadata().then((result) => {
      if (result?.data?.generateSiteMetadata?.result === "success") {
        const newMetadata = result.data.generateSiteMetadata.websiteMetadata
        setWebsiteMetadata(newMetadata)
        setLastSavedValues(newMetadata)
        showToast("SEO settings generated successfully")
      } else {
        const errorMessage = result?.data?.generateSiteMetadata?.errors?.join(" ") || "An error occurred"
        showToast({
          content: errorMessage,
          type: "error"
        })
      }
    })
  }

  const handleGenerateAltText = () => {
    generateAltText().then((result) => {
      if (result?.data?.generateImageAltText?.result === "success") {
        showToast("Alt text generated successfully")
      } else {
        const errorMessage = result?.data?.generateImageAltText?.errors?.join(" ") || "An error occurred"
        showToast({
          content: errorMessage,
          type: "error"
        })
      }
    })
  }

  return (
    <>
      <Typography as="h2" variant="h4" className="mb-4">
        SEO Settings
      </Typography>
      <div className="flex w-max flex-col items-start justify-start gap-6 rounded-xl border border-gray-light p-8 md:w-full min_sm:min-w-[529px]">
        <EditableValue
          name="Site Title"
          value={websiteMetadata.title}
          saveDisabled={websiteMetadata.title === lastSavedValues.title}
          disabled={fetching}
          onSave={() => handleSave("title", websiteMetadata.title)}
          onCancel={() => setWebsiteMetadata((prev) => ({ ...prev, title: lastSavedValues.title }))}
          className="w-full">
          <Input
            name="Site Title"
            value={websiteMetadata.title}
            onChange={(e) => setWebsiteMetadata((prev) => ({ ...prev, title: e.target.value }))}
            className="w-full"
          />
        </EditableValue>

        <EditableValue
          name="Description"
          value={websiteMetadata.description}
          saveDisabled={websiteMetadata.description === lastSavedValues.description}
          disabled={fetching}
          onSave={() => handleSave("description", websiteMetadata.description)}
          onCancel={() => setWebsiteMetadata((prev) => ({ ...prev, description: lastSavedValues.description }))}
          className="w-full">
          <TextArea
            name="Description"
            value={websiteMetadata.description}
            onChange={(e) => setWebsiteMetadata((prev) => ({ ...prev, description: e.target.value }))}
            className="w-full"
          />
        </EditableValue>

        <EditableValue
          name="Open Graph Site Name"
          value={websiteMetadata.ogSiteName}
          saveDisabled={websiteMetadata.ogSiteName === lastSavedValues.ogSiteName}
          disabled={fetching}
          onSave={() => handleSave("ogSiteName", websiteMetadata.ogSiteName)}
          onCancel={() => setWebsiteMetadata((prev) => ({ ...prev, ogSiteName: lastSavedValues.ogSiteName }))}
          className="w-full">
          <Input
            name="Open Graph Site Name"
            value={websiteMetadata.ogSiteName}
            onChange={(e) => setWebsiteMetadata((prev) => ({ ...prev, ogSiteName: e.target.value }))}
            className="w-full"
          />
        </EditableValue>

        <EditableValue
          name="Primary Modality"
          value={websiteMetadata.primaryModality}
          saveDisabled={websiteMetadata.primaryModality === lastSavedValues.primaryModality}
          disabled={fetching}
          onSave={() => handleSave("primaryModality", websiteMetadata.primaryModality)}
          onCancel={() => setWebsiteMetadata((prev) => ({ ...prev, primaryModality: lastSavedValues.primaryModality }))}
          className="w-full">
          <Input
            name="Primary Modality"
            value={websiteMetadata.primaryModality}
            onChange={(e) => setWebsiteMetadata((prev) => ({ ...prev, primaryModality: e.target.value }))}
            className="w-full"
          />
        </EditableValue>

        <div className="flex gap-4">
          <Button onClick={handleGenerate} disabled={generating || generatingAlt}>
            {generating ? "Generating..." : "Generate Site Metadata"}
          </Button>
          <Button onClick={handleGenerateAltText} disabled={generating || generatingAlt}>
            {generatingAlt ? "Generating..." : "Generate Alt Text"}
          </Button>
        </div>
      </div>
    </>
  )
}

export default SeoSettings

import { GlobeAltIcon, StarIcon } from "@heroicons/react/24/outline"
import React from "react"

import { Button } from "../components/shared/Buttons"
import Typography from "../components/shared/Typography"

const WebsiteDash = () => (
  <div>
    <div className="mb-9 flex flex-col sm:mb-4">
      <Typography as="h1" variant="h3">
        Website
      </Typography>
      <Typography variant="subtitle" className="sm:hidden">
        Build your Heal.me website and choose a custom domain.
      </Typography>
    </div>
    <div className="grid grid-cols-2 gap-8 sm:grid-cols-1">
      <div className="flex flex-row gap-4 rounded-xl border border-gray-light p-8">
        <div className="mb-8 h-fit w-fit rounded-xl bg-green-light p-4">
          <StarIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <div className="flex flex-col">
          <Typography variant="h5" className="mb-2">
            Builder (soon)
          </Typography>
          <Typography variant="subtitle" className="mb-4">
            Choose a website theme and customization option.
          </Typography>
          <a href="#">
            <Button type="tertiary" className="w-fit">
              Soon
            </Button>
          </a>
        </div>
      </div>

      <div className="flex flex-row gap-4 rounded-xl border border-gray-light p-8">
        <div className="mb-8 h-fit w-fit rounded-xl bg-orange-light p-4">
          <GlobeAltIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <div className="flex flex-col">
          <Typography variant="h5" className="mb-2">
            Domain
          </Typography>
          <Typography variant="subtitle" className="mb-4">
            Setup your custom domain: yourdomain.com
          </Typography>
          <a href="/portal/website/domain">
            <Button type="tertiary" className="w-fit">
              Manage
            </Button>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default WebsiteDash

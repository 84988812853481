import React from "react"

import { Button } from "../../components/shared/Buttons"
import { Flyout } from "../../components/shared/Layout"
import { useToast } from "../../contexts/ToastContext"

import BookableEventForm from "./BookableEventForm"
import { useBookableEvents } from "./BookableEventsContext"

const BookableEventsFlyout = () => {
  const {
    showFlyout,
    setShowFlyout,
    bookableEvent,
    fetching,
    upsertBookableEvent,
    updateBookableEvents,
    setDeleteModalVisible
  } = useBookableEvents()
  const { showToast } = useToast()

  const editingExistingEvent = !!bookableEvent.id
  const saveEnabled = !fetching && bookableEvent.name?.length > 0 && bookableEvent.location?.length > 0

  const onSave = () => {
    upsertBookableEvent({ ...bookableEvent }).then((result) => {
      if (result?.data?.upsertBookableEvent?.result === "success") {
        const newEvent = result.data.upsertBookableEvent.bookableEvent
        newEvent.id = Number(newEvent.id)
        updateBookableEvents(newEvent)
        setShowFlyout(false)
        showToast(`Your event '${newEvent.name}' was saved successfully`)
      } else {
        console.error(result)
        let errorMessage = "There was an error saving your event"
        if (result.data?.upsertBookableEvent?.errors) errorMessage += `: ${result.data.upsertBookableEvent.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  return (
    <>
      <Flyout
        header={editingExistingEvent ? "Edit event" : "Create event"}
        visible={showFlyout}
        closeFlyout={() => setShowFlyout(false)}
        onSave={onSave}
        footer={
          <div className={`flex w-full items-center ${editingExistingEvent ? "justify-between" : "justify-end"}`}>
            {editingExistingEvent && (
              <Button type="destructive" onClick={() => setDeleteModalVisible(true)} disabled={fetching}>
                Delete
              </Button>
            )}
            <div className="flex gap-4">
              <Button type="tertiary" onClick={() => setShowFlyout(false)} disabled={fetching}>
                Cancel
              </Button>
              <Button type="primary" onClick={onSave} disabled={fetching || !saveEnabled}>
                Save
              </Button>
            </div>
          </div>
        }>
        <BookableEventForm />
      </Flyout>
    </>
  )
}

export default BookableEventsFlyout

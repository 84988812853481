import React from "react"
import ReactSelect from "react-select"

import { InputWithLabel, Label, TextArea, ToggleButtonGroup } from "../../components/shared/Inputs"
import { Banner } from "../../components/shared/Layout"
import { UnderlinedLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"
import { usePractice } from "../../contexts/PracticeContext"
import { getGroupedTimezoneOptions } from "../../utils/timeZoneUtils"
import { selectStyling } from "../../utils/utils"

const VirtualLocationFields = ({ location, setLocation, validationErrors }) => {
  const { practice } = usePractice()

  const zoomActive = practice.conferencingServices.some((service) => service.provider === "zoom" && service.active)

  // State to force update the times on timezone select
  const [timeUpdate, setTimeUpdate] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeUpdate((prev) => prev + 1)
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  const timezoneOptions = React.useMemo(() => getGroupedTimezoneOptions(), [timeUpdate])

  return (
    <>
      <ToggleButtonGroup
        options={[
          { label: "Video calls", value: "video" },
          { label: "Phone calls", value: "phone" }
        ]}
        onChange={(value) => setLocation({ ...location, virtualType: value })}
        value={location.virtualType || "video"}
      />

      <div className="flex flex-col gap-2">
        <Label>Time Zone</Label>
        <ReactSelect
          id="timezone-select"
          options={timezoneOptions}
          value={
            timezoneOptions
              .flatMap((group) => group.options)
              .find((tz) => tz.label === location.timeZone || tz.value === location.timeZone) || null
          }
          onChange={(option) => setLocation({ ...location, timeZone: option.label })}
          styles={{
            ...selectStyling(),
            option: (provided, state) => ({
              ...provided,
              display: "flex",
              justifyContent: "space-between",
              "&:after": {
                content: `"${state.data.currentTime}"`,
                color: "#666"
              }
            }),
            group: (provided) => ({
              ...provided,
              padding: "8px"
            })
          }}
          formatGroupLabel={(group) => <div className="font-semibold text-gray-dark">{group.label}</div>}
          placeholder="Select a time zone..."
        />
      </div>

      {location.virtualType === "video" && (
        <>
          {!zoomActive ? (
            <>
              <div className="flex flex-col gap-2">
                <InputWithLabel
                  label="Virtual link"
                  value={location.virtualLink || ""}
                  explanatorySubtext="Ex: https://zoom.us/j/1234567890"
                  onChange={(e) => setLocation({ ...location, virtualLink: e.target.value })}
                  validationError={validationErrors.virtualLink}
                />
                <Banner type="info" className="text-sm">
                  You can add Zoom to generate unique links automatically.{" "}
                  <UnderlinedLink href="/portal/settings/conferencing">Click here to connect</UnderlinedLink>
                </Banner>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-2">
                <Label>Virtual link</Label>
                <Banner type="info" className="text-sm">
                  Zoom is active and unique links will be generated automatically.{" "}
                  <UnderlinedLink href="/portal/settings/conferencing">
                    Click here to manage Zoom integration
                  </UnderlinedLink>
                </Banner>
              </div>
            </>
          )}
        </>
      )}
      <div>
        <Label htmlFor="virtual-instructions">Instructions on how to join</Label>
        <TextArea
          id="virtual-instructions"
          value={location.virtualInstructions || ""}
          onChange={(e) => setLocation({ ...location, virtualInstructions: e.target.value })}
          validationError={validationErrors.virtualInstructions}
        />
        <Typography variant="micro" as="div">
          Add simple instructions for how your clients will join these calls
        </Typography>
      </div>
    </>
  )
}

export default VirtualLocationFields

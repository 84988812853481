import { PlusIcon } from "@heroicons/react/24/outline"
import { PencilIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { FilePicker } from "../components/shared/FileStackWidgets"
import { EditableValue, Input, ShortUrlInput, TextArea } from "../components/shared/Inputs"
import { Box, Divider } from "../components/shared/Layout"
import PlacesAutocomplete from "../components/shared/PlacesAutocomplete"
import Typography from "../components/shared/Typography"
import { useProfileStatus } from "../contexts/ProfileStatusContext"
import { useToast } from "../contexts/ToastContext"
import { stripUSAFromString } from "../utils/utils"

import ProfileEditProvider from "./ProfileEdit/ProfileEditProvider"
import ProfileEditView from "./ProfileEdit/ProfileEditView"

const UPDATE_INFO_MUTATION = `
  mutation UpdateInfo($name: String, $location: String, $locationId: String, $title: String, $headline: String, $about: String $shortLink: String) {
    updateInfo(name: $name, location: $location, locationId: $locationId, title: $title, headline: $headline, about: $about, shortLink: $shortLink) {
      result
      errors
      profileDetails { name location title headline shortLink }
    }
  }
`

const UPDATE_USER_PHOTO_MUTATION = `
  mutation UpdateUserPhoto($handle: String!) {
    updateUserPhoto(handle: $handle) {
      result
      errors
      photoUrl
    }
  }
`

const Details = ({ details, path, photoUrl, allShortLinks }) => {
  const { profileStatusData, syncProfileStatus } = useProfileStatus()
  const [detailsValues, setDetailsValues] = useState(details)
  const [photoUrlValue, setPhotoUrlValue] = useState(photoUrl)
  const [lastSavedDetailsValues, setLastSavedDetailsValues] = useState(details)
  const [openField, setOpenField] = useState(null)

  const [{ fetching: infoFetching }, updateInfo] = useMutation(UPDATE_INFO_MUTATION)
  const [{ fetching: photoFetching }, updateUserPhoto] = useMutation(UPDATE_USER_PHOTO_MUTATION)

  const fetching = infoFetching || photoFetching

  const { showToast } = useToast()

  const { name, location, title, headline, about, shortLink } = detailsValues

  const onSave = () => {
    updateInfo(detailsValues).then((result) => {
      setOpenField(null)
      if (result?.data?.updateInfo?.result === "success") {
        setLastSavedDetailsValues(result.data.updateInfo.profileDetails)
        syncProfileStatus()
        showToast("Your profile details have been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your profile details. Please try again later or contact support."
        if (result.data?.updateInfo?.errors) errorMessage += ` ${result.data.updateInfo.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const onOpened = (fieldName) => {
    setOpenField(fieldName)
  }

  const onUploadDone = (res) => {
    updateUserPhoto({
      handle: res.filesUploaded[0].handle
    }).then((result) => {
      setOpenField(null)
      if (result?.data?.updateUserPhoto?.result === "success") {
        setPhotoUrlValue(result.data.updateUserPhoto.photoUrl)
        syncProfileStatus()
        showToast("Your profile photo has been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your profile photo. Please try again later or contact support."
        if (result.data?.updateUserPhoto?.errors) errorMessage += ` ${result.data.updateUserPhoto.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  return (
    <ProfileEditView path={path} profileStatusData={profileStatusData}>
      <Box>
        <div className={twMerge("mb-4 flex items-center justify-center", fetching || openField ? "opacity-50" : "")}>
          {photoUrlValue.indexOf("defaults") > -1 ? (
            <FilePicker
              as="button"
              className={twMerge(
                "flex cursor-pointer flex-col items-center justify-center gap-2",
                fetching || openField ? "pointer-events-none" : ""
              )}
              onUploadDone={onUploadDone}>
              <div className="flex h-20 w-20 items-center justify-center rounded-full bg-teal/[0.12]">
                <PlusIcon className="h-6 w-6 text-teal" />
              </div>
              <div className="font-bold text-teal">Upload headshot</div>
            </FilePicker>
          ) : (
            <div className="relative flex h-32 w-32 items-center justify-center">
              <img className="h-full w-full rounded-xl" src={photoUrlValue} />
              <FilePicker
                className={twMerge(
                  "absolute -right-5 flex h-10 w-10 items-center justify-center rounded-full border border-gray bg-white",
                  fetching || openField ? "pointer-events-none" : ""
                )}
                onUploadDone={onUploadDone}>
                <PencilIcon className="h-5 w-5 text-gray-dark" />
              </FilePicker>
            </div>
          )}
        </div>
        <div>
          <EditableValue
            name="Name"
            value={name}
            saveDisabled={name === lastSavedDetailsValues.name || name?.length < 2}
            disabled={fetching || (openField && openField !== "Name")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setDetailsValues(lastSavedDetailsValues)
            }}>
            <Input
              name="Name"
              value={name || ""}
              onChange={(e) => setDetailsValues((values) => ({ ...values, name: e.target.value }))}
            />
          </EditableValue>
          <Divider />
          <EditableValue
            name="Location"
            hint="Where is your practice located?"
            value={location}
            disabled={fetching || (openField && openField !== "Location")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setDetailsValues(lastSavedDetailsValues)
            }}>
            <PlacesAutocomplete
              apiKey={process.env.GOOGLE_MAPS_API_KEY}
              name="Location"
              types={["locality", "administrative_area_level_3"]}
              onPlaceSelected={(place) => {
                setDetailsValues((values) => ({
                  ...values,
                  location: stripUSAFromString(place.formatted_address),
                  locationId: place.place_id
                }))
              }}
              defaultValue={location ? stripUSAFromString(location) : ""}
            />
          </EditableValue>
          <Divider />
          <EditableValue
            name="Title"
            hint="Set what your professional title is. Pro-tip: Keep this as short and clear as possible so it’s easily understood by clients."
            value={title}
            saveDisabled={title === lastSavedDetailsValues.title || title?.length < 2}
            disabled={fetching || (openField && openField !== "Title")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setDetailsValues(lastSavedDetailsValues)
            }}>
            <Input
              name="Title"
              placeholder="Ex: Licensed Massage Therapist"
              value={title || ""}
              onChange={(e) => setDetailsValues((values) => ({ ...values, title: e.target.value }))}
            />
          </EditableValue>
          <Divider />
          <EditableValue
            name="Headline"
            hint="Share your approach to helping your clients."
            value={headline}
            saveDisabled={headline === lastSavedDetailsValues.headline || headline?.length < 2}
            disabled={fetching || (openField && openField !== "Headline")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setDetailsValues(lastSavedDetailsValues)
            }}>
            <TextArea
              name="Headline"
              placeholder="Ex: I help people to heal and find their inner wisdom, and make meaningful, lasting change in their lives."
              value={headline || ""}
              onChange={(e) => setDetailsValues((values) => ({ ...values, headline: e.target.value }))}
              maxLength={200}
            />
          </EditableValue>
          <Divider />
          <EditableValue
            name="About me"
            value={about}
            hint="Add in a short description about your background and your approach to working with your clients."
            saveDisabled={about === lastSavedDetailsValues.about || about?.length < 2}
            disabled={fetching || (openField && openField !== "About me")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setDetailsValues(lastSavedDetailsValues)
            }}>
            <TextArea
              name="About me"
              value={about || ""}
              onChange={(e) => setDetailsValues((values) => ({ ...values, about: e.target.value }))}
            />
          </EditableValue>
          <Divider />
          <EditableValue
            name="Short link"
            hint="Set your short profile link so it’s easy to share your profile."
            value={shortLink ? `https://heal.me/${shortLink}` : null}
            saveDisabled={
              allShortLinks.includes(shortLink) ||
              shortLink?.length < 1 ||
              shortLink === lastSavedDetailsValues.shortLink
            }
            disabled={fetching || (openField && openField !== "Short link")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setDetailsValues(lastSavedDetailsValues)
            }}>
            <>
              <ShortUrlInput
                name="Short link"
                value={shortLink || ""}
                onChange={(e) => {
                  const newValue = e.target.value.toLowerCase().replace(/[^a-z0-9_-]/g, "")
                  setDetailsValues((values) => ({ ...values, shortLink: newValue }))
                }}
              />
              <Typography variant="micro">Only lowercase letters, numbers, hyphen and underscore allowed</Typography>
              {shortLink?.length >= 1 && shortLink !== lastSavedDetailsValues.shortLink && (
                <>
                  {allShortLinks.includes(shortLink) ? (
                    <div className="mt-2 text-red">This short link is already in use.</div>
                  ) : (
                    <div className="mt-2 text-green">🎉 This short link is available.</div>
                  )}
                </>
              )}
            </>
          </EditableValue>
        </div>
      </Box>
    </ProfileEditView>
  )
}

export default function ProfileEditDetails({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <Details {...props} />
    </ProfileEditProvider>
  )
}

import React, { useState } from "react"
import { useMutation } from "urql"

import Breadcrumbs from "../../components/shared/Breadcrumbs"
import { Button } from "../../components/shared/Buttons"
import { Label, Select, TextArea } from "../../components/shared/Inputs"
import { Box, Divider } from "../../components/shared/Layout"
import { UnderlinedLink } from "../../components/shared/Links"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import Typography from "../../components/shared/Typography"
import { useToast, ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

const UPDATE_BOOKING_SETTINGS = `
  mutation UpdateBookingSettings(
    $appointmentBufferTime: Int,
    $instantClientApproval: Boolean,
    $appointmentLeadTime: Int,
    $startTimeIncrement: Int,
    $requireClientCcToBook: Boolean,
    $chargeClientCcAtBooking: Boolean,
    $cancellationPolicy: String,
  ) {
    updateBookingSettings(
      appointmentBufferTime: $appointmentBufferTime,
      instantClientApproval: $instantClientApproval,
      appointmentLeadTime: $appointmentLeadTime,
      startTimeIncrement: $startTimeIncrement,
      requireClientCcToBook: $requireClientCcToBook,
      chargeClientCcAtBooking: $chargeClientCcAtBooking,
      cancellationPolicy: $cancellationPolicy,
    ) {
      result
      error
    }
  }
`

const options = [
  { value: true, label: "Yes (recommended)" },
  { value: false, label: "No (approval required)" }
]

const bufferIntervals = [
  { value: 0, label: "None" },
  { value: 5, label: "5 minutes" },
  { value: 10, label: "10 minutes" },
  { value: 15, label: "15 minutes (recommended)" },
  { value: 20, label: "20 minutes" },
  { value: 30, label: "30 minutes" },
  { value: 45, label: "45 minutes" },
  { value: 60, label: "1 hour" },
  { value: 90, label: "1.5 hours" },
  { value: 120, label: "2 hours" },
  { value: 150, label: "2.5 hours" },
  { value: 180, label: "3 hours" }
]

const leadTimes = [
  { value: 0, label: "No lead time" },
  { value: 15, label: "15 minutes in advance" },
  { value: 30, label: "30 minutes in advance" },
  { value: 60, label: "1 hour in advance" },
  { value: 180, label: "3 hours in advance" },
  { value: 480, label: "8 hours in advance" },
  { value: 720, label: "12 hours in advance" },
  { value: 1440, label: "1 day in advance (recommended)" },
  { value: 2880, label: "2 days in advance" },
  { value: 4320, label: "3 days in advance" },
  { value: 10080, label: "1 week in advance" }
]

const startTimeIncrements = [
  { value: 15, label: "15 minutes" },
  { value: 30, label: "30 minutes (recommended)" },
  { value: 60, label: "60 minutes" },
  { value: 75, label: "75 minutes" },
  { value: 90, label: "1.5 hours" },
  { value: 120, label: "2 hours" },
  { value: 150, label: "2.5 hours" },
  { value: 180, label: "3 hours" },
  { value: 240, label: "4 hours" },
  { value: 300, label: "5 hours" },
  { value: 360, label: "6 hours" }
]

const BookingSettings = ({
  bookingSettings,
  requireCcEnabled,
  hasPaymentProvider,
  hasOffline,
  cancellationPolicyEnabled
}) => {
  const [, updateBookingSettings] = useMutation(UPDATE_BOOKING_SETTINGS)

  const [settings, setSettings] = useState(bookingSettings)
  const [originalSettings, setOriginalSettings] = useState(bookingSettings)

  const { showToast } = useToast()

  const handleSave = () => {
    updateBookingSettings(settings)
      .then((result) => {
        if (result.data?.updateBookingSettings?.result) {
          showToast({ content: "Settings updated successfully.", timeout: 3000 })
          setOriginalSettings(settings)
        } else {
          const error =
            result.error?.message?.replace("[GraphQL]", "") ||
            result.data?.updateBookingSettings?.error ||
            "Please try again later."

          showToast({ type: "error", content: error })
        }
      })
      .catch((error) => {
        showToast({ type: "error", content: error.message })
      })
  }

  if (Object.keys(settings).length === 0) {
    return null
  }

  const settingsChanged = JSON.stringify(settings) !== JSON.stringify(originalSettings)

  return (
    <div>
      <Breadcrumbs links={[{ href: "/portal/settings", text: "Settings" }]} activePage="Booking" />
      <div className="mb-4 flex flex-row justify-between">
        <div className="flex flex-col">
          <Typography as="h2" variant="h3">
            Booking
          </Typography>
          <Typography variant="subtitle" className="sm:hidden">
            Auto-accept new bookings, edit the time between appointments, and more.
          </Typography>
        </div>

        <div className="mr-2">
          <Button
            className="mr-4"
            type="tertiary"
            onClick={() => {
              setSettings(originalSettings)
              showToast({ content: "Your changes have been discarded.", timeout: 3000 })
            }}>
            Cancel
          </Button>
          <Button type="primary" disabled={!settingsChanged} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <Box className="w-1/2 sm:w-full">
        {requireCcEnabled && (
          <div className="mb-6">
            <div className="flex flex-col">
              <Label htmlFor="require-booking" className="mb-2 text-base">
                Require credit card when a client books:
              </Label>
              <Typography variant="subtitle" className="mb-4">
                Booking free services will not require a credit card.
              </Typography>

              {hasPaymentProvider ? (
                <div>
                  <div className="mb-4 flex flex-row gap-4">
                    <RadioWithLabel
                      id="require-cc-yes"
                      value={true}
                      name="require-cc"
                      label="Yes"
                      checked={settings.requireClientCcToBook}
                      onChange={() => setSettings({ ...settings, requireClientCcToBook: true })}
                    />
                    <RadioWithLabel
                      value={false}
                      id="require-cc-no"
                      name="require-cc"
                      label="No (recommended)"
                      checked={!settings.requireClientCcToBook}
                      onChange={() =>
                        setSettings({ ...settings, requireClientCcToBook: false, chargeClientCcAtBooking: false })
                      }
                    />
                  </div>
                  {hasOffline && (
                    <Typography variant="subtitle" className="mb-2">
                      Your clients will not have the option to pay with another method.
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography variant="subtitle" className="mb-4 text-red">
                  Requires a connected Payment Provider account.{" "}
                  <UnderlinedLink className="ml-2" href="/portal/settings/banking">
                    Connect Now
                  </UnderlinedLink>
                </Typography>
              )}
            </div>
          </div>
        )}
        <Divider />

        {requireCcEnabled && settings.requireClientCcToBook && (
          <div className="mb-6">
            <div className="flex flex-col">
              <Label htmlFor="charge-on-booking" className="mb-2 text-base">
                Charge credit card when client books:
              </Label>
              <div className="flex flex-row gap-4">
                <RadioWithLabel
                  id="charge-cc-yes"
                  value={true}
                  name="charge-cc"
                  label="Yes"
                  checked={settings.chargeClientCcAtBooking}
                  onChange={() => setSettings({ ...settings, chargeClientCcAtBooking: true })}
                />
                <RadioWithLabel
                  id="charge-cc-no"
                  value={false}
                  name="charge-cc"
                  label="No (recommended)"
                  checked={!settings.chargeClientCcAtBooking}
                  onChange={() => setSettings({ ...settings, chargeClientCcAtBooking: false })}
                />
              </div>
            </div>
            <Divider />
          </div>
        )}
        <div className="mb-6">
          <Label htmlFor="instant-approval" className="mb-2 text-base">
            Auto-accept new client appointments:
          </Label>
          <Select
            id="instant-approval"
            className="w-fit"
            options={options}
            value={settings.instantClientApproval}
            onChange={(e) => setSettings({ ...settings, instantClientApproval: e.target.value === "true" })}
            defaultOption="Select an option"
          />
        </div>
        <Divider />
        <div className="mb-6">
          <Label htmlFor="buffer-time" className="mb-2 text-base">
            Buffer time between your appointments:
          </Label>
          <Select
            id="buffer-time"
            className="w-fit"
            options={bufferIntervals}
            value={settings.appointmentBufferTime}
            onChange={(e) => setSettings({ ...settings, appointmentBufferTime: Number(e.target.value) })}
            defaultOption="Select time interval"
          />
        </div>
        <Divider />
        <div className="mb-6">
          <Label htmlFor="lead-time" className="mb-2 text-base">
            Appointments can be scheduled:
          </Label>
          <Select
            id="lead-time"
            className="w-fit"
            options={leadTimes}
            value={settings.appointmentLeadTime}
            onChange={(e) => setSettings({ ...settings, appointmentLeadTime: Number(e.target.value) })}
            defaultOption="Select time interval"
          />
        </div>
        <Divider />
        <div className="mb-6">
          <Label htmlFor="start-time" className="mb-2 text-base">
            Start appointment slots every:
          </Label>
          <Select
            id="start-time"
            className="w-fit"
            options={startTimeIncrements}
            value={settings.startTimeIncrement}
            onChange={(e) => setSettings({ ...settings, startTimeIncrement: Number(e.target.value) })}
            defaultOption="Select time interval"
          />
        </div>
        {cancellationPolicyEnabled && (
          <>
            <Divider />
            <div className="mb-6">
              <Label htmlFor="cancellation-policy" className="mb-2 text-base">
                Cancellation Policy:
              </Label>
              <TextArea
                id="cancellation-policy"
                className="w-full"
                rows={4}
                maxLength={250}
                placeholder="Enter your cancellation policy..."
                value={settings.cancellationPolicy || ""}
                onChange={(e) => setSettings({ ...settings, cancellationPolicy: e.target.value })}
              />
            </div>
          </>
        )}
      </Box>
    </div>
  )
}

export default function ProBookingSettings(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <BookingSettings {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

import React, { useState } from "react"

import { TabNavLink } from "../components/shared/Links"
import Typography from "../components/shared/Typography"
import { PracticeProvider } from "../contexts/PracticeContext"
import DefaultProvider from "../providers/DefaultProvider"

import ProImportReviews from "./ProReviews/ProImportReviews"
import ProReviewRequestsTable from "./ProReviews/ProReviewRequestsTable"
import ProReviewsTable from "./ProReviews/ProReviewsTable"
import ReviewRequestForm from "./ProReviews/ReviewRequestForm"

const Reviews = ({ requestReviewLink, clientRequests, reviews, importReviewsEnabled }) => {
  const [activeTab, setActiveTab] = useState(reviews.length < 11 ? "newRequests" : "completed")

  const handleTabClick = (tabId) => {
    setActiveTab(tabId)
  }

  return (
    <>
      <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
        <div className="flex flex-col">
          <Typography as="h1" variant="h3">
            Reviews
          </Typography>
          <Typography variant="subtitle">
            {reviews.length === 0 ? <>No reviews </> : <>{reviews?.length} completed </>}
            {clientRequests?.length > 0 && <>• {clientRequests.length} pending</>}
          </Typography>
        </div>
      </div>
      <div className="mb-8 mt-4 flex gap-8 border-b border-gray-light">
        {reviews.length < 999999 && (
          <>
            <TabNavLink active={activeTab === "newRequests"} onClick={() => handleTabClick("newRequests")}>
              Request reviews
            </TabNavLink>
            {importReviewsEnabled && (
              <TabNavLink active={activeTab === "import"} onClick={() => handleTabClick("import")}>
                Import reviews
              </TabNavLink>
            )}
          </>
        )}
        <TabNavLink active={activeTab === "completed"} onClick={() => handleTabClick("completed")}>
          Completed
        </TabNavLink>
        <TabNavLink active={activeTab === "pending"} onClick={() => handleTabClick("pending")}>
          Pending
        </TabNavLink>
      </div>
      {activeTab === "newRequests" && (
        <ReviewRequestForm
          existingRequests={clientRequests.length}
          requestReviewLink={requestReviewLink}
          defaultRows={1}
        />
      )}
      {activeTab === "import" && importReviewsEnabled && <ProImportReviews />}

      {activeTab === "pending" && <ProReviewRequestsTable clientRequests={clientRequests} />}

      {activeTab === "completed" && <ProReviewsTable reviews={reviews} />}
    </>
  )
}

export default function ProReviews(props) {
  return (
    <DefaultProvider>
      <PracticeProvider>
        <Reviews {...props} />
      </PracticeProvider>
    </DefaultProvider>
  )
}

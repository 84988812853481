import React, { useState } from "react"
import { useMutation, useQuery } from "urql"

import { EditableValue, TextArea } from "./Inputs"

const CLIENT_NOTES_QUERY = `
  query ClientNotes($clientId: ID!) {
    client(clientId: $clientId) {
      id
      clientNote { text time }
    }
  }
`

const ADD_NOTE_MUTATION = `
  mutation($text: String!, $clientId: ID!) {
    addNote(text: $text, clientId: $clientId) {
      result
      errors
    }
  }
`

const ClientNotes = ({ client, className }) => {
  const [text, setText] = useState("")
  const [{ data, fetching }, refetchQuery] = useQuery({
    query: CLIENT_NOTES_QUERY,
    variables: { clientId: client.id },
    requestPolicy: "network-only"
  })

  const [{ fetching: addNoteFetching }, addNote] = useMutation(ADD_NOTE_MUTATION)

  const onSave = () => {
    addNote({ text, clientId: client.id }).then((result) => {
      if (result.data?.addNote?.result === "success") {
        refetchQuery()
      } else {
        console.error(result)
      }
    })
  }

  const value = data?.client?.clientNote?.text || ""

  return (
    <EditableValue
      className={className}
      name="Client notes"
      value={value.replaceAll("\n", "<br />")}
      saveDisabled={fetching || addNoteFetching || text.length < 1}
      disabled={fetching || addNoteFetching}
      titleClassName="text-sm"
      truncateLength={null}
      onSave={onSave}
      onOpened={() => setText(value)}
      onCancel={() => setText(value)}>
      <TextArea value={text} onChange={(e) => setText(e.target.value)} />
    </EditableValue>
  )
}

export default ClientNotes

import capitalize from "lodash/capitalize"
import React, { useState } from "react"
import { useQuery, useMutation } from "urql"

import PaymentProviderWrapper from "../../components/payments/PaymentProviderWrapper"
import { AnimatedModal } from "../../components/shared/Modal"
import { useToast } from "../../contexts/ToastContext"
import { DEFAULT_PAYMENT_METHOD_QUERY } from "../../utils/mutations"
import AddCreditCardModal from "../ManagePaymentMethods/AddCreditCardModal"
import { DETACH_PAYMENT_METHOD_PRACTICE_MUTATION } from "../ManagePaymentMethods/ManagePaymentMethods"

const ManageCreditCard = ({ client, practice }) => {
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: {
      clientId: client.id,
      userId: client.userId,
      practiceId: practice.id
    },
    requestPolicy: "cache-and-network"
  })
  const [{ fetching: detachingPaymentMethod }, detachPaymentMethodPractice] = useMutation(
    DETACH_PAYMENT_METHOD_PRACTICE_MUTATION
  )
  const { showToast } = useToast()

  const paymentMethod = data?.defaultPaymentMethodPractice

  return (
    <div className="w-full">
      {!practice.hasPaymentProvider ? (
        <a className="font-bold text-teal hover:underline" href={`/portal/settings/banking`}>
          Click here to connect to a payment provider
        </a>
      ) : fetching ? (
        <img className="loading" src="/loading-spinner.png" width="40px" />
      ) : error ? (
        <div>Error fetching payment method</div>
      ) : (
        <div className="flex w-full items-start justify-between gap-2">
          <div className="pb-5 text-sm text-gray-dark">
            {paymentMethod
              ? `${capitalize(paymentMethod.brand)} ending in ${paymentMethod.last4} • Expires ${
                  paymentMethod.expMonth
                }/${paymentMethod.expYear}`
              : "No payment method on file"}
          </div>

          <div className="flex gap-4">
            {paymentMethod && (
              <button className="font-bold text-red hover:underline" onClick={() => setShowDeleteModal(true)}>
                Delete
              </button>
            )}
            <button className="font-bold text-teal hover:underline" onClick={() => setCreditCardModalOpen(true)}>
              {paymentMethod ? "Change" : "Add"}
            </button>
          </div>
        </div>
      )}
      {creditCardModalOpen && client && practice.hasPaymentProvider && (
        <PaymentProviderWrapper practice={practice} client={client}>
          <AddCreditCardModal
            headerLabel="Add credit card"
            open={creditCardModalOpen}
            closeModal={() => setCreditCardModalOpen(false)}
            practice={practice}
            onSave={reexecuteQuery}
          />
        </PaymentProviderWrapper>
      )}
      {showDeleteModal && (
        <AnimatedModal
          visible={showDeleteModal}
          header="Delete payment method"
          hideModal={() => setShowDeleteModal(false)}
          actionButtonCopy="Yes, delete"
          actionButtonType="destructive"
          showFooter={true}
          saveDisabled={detachingPaymentMethod}
          onSave={() => {
            detachPaymentMethodPractice({
              clientId: client.id
            }).then((res) => {
              if (res.data?.detachPaymentMethodPractice?.result === "success") {
                showToast({ content: "Payment method deleted successfully", type: "success" })
                reexecuteQuery()
              } else {
                showToast({ content: "There was an error deleting the payment method", type: "error" })
                console.error(res) // eslint-disable-line no-console
              }
              setShowDeleteModal(false)
            })
          }}>
          <p>Are you sure you want to delete this payment method?</p>
        </AnimatedModal>
      )}
    </div>
  )
}

export default ManageCreditCard

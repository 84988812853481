import React, { useState, useEffect } from "react"
import { useMutation } from "urql"

import { InputWithLabel } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import ReviewProvider from "../../providers/ReviewProvider"

import { ReviewLayout } from "./ReviewLayout"

const CREATE_CLIENT_MUTATION = `
  mutation ReviewCreateClient($practiceId: ID!, $phone: String!, $firstName: String!) {
    reviewCreateClient(practiceId: $practiceId, phone: $phone, firstName: $firstName) {
      result
      clientId
      errors
    }
  }
`

const ClientInfo = ({ review }) => {
  const { showToast } = useToast()
  const [firstName, setFirstName] = useState("")
  const [phone, setPhone] = useState("")
  const [, createClient] = useMutation(CREATE_CLIENT_MUTATION)
  const [createdClientInfo, setCreatedClientInfo] = useState({})

  useEffect(() => {
    const createdClientInfo = localStorage.getItem("createdClientInfo")
    if (createdClientInfo) {
      setCreatedClientInfo(JSON.parse(localStorage.getItem("createdClientInfo")))
    }
  }, [])

  const isFormValid = createdClientInfo || (firstName.trim().length > 2 !== "" && phone.trim().length > 12)

  const handleCreateClient = () => {
    if (createdClientInfo?.id) {
      window.location.href = `/i/r/rating?referral_code=${review.referralCode}&client_id=${createdClientInfo.id}`
    } else {
      createClient({ practiceId: review.practiceId, phone, firstName }).then((res) => {
        localStorage.setItem(
          "createdClientInfo",
          JSON.stringify({
            firstName: firstName,
            phone: phone,
            id: res.data.reviewCreateClient.clientId
          })
        )
        if (res.data.reviewCreateClient.result === "success") {
          window.location.href = `/i/r/rating?referral_code=${review.referralCode}&client_id=${res.data.reviewCreateClient.clientId}`
        } else {
          showToast({
            content: `Error creating client: Please enter a valid phone number to continue and leave a review.`,
            type: "error"
          })
        }
      })
    }
  }

  return (
    <ReviewLayout percentDone="30%" onContinue={handleCreateClient} continueDisabled={!isFormValid}>
      <Typography variant="h3">Oh great, thank you for that!</Typography>
      <Typography variant="body">Before we continue, can you tell us who you are:</Typography>
      <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-1">
        <InputWithLabel
          label="First name"
          disabled={!!createdClientInfo?.id}
          value={createdClientInfo?.id ? createdClientInfo.firstName : firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputWithLabel
          label="Phone"
          disabled={!!createdClientInfo?.id}
          value={createdClientInfo?.id ? createdClientInfo.phone : phone}
          type="phone"
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
    </ReviewLayout>
  )
}

export default function ReviewClientInfo(props) {
  return (
    <ReviewProvider>
      <ClientInfo {...props} />
    </ReviewProvider>
  )
}

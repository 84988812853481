import React, { useState } from "react"
import { useMutation, useQuery } from "urql"

import PaymentProviderCardForm from "../../components/payments/PaymentProviderCardForm"
import { Banner } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { AnimatedModal } from "../../components/shared/Modal"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import Typography from "../../components/shared/Typography"
import { usePractice } from "../../contexts/PracticeContext"
import { useToast } from "../../contexts/ToastContext"

import { PROCESS_REGISTRATION_PAYMENT_MUTATION } from "./mutations"
import { DEFAULT_PAYMENT_METHOD_QUERY } from "./queries"

export default function BookableEventPaymentModal({ visible, hideModal, client, registration, onPaymentSuccess }) {
  const [paymentMethod, setPaymentMethod] = useState("saved_card")
  const [processingPayment, setProcessingPayment] = useState(false)
  const [paymentError, setPaymentError] = useState(null)
  const { practice } = usePractice()
  const { showToast } = useToast()

  const [{ data: paymentMethodData, fetching: fetchingPaymentMethod }] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: {
      clientId: client?.id,
      practiceId: practice.id
    }
  })

  const [, processPayment] = useMutation(PROCESS_REGISTRATION_PAYMENT_MUTATION)

  const defaultPaymentMethod = paymentMethodData?.defaultPaymentMethodPractice
  const hasSavedCard = !!defaultPaymentMethod

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value)
  }

  const handleProcessPayment = async () => {
    setProcessingPayment(true)
    setPaymentError(null)

    try {
      const result = await processPayment({
        registrationId: registration.id
      })

      if (result.data?.processRegistrationPayment?.result === "success") {
        showToast("Payment processed successfully")
        hideModal()
        onPaymentSuccess()
      } else {
        const errorMessage = result.data?.processRegistrationPayment?.errors?.[0] || "Payment processing failed"
        setPaymentError(errorMessage)
      }
    } catch (err) {
      setPaymentError(err.message || "An unexpected error occurred")
    } finally {
      setProcessingPayment(false)
    }
  }

  const formatCurrency = (amountCents) => `$${(amountCents / 100).toFixed(2)}`

  if (!registration || fetchingPaymentMethod) {
    return (
      <AnimatedModal visible={visible} hideModal={hideModal} header="Process Payment">
        <div className="py-4">
          <LoadingSpinner />
        </div>
      </AnimatedModal>
    )
  }

  return (
    <AnimatedModal
      visible={visible}
      hideModal={hideModal}
      header="Process Payment"
      showFooter={true}
      cancelButtonCopy="Cancel"
      cancelButtonType="tertiary"
      actionButtonCopy={processingPayment ? "Processing..." : "Process Payment"}
      actionButtonType="primary"
      saveDisabled={processingPayment || (paymentMethod === "saved_card" && !hasSavedCard)}
      onSave={handleProcessPayment}>
      <div className="py-4">
        <div className="mb-6">
          <Typography variant="body">
            Process payment for{" "}
            <strong>
              {client?.firstName} {client?.lastName}
            </strong>
          </Typography>
          <Typography variant="body" className="mt-2">
            Amount: <strong>{formatCurrency(registration.amountCents)}</strong>
          </Typography>
        </div>

        <div className="mb-6">
          <Typography variant="body" className="mb-3 font-semibold">
            Select Payment Method
          </Typography>

          <RadioWithLabel
            id="saved_card"
            name="payment_method"
            label={
              hasSavedCard
                ? `Use saved card (${defaultPaymentMethod.brand} ending in ${defaultPaymentMethod.last4})`
                : "No saved card available"
            }
            checked={paymentMethod === "saved_card"}
            onChange={handlePaymentMethodChange}
            value="saved_card"
            disabled={!hasSavedCard}
            className="mb-3"
          />

          <RadioWithLabel
            id="new_card"
            name="payment_method"
            label="Add a new card"
            checked={paymentMethod === "new_card"}
            onChange={handlePaymentMethodChange}
            value="new_card"
            className="mb-3"
          />

          {paymentMethod === "new_card" && <PaymentProviderCardForm practice={practice} />}
        </div>

        {paymentError && (
          <Banner type="error" className="mb-4">
            {paymentError}
          </Banner>
        )}
      </div>
    </AnimatedModal>
  )
}

import NiceModal from "@ebay/nice-modal-react"
import React, { useState, useEffect } from "react"
import { useMutation } from "urql"

import { useToast } from "../../contexts/ToastContext"
import { formatPhone, isClientValid } from "../../utils/utils"

import { Button } from "./Buttons"
import DeleteClientModal from "./DeleteClientModal"
import { Input, Label, InputWithLabel } from "./Inputs"
import { NewModal } from "./Modal"

const EDIT_CLIENT_MUTATION = `
  mutation EditClient($clientId: ID!, $fields: ClientInput!) {
    editClient(clientId: $clientId, fields: $fields) {
      result
      errors
    }
  }
`

export default function EditClientModal({ client, modalId, deleteModalId, onSuccess }) {
  const { showToast } = useToast()
  const [, editClient] = useMutation(EDIT_CLIENT_MUTATION)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [clientInput, setClientInput] = useState({
    email: client?.email,
    phone: client?.phone,
    firstName: client?.firstName,
    lastName: client?.lastName,
    address: client?.address,
    addressTwo: client?.addressTwo,
    city: client?.city,
    state: client?.state,
    postalCode: client?.postalCode,
    country: client?.country
  })

  useEffect(() => {
    if (client) {
      setClientInput({
        email: client?.email,
        phone: client?.phone,
        firstName: client?.firstName,
        lastName: client?.lastName,
        address: client?.address,
        addressTwo: client?.addressTwo,
        city: client?.city,
        state: client?.state,
        postalCode: client?.postalCode,
        country: client?.country
      })
    }
  }, [client])

  return (
    <>
      <NewModal id={modalId} fullHeightInMobile={true} header="Edit client">
        <div className="pb-24">
          {error && <p className="text-red">{error}</p>}
          <Label className="mt-4">Full name</Label>
          <div className="flex flex-row">
            <Input
              placeholder="First"
              id="client-first-name-edit"
              defaultValue={clientInput.firstName}
              onChange={(input) => setClientInput({ ...clientInput, firstName: input.target.value })}
              className="relative -mr-1 mb-4 rounded-none rounded-l-lg hover:z-20 focus:z-20"
            />
            <Input
              placeholder="Last"
              id="client-last-name-edit"
              defaultValue={clientInput.lastName}
              onChange={(input) => setClientInput({ ...clientInput, lastName: input.target.value })}
              className="relative mb-4 rounded-none rounded-r-lg hover:z-20 focus:z-20"
            />
          </div>

          <InputWithLabel
            label="Email"
            id="client-email-edit"
            placeholder="Email"
            onChange={(input) => {
              setClientInput({ ...clientInput, email: input.target.value })
            }}
            value={clientInput?.email ? clientInput.email : ""}
            className="mb-4"
            labelClassName="mb-2"
          />

          <InputWithLabel
            label="Phone"
            id="client-phone-edit"
            placeholder="Phone"
            onChange={(input) => {
              setClientInput({ ...clientInput, phone: input.target.value })
            }}
            value={clientInput?.phone ? formatPhone(clientInput.phone) : ""}
            className="mb-4 w-1/2"
            labelClassName="mb-2"
          />

          <InputWithLabel
            label="Address"
            id="client-address-edit"
            placeholder="Address"
            onChange={(input) => {
              setClientInput({ ...clientInput, address: input.target.value })
            }}
            value={clientInput?.address || ""}
            className="mb-4"
            labelClassName="mb-2"
          />

          <InputWithLabel
            label="Address Line 2"
            id="client-address-two-edit"
            placeholder="Apartment, suite, etc."
            onChange={(input) => {
              setClientInput({ ...clientInput, addressTwo: input.target.value })
            }}
            value={clientInput?.addressTwo || ""}
            className="mb-4"
            labelClassName="mb-2"
          />

          <div className="mb-4 flex flex-row gap-4">
            <InputWithLabel
              label="City"
              id="client-city-edit"
              placeholder="City"
              onChange={(input) => {
                setClientInput({ ...clientInput, city: input.target.value })
              }}
              value={clientInput?.city || ""}
              className="flex-1"
              labelClassName="mb-2"
            />

            <InputWithLabel
              label="State/Province"
              id="client-state-edit"
              placeholder="State"
              onChange={(input) => {
                setClientInput({ ...clientInput, state: input.target.value })
              }}
              value={clientInput?.state || ""}
              className="flex-1"
              labelClassName="mb-2"
            />
          </div>

          <div className="mb-4 flex flex-row gap-4">
            <InputWithLabel
              label="Postal Code"
              id="client-postal-code-edit"
              placeholder="Postal Code"
              onChange={(input) => {
                setClientInput({ ...clientInput, postalCode: input.target.value })
              }}
              value={clientInput?.postalCode || ""}
              className="flex-1"
              labelClassName="mb-2"
            />

            <InputWithLabel
              label="Country"
              id="client-country-edit"
              placeholder="Country"
              onChange={(input) => {
                setClientInput({ ...clientInput, country: input.target.value })
              }}
              value={clientInput?.country || ""}
              className="flex-1"
              labelClassName="mb-2"
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between border-t border-gray-light bg-white p-8 sm:bottom-12">
            <Button
              type="warning"
              onClick={() => {
                NiceModal.show(deleteModalId)
              }}>
              Delete
            </Button>
            <div className="flex w-full items-center justify-end gap-4">
              <Button
                type="tertiary"
                onClick={() => {
                  NiceModal.remove(modalId)
                }}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                disabled={!isClientValid}
                onClick={() => {
                  setLoading(true)
                  editClient({ clientId: client.id, fields: clientInput }).then((result) => {
                    if (result.error || result.data.editClient.errors) {
                      setError(`Error updating client: ${result.error || result.data.editClient.errors}`)
                    } else {
                      setError(null)
                      showToast("Client edited successfully. ")
                      NiceModal.remove(modalId)
                      onSuccess(clientInput)
                    }
                    setLoading(false)
                  })
                }}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </NewModal>
      <DeleteClientModal client={client} onSuccess={onSuccess} deleteModalId={deleteModalId} />
    </>
  )
}

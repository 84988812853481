import React from "react"

import PersonalInformationUser from "./PersonalInformationUser"
import ProfileEditProvider from "./ProfileEditProvider"

export default function PersonalInformationPage({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <PersonalInformationUser {...props} />
    </ProfileEditProvider>
  )
}

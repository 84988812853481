import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import { Flyout } from "../../components/shared/Layout"
import { AnimatedModal } from "../../components/shared/Modal"
import { useToast } from "../../contexts/ToastContext"

import BookableEventTypeForm from "./BookableEventTypeForm"
import { useBookableEventTypes } from "./BookableEventTypesContext"

const BookableEventTypeFlyout = () => {
  const {
    showFlyout,
    setShowFlyout,
    bookableEventType,
    saveEventType,
    refetchEventTypes,
    deleteBookableEventType,
    removeBookableEventTypeFromList
  } = useBookableEventTypes()

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [fetching, setFetching] = useState(false)
  const { showToast } = useToast()

  const editingExistingEventType = !!bookableEventType.id

  const saveEnabled = !fetching && bookableEventType.name?.length > 0 && bookableEventType.location?.length > 0

  const handleDelete = async () => {
    setFetching(true)
    try {
      const result = await deleteBookableEventType({ id: bookableEventType.id })

      if (result?.data?.deleteBookableEventType?.result === "success") {
        showToast(`Event type '${bookableEventType.name}' was deleted successfully`)
        removeBookableEventTypeFromList(bookableEventType.id)
        setDeleteModalVisible(false)
        setShowFlyout(false)
        if (refetchEventTypes) {
          refetchEventTypes()
        }
      } else {
        let errorMessage = "There was an error deleting your event type"
        if (result?.data?.deleteBookableEventType?.errors) {
          errorMessage += `: ${result.data.deleteBookableEventType.errors}`
        }
        showToast({ type: "error", content: errorMessage })
      }
    } catch (error) {
      console.error("Error deleting event type:", error)
      showToast({ type: "error", content: "There was an error deleting your event type" })
    } finally {
      setFetching(false)
    }
  }

  const handleSave = async () => {
    setFetching(true)
    try {
      const result = await saveEventType()
      if (result?.data?.upsertBookableEventType?.result === "success") {
        showToast(`Event type '${bookableEventType.name}' was saved successfully`)
        setShowFlyout(false)
      } else {
        let errorMessage = "Error"
        if (result?.data?.upsertBookableEventType?.errors) {
          errorMessage += `: ${result.data.upsertBookableEventType.errors}`
        }
        showToast({ type: "error", content: errorMessage })
      }
    } catch (error) {
      console.error("Error saving event type:", error)
      showToast({ type: "error", content: "There was an error saving your event type" })
    } finally {
      setFetching(false)
    }
  }

  return (
    <>
      <Flyout
        header={editingExistingEventType ? "Edit Event Type" : "Create Event Type"}
        visible={showFlyout}
        closeFlyout={() => setShowFlyout(false)}
        onSave={handleSave}
        saveEnabled={saveEnabled}
        footer={
          <div className={`flex w-full items-center ${editingExistingEventType ? "justify-between" : "justify-end"}`}>
            {editingExistingEventType && (
              <Button type="destructive" onClick={() => setDeleteModalVisible(true)} disabled={fetching}>
                Delete
              </Button>
            )}
            <div className="flex gap-4">
              <Button type="tertiary" onClick={() => setShowFlyout(false)} disabled={fetching}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleSave} disabled={!saveEnabled || fetching}>
                Save
              </Button>
            </div>
          </div>
        }>
        <BookableEventTypeForm />
      </Flyout>

      {/* Delete Modal */}
      <AnimatedModal
        visible={deleteModalVisible}
        header="Delete Event Type"
        confirmLabel="Delete"
        confirmType="destructive"
        hideModal={() => setDeleteModalVisible(false)}
        onSave={handleDelete}
        disabled={fetching}
        showFooter={true}
        actionButtonCopy="Delete"
        cancelButtonCopy="Cancel"
        cancelButtonType="tertiary"
        actionButtonType="destructive">
        <p>
          Are you sure you want to delete the event type <strong>{bookableEventType.name}</strong>? This action cannot
          be undone.
        </p>
      </AnimatedModal>
    </>
  )
}

export default BookableEventTypeFlyout

import React, { useContext, useEffect, useRef } from "react"

import { PaymentProviderContext } from "./PaymentProviderWrapper"

export default function SquarePaymentForm() {
  const cardRef = useRef()

  const { squarePayments, squareCard, setSquareCard } = useContext(PaymentProviderContext)

  useEffect(() => {
    const initializePayment = async () => {
      const card = await squarePayments.card()
      await card.attach(cardRef.current)
      setSquareCard(card)
    }
    if (squarePayments) {
      initializePayment()
    }
  }, [squarePayments])

  return (
    <>
      {!squareCard && <div>Loading...</div>}
      <div id="card-container" ref={cardRef}></div>
    </>
  )
}

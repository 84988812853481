import React, { useState } from "react"

import GraphQLProvider from "../../providers/GraphQLProvider"

import ClientChronology from "./ClientChronology"
import ClientPageView from "./ClientPageView"

const Overview = ({ client: clientProp, upcomingAppointments, clientNotesEnabled }) => {
  const [client] = useState(clientProp)

  return (
    <ClientPageView
      client={client}
      upcomingAppointments={upcomingAppointments}
      path={`/portal/clients/${client.id}`}
      clientNotesEnabled={clientNotesEnabled}>
      <ClientChronology client={client} clientNotesEnabled={clientNotesEnabled} />
    </ClientPageView>
  )
}

export default function ClientOverview(props) {
  return (
    <GraphQLProvider>
      <Overview {...props} />
    </GraphQLProvider>
  )
}

import React, { useEffect, useState } from "react"
import { useMutation } from "urql"

import { useToast } from "../../contexts/ToastContext"
import AddOrRetryButton from "../pro_portal/calendar/AddOrRetryButton"

import Badge from "./Badge"
import { Box, Flyout } from "./Layout"
import { Link, UnderlinedLink } from "./Links"
import LoadingSpinner from "./LoadingSpinner"
import Typography from "./Typography"

const ACCEPT_APPOINTMENT_MUTATION = `
  mutation AcceptAppointment($appointmentId: ID!) {
    acceptAppointment(appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

const RETRY_PAYMENT_MUTATION = `
  mutation RetryPayment($appointmentId: ID!) {
    retryPayment(appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

const NotificationsFlyout = ({ visible, closeFlyout, fetching, notificationAppointments, reexecuteQuery }) => {
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    setAppointments(notificationAppointments)
  }, [notificationAppointments])

  const [{ fetching: acceptAppointmentFetching }, acceptAppointment] = useMutation(ACCEPT_APPOINTMENT_MUTATION)
  const [{ fetching: retryPaymentFetching }, retryPayment] = useMutation(RETRY_PAYMENT_MUTATION)
  const mutationFetching = acceptAppointmentFetching || retryPaymentFetching
  const { showToast } = useToast()

  // TODO: refactor this to open the notification flyout on any page
  const handleViewDetailsClick = (event, url) => {
    event.preventDefault()
    window.location.href = url
    if (window.location.href.includes("/portal/calendar")) {
      window.location.reload()
    }
  }

  const handleRetryPayment = (appointmentId) => {
    retryPayment({ appointmentId }).then((res) => {
      if (res.data?.retryPayment?.result === "success") {
        showToast("Success! Payment processed.")
        setAppointments((prev) => prev.filter((appointment) => appointment.id !== appointmentId))
      } else {
        showToast({
          type: "error",
          content: `Payment failed: ${res.data?.retryPayment?.errors[0] || "Internal server error"}`
        })
      }
    })
  }

  return (
    <Flyout header="Notifications" visible={visible} closeFlyout={closeFlyout}>
      {fetching ? (
        <LoadingSpinner />
      ) : appointments.length > 0 ? (
        <div className="flex flex-col gap-4">
          {appointments.map((appointment) => {
            const upcoming = new Date(appointment.startsAt) > Date.now()
            const pending = appointment.state === "pending"
            const failed = appointment.paymentError
            const overdue = !appointment.paymentError && !appointment.hasTransaction && !upcoming
            const unprocessed = !appointment.paymentError && appointment.hasTransaction && !upcoming
            const client = appointment.user || appointment.client
            const buttonText = failed || unprocessed ? "Retry" : "Add card"
            const badgeType = pending ? "success" : "error"
            const badgeText = pending
              ? "New client"
              : failed
              ? "Payment failed"
              : overdue
              ? "Payment past due"
              : unprocessed
              ? "Payment unprocessed"
              : ""

            const url = `/portal/calendar#${appointment.instantActionToken}`

            return (
              <Box
                key={appointment.id}
                className={mutationFetching ? "pointer-events-none opacity-25" : ""}
                keepBorderInMobile={true}>
                <div>
                  <div className="flex justify-between">
                    <div className="font-bold">{client.name}</div>
                    <Badge type={badgeType}>{badgeText}</Badge>
                  </div>
                  <Typography variant="subtitle">{appointment.formattedStartsAt}</Typography>
                  <div className="mt-4 flex justify-between">
                    <Link href={url} onClick={(event) => handleViewDetailsClick(event, url)}>
                      View details
                    </Link>
                    {pending ? (
                      <button
                        disabled={acceptAppointmentFetching}
                        className="underline"
                        onClick={() => {
                          acceptAppointment({ appointmentId: appointment.id }).then((res) => {
                            if (res.data.acceptAppointment.result === "success") {
                              showToast(
                                <>
                                  🎉 Appointment confirmed. <UnderlinedLink href={url}>Show</UnderlinedLink>
                                </>
                              )
                            } else {
                              showToast({ type: "error", content: "There was an error confirming your appointment." })
                            }
                            reexecuteQuery()
                          })
                        }}>
                        Confirm
                      </button>
                    ) : overdue || failed || unprocessed ? (
                      <>
                        <AddOrRetryButton
                          appointment={appointment}
                          headerLabel={"Choose your payment method"}
                          label={buttonText}
                          onSave={() => handleRetryPayment(appointment.id)}
                          type={"link"}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </Box>
            )
          })}
        </div>
      ) : (
        <div className="text-center">You&apos;re all caught up 🎉</div>
      )}
    </Flyout>
  )
}

export default NotificationsFlyout

import "animate.css/animate.css"
import LocalTime from "local-time"

document.addEventListener("turbolinks:load", function () {
  let calendarIndex = 0

  // LocalTime
  LocalTime.run()

  // Flash
  if ($(".flashes").length) {
    setTimeout(function () {
      $(".flashes").attr("data-state", "displayed")
    }, 10000)
  }

  // Buttons
  $("a[data-icon]").each(function () {
    const text = $(this).text()
    const icon = $(this).attr("data-icon")
    return $(this).html(`<i class='icon-${icon}'></i><span>${text}</span>`)
  })

  $("body").on("click", "a[data-working]", function (e) {
    $(this).addClass("working")
  })

  // Close Dropdowns
  $("body").on("click", ".dropdown button.button-close", function (e) {
    e.preventDefault()
    $(this).closest(".dropdown").removeClass("is-active")
  })

  // Read More
  $("[data-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("data-read-more"))
    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" data-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[data-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[data-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // Read More Bottom
  $("[data-read-more-bottom]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("data-read-more-bottom"))

    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" data-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[data-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[data-read-more-bottom]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // Read More Field2
  $("[field2-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("field2-read-more"))
    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" field2-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[field2-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[field2-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // Read More Field3
  $("[field3-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("field3-read-more"))

    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" field3-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[field3-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[field3-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // Read More Field4
  $("[field4-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("field4-read-more"))

    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" field4-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[field4-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[field4-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // Read More Field5
  $("[field5-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("field5-read-more"))

    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" field5-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[field5-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[field5-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // Service description read more
  $("[service-description-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("service-description-read-more"))
    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" service-description-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[service-description-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[service-description-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  // See more reviews - Parctitioner Page
  $("body").on("click", "#see-more-reviews-button", function (e) {
    $("html,body").animate(
      {
        scrollTop: $("#bottom-reviews").offset().top - 150
      },
      "slow"
    )
    $("#see-more-reviews-bottom").fadeIn(() => {
      if ((document.getElementById("see-more-reviews-button").value = !null)) {
        document.getElementById("see-less-reviews-bottom-button").style.display = "inline-block"
      }
    })
  })

  // See less reviews - Parctitioner Page
  $("body").on("click", "#see-less-reviews-button", function (e) {
    document.getElementById("see-less-reviews-button").style.display = "none"
    $("#see-more-reviews").fadeOut(() => {
      document.getElementById("see-more-reviews-button").style.display = "inline-block"
    })
  })

  // See more reviews bottom - Parctitioner Page
  $("body").on("click", "#see-more-reviews-bottom-button", function (e) {
    document.getElementById("see-more-reviews-bottom-button").style.display = "none"
    $("#see-more-reviews-bottom").fadeIn(() => {
      document.getElementById("see-less-reviews-bottom-button").style.display = "inline-block"
    })
  })

  // See less reviews bottom - Parctitioner Page
  $("body").on("click", "#see-less-reviews-bottom-button", function (e) {
    document.getElementById("see-less-reviews-bottom-button").style.display = "none"
    $("#see-more-reviews-bottom").fadeOut(() => {
      document.getElementById("see-more-reviews-bottom-button").style.display = "inline-block"
    })
  })

  // See more skills - Parctitioner Page
  $("body").on("click", "#see-more-skills-button", function (e) {
    document.getElementById("see-more-skills-button").style.display = "none"
    $("#see-more-skills").fadeIn(() => {
      document.getElementById("see-less-skills-button").style.display = "inline-block"
    })
  })

  // See less skills - Parctitioner Page
  $("body").on("click", "#see-less-skills-button", function (e) {
    document.getElementById("see-less-skills-button").style.display = "none"
    $("#see-more-skills").fadeOut(() => {
      document.getElementById("see-more-skills-button").style.display = "inline-block"
    })
  })

  // See more articles  - Parctitioner Page
  $("body").on("click", "#see-more-articles-button", function (e) {
    document.getElementById("see-more-articles-button").style.display = "none"
    $("#see-more-articles").fadeIn(() => {
      document.getElementById("see-less-articles-button").style.display = "inline-block"
    })
  })

  // See less articles  - Parctitioner Page
  $("body").on("click", "#see-less-articles-button", function (e) {
    document.getElementById("see-less-articles-button").style.display = "none"
    $("#see-more-articles").fadeOut(() => {
      document.getElementById("see-more-articles-button").style.display = "inline-block"
    })
  })

  // See more images  - Parctitioner Page
  $("body").on("click", "#see-more-images-button", function (e) {
    document.getElementById("see-more-images-button").style.display = "none"
    $("#see-more-images").fadeIn(() => {
      document.getElementById("see-less-images-button").style.display = "inline-block"
    })
  })

  // See less images  - Parctitioner Page
  $("body").on("click", "#see-less-images-button", function (e) {
    document.getElementById("see-less-images-button").style.display = "none"
    $("#see-more-images").fadeOut(() => {
      document.getElementById("see-more-images-button").style.display = "inline-block"
    })
  })

  // See more answers  - Parctitioner Page
  $("body").on("click", "#see-more-answers-button", function (e) {
    document.getElementById("see-more-answers-button").style.display = "none"
    $("#see-more-answers").fadeIn(() => {
      document.getElementById("see-less-answers-button").style.display = "inline-block"
    })
  })

  // See less answers  - Parctitioner Page
  $("body").on("click", "#see-less-answers-button", function (e) {
    document.getElementById("see-less-answers-button").style.display = "none"
    $("#see-more-answers").fadeOut(() => {
      document.getElementById("see-more-answers-button").style.display = "inline-block"
    })
  })

  // See more services  - Parctitioner Page
  $("body").on("click", "#see-more-services-button", function (e) {
    document.getElementById("see-more-services-button").style.display = "none"
    $("#hidden-divider").fadeIn()
    $("#see-more-services").fadeIn(() => {
      document.getElementById("see-less-services-button").style.display = "inline-block"
    })
  })

  // See less services  - Parctitioner Page
  $("body").on("click", "#see-less-services-button", function (e) {
    document.getElementById("see-less-services-button").style.display = "none"
    $("#see-more-services").fadeOut(() => {
      document.getElementById("see-more-services-button").style.display = "inline-block"
    })
  })

  // Add License - Profile Setup
  $("body").on("click", "#add-license-button", function (e) {
    document.getElementById("add-license-button").style.display = "none"
    $("#add-license").fadeIn(() => {
      document.getElementById("done-add-license-button").style.display = "inline-block"
    })
  })

  // Done adding license - Profile Setup
  $("body").on("click", "#done-add-license-button", function (e) {
    document.getElementById("done-add-license-button").style.display = "none"
    $("#add-license").fadeOut(() => {
      document.getElementById("add-license-button").style.display = "inline-block"
    })
  })

  // Add Certificate - Profile Setup
  $("body").on("click", "#add-certificate-button", function (e) {
    document.getElementById("add-certificate-button").style.display = "none"
    $("#add-certificate").fadeIn(() => {
      document.getElementById("done-add-certificate-button").style.display = "inline-block"
    })
  })

  // Done adding certificate - Profile Setup
  $("body").on("click", "#done-add-certificate-button", function (e) {
    document.getElementById("done-add-certificate-button").style.display = "none"
    $("#add-certificate").fadeOut(() => {
      document.getElementById("add-certificate-button").style.display = "inline-block"
    })
  })

  // Add Degree - Profile Setup
  $("body").on("click", "#add-degree-button", function (e) {
    document.getElementById("add-degree-button").style.display = "none"
    $("#add-degree").fadeIn(() => {
      document.getElementById("done-add-degree-button").style.display = "inline-block"
    })
  })

  // Done adding degree - Profile Setup
  $("body").on("click", "#done-add-degree-button", function (e) {
    document.getElementById("done-add-degree-button").style.display = "none"
    $("#add-degree").fadeOut(() => {
      document.getElementById("add-degree-button").style.display = "inline-block"
    })
  })

  // Add Membership - Profile Setup
  $("body").on("click", "#add-membership-button", function (e) {
    document.getElementById("add-membership-button").style.display = "none"
    $("#add-membership").fadeIn(() => {
      document.getElementById("done-add-membership-button").style.display = "inline-block"
    })
  })

  // Done adding membership - Profile Setup
  $("body").on("click", "#done-add-membership-button", function (e) {
    document.getElementById("done-add-membership-button").style.display = "none"
    $("#add-membership").fadeOut(() => {
      document.getElementById("add-membership-button").style.display = "inline-block"
    })
  })

  // show more days - appointment offer alternative times modal
  $("body").on("click", "#show-more-days", function (e) {
    document.getElementById("show-more-days").style.display = "none"
    document.getElementById("calendar-times").style.overflowY = "scroll"
  })

  // See all insurances - Parctitioner Search Page
  $("body").on("click", "#see-all-insurances", function (e) {
    document.getElementById("see-all-insurances").style.display = "none"
    document.getElementById("insurance-accepted").style.maxHeight = "100%"
  })

  // Clear all filters - Parctitioner Search Page
  $("body").on("click", "#clear-all-filters", function (e) {
    $('input[type="checkbox"]:checked').prop("checked", false)
  })

  // Equalizer heights
  $("[data-equalizer]").each(function () {
    const parentElement = $(this)
    const children = parentElement.find("[data-equalizer-watch]")
    if (children.length) {
      const heights = children.map(function () {
        return $(this).height()
      })
      const maxHeight = heights.get().reduce(function (a, b) {
        return Math.max(a, b)
      })
      children.height(maxHeight)
    }
  })

  // Modal Close
  $(".modal button.close").click(function (e) {
    e.preventDefault()
    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal .modal-background").click(function (e) {
    e.preventDefault()
    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal button.close-fullwidth").click(function (e) {
    e.preventDefault()
    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal [data-close]").click(function (e) {
    e.preventDefault()
    if ($(this).closest(".modal").hasClass("video")) {
      $("iframe.youtube-video").attr("src", function (i, val) {
        return val
      })
    }

    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal [data-close]").click(function (e) {
    e.preventDefault()
    if ($(this).closest(".modal").hasClass("video")) {
      $("iframe.vimeo-video").attr("src", function (i, val) {
        return val
      })
    }

    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal [data-close]").click(function (e) {
    e.preventDefault()
    if ($(this).closest(".modal").hasClass("video")) {
      $("iframe.bitchute-video").attr("src", function (i, val) {
        return val
      })
    }

    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal [data-close]").click(function (e) {
    e.preventDefault()
    if ($(this).closest(".modal").hasClass("video")) {
      $("iframe.brandnewtube-video").attr("src", function (i, val) {
        return val
      })
    }

    $(this).closest(".modal").removeClass("is-active")
  })

  $(".modal [data-close]").click(function (e) {
    e.preventDefault()
    if ($(this).closest(".modal").hasClass("video")) {
      $("iframe.brighteon-video").attr("src", function (i, val) {
        return val
      })
    }

    $(this).closest(".modal").removeClass("is-active")
  })

  // Toggle View Password
  $(".toggle-view-password").click(function () {
    const pass = document.getElementById("session_password")
    if (pass.type === "password") {
      pass.type = "text"
      document.getElementById("show-hide-pw").classList.add("icon-eye-blocked")
      document.getElementById("show-hide-pw").classList.remove("icon-eye")
    } else {
      pass.type = "password"
      document.getElementById("show-hide-pw").classList.remove("icon-eye-blocked")
      document.getElementById("show-hide-pw").classList.add("icon-eye")
    }
  })

  // Toggle View Password
  $(".toggle-view-password-registration").click(function () {
    const pass = document.getElementById("user_password")
    if (pass.type === "password") {
      pass.type = "text"
      document.getElementById("show-hide-signup-pw").classList.add("icon-eye-blocked")
      document.getElementById("show-hide-signup-pw").classList.remove("icon-eye")
    } else {
      pass.type = "password"
      document.getElementById("show-hide-signup-pw").classList.remove("icon-eye-blocked")
      document.getElementById("show-hide-signup-pw").classList.add("icon-eye")
    }
  })

  $("#next-calendar").click(function (e) {
    e.preventDefault()
    if (calendarIndex < 2) {
      calendarIndex += 1
    }
    const baseIndex = calendarIndex * 4
    document.getElementsByClassName("pro-search-calendar").forEach((element) => {
      $(element).find("th").hide()
      $(element).find("td").hide()
      $(element).find(`th[data-index=${baseIndex}]`).show()
      $(element).find(`td[data-index=${baseIndex}]`).show()
      $(element)
        .find(`th[data-index=${baseIndex + 1}]`)
        .show()
      $(element)
        .find(`td[data-index=${baseIndex + 1}]`)
        .show()
      $(element)
        .find(`th[data-index=${baseIndex + 2}]`)
        .show()
      $(element)
        .find(`td[data-index=${baseIndex + 2}]`)
        .show()
      $(element)
        .find(`th[data-index=${baseIndex + 3}]`)
        .show()
      $(element)
        .find(`td[data-index=${baseIndex + 3}]`)
        .show()
    })
  })

  $("#prev-calendar").click(function (e) {
    e.preventDefault()
    if (calendarIndex > 0) {
      calendarIndex -= 1
    }
    const baseIndex = calendarIndex * 4
    document.getElementsByClassName("pro-search-calendar").forEach((element) => {
      $(element).find("th").hide()
      $(element).find("td").hide()
      $(element).find(`th[data-index=${baseIndex}]`).show()
      $(element).find(`td[data-index=${baseIndex}]`).show()
      $(element)
        .find(`th[data-index=${baseIndex + 1}]`)
        .show()
      $(element)
        .find(`td[data-index=${baseIndex + 1}]`)
        .show()
      $(element)
        .find(`th[data-index=${baseIndex + 2}]`)
        .show()
      $(element)
        .find(`td[data-index=${baseIndex + 2}]`)
        .show()
      $(element)
        .find(`th[data-index=${baseIndex + 3}]`)
        .show()
      $(element)
        .find(`td[data-index=${baseIndex + 3}]`)
        .show()
    })
  })

  // Make calendar/practitioners found sticky on scroll
  $(window).scroll(function () {
    if ($(window).scrollTop() > 5) {
      $(".sticky-calendar").addClass("sticky")
    } else {
      $(".sticky-calendar").removeClass("sticky")
    }
  })

  // "Fake" clicking buttons to trigger actions in stimulus controller
  $("#virtual-button").click(function (e) {
    const btn = document.getElementById("virtual-remote-checkbox")
    btn.click()
    document.getElementById("all-pros-button").classList.remove("active")
    document.getElementById("in-person-button").classList.remove("active")
    document.getElementById("in-home-button").classList.remove("active")
    document.getElementById("virtual-button").classList.add("active")
  })
  $("#in-home-button").click(function (e) {
    const btn = document.getElementById("in-home-remote-checkbox")
    btn.click()
    document.getElementById("all-pros-button").classList.remove("active")
    document.getElementById("in-person-button").classList.remove("active")
    document.getElementById("virtual-button").classList.remove("active")
    document.getElementById("in-home-button").classList.add("active")
  })
  $("#in-person-button").click(function (e) {
    const btn = document.getElementById("inperson-remote-checkbox")
    btn.click()
    document.getElementById("all-pros-button").classList.remove("active")
    document.getElementById("in-person-button").classList.add("active")
    document.getElementById("virtual-button").classList.remove("active")
    document.getElementById("in-home-button").classList.remove("active")
  })
  $("#all-pros-button").click(function (e) {
    const btn = document.getElementById("allpros-remote-checkbox")
    btn.click()
    document.getElementById("all-pros-button").classList.add("active")
    document.getElementById("in-person-button").classList.remove("active")
    document.getElementById("virtual-button").classList.remove("active")
    document.getElementById("in-home-button").classList.remove("active")
  })
  $("#apply-filters")
    .unbind()
    .click(function (e) {
      console.log("track this click event")
      const btn = document.getElementById("submitFilters")
      btn.click()
      $(this).closest(".modal").removeClass("is-active")
    })

  $("#search-field-mobile-button").click(function (e) {
    $("#modal-slider").fadeIn(() => {
      document.getElementById("modal-slider").style.display = "inline-block"
    })
  })

  $("#close-modal-slider").click(function (e) {
    $("#modal-slider").fadeOut(() => {})
  })

  $("#search-button").click(function (e) {
    $("#modal-slider").fadeOut(() => {})
  })

  // "Fake" clicking buttons to trigger filters in localities page
  $("#virtual-localities-button").click(function (e) {
    document.getElementById("all-pros-localities-button").classList.remove("active")
    document.getElementById("in-person-localities-button").classList.remove("active")
    document.getElementById("virtual-localities-button").classList.add("active")
    $("#virtual-locality-value").prop("checked", true)
    $("#in-person-locality-value").prop("checked", false)
    const btn = document.getElementById("submit-localities-filters")
    btn.click()
  })
  $("#in-person-localities-button").click(function (e) {
    document.getElementById("all-pros-localities-button").classList.remove("active")
    document.getElementById("in-person-localities-button").classList.add("active")
    document.getElementById("virtual-localities-button").classList.remove("active")
    $("#virtual-locality-value").prop("checked", false)
    $("#in-person-locality-value").prop("checked", true)
    const btn = document.getElementById("submit-localities-filters")
    btn.click()
  })
  $("#all-pros-localities-button").click(function (e) {
    document.getElementById("all-pros-localities-button").classList.add("active")
    document.getElementById("in-person-localities-button").classList.remove("active")
    document.getElementById("virtual-localities-button").classList.remove("active")
    $("#virtual-locality-value").prop("checked", false)
    $("#in-person-locality-value").prop("checked", false)
    const btn = document.getElementById("submit-localities-filters")
    btn.click()
  })

  // "Fake" clicking buttons to trigger filters in quiz page
  $("#virtual-quiz-button").click(function (e) {
    document.getElementById("all-pros-quiz-button").classList.remove("active")
    document.getElementById("in-person-quiz-button").classList.remove("active")
    document.getElementById("virtual-quiz-button").classList.add("active")
    $("#virtual-quiz-value").prop("checked", true)
    $("#in-person-quiz-value").prop("checked", false)
    const btn = document.getElementById("submit-quiz-filters")
    btn.click()
  })
  $("#in-person-quiz-button").click(function (e) {
    document.getElementById("all-pros-quiz-button").classList.remove("active")
    document.getElementById("in-person-quiz-button").classList.add("active")
    document.getElementById("virtual-quiz-button").classList.remove("active")
    $("#virtual-quiz-value").prop("checked", false)
    $("#in-person-quiz-value").prop("checked", true)
    const btn = document.getElementById("submit-quiz-filters")
    btn.click()
  })
  $("#all-pros-quiz-button").click(function (e) {
    document.getElementById("all-pros-quiz-button").classList.add("active")
    document.getElementById("in-person-quiz-button").classList.remove("active")
    document.getElementById("virtual-quiz-button").classList.remove("active")
    $("#virtual-quiz-value").prop("checked", false)
    $("#in-person-quiz-value").prop("checked", false)
    const btn = document.getElementById("submit-quiz-filters")
    btn.click()
  })

  // Read More Service description
  $("[service-read-more]").each(function () {
    let innerStr = $(this).text()
    const maxLength = parseInt($(this).attr("service-read-more"))
    if (innerStr.includes("read more...")) {
      innerStr = innerStr.substring(maxLength + 13)
    }

    if (
      innerStr.length > maxLength &&
      innerStr.substring(maxLength, maxLength + maxLength / 2) != innerStr.substring(0, maxLength / 2)
    ) {
      const newStr = innerStr.substring(0, maxLength)
      $(this).empty().html(newStr)
      $(this).append(' <a href="" service-read-more-trigger>read more...</a>')
      $(this).append('<span data-more-text class="hidden">' + innerStr + "</span>")
    }
  })

  $("body").on("click", "a[service-read-more-trigger]", function (e) {
    e.preventDefault()
    const parent = $(this).closest("[service-read-more]")
    const originalString = parent.find("span[data-more-text]").html()
    parent.empty().html(originalString)
  })

  $("body").on("click", "#add-time-length-1", function (e) {
    e.preventDefault()
    $(this).parent().siblings(".is-hidden").first().removeClass("is-hidden")
    $(this).addClass("hidden")
  })

  $("body").on("click", "#add-time-length-2", function (e) {
    e.preventDefault()
    $(this).parent().parent().siblings(".is-hidden").first().removeClass("is-hidden")
    $(this).parent().remove()
  })

  $("body").on("click", "#add-time-length-3", function (e) {
    e.preventDefault()
    $(this).parent().siblings(".is-hidden").first().removeClass("is-hidden")
    $(this).addClass("hidden")
  })

  $("body").on("click", "#add-time-length-4", function (e) {
    e.preventDefault()
    $(this).parent().parent().siblings(".is-hidden").first().removeClass("is-hidden")
    $(this).parent().remove()
  })

  $("body").on("click", "#book-now-services", function (e) {
    document.getElementById("booking-side-bar").style.border = "2px solid #0c3954"
    document.getElementById("booking-side-bar").style.transition = "border-width 1.5s linear"
  })

  $("body").on("click", "#coupon-section", function (e) {
    $("#coupon-section").fadeOut(() => {
      $("#coupon-form").fadeIn()
    })
  })

  // TEMPORARY: Show Performance data in Development
  if (process.env.NODE_ENV === "development" && window.performance) {
    const performance = window.performance
    const performanceEntries = performance.getEntriesByType("paint")
    performanceEntries.forEach((performanceEntry, i, entries) => {
      console.log("The time to " + performanceEntry.name + " was " + performanceEntry.startTime + " milliseconds.")
    })
  }

  $("#phone_input").intlTelInput({
    formatOnInit: true,
    separateDialCode: true
  })

  // Press Escape key to close modal
  $(document).keydown(function (event) {
    if (event.keyCode === 27) {
      Array.from(document.getElementsByClassName("modal is-active")).forEach((modal) => {
        modal.classList.remove("is-active")
      })
    }
  })

  const current_url = document.location.toString()
  window.addEventListener("popstate", (event) => {
    if (
      document.location.toString().includes("practitioner-search") &&
      document.location.toString().includes("query")
    ) {
      if (current_url && !current_url.includes("practitioner-search")) {
        location.reload()
      }
    }
  })

  $("#checkout_is_recurring_true").click(function () {
    $(".recurrence").show()
  })
  $("#checkout_is_recurring_false").click(function () {
    $(".recurrence").hide()
  })

  $("body").on("click", ".copy-link", function (e) {
    e.preventDefault()
    const parent = $(this).closest(".copy-link")
    var link = parent.prevObject[0].dataset.value
    var txtArea = document.createElement("textarea")
    document.body.appendChild(txtArea)
    txtArea.value = link
    txtArea.select()
    document.execCommand("copy")
    document.body.removeChild(txtArea)
    document.execCommand("copy")
    parent.prevObject[0].innerText = "Copied to clipboard"
  })
})

import { gql } from "urql"

export const GENERATE_SITE_METADATA_MUTATION = gql`
  mutation GenerateSiteMetadata {
    generateSiteMetadata {
      result
      errors
      websiteMetadata {
        title
        description
        ogSiteName
        primaryModality
      }
    }
  }
`

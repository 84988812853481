import React from "react"

import { CheckBox, InputWithLabel } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"

import LocationPlacesSelect from "./LocationPlacesSelect"

const OfficeLocationFields = ({ location, setLocation, validationErrors }) => (
  <>
    <div>
      <LocationPlacesSelect
        setLocation={setLocation}
        location={location}
        label="Office address"
        validationError={validationErrors.fullStreetAddress}
      />
      {location.address && (
        <InputWithLabel
          validationError={validationErrors.secondaryAddress}
          className="mt-3"
          label="Unit or suite number (optional)"
          placeholder="E.g. Apt. 1A"
          value={location.secondaryAddress || ""}
          onChange={(e) => setLocation({ ...location, secondaryAddress: e.target.value })}
        />
      )}
    </div>
    <div>
      <CheckBox
        className="text-base"
        label="Keep address hidden until booking"
        checked={location.hideAddress}
        onChange={() => {
          setLocation({ ...location, hideAddress: !location.hideAddress })
        }}
      />
      <Typography variant="micro" as="div" className="-mt-2 ml-8">
        Clients will see the area but not the street address
      </Typography>
    </div>
  </>
)

export default OfficeLocationFields

import React, { useState } from "react"
import IntlTelInput from "react-intl-tel-input"
import styled from "styled-components"

import HealMeSquareButton from "../shared/HealMeSquareButton"
import InputField from "../shared/InputField"

const EnterPhoneWrapper = styled.div`
  .input-row {
    margin-bottom: 16px;
  }
`

export default function EnterPhone({ client, nextStep }) {
  const [name, setName] = useState(client.firstName ? client.firstName : "")

  return (
    <div className="column is-full has-text-left">
      <EnterPhoneWrapper>
        <p className="header has-text-left mb-5">Add your name to post your review</p>
        <div className="create-form">
          <div className="input-row">
            <label>Name (publicly available)</label>
            <InputField placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <HealMeSquareButton
            type="primary"
            label="Continue"
            fullWidth={true}
            onClick={() => {
              nextStep(name)
            }}
            disabled={!name}
          />
        </div>
      </EnterPhoneWrapper>
    </div>
  )
}

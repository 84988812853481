import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

export const TIMEZONE_GROUPS = {
  US_CANADA: "US & Canada",
  CENTRAL_SOUTH_AMERICA: "Central & South America",
  ATLANTIC: "Atlantic",
  EUROPE_RUSSIA: "Europe & Russia",
  AFRICA_MIDDLE_EAST: "Africa & Middle East",
  INDIA: "India",
  ASIA_AUSTRALIA: "Asia & Australia",
  PACIFIC: "Pacific"
}

export const TIMEZONE_OPTIONS = [
  // US & Canada
  {
    group: TIMEZONE_GROUPS.US_CANADA,
    timezones: [
      { value: "America/Los_Angeles", label: "Pacific Time (US & Canada)" },
      { value: "America/Denver", label: "Mountain Time (US & Canada)" },
      { value: "America/Chicago", label: "Central Time (US & Canada)" },
      { value: "America/New_York", label: "Eastern Time (US & Canada)" },
      { value: "America/Halifax", label: "Atlantic Time (Canada)" },
      { value: "Pacific/Honolulu", label: "Hawaii" },
      { value: "America/Anchorage", label: "Alaska" },
      { value: "America/Phoenix", label: "Arizona" },
      { value: "America/St_Johns", label: "Newfoundland" },
      { value: "America/Puerto_Rico", label: "Puerto Rico" }
    ]
  },
  // Central & South America
  {
    group: TIMEZONE_GROUPS.CENTRAL_SOUTH_AMERICA,
    timezones: [
      { value: "America/Mexico_City", label: "Mexico City" },
      { value: "America/Sao_Paulo", label: "Brasilia" },
      { value: "America/Argentina/Buenos_Aires", label: "Buenos Aires" },
      { value: "America/Montevideo", label: "Montevideo" },
      { value: "America/Tijuana", label: "Tijuana" },
      { value: "America/Chihuahua", label: "Chihuahua" },
      { value: "America/Mazatlan", label: "Mazatlan" },
      { value: "America/Guatemala", label: "Central America" },
      { value: "America/Monterrey", label: "Monterrey" },
      { value: "America/Regina", label: "Saskatchewan" },
      { value: "America/Bogota", label: "Bogota" },
      { value: "America/Lima", label: "Lima" },
      { value: "America/Lima", label: "Quito" },
      { value: "America/Caracas", label: "Caracas" },
      { value: "America/Guyana", label: "Georgetown" },
      { value: "America/La_Paz", label: "La Paz" },
      { value: "America/Santiago", label: "Santiago" }
    ]
  },
  // Atlantic
  {
    group: TIMEZONE_GROUPS.ATLANTIC,
    timezones: [
      { value: "America/Godthab", label: "Greenland" },
      { value: "Atlantic/South_Georgia", label: "Mid-Atlantic" },
      { value: "Atlantic/Azores", label: "Azores" },
      { value: "Atlantic/Cape_Verde", label: "Cape Verde Is." }
    ]
  },
  // Europe & Russia
  {
    group: TIMEZONE_GROUPS.EUROPE_RUSSIA,
    timezones: [
      { value: "Europe/Lisbon", label: "Edinburgh" },
      { value: "Europe/Lisbon", label: "Lisbon" },
      { value: "Europe/London", label: "London" },
      { value: "Africa/Monrovia", label: "Monrovia" },
      { value: "UTC", label: "UTC" },
      { value: "Europe/Amsterdam", label: "Amsterdam" },
      { value: "Europe/Belgrade", label: "Belgrade" },
      { value: "Europe/Berlin", label: "Berlin" },
      { value: "Europe/Zurich", label: "Bern" },
      { value: "Europe/Bratislava", label: "Bratislava" },
      { value: "Europe/Brussels", label: "Brussels" },
      { value: "Europe/Budapest", label: "Budapest" },
      { value: "Africa/Casablanca", label: "Casablanca" },
      { value: "Europe/Copenhagen", label: "Copenhagen" },
      { value: "Europe/Dublin", label: "Dublin" },
      { value: "Europe/Ljubljana", label: "Ljubljana" },
      { value: "Europe/Madrid", label: "Madrid" },
      { value: "Europe/Paris", label: "Paris" },
      { value: "Europe/Prague", label: "Prague" },
      { value: "Europe/Rome", label: "Rome" },
      { value: "Europe/Sarajevo", label: "Sarajevo" },
      { value: "Europe/Skopje", label: "Skopje" },
      { value: "Europe/Stockholm", label: "Stockholm" },
      { value: "Europe/Vienna", label: "Vienna" },
      { value: "Europe/Warsaw", label: "Warsaw" },
      { value: "Europe/Zagreb", label: "Zagreb" },
      { value: "Europe/Athens", label: "Athens" },
      { value: "Europe/Bucharest", label: "Bucharest" },
      { value: "Europe/Helsinki", label: "Helsinki" },
      { value: "Europe/Kiev", label: "Kyiv" },
      { value: "Europe/Riga", label: "Riga" },
      { value: "Europe/Sofia", label: "Sofia" },
      { value: "Europe/Tallinn", label: "Tallinn" },
      { value: "Europe/Vilnius", label: "Vilnius" },
      { value: "Europe/Minsk", label: "Minsk" },
      { value: "Europe/Moscow", label: "Moscow" },
      { value: "Europe/Volgograd", label: "Volgograd" },
      { value: "Asia/Tbilisi", label: "Tbilisi" },
      { value: "Europe/Samara", label: "Samara" },
      { value: "Asia/Yekaterinburg", label: "Ekaterinburg" },
      { value: "Europe/Kaliningrad", label: "Kaliningrad" },
      { value: "Asia/Vladivostok", label: "Vladivostok" },
      { value: "Asia/Kamchatka", label: "Kamchatka" }
    ]
  },
  // Africa & Middle East
  {
    group: TIMEZONE_GROUPS.AFRICA_MIDDLE_EAST,
    timezones: [
      { value: "Africa/Lagos", label: "West Central Africa" },
      { value: "Africa/Cairo", label: "Cairo" },
      { value: "Africa/Harare", label: "Harare" },
      { value: "Asia/Jerusalem", label: "Jerusalem" },
      { value: "Africa/Johannesburg", label: "Pretoria" },
      { value: "Asia/Baghdad", label: "Baghdad" },
      { value: "Europe/Istanbul", label: "Istanbul" },
      { value: "Asia/Kuwait", label: "Kuwait" },
      { value: "Africa/Nairobi", label: "Nairobi" },
      { value: "Asia/Riyadh", label: "Riyadh" },
      { value: "Asia/Tehran", label: "Tehran" },
      { value: "Asia/Dubai", label: "Abu Dhabi" },
      { value: "Asia/Baku", label: "Baku" },
      { value: "Asia/Muscat", label: "Muscat" },
      { value: "Asia/Yerevan", label: "Yerevan" },
      { value: "Asia/Kabul", label: "Kabul" },
      { value: "Asia/Karachi", label: "Karachi" },
      { value: "Asia/Tashkent", label: "Tashkent" },
      { value: "Asia/Almaty", label: "Almaty" },
      { value: "Asia/Dhaka", label: "Astana" }
    ]
  },
  // India
  {
    group: TIMEZONE_GROUPS.INDIA,
    timezones: [
      { value: "Asia/Kolkata", label: "Chennai" },
      { value: "Asia/Kolkata", label: "Kolkata" },
      { value: "Asia/Kolkata", label: "Mumbai" },
      { value: "Asia/Kolkata", label: "New Delhi" },
      { value: "Asia/Colombo", label: "Sri Jayawardenepura" },
      { value: "Asia/Kathmandu", label: "Kathmandu" },
      { value: "Asia/Dhaka", label: "Dhaka" }
    ]
  },
  // Asia & Australia
  {
    group: TIMEZONE_GROUPS.ASIA_AUSTRALIA,
    timezones: [
      { value: "Asia/Urumqi", label: "Urumqi" },
      { value: "Asia/Rangoon", label: "Rangoon" },
      { value: "Asia/Bangkok", label: "Bangkok" },
      { value: "Asia/Ho_Chi_Minh", label: "Hanoi" },
      { value: "Asia/Jakarta", label: "Jakarta" },
      { value: "Asia/Krasnoyarsk", label: "Krasnoyarsk" },
      { value: "Asia/Novosibirsk", label: "Novosibirsk" },
      { value: "Asia/Shanghai", label: "Beijing" },
      { value: "Asia/Shanghai", label: "Chongqing" },
      { value: "Asia/Hong_Kong", label: "Hong Kong" },
      { value: "Asia/Irkutsk", label: "Irkutsk" },
      { value: "Asia/Kuala_Lumpur", label: "Kuala Lumpur" },
      { value: "Australia/Perth", label: "Perth" },
      { value: "Asia/Singapore", label: "Singapore" },
      { value: "Asia/Taipei", label: "Taipei" },
      { value: "Asia/Ulaanbaatar", label: "Ulaanbaatar" },
      { value: "Asia/Tokyo", label: "Osaka" },
      { value: "Asia/Tokyo", label: "Sapporo" },
      { value: "Asia/Seoul", label: "Seoul" },
      { value: "Asia/Tokyo", label: "Tokyo" },
      { value: "Asia/Yakutsk", label: "Yakutsk" },
      { value: "Australia/Adelaide", label: "Adelaide" },
      { value: "Australia/Darwin", label: "Darwin" },
      { value: "Australia/Brisbane", label: "Brisbane" },
      { value: "Australia/Canberra", label: "Canberra" },
      { value: "Australia/Hobart", label: "Hobart" },
      { value: "Australia/Melbourne", label: "Melbourne" },
      { value: "Pacific/Port_Moresby", label: "Port Moresby" },
      { value: "Australia/Sydney", label: "Sydney" },
      { value: "Asia/Magadan", label: "Magadan" },
      { value: "Pacific/Noumea", label: "New Caledonia" },
      { value: "Asia/Srednekolymsk", label: "Srednekolymsk" },
      { value: "Pacific/Auckland", label: "Auckland" },
      { value: "Pacific/Fiji", label: "Fiji" },
      { value: "Pacific/Auckland", label: "Wellington" }
    ]
  },
  // Pacific
  {
    group: TIMEZONE_GROUPS.PACIFIC,
    timezones: [
      { value: "Pacific/Midway", label: "International Date Line West" },
      { value: "Pacific/Pago_Pago", label: "American Samoa" },
      { value: "Pacific/Midway", label: "Midway Island" },
      { value: "Pacific/Guam", label: "Guam" },
      { value: "Pacific/Guadalcanal", label: "Solomon Is." },
      { value: "Pacific/Tarawa", label: "Marshall Is." },
      { value: "Pacific/Chatham", label: "Chatham Is." },
      { value: "Pacific/Tongatapu", label: "Nuku'alofa" },
      { value: "Pacific/Apia", label: "Samoa" },
      { value: "Pacific/Fakaofo", label: "Tokelau Is." }
    ]
  }
]

export const formatTimezoneOption = (timezone) => {
  const timeString = dayjs().tz(timezone.value).format("hh:mm A")

  return {
    ...timezone,
    label: `${timezone.label}`,
    formattedLabel: `${timezone.label} ${timeString}`,
    currentTime: timeString
  }
}

export const getGroupedTimezoneOptions = () =>
  TIMEZONE_OPTIONS.map((group) => ({
    label: group.group,
    options: group.timezones.map(formatTimezoneOption)
  }))

import React, { useState, useEffect } from "react"
import { useMutation, useQuery } from "urql"

import { PAYMENT_STATUS_OPTIONS } from "../../../@core/appointment/appointment.model"
import { useManualBooking } from "../../../contexts/ManualBookingContext"
import { usePractice } from "../../../contexts/PracticeContext"
import { useToast } from "../../../contexts/ToastContext"
import { formatPhone } from "../../../utils/utils"
import AppointmentAdjustments from "../../shared/AppointmentAdjustments"
import { Link } from "../../shared/Links"

import {
  PRACTICE_QUERY,
  CREATE_APPOINTMENT_MUTATION,
  CANCEL_APPOINTMENT_MUTATION,
  ACCEPT_APPOINTMENT_MUTATION,
  DECLINE_APPOINTMENT_MUTATION,
  UPDATE_APPOINTMENT_V2_MUTATION
} from "./appointment_graphql"
import AppointmentActions from "./client_event_form/AppointmentActions"
import AppointmentDetails from "./client_event_form/AppointmentDetails"
import AppointmentReview from "./client_event_form/AppointmentReview"
import AppointmentStatus from "./client_event_form/AppointmentStatus"
import ClientInfo from "./client_event_form/ClientInfo"
import ConfirmModals from "./client_event_form/ConfirmModals"
import ErrorBanner from "./client_event_form/ErrorBanner"
import ManualPaymentInvoiceSection from "./client_event_form/ManualPaymentInvoiceSection"

const ClientEventForm = ({
  onAppointmentChanged,
  onRescheduleSuccess,
  onAppointmentCancelled,
  onRecurringAppointmentsChanged
}) => {
  const [showReschedule, setShowReschedule] = useState(null)
  const [practitionerNote, setPractitionerNote] = useState("")
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showPackageConfirmModal, setShowPackageConfirmModal] = useState(false)
  const [showPastAppointmentModal, setShowPastAppointmentModal] = useState(false)
  const [pendingAction, setPendingAction] = useState(null)
  const [cancelRecurringSeries, setCancelRecurringSeries] = useState(false)

  const {
    appointment,
    services,
    locationId,
    client,
    startsAt,
    endsAt,
    recurringStartTimes,
    recurrencePattern,
    createRecurringAppointments,
    createRecurringAppointmentsFetching,
    resetBookingState,
    appointmentErrors,
    setAppointmentErrors
  } = useManualBooking()

  const [{ fetching: createFetching }, createAppointment] = useMutation(CREATE_APPOINTMENT_MUTATION)
  const [{ fetching: updateV2Fetching }, updateAppointmentV2] = useMutation(UPDATE_APPOINTMENT_V2_MUTATION)
  const [{ fetching: cancelFetching }, cancelAppointment] = useMutation(CANCEL_APPOINTMENT_MUTATION)
  const [{ fetching: acceptFetching }, acceptAppointment] = useMutation(ACCEPT_APPOINTMENT_MUTATION)
  const [{ fetching: declineFetching }, declineAppointment] = useMutation(DECLINE_APPOINTMENT_MUTATION)

  const fetching =
    createFetching ||
    updateV2Fetching ||
    cancelFetching ||
    createRecurringAppointmentsFetching ||
    acceptFetching ||
    declineFetching

  const { showToast } = useToast()
  const { practice, setPracticeServices, setPractice } = usePractice()

  const [{ data: practiceData }] = useQuery({
    query: PRACTICE_QUERY,
    variables: { includePrivate: true, includeAddOns: true },
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (practiceData?.currentPractice?.services) {
      setPracticeServices(practiceData.currentPractice.services)
    }
    if (practiceData && practiceData.currentPractice.bookingAvailability.availableSlots && !appointment) {
      resetDefaultValues()
      setPractice((prev) => ({ ...prev, bookingAvailability: practiceData.currentPractice.bookingAvailability }))
    }
  }, [practiceData, appointment])

  const resetDefaultValues = () => {
    resetBookingState()
  }

  const handleErrors = (data, successCallback) => {
    if (data?.result === "success") {
      successCallback(data)
    } else {
      setAppointmentErrors(data?.errors?.join() || "Unexpected error. Please contact support if this persists.")
    }
  }

  const createManualAppointment = () => {
    let clientId = null
    let newClient = null

    if (client.id) {
      clientId = client.id
    } else {
      newClient = client
    }

    createAppointment({
      appointmentServices: services
        .filter((service) => service.service?.id)
        .map((service) => ({
          amountCents: service.amountCents,
          serviceId: service.service.id,
          saveCustomPrice: service.saveCustomPrice
        })),
      locationId: locationId,
      startsAt,
      endsAt,
      clientId: clientId,
      client: newClient
    }).then((result) => {
      handleErrors(result.data.proCreateAppointment, () => {
        resetDefaultValues()
        setShowPackageConfirmModal(false)
        showToast("Appointment created successfully.")
        onAppointmentChanged(result.data.proCreateAppointment.appointment)
      })
    })
  }

  const editManualAppointment = () => {
    updateAppointmentV2({
      appointmentId: appointment.id,
      appointmentServices: services
        .filter((service) => service.service?.id)
        .map((service) => ({
          amountCents: service.amountCents,
          serviceId: service.service.id,
          saveCustomPrice: service.saveCustomPrice
        })),
      locationId,
      startsAt,
      endsAt,
      practitionerNote
    }).then((result) => {
      handleErrors(result.data?.updateAppointmentV2, () => {
        showToast("Appointment updated successfully.")
        onAppointmentChanged(result.data?.updateAppointmentV2?.appointment)
      })
    })
  }

  const handleCancelSuccess = () => {
    cancelAppointment({ appointmentId: appointment.id, practitionerNote, cancelRecurringSeries }).then((result) => {
      if (result.data.cancelAppointment.result === "success") {
        setShowCancelModal(false)
        showToast({
          type: "info",
          content: cancelRecurringSeries ? "Your recurring series was cancelled" : "Your appointment was cancelled"
        })
        onAppointmentCancelled(result.data.cancelAppointment.cancelledAppointmentIds)
      } else {
        setShowCancelModal(false)
        setAppointmentErrors(result.data.cancelAppointment.errors.join())
      }
    })
  }

  const handlePackageConfirm = () => {
    if (recurringStartTimes && recurrencePattern) {
      createRecurringAppointments((appointments) => {
        resetDefaultValues()
        onRecurringAppointmentsChanged(appointments)
        setShowPackageConfirmModal(false)
      })
    } else {
      createManualAppointment()
    }
  }

  const handlePastAppointmentConfirm = () => {
    if (pendingAction) {
      pendingAction()
    }
    setShowPastAppointmentModal(false)
    setPendingAction(null)
  }

  const showManualPaymentButton =
    appointment?.paymentStatus !== PAYMENT_STATUS_OPTIONS.PAID && appointment?.amountCents > 0

  let clientPhone = formatPhone(client?.phone)
  if (clientPhone) {
    clientPhone = clientPhone.startsWith("+1") || clientPhone.startsWith("1") ? clientPhone : "+1 " + clientPhone
  }

  return (
    <div className={fetching ? "pointer-events-none opacity-25" : ""}>
      {appointment && <AppointmentStatus />}

      {showManualPaymentButton && <ManualPaymentInvoiceSection />}

      <ClientInfo />

      {practice.clientNotesEnabled && client && appointment && (
        <div className="mb-6 flex w-full justify-end">
          <Link href={`/portal/clients/${client?.id}#${appointment.instantActionToken}`}>+ Add appointment notes</Link>
        </div>
      )}

      <AppointmentDetails />

      <AppointmentReview />

      {appointment && appointment.paymentStatus === "Paid" && <AppointmentAdjustments />}

      <ErrorBanner appointmentErrors={appointmentErrors} />

      <AppointmentActions
        fetching={fetching}
        onDecline={() => declineAppointment({ appointmentId: appointment.id })}
        removeAppointmentFromCalendar={onAppointmentCancelled}
        onAccept={() => acceptAppointment({ appointmentId: appointment.id })}
        onAppointmentChanged={onAppointmentChanged}
        onCancel={() => setShowCancelModal(true)}
        createManualAppointment={createManualAppointment}
        editManualAppointment={editManualAppointment}
        onRecurringAppointmentsChanged={onRecurringAppointmentsChanged}
        resetBookingState={resetBookingState}
        setShowPackageConfirmModal={setShowPackageConfirmModal}
        setShowPastAppointmentModal={setShowPastAppointmentModal}
        setPendingAction={setPendingAction}
      />

      <ConfirmModals
        showReschedule={showReschedule}
        showCancelModal={showCancelModal}
        showPackageConfirmModal={showPackageConfirmModal}
        showPastAppointmentModal={showPastAppointmentModal}
        onRescheduleSuccess={onRescheduleSuccess}
        onCancelSuccess={handleCancelSuccess}
        hideRescheduleModal={() => setShowReschedule(false)}
        hideCancelModal={() => setShowCancelModal(false)}
        hidePackageConfirmModal={() => setShowPackageConfirmModal(false)}
        hidePastAppointmentModal={() => {
          setShowPastAppointmentModal(false)
          setPendingAction(null)
        }}
        onPackageConfirm={handlePackageConfirm}
        onPastAppointmentConfirm={handlePastAppointmentConfirm}
        practitionerNote={practitionerNote}
        setPractitionerNote={setPractitionerNote}
        cancelRecurringSeries={cancelRecurringSeries}
        setCancelRecurringSeries={setCancelRecurringSeries}
      />
    </div>
  )
}

export default ClientEventForm

import capitalize from "lodash/capitalize"
import React, { useState } from "react"
import { useMutation, useQuery } from "urql"

import PaymentProviderWrapper from "../../components/payments/PaymentProviderWrapper"
import { Banner, EmptyStateBox } from "../../components/shared/Layout"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { ToastProvider, useToast } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import { DEFAULT_PAYMENT_METHOD_QUERY } from "../../utils/mutations"

import AddCreditCardModal from "./AddCreditCardModal"

export const DETACH_PAYMENT_METHOD_PRACTICE_MUTATION = `
  mutation DetachPaymentMethodPractice($clientId: ID!) {
    detachPaymentMethodPractice(clientId: $clientId) {
      result
      errors
    }
  }
`

const ClientPaymentMethod = ({ client, setCreditCardModalOpen, setSelectedClient, setShowDeleteModal }) => {
  const [{ data, fetching, error }] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: {
      clientId: client.id,
      userId: client.userId,
      practiceId: client.practiceId
    },
    requestPolicy: "cache-and-network"
  })

  const paymentMethod = data?.defaultPaymentMethodPractice

  return (
    <Banner className="w-full">
      <Typography variant="h5" as="div">
        {client.practice?.user?.firstName + " " + client.practice?.user?.lastName}
      </Typography>
      <div className="flex items-center justify-between gap-2">
        {fetching ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error fetching payment method</div>
        ) : (
          <>
            <div>
              {paymentMethod
                ? `${capitalize(paymentMethod.brand)} ending in ${paymentMethod.last4} • Expires ${
                    paymentMethod.expMonth
                  }/${paymentMethod.expYear}`
                : "No default payment method"}
            </div>

            <div className="flex gap-4">
              {paymentMethod && (
                <button
                  className="font-bold text-red hover:underline"
                  onClick={() => {
                    setShowDeleteModal(true)
                    setSelectedClient(client)
                  }}>
                  Delete
                </button>
              )}

              <button
                className="font-bold text-teal hover:underline"
                onClick={() => {
                  setCreditCardModalOpen(true)
                  setSelectedClient(client)
                }}>
                {paymentMethod ? "Change" : "Add"}
              </button>
            </div>
          </>
        )}
      </div>
    </Banner>
  )
}

const PaymentMethods = ({ clients }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [, detachPaymentMethodPractice] = useMutation(DETACH_PAYMENT_METHOD_PRACTICE_MUTATION)

  const { showToast } = useToast()

  const getFilteredClients = (clients) => {
    const latestClients = {}

    clients.forEach((client) => {
      const { practiceId, id } = client
      const hasPaymentProvider = client.practice.hasPaymentProvider
      if ((!latestClients[practiceId] || latestClients[practiceId].id < id) && hasPaymentProvider) {
        latestClients[practiceId] = client
      }
    })

    return Object.values(latestClients)
  }

  const filteredClients = getFilteredClients(clients)

  return (
    <div>
      <Typography variant="h3" as="h1">
        Payment methods
      </Typography>
      <Typography variant="subtitle" className="mb-4">
        Payment methods are saved related to a practitioner.
      </Typography>

      {filteredClients.length === 0 && (
        <EmptyStateBox>
          <div className="font-bold">No payment methods saved</div>
        </EmptyStateBox>
      )}

      <div className="flex flex-col gap-4">
        {filteredClients.map((client) => (
          <ClientPaymentMethod
            key={client.id}
            client={client}
            setCreditCardModalOpen={setCreditCardModalOpen}
            setSelectedClient={setSelectedClient}
            setShowDeleteModal={setShowDeleteModal}
          />
        ))}
      </div>

      {creditCardModalOpen && selectedClient && (
        <PaymentProviderWrapper practice={selectedClient.practice} client={selectedClient}>
          <AddCreditCardModal
            headerLabel={"Add a credit card"}
            open={creditCardModalOpen}
            closeModal={() => setCreditCardModalOpen(false)}
            practice={selectedClient.practice}
            onSave={() => window.location.reload()}
          />
        </PaymentProviderWrapper>
      )}

      {showDeleteModal && selectedClient && (
        <AnimatedModal
          visible={showDeleteModal}
          header="Delete payment method"
          hideModal={() => setShowDeleteModal(false)}
          actionButtonCopy="Yes, delete"
          actionButtonType="destructive"
          showFooter={true}
          onSave={() => {
            detachPaymentMethodPractice({
              clientId: selectedClient.id
            }).then((res) => {
              if (res.data?.detachPaymentMethodPractice?.result === "success") {
                showToast({ content: "Payment method deleted successfully", type: "success" })
                window.location.reload()
              } else {
                showToast({ content: "There was an error deleting the payment method", type: "error" })
                console.error(res) // eslint-disable-line no-console
              }
              setShowDeleteModal(false)
            })
          }}>
          <p>Are you sure you want to delete this payment method?</p>
        </AnimatedModal>
      )}
    </div>
  )
}

export default function ManagePaymentMethods(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <PaymentMethods {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

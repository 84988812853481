import { ExportToCsv } from "export-to-csv"
import React, { useState, useEffect } from "react"
import { useQuery } from "urql"

import { CalendarFlyoutProvider } from "../../../contexts/CalendarFlyoutContext"
import { ManualBookingProvider, useManualBooking } from "../../../contexts/ManualBookingContext"
import { PracticeProvider } from "../../../contexts/PracticeContext"
import { BookableEventsProvider } from "../../../pages/BookableEvents/BookableEventsContext"
import DefaultProvider from "../../../providers/DefaultProvider"
import { fetchAppointment } from "../../../utils/fetchAppointment"
import { formatShortDate, formatPrice } from "../../../utils/utils"
import Badge from "../../shared/Badge"
import { Button } from "../../shared/Buttons"
import HealMeTable from "../../shared/HealMeTable"
import { Select } from "../../shared/Inputs"
import Typography from "../../shared/Typography"
import AppointmentFlyout from "../calendar/AppointmentFlyout"

import PaymentSettingsFlyout from "./PaymentSettingsFlyout"

const APPOINTMENTS_QUERY = `
  query PaymentDirectory($limit: Int, $offset: Int, $dateFilter: String, $stateFilter: String, $searchTerm: String, $clientId: ID) {
    currentPractice {
      isStripeApproved
      hasPaymentProvider
      requireCcEnabled
      offlinePaymentMethod
      pastAppointments(limit: $limit, offset: $offset, dateFilter: $dateFilter, stateFilter: $stateFilter, searchTerm: $searchTerm, clientId: $clientId) {
        id
        startsAt
        endsAt
        amountCents
        tipAmountCents
        fullTotal
        timeZone
        state
        instantActionToken
        clientName
        paymentStatus
        markPaid
        paidBy
        paymentMethod
        createdAt
        service { id name timeLength }
        appointmentServices {
          amountCents
          service {
            id
            serviceId
            name
            amountCents
            timeLength
            package
          }
        }
        location { id name }
        user { id firstName lastName }
        client { id firstName lastName }
        package { id numberOfSessions }
        discountCodeRedemptions { code amountCents }
      }
      pastAppointmentsCount(dateFilter: $dateFilter, stateFilter: $stateFilter, searchTerm: $searchTerm, clientId: $clientId)
      totalCollected(clientId: $clientId)
      totalOutstanding(clientId: $clientId)
    }
  }
`

const dateRangeOptions = [
  { value: "alltime", label: "All time" },
  { value: "thisweek", label: "This week" },
  { value: "lastweek", label: "Last week" },
  { value: "thismonth", label: "This month" },
  { value: "lastmonth", label: "Last month" },
  { value: "thisyear", label: "This year" },
  { value: "lastyear", label: "Last year" }
]

const stateOptions = [
  { value: "allpayments", label: "All payments" },
  { value: "paid", label: "Paid" },
  { value: "upcoming", label: "Upcoming" },
  { value: "unpaid", label: "Unpaid" },
  { value: "free", label: "Free" }
]

const limitOptions = [
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: null, label: "All" }
]

const consolidatePackages = (appointments) => {
  const seenPackages = new Set()
  const filteredAppointments = []
  for (const item of appointments) {
    const packageId = item.package?.id

    if (packageId) {
      // This is a hack so the "createdAt" accessor for the "Date" header in the table can work both packages and appointments.
      // For packages the date should be the date the package was purchased, not the date of the first appointment.
      item.startsAt = item.package.createdAt
    }
    if (!packageId || !seenPackages.has(packageId)) {
      filteredAppointments.push(item)
      if (packageId) seenPackages.add(packageId)
    }
  }
  return filteredAppointments
}

const TableHeader = ({ dateFilter, stateFilter, limit, handleFilter, totalCount, showPagination, showSearch }) => (
  <div className={`flex w-full justify-start gap-2 lg:flex-col sm:ml-0 sm:mt-4 ${showSearch ? "ml-4" : ""}`}>
    <div className="flex flex-row gap-2 sm:justify-between">
      <Select
        options={dateRangeOptions}
        value={dateFilter}
        onChange={(e) => handleFilter(e.target.value, stateFilter, limit)}
        className="sm:w-1/2"
      />
      <Select
        options={stateOptions}
        value={stateFilter}
        onChange={(e) => handleFilter(dateFilter, e.target.value, limit)}
        className="sm:w-1/2"
      />
    </div>
    {showPagination && (
      <div className="ml-auto flex flex-row items-center gap-2">
        <span>Showing</span>
        <Select
          options={limitOptions}
          value={limit}
          onChange={(e) => handleFilter(dateFilter, stateFilter, e.target.value)}
        />
        <span>of {totalCount}</span>
      </div>
    )}
  </div>
)

const PaymentsTable = ({ communicationSettings, clientId }) => {
  const { setAppointment } = useManualBooking()

  const [appointmentFlyoutVisible, setAppointmentFlyoutVisible] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [filteredPayments, setFilteredPayments] = useState([])
  const [dateFilter, setDateFilter] = useState(dateRangeOptions[0].value)
  const [stateFilter, setStateFilter] = useState(stateOptions[0].value)
  const [limit, setLimit] = useState(limitOptions[0].value)
  const [page, setPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: APPOINTMENTS_QUERY,
    variables: {
      limit: parseInt(limit) || undefined,
      offset: limit ? page * parseInt(limit) : undefined,
      dateFilter,
      stateFilter,
      searchTerm,
      clientId
    },
    requestPolicy: "cache-and-network"
  })

  if (error) console.error(error)

  useEffect(() => {
    if (data && data.currentPractice) {
      setFilteredPayments(consolidatePackages(data.currentPractice.pastAppointments))
    }
  }, [data])

  const handleFilter = (dateSelection, stateSelection, limitSelection) => {
    setDateFilter(dateSelection || dateFilter)
    setStateFilter(stateSelection || stateFilter)
    setLimit(limitSelection || limit)
    setPage(0)
    reexecuteQuery({ requestPolicy: "cache-and-network" })
  }

  const columns = [
    {
      accessor: "startsAt",
      Header: "Date",
      Cell: (props) => formatShortDate(props.cell.value)
    },
    {
      accessor: "clientName",
      Header: "Client"
    },
    {
      accessor: "service.name",
      Header: "Service"
    },
    {
      accessor: "fullTotal",
      Header: "Amount",
      Cell: (props) => {
        const paymentStatus = props.cell.row.original.paymentStatus
        let textColorClass = ""

        if (paymentStatus === "Unpaid") {
          textColorClass = "text-red"
        } else if (paymentStatus === "Paid") {
          textColorClass = "text-teal"
        } else {
          textColorClass = "text-gray-dark"
        }

        return <span className={`sm:${textColorClass}`}>{formatPrice(props.cell.value || 0)}</span>
      }
    },
    {
      accessor: "paymentStatus",
      Header: "Status",
      className: "status",
      Cell: (props) => {
        const status = props.cell.value

        let badgeType
        switch (status) {
          case "Unpaid":
            badgeType = "error"
            break
          case "Free":
            badgeType = "info"
            break
          case "Paid":
            badgeType = "success"
            break
          case "Upcoming":
            badgeType = "warning"
            break
          default:
            badgeType = "success"
        }

        let paidBy
        if (badgeType === "success") {
          if (props.cell.row.original.markPaid) {
            if (!props.cell.row.original.paidBy) {
              paidBy = "offline"
            } else {
              paidBy = props.cell.row.original.paidBy
            }
          } else {
            paidBy = props.cell.row.original.paymentMethod
          }
        }

        return (
          <>
            <Badge type={badgeType}>{props.cell.value}</Badge>
            <span className="ml-2 text-[12px] font-extrabold uppercase tracking-[0.045rem]">{paidBy}</span>
          </>
        )
      }
    },
    {
      accessor: "instantActionToken",
      Header: "Action",
      className: "row-action",
      Cell: (props) => (
        <>
          {props.cell.row.original.paymentStatus !== "Free" && (
            <a className="font-bold text-teal" onClick={() => showAppointment(props.cell.value)}>
              Manage
            </a>
          )}
        </>
      )
    }
  ]

  if (clientId) {
    const index = columns.findIndex((obj) => obj.accessor === "clientName")
    if (index !== -1) columns.splice(index, 1)
  }

  const showAppointment = async (instantActionToken) => {
    const selectedAppointment = await fetchAppointment(instantActionToken)
    setAppointmentFlyoutVisible(true)
    setAppointment(selectedAppointment)
  }

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: "Heal.me Payments",
    headers: ["Appointment Date", "Client Name", "Service", "Location", "Amount", "Status", "Paid By"]
  }

  const exportToCSV = () => {
    const csvExporter = new ExportToCsv(options)
    const exportable = filteredPayments.map((appt) => ({
      "Appointment Date": formatShortDate(appt.startsAt),
      "Client Name": appt.clientName,
      Service: appt.service.name,
      Location: appt.location.name ? appt.location.name : "",
      Amount: formatPrice(appt.fullTotal),
      Status: appt.paymentStatus,
      "Paid By": appt.markPaid ? (appt.paidBy ? appt.paidBy : "offline") : appt.paymentMethod
    }))
    csvExporter.generateCsv(exportable)
  }

  const genericCallback = () => {
    setAppointmentFlyoutVisible(false)
    setAppointment(null)
    reexecuteQuery()
  }

  if (fetching) {
    return (
      <div className="flex h-screen items-center justify-center">
        <img src="/loading-spinner.png" width="120px" />
      </div>
    )
  }

  return (
    <>
      {!fetching && (
        <>
          {filteredPayments && (filteredPayments.length > 0 || data.currentPractice.isStripeApproved) ? (
            <>
              <div className="mb-8 flex items-end justify-between sm:mb-4">
                <div className="flex flex-col">
                  <Typography as="h1" variant="h3">
                    Payments
                  </Typography>
                  <Typography variant="subtitle" className="sm:hidden">
                    ${data.currentPractice.totalCollected} collected {"\u2022"} ${data.currentPractice.totalOutstanding}{" "}
                    outstanding
                  </Typography>
                </div>
                <div className="block">
                  <Button type="tertiary" size="small" onClick={exportToCSV}>
                    Export
                  </Button>
                  {data.currentPractice.requireCcEnabled && (
                    <Button type="tertiary" size="small" className="ml-4" onClick={() => setShowSettings(true)}>
                      Settings
                    </Button>
                  )}
                </div>
              </div>
              <HealMeTable
                data={filteredPayments}
                columns={columns}
                searchType="payments"
                hasPagination={false}
                header={() => (
                  <TableHeader
                    dateFilter={dateFilter}
                    stateFilter={stateFilter}
                    limit={limit}
                    handleFilter={handleFilter}
                    totalCount={data.currentPractice.pastAppointmentsCount}
                    showPagination={data.currentPractice.pastAppointmentsCount > limit}
                    showSearch={!clientId}
                  />
                )}
                rowClick={(rowData) => showAppointment(rowData.instantActionToken)}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchPlaceholder="Search by name or email"
                showSearch={!clientId}
              />
              {data.currentPractice.pastAppointmentsCount > limit && (
                <div className="mt-2 flex justify-center gap-4">
                  <Button disabled={page === 0} className="w-36" onClick={() => setPage(page - 1)}>
                    Previous
                  </Button>
                  <Button
                    disabled={
                      data.currentPractice.pastAppointments.length < limit ||
                      filteredPayments.length === 0 ||
                      limit === "All"
                    }
                    className="w-36"
                    onClick={() => setPage(page + 1)}>
                    Next
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="flex h-screen flex-col items-center justify-center rounded-xl bg-gray-ultralight">
              <Typography as="h1" variant="h3">
                Start accepting payments today
              </Typography>
              <Typography variant="subtitle" className="mb-3">
                Accept online payments and get paid on time, every time.
              </Typography>
              <Button type="primary" size="large" onClick={() => (window.location.href = "/portal/settings/banking")}>
                Power-up my practice
              </Button>
            </div>
          )}
        </>
      )}
      <AppointmentFlyout
        visible={appointmentFlyoutVisible}
        closeFlyout={genericCallback}
        onAppointmentChanged={genericCallback}
        onAppointmentCancelled={genericCallback}
        onRescheduleSuccess={genericCallback}
      />
      <PaymentSettingsFlyout
        hasPaymentProvider={data.currentPractice.hasPaymentProvider}
        hasOffline={data.currentPractice.offlinePaymentMethod}
        communicationSettings={communicationSettings}
        closeFlyout={() => {
          setShowSettings(false)
        }}
        visible={showSettings}
      />
    </>
  )
}

export default function PaymentDirectory(props) {
  return (
    <DefaultProvider>
      <PracticeProvider>
        <ManualBookingProvider>
          <CalendarFlyoutProvider>
            <BookableEventsProvider>
              <PaymentsTable {...props} />
            </BookableEventsProvider>
          </CalendarFlyoutProvider>
        </ManualBookingProvider>
      </PracticeProvider>
    </DefaultProvider>
  )
}

import amplitude from "amplitude-js"

document.addEventListener("DOMContentLoaded", () => {
  amplitude.getInstance().init(App.amplitude_id, null, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    includeFbclid: true
  })

  amplitude.getInstance().logEvent(`page view: ${App.page_name}`, { "page title": document.title })
  if (App.user_id) {
    amplitude.getInstance().setUserId(App.user_id)
    amplitude.getInstance().setUserProperties({ "profile type": App.user_kind })
  }

  $("a:not([data-amplitude])").click(() => {
    const link = $(this).attr("href")
    const text = $(this).text()
    if (link) {
      amplitude.getInstance().logEvent("link clicked", { href: link, screen: App.page_name, text: text })
    }
  })

  $("[data-amplitude='home-search']").click(() => {
    amplitude.getInstance().logEvent("search from homepage", { query: $("[data-amplitude='query']").val() })
  })

  $("[data-amplitude='homepage-category']").click(() => {
    amplitude.getInstance().logEvent("homepage category clicked", { Category: $(this).text() })
  })

  $("[data-amplitude='homepage-list-practice']").click(() => {
    amplitude.getInstance().logEvent("homepage list practice clicked")
  })

  $("[data-amplitude='slide-pro-carousel']").click(() => {
    amplitude.getInstance().logEvent("viewed more pros")
  })

  $("[data-amplitude='homepage-top-practitioner']").click(() => {
    amplitude.getInstance().logEvent("homepage top practitioner clicked")
  })

  // Booking Widget Clicks
  $("[data-amplitude='service']").click(() => {
    const serviceName = $(this).text()
    amplitude.getInstance().logEvent("service selected", { value: serviceName })
  })

  $("[data-amplitude='location']").click(() => {
    const locationName = $(this).text()
    amplitude.getInstance().logEvent("location selected", { value: locationName })
  })

  $("[data-amplitude='time']").click(() => {
    amplitude.getInstance().logEvent("time selected")
  })

  $("[data-amplitude='booking-started-profile']").click(() => {
    amplitude.getInstance().logEvent("booking started", {
      screen: "Profile Page",
      "pro name": $("[data-amplitude=name-selection]").text(),
      "service name": $("[data-amplitude=service-selection]").text(),
      "service location": $("[data-amplitude=location-selection]").text(),
      "service price": $("[data-amplitude=cost-selection]").text()
    })
  })

  function initAccountHandler() {
    setTimeout(() => {
      $("[data-amplitude='login-created']").click(() => {
        amplitude.getInstance().logEvent("client login created", {
          email: $("[data-amplitude=email-selection]").val()
        })
      })
    }, 1000)
  }

  $("[data-amplitude='personal-details-submitted']").click(() => {
    amplitude.getInstance().logEvent("client personal details entered", {
      "full name": $("[data-amplitude=name-selection]").val(),
      dob: $("[data-amplitude=dob-selection]").val(),
      gender: $("[data-amplitude=gender-selection]").val()
    })
    initAccountHandler()
  })

  function initVerifyHandler() {
    setTimeout(() => {
      $("[data-amplitude='verify-phone']").click(() => {
        amplitude.getInstance().logEvent("client phone verified")
      })

      $("[data-amplitude='phone-no-code']").click(() => {
        amplitude.getInstance().logEvent("phone code not received")
      })
    }, 1000)
  }

  $("[data-amplitude='text-verify']").click(() => {
    initVerifyHandler()
    amplitude.getInstance().logEvent("client phone entered", {
      "phone number": $("[data-amplitude=phone-selection]").val(),
      "verification method": "text"
    })
  })

  $("[data-amplitude='call-verify']").click(() => {
    initVerifyHandler()
    amplitude.getInstance().logEvent("client phone entered", {
      "phone number": $("[data-amplitude=phone-selection]").val(),
      "verification method": "call"
    })
  })

  $("[data-amplitude='quiz-start']").click(() => {
    amplitude.getInstance().logEvent("quiz started")
  })

  if ($("[data-amplitude='checkout-screen").length > 0) {
    amplitude.getInstance().logEvent("checkout started", {
      "pro name": $("[data-amplitude=name-selection]").text(),
      "service name": $("[data-amplitude=service-selection]").text(),
      "service location": $("[data-amplitude=location-selection]").text(),
      "service price": $("[data-amplitude=cost-selection]").text()
    })
  }

  $("[data-amplitude='new-card']").click(() => {
    amplitude.getInstance().logEvent("card added", {
      "pro name": $("[data-amplitude=name-selection]").text(),
      "service name": $("[data-amplitude=service-selection]").text(),
      "service location": $("[data-amplitude=location-selection]").text(),
      "service price": $("[data-amplitude=cost-selection]").text()
    })
  })

  function collectCancelData(canceledBy) {
    amplitude.getInstance().logEvent("booking canceled", {
      "pro name": $(this).data("amplitude-pro"),
      "service name": $(this).data("amplitude-service"),
      "service location": $(this).data("amplitude-location"),
      "service price": $(this).data("amplitude-price"),
      "canceled by": canceledBy
    })
  }

  $("[data-amplitude='consumer-cancel']").click(() => {
    collectCancelData("cancel")
  })

  $("[data-amplitude='pro-cancel']").click(() => {
    collectCancelData("pro")
  })

  function initRescheduleHandler() {
    setTimeout(() => {
      $("[data-amplitude='pro-reject-reschedule']").click(() => {
        amplitude.getInstance().logEvent("booking rejected", {
          "pro name": $(this).data("amplitude-pro"),
          "service name": $(this).data("amplitude-service"),
          "service location": $(this).data("amplitude-location"),
          "service price": $(this).data("amplitude-price"),
          "canceled by": "pro"
        })
      })
    }, 1000)
  }

  $("[data-amplitude='start-reschedule']").click(() => {
    initRescheduleHandler()
  })

  $("[data-amplitude='new-time-accepted']").click(() => {
    amplitude.getInstance().logEvent("booking new time accepted", {
      "pro name": $(this).data("amplitude-pro"),
      "service name": $(this).data("amplitude-service"),
      "service location": $(this).data("amplitude-location"),
      "service price": $(this).data("amplitude-price")
    })
  })

  $("[data-amplitude='question-asked']").click(() => {
    amplitude.getInstance().logEvent("question asked", {
      question: $("[data-amplitude=question]").val(),
      tags: $("[data-amplitude=tags]").val(),
      "additional details": $("[data-amplitude=details]").val()
    })
  })

  function initQuestionHandler() {
    setTimeout(() => {
      $("[data-amplitude='quiz-step-completed']").on("click", () => {
        amplitude.getInstance().logEvent("quiz step completed", {
          "question title": $(this).data("amplitude-title"),
          "question step": $(this).data("amplitude-step"),
          "question answer": $("#quiz_answer_values").val()
        })
        initQuestionHandler()
      })
    }, 1000)
  }

  $("[data-amplitude='quiz-start']").click(() => {
    amplitude.getInstance().logEvent("quiz started")
    initQuestionHandler()
  })

  $("[data-amplitude='first-login']").click(() => {
    amplitude.getInstance().logEvent("first time pro login")
  })

  $("[data-amplitude='save-info']").click(() => {
    if ($("#setup_info_title").val() && $("#headline-id").val() && $("#setup_info_personal_nickname").val()) {
      amplitude.getInstance().logEvent("basic info completed")
    }
  })

  $("[data-amplitude='save-photo']").click(() => {
    if ($(".filestack-upload.with-profile").length) {
      amplitude.getInstance().logEvent("profile photo uploaded")
    }

    if ($(".filestack-upload.with-video").length) {
      amplitude.getInstance().logEvent("video uploaded")
    }

    if ($(".filestack-upload.with-header").length) {
      amplitude.getInstance().logEvent("cover photo uploaded")
    }
  })

  $("[data-amplitude='save-location']").click(() => {
    amplitude.getInstance().logEvent("location saved")
  })

  if ($("[data-amplitude='stripe-connected']").length && $("[data-amplitude='stripe-connected']").is(":visible")) {
    amplitude.getInstance().logEvent("stripe connected")
  }

  $("[data-amplitude='save-service']").click(() => {
    amplitude.getInstance().logEvent("service saved", {
      "service name": $("[data-amplitude=service-name]").val(),
      "service price": $("[data-amplitude=service-price]").val(),
      "service length": $("[data-amplitude=service-length]").val()
    })
  })

  $("[data-amplitude='save-license']").click(() => {
    amplitude.getInstance().logEvent("credential uploaded", {
      "credential type": "license"
    })
  })

  $("[data-amplitude='save-certificate']").click(() => {
    amplitude.getInstance().logEvent("credential uploaded", {
      "credential type": "certificate"
    })
  })

  $("[data-amplitude='save-membership']").click(() => {
    amplitude.getInstance().logEvent("credential uploaded", {
      "credential type": "membership"
    })
  })

  $("[data-amplitude='save-degree']").click(() => {
    amplitude.getInstance().logEvent("credential uploaded", {
      "Credential Type": "degree"
    })
  })

  $("[data-amplitude='save-skills']").click(() => {
    if ($("[data-amplitude='specialty-ids']").val()) {
      amplitude.getInstance().logEvent("skills added")
    }
  })

  $("[data-amplitude='save-shortlink']").click(() => {
    if ($("[data-amplitude='vanity-slug']").val()) {
      amplitude.getInstance().logEvent("skills added")
    }
  })

  $("[data-amplitude='save-about']").click(() => {
    amplitude.getInstance().logEvent("learn more fields edited", {
      "Bio / About": $("[data-amplitude='one']").val(),
      "Your Philosophy of Care": $("[data-amplitude='two']").val(),
      "What Can a Client Expect on Their First Visit?": $("[data-amplitude='three']").val(),
      "Your Inspiration to Become a Healer": $("[data-amplitude='four']").val()
    })
  })

  $("[data-amplitude='save-details']").click(() => {
    amplitude.getInstance().logEvent("practice details edited", {
      Insurance: $("[data-amplitude='insurance']").val(),
      "Work Withs": $("[data-amplitude='work-withs']").val(),
      Ages: $("[data-amplitude='ages']").val(),
      Languages: $("[data-amplitude='languages']").val()
    })
  })

  $("[data-amplitude='upload-gallery']").click(() => {
    amplitude.getInstance().logEvent("gallery photo uploaded")
  })

  $("[data-amplitude='connect-calendar']").click(() => {
    amplitude.getInstance().logEvent("calendar connected", {
      "calendar type": "Google"
    })
  })

  $("[data-amplitude='bookings-settings-updated']").click(() => {
    amplitude.getInstance().logEvent("booking settings updated", {
      "instant booking": $("[data-amplitude='instant-booking']").val(),
      "artificial scarcity": $("[data-amplitude='scarcity']").val(),
      "buffer time": $("[data-amplitude='buffer-time']").val(),
      "lead time": $("[data-amplitude='lead-time']").val(),
      "increment time": $("[data-amplitude='increment-time']").val()
    })
  })

  $("[data-amplitude='invite-single-client']").click(() => {
    amplitude.getInstance().logEvent("single client invited", {
      "client email": $("[data-amplitude='client-email']").val()
    })
  })

  $("[data-amplitude='invite-multiple']").click(() => {
    amplitude.getInstance().logEvent("import clients started")
  })

  $("[data-amplitude='send-message-client']").click(() => {
    amplitude.getInstance().logEvent("consumer message sent", {
      "pro id": $("[data-amplitude='pro-id']").val(),
      "consumer email": $("[data-amplitude='consumer-email']").val()
    })
  })
})

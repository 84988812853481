import { ArrowPathIcon, CheckIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline"
import React from "react"
import { twMerge } from "tailwind-merge"
import { useMutation, useQuery } from "urql"

import { Button } from "../../components/shared/Buttons"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import {
  customDomainConfig,
  handleCustomDomainModalStepChange,
  showCustomDomainModal,
  showPurchaseDomainModal
} from "../../utils/customDomainUtils"
import { isValidUrl } from "../../utils/utils"

const JWT_TOKEN_MUTATION = `
  mutation DomainProviderJwtToken {
    domainProviderJwtToken {
      result
      jwtToken
      errors
    }
  }
`

const UPSERT_CUSTOM_DOMAIN_MUTATION = `
  mutation upsertCustomDomain($domain: String!) {
    upsertCustomDomain(domain: $domain ) {
      result
      errors
    }
  }
`

const DELETE_CUSTOM_DOMAIN_MUTATION = `
  mutation deleteCustomDomain {
    deleteCustomDomain {
      result
      errors
    }
  }
`

const GET_DOMAIN_STATUS_QUERY = `
  query domainStatus($domain: String!) {
    domainStatus(domain: $domain)
  }
`

export default function CustomDomainSection({ customDomain, setDetailsValues, website }) {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [domainStatus, setDomainStatus] = React.useState(null)

  const [{ fetching: jwtTokenFetching }, domainProviderJwtToken] = useMutation(JWT_TOKEN_MUTATION)
  const [, upsertCustomDomain] = useMutation(UPSERT_CUSTOM_DOMAIN_MUTATION)
  const [{ fetching: deleteFetching }, deleteCustomDomain] = useMutation(DELETE_CUSTOM_DOMAIN_MUTATION)

  const [{ data, fetching: statusFeching, error }, reexecuteQuery] = useQuery({
    query: GET_DOMAIN_STATUS_QUERY,
    variables: { domain: customDomain },
    requestPolicy: "network-only"
  })

  React.useEffect(() => {
    if (data?.domainStatus) {
      setDomainStatus(data.domainStatus)
    }
  }, [data])

  const { showToast } = useToast()

  const handleAddOrEditDomain = () => {
    domainProviderJwtToken().then((result) => {
      if (result?.data?.domainProviderJwtToken?.result === "success") {
        const token = result.data.domainProviderJwtToken.jwtToken

        const config = customDomainConfig(token)

        showCustomDomainModal(config)
        window.addEventListener(
          "onEntriStepChange",
          (event) =>
            handleCustomDomainModalStepChange({
              event,
              upsertCustomDomain,
              showToast,
              updateState: setDetailsValues
            }),
          false
        )
      } else {
        let errorMessage = "There was an error getting your custom domain."
        if (result.data?.domainProviderJwtToken?.errors) errorMessage += ` ${result.data.domainProviderJwtToken.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const handleDeleteDomain = () => {
    deleteCustomDomain().then((result) => {
      if (result?.data?.deleteCustomDomain?.result === "success") {
        setDetailsValues("")
        setShowDeleteModal(false)
        showToast({
          type: "success",
          content: "Custom domain deleted successfully"
        })
      } else {
        let errorMessage = "There was an error deleting your custom domain."
        if (result.data?.deleteCustomDomain?.errors) errorMessage += ` ${result.data.deleteCustomDomain.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }
  const handleRefreshDomain = () => {
    reexecuteQuery()
  }

  const handlePurchaseDomain = () => {
    domainProviderJwtToken().then((result) => {
      if (result?.data?.domainProviderJwtToken?.result === "success") {
        const token = result.data.domainProviderJwtToken.jwtToken

        const config = customDomainConfig(token)

        showPurchaseDomainModal(config)
        window.addEventListener(
          "onEntriStepChange",
          (event) =>
            handleCustomDomainModalStepChange({
              event,
              upsertCustomDomain,
              showToast,
              updateState: setDetailsValues
            }),
          false
        )
      } else {
        let errorMessage = "There was an error getting your custom domain."
        if (result.data?.domainProviderJwtToken?.errors) errorMessage += ` ${result.data.domainProviderJwtToken.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const domainActive = domainStatus === "active"

  const domainStatsIcon = statusFeching ? (
    <ArrowPathIcon className="inline-block h-6 w-6 animate-spin rounded-full bg-orange p-1 text-white" />
  ) : error ? (
    <span className="text-red">Error getting status</span>
  ) : domainActive ? (
    <CheckIcon className="inline-block h-6 w-6 rounded-full bg-green p-1 text-white" />
  ) : (
    <ExclamationCircleIcon className="inline-block h-6 w-6 rounded-full bg-red p-1 text-white" />
  )

  const domainStatsText = statusFeching ? "Processing" : error ? "Error" : domainActive ? "Active" : "Activating"

  const cleanWebsite = customDomain
    ? ""
    : website && isValidUrl(website)
    ? `(${new URL(website).hostname.replace(/^www\./, "")})`
    : "(yourwebsite.com)"

  return (
    <div className="flex flex-col">
      <Typography variant="title">Custom domain {cleanWebsite}</Typography>
      <Typography variant="smSubtitle">
        This will point your personal web address to your Heal.me website.
        <br />
        Heal.me partners with Entri to connect and configure custom domains.
      </Typography>
      {customDomain && (
        <>
          <div
            className={twMerge(
              "mt-2 flex w-full items-start justify-start gap-1 rounded-lg border p-4 md:flex-col md:gap-2",
              domainActive ? "border-green bg-green-light" : "",
              statusFeching ? "border-orange bg-orange-light" : "",
              !statusFeching && (error || !domainActive) ? "border-gray-light bg-gray-ultralight" : ""
            )}>
            <div className="flex flex-col items-start justify-start gap-1">
              <Typography variant="smSubtitle">Custom domain:</Typography>
              <a href={`https://${customDomain}`} target="_blank" rel="noreferrer" className="font-bold text-teal">
                {`https://${customDomain}`}
              </a>
            </div>
            <div className="ml-auto flex flex-col items-start justify-start gap-1 md:ml-0">
              <Typography variant="smSubtitle">Status:</Typography>
              <div className="flex items-center justify-start gap-1">
                <div className="flex items-start justify-start gap-2">
                  {domainStatsIcon}

                  <div className="font-bold">{domainStatsText}</div>
                </div>
              </div>
            </div>
          </div>
          {!statusFeching && !domainActive && (
            <Typography variant="smSubtitle" className="mt-2">
              It may take up to 48 hours for the domain to be fully active.
              <br />
              If it takes more than that, please contact support.
            </Typography>
          )}

          <div className="mt-4 flex items-center justify-start gap-2">
            <Button onClick={handleAddOrEditDomain}>Manage</Button>
            <Button
              type="secondary"
              onClick={() => {
                setShowDeleteModal(true)
              }}>
              Delete
            </Button>
            <Button type="secondary" onClick={handleRefreshDomain}>
              Refresh
            </Button>
          </div>
        </>
      )}
      {!customDomain && (
        <>
          <Button className="my-2 w-max" disabled={jwtTokenFetching} onClick={handleAddOrEditDomain}>
            Connect my website domain
          </Button>
          <Button type="link" className="w-fit text-left text-sm" onClick={handlePurchaseDomain}>
            Purchase a website domain
          </Button>
        </>
      )}

      <AnimatedModal
        visible={showDeleteModal}
        hideModal={() => {
          setShowDeleteModal(false)
        }}
        showFooter={true}
        actionButtonCopy="Delete domain"
        actionButtonType="destructive"
        onSave={handleDeleteDomain}
        header={"Delete custom domain"}
        saveDisabled={deleteFetching}>
        <Typography variant="smSubtitle">
          Are you sure you want to delete your custom domain? Your custom domain will stop working but your short link
          will continue working.
        </Typography>
      </AnimatedModal>
    </div>
  )
}

import NiceModal from "@ebay/nice-modal-react"
import { ExportToCsv } from "export-to-csv"
import React, { useState } from "react"
import { useQuery } from "urql"

import { PracticeProvider } from "../../../contexts/PracticeContext"
import { useToast } from "../../../contexts/ToastContext"
import DefaultProvider from "../../../providers/DefaultProvider"
import { formatShortDate, formatPhone } from "../../../utils/utils"
import { Button } from "../../shared/Buttons"
import ClientFlyout from "../../shared/ClientFlyout"
import ClientUpload from "../../shared/ClientUpload"
import CreateClientModal, { modalId } from "../../shared/CreateClientModal"
import HealMeTable from "../../shared/HealMeTable"
import { Link } from "../../shared/Links"
import Typography from "../../shared/Typography"

const CLIENTS_QUERY = `
  query clientsQuery{
    clients {
      id
      practiceId
      userId
      email
      phone
      firstName
      lastName
      name
      clientType
      invitedFrom
      lastAppointmentDate
    }
  }
`

const ClientsTable = ({ practice }) => {
  const [showClientDetail, setShowClientDetail] = useState(false)
  const [clientToDetail, setClientToDetail] = useState()
  const [editClient, setEditClient] = useState()

  const [{ data, fetching }, reexecuteQuery] = useQuery({
    query: CLIENTS_QUERY,
    requestPolicy: "network-only"
  })

  const showHideClient = (client) => {
    setShowClientDetail(true)
    setClientToDetail(client)
  }

  const { showToast } = useToast()

  const columns = [
    {
      accessor: "name",
      Header: "Full Name",
      Cell: (props) => {
        const preventBooking = !props.cell.row.original.phone && !props.cell.row.original.email
        return (
          <div className={preventBooking ? "pb-6" : ""}>
            <span>{props.cell.value}</span>
            {preventBooking && (
              <Typography variant="small" className="absolute mt-2 block text-gray-dark">
                You cannot book any sessions for this client until you add an email or phone number.{" "}
                <Link
                  className="font-semibold"
                  onClick={() => {
                    NiceModal.show("edit-client-directory")
                  }}>
                  Add now
                </Link>
              </Typography>
            )}
          </div>
        )
      }
    },
    {
      accessor: "email",
      Header: "Email"
    },
    {
      accessor: "phone",
      Header: "Phone",
      Cell: (props) => (formatPhone(props.cell.value) ? `${formatPhone(props.cell.value)}` : "")
    },
    {
      accessor: "lastAppointmentDate",
      Header: "Last appt."
    }
  ]

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: "Heal.me Clients",
    headers: ["First Name", "Last Name", "Email", "Phone", "Last Appointment"]
  }

  const exportToCSV = () => {
    const csvExporter = new ExportToCsv(options)
    const exportable = data.clients.map((client) => ({
      "First Name": client.firstName,
      "Last Name": client.lastName,
      Email: client.email || "",
      Phone: formatPhone(client.phone) ? formatPhone(client.phone) : "",
      "Last Appointment": client.lastAppointmentDate ? formatShortDate(client.lastAppointmentDate) : ""
    }))
    csvExporter.generateCsv(exportable)
  }

  const refreshScreen = () => {
    setShowClientDetail(false)
    setClientToDetail(null)
    setEditClient(false)
    reexecuteQuery()
  }

  return (
    <>
      <div className="mb-8 flex justify-between align-bottom sm:mb-4">
        <div className="flex flex-col">
          <Typography as="h1" variant="h3">
            Clients
          </Typography>
          <Typography variant="subtitle">{data?.clients?.length} clients</Typography>
        </div>
        <div className="flex">
          {data?.clients.length > 1 && (
            <Button type="tertiary" className="mr-4 sm:hidden" size="small" onClick={exportToCSV}>
              Export
            </Button>
          )}
          <ClientUpload practice={practice} buttonStyle="white" buttonSize="small" className="mr-4 sm:hidden" />
          <Button
            type="primary"
            size="small"
            floatingInMobile={true}
            onClick={() => {
              NiceModal.show(modalId)
            }}>
            <span>Create client</span>
          </Button>
        </div>
      </div>
      {data?.clients && !fetching && (
        <HealMeTable data={data?.clients} columns={columns} searchType="clients" rowClick={showHideClient} />
      )}

      <ClientFlyout
        closeFlyout={() => {
          setShowClientDetail(false)
        }}
        visible={showClientDetail}
        client={clientToDetail}
        openToEdit={editClient}
        refreshClient={refreshScreen}
        clientDeleted={refreshScreen}
      />

      <CreateClientModal
        close={(clientAdded) => {
          NiceModal.remove(modalId)
          if (clientAdded) {
            reexecuteQuery()
            showToast("Client added successfully")
          }
        }}
      />
    </>
  )
}

export default function ClientDirectory(props) {
  return (
    <DefaultProvider>
      <PracticeProvider>
        <ClientsTable {...props} />
      </PracticeProvider>
    </DefaultProvider>
  )
}

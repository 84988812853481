import daysjs from "dayjs"
import React from "react"

export const formatEventTitle = (appt) => {
  const timeLength = daysjs(appt.endsAt).diff(daysjs(appt.startsAt), "minutes")
  const service = appt.appointmentServices[0].service
  let result
  if (appt.user) {
    result = timeLength < 30 ? `${appt.user.name} - ${service.name}` : `${appt.user.name}`
  } else if (appt.client) {
    let name = appt.client.firstName
    if (appt.client.lastName) name += ` ${appt.client.lastName}`
    result = timeLength < 30 ? `${name} - ${service.name}` : `${name}`
  } else {
    result = service.name
  }
  return appt.state === "pending" ? `Request: ${result}` : result
}

export const handleDayHeaderContent = (args) => {
  const dayString = args.date.toDateString()
  const dayName = dayString.split(" ")[0]
  const dayNumber = dayString.split(" ")[2]

  return (
    <>
      <span className="day-name">{dayName}</span> {dayNumber}
    </>
  )
}

export const renderEventContent = (eventInfo) => (
  <>
    <span className="event-title">
      {eventInfo.event.extendedProps.duration < "30" && eventInfo.timeText} {eventInfo.event.title}
    </span>
    {eventInfo.event.extendedProps.duration >= "30" && (
      <>
        <span className="event-time">{eventInfo.timeText}</span>
        <span className="event-description">{eventInfo.event.extendedProps.description}</span>
      </>
    )}
  </>
)

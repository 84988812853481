import NiceModal from "@ebay/nice-modal-react"
import React, { useState } from "react"
import { useMutation } from "urql"

import { usePractice } from "../../contexts/PracticeContext"
import { formatPhone, isClientValid, validEmail } from "../../utils/utils"

import { Input, Label, InputWithLabel, TextArea } from "./Inputs"
import { Banner } from "./Layout"
import { NewModal } from "./Modal"
import Typography from "./Typography"

const CREATE_CLIENT_MUTATION = `
  mutation CreateClient($client: ClientInput!) {
    createClient(client: $client) {
      result
      errors
    }
  }
`

export const modalId = "create-client-modal"

const initialClientInput = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  notes: ""
}

export default function CreateClientModal({ close }) {
  const [errorMessage, setErrorMessage] = useState("")

  const [createClientResult, createClient] = useMutation(CREATE_CLIENT_MUTATION)
  const [clientInput, setClientInput] = useState(initialClientInput)

  const { practicePhone, practiceEmail } = usePractice()

  let clientPhone = formatPhone(clientInput?.phone?.trim())
  if (clientPhone) {
    clientPhone = clientPhone?.startsWith("+1") || clientPhone?.startsWith("1") ? clientPhone : "+1 " + clientPhone
  }

  const handleSave = () => {
    if (clientInput.email && !validEmail(clientInput.email)) {
      setErrorMessage("Invalid email")
      return
    }

    const invitedClient = {
      ...clientInput,
      clientType: "invited",
      invitedFrom: "web_single_invite"
    }

    createClient({ client: invitedClient }).then((result) => {
      if (result.error || result.data.createClient.errors) {
        setErrorMessage(`Error creating client: ${result.error || result.data.createClient.errors.join(", ")}`)
      } else {
        setClientInput(initialClientInput)
        setErrorMessage("")
        close(true)
      }
    })
  }

  return (
    <NewModal
      id={modalId}
      showFooter={true}
      fullHeightInMobile={true}
      header="Create client"
      saveDisabled={
        !isClientValid(clientInput, clientPhone, practicePhone, practiceEmail) || createClientResult.fetching
      }
      onSave={handleSave}
      hideModal={() => {
        setClientInput(initialClientInput)
        setErrorMessage("")
        NiceModal.hide(modalId)
      }}>
      <div className="sm:pb-12">
        <Typography variant="subtitle" className="mb-2">
          Phone is required to create a client.
        </Typography>
        <Label className="mt-4">
          Full name <span className="text-red">*</span>
        </Label>
        <div className="flex flex-row">
          <Input
            placeholder="First"
            onChange={(input) => setClientInput({ ...clientInput, firstName: input.target.value })}
            className="relative -mr-1 mb-4 rounded-none rounded-l-lg hover:z-20 focus:z-20"
          />

          <Input
            placeholder="Last"
            onChange={(input) => setClientInput({ ...clientInput, lastName: input.target.value })}
            className="relative mb-4 rounded-none rounded-r-lg hover:z-20 focus:z-20"
          />
        </div>

        <InputWithLabel
          label="Email"
          onChange={(input) => {
            setClientInput({ ...clientInput, email: input.target.value })
            if (errorMessage) setErrorMessage("")
          }}
          className="mb-4"
        />

        {clientInput?.email?.trim() === practiceEmail && (
          <Banner type="error">
            Cannot use your email for a client. Please add phone for client or use a placeholder email.
          </Banner>
        )}

        <InputWithLabel
          label={"Phone"}
          required
          onChange={(input) => {
            setClientInput({ ...clientInput, phone: input.target.value })
          }}
          value={clientInput?.phone ? formatPhone(clientInput.phone) : ""}
          className="mb-4 w-1/2"
        />

        {clientPhone === practicePhone && !!practicePhone && (
          <Banner type="error">
            Cannot use your phone number for a client. Please leave blank and add email if client phone is unknown.
          </Banner>
        )}

        {errorMessage && <Banner type="error">{errorMessage}</Banner>}

        <div className="mt-4">
          <Label>Notes</Label>
          <TextArea
            onChange={(input) => {
              setClientInput({ ...clientInput, notes: input.target.value })
            }}
          />
        </div>
      </div>
    </NewModal>
  )
}

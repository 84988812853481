import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { TrashIcon } from "@heroicons/react/24/solid"
import React, { useMemo } from "react"
import ReactSelect, { components } from "react-select"

import { PAYMENT_STATUS_OPTIONS } from "../../../@core/appointment/appointment.model"
import { useManualBooking } from "../../../contexts/ManualBookingContext"
import { usePractice } from "../../../contexts/PracticeContext"
import { formatPrice, selectStyling, unformatPrice } from "../../../utils/utils"
import { Button } from "../../shared/Buttons"
import CurrencyInput from "../../shared/CurrencyInput"
import HealMeTooltip from "../../shared/HealMeTooltip"
import { CheckBox, Label } from "../../shared/Inputs"
import Typography from "../../shared/Typography"

import RecurrenceSelect from "./RecurrenceSelect"

const getServiceLabel = (service, variation) => {
  if (service.package) {
    return `${service.name} (${service.numberOfSessions} sessions${
      variation.timeLength ? `, ${variation.timeLength} mins` : ""
    })`
  }
  if (service.addOn) {
    return `${service.name}`
  }
  return `${service.name}${variation.timeLength ? ` (${variation.timeLength} mins)` : ""}`
}

export default function EditAppointmentService() {
  const { appointment, client, services, setServices, updateEndsAtWithServicesDuration } = useManualBooking()
  const { practice, practiceServices } = usePractice()

  const upcoming = new Date(appointment?.startsAt) >= new Date()
  const isServicesEditable = appointment?.paymentStatus !== PAYMENT_STATUS_OPTIONS.PAID

  const handleServiceSelect = (serviceId, variationId, index) => {
    const fullService = practiceServices.find((service) => service.id === serviceId)
    if (!fullService) return

    const selectedVariation = fullService.variations.find((variation) => variation.id === variationId)
    if (!selectedVariation) return

    const updatedServices = [...services]
    updatedServices[index] = {
      amountCents: selectedVariation.amountCents,
      service: {
        ...fullService,
        id: variationId,
        serviceId: serviceId,
        timeLength: selectedVariation.timeLength
      }
    }

    setServices(updatedServices)
    updateEndsAtWithServicesDuration(updatedServices)

    // If client is set, query for the custom price and update the service price
    if (client) {
      const originalPrice = selectedVariation.amountCents
      const customPrice = client.customPrices?.find((cp) => cp.serviceId === variationId)
      if (customPrice) {
        const updatedServicesAfterPriceChange = [...updatedServices]
        updatedServicesAfterPriceChange[index].amountCents = customPrice.amountCents
        updatedServicesAfterPriceChange[index].priceChanged = originalPrice !== customPrice.amountCents
        updatedServicesAfterPriceChange[index].saveCustomPrice = originalPrice !== customPrice.amountCents
        setServices(updatedServicesAfterPriceChange)
      }
    }
  }

  const handlePriceChange = (index, newDisplayPrice) => {
    const newAmountCents = unformatPrice(newDisplayPrice)
    const updatedServices = [...services]
    const currentService = updatedServices[index]

    const clientCustomPrice = client?.customPrices?.find((cp) => cp.serviceId === currentService.service.serviceId)

    updatedServices[index] = {
      ...currentService,
      amountCents: newAmountCents,
      priceChanged: clientCustomPrice?.amountCents !== newAmountCents,
      saveCustomPrice: false // Reset when price changes
    }
    setServices(updatedServices)
  }

  // Add new handler for checkbox
  const handleSaveCustomPriceChange = (index) => {
    const updatedServices = [...services]
    updatedServices[index] = {
      ...updatedServices[index],
      saveCustomPrice: !updatedServices[index].saveCustomPrice
    }
    setServices(updatedServices)
  }

  // Flatten services and variations for the select options
  const serviceOptions = useMemo(() => {
    if (!practiceServices) return []
    return practiceServices.flatMap((service) =>
      service.variations.map((variation) => ({
        value: `${service.id}-${variation.id}`,
        label: getServiceLabel(service, variation),
        package: service.package,
        addOn: service.addOn
      }))
    )
  }, [practiceServices])

  const serviceOptionsWithoutPackages = useMemo(
    () => serviceOptions?.filter((service) => !service.package),
    [serviceOptions]
  )

  const serviceOptionsToUse = useMemo(
    () => (services.length > 1 ? serviceOptionsWithoutPackages : serviceOptions),
    [services, serviceOptions, serviceOptionsWithoutPackages]
  )

  // Build the options for ReactSelect
  const regularServiceOptions = useMemo(
    () => serviceOptionsToUse?.filter((service) => !service.addOn),
    [serviceOptionsToUse]
  )
  const addOnServiceOptions = useMemo(
    () => serviceOptionsToUse?.filter((service) => service.addOn),
    [serviceOptionsToUse]
  )
  const groupedOptions = useMemo(
    () =>
      services.length > 1
        ? [
            {
              label: "Add-ons",
              options: addOnServiceOptions
            },
            {
              label: "Services",
              options: regularServiceOptions
            }
          ]
        : [
            {
              label: "Services",
              options: regularServiceOptions
            },
            {
              label: "Add-ons",
              options: addOnServiceOptions
            }
          ],
    [services, addOnServiceOptions, regularServiceOptions]
  )
  const formatGroupLabel = (data) => (
    <div className="flex justify-between">
      <span>{data.label}</span>
      <span>{data.options.length}</span>
    </div>
  )
  const CustomOptionComponent = (props) => (
    <components.Option {...props} innerProps={{ ...props.innerProps, "data-test-id": `option-${props.data.value}` }}>
      {props.children}
    </components.Option>
  )

  const totalPrice = useMemo(() => services.reduce((sum, service) => sum + (service.amountCents || 0), 0), [services])

  // last service is empty OR
  // first service is a package
  const addServiceDisabled =
    (services.length > 0 && !services[services.length - 1].service) ||
    (services.length > 0 && services[0].service && services[0].service.package)

  // First service is a package, and is in client's open packages
  const isBookingOpenPackage =
    services.length > 0 &&
    services[0].service &&
    services[0].service.package &&
    client?.openPackages?.find((pkg) => pkg.serviceId === services[0].service.serviceId)

  const getAddOnsTooltip = () => {
    if (services.length > 0 && !services[services.length - 1].service) {
      return "Please select a service before adding another"
    }
    if (services.length > 0 && services[0].service?.package) {
      return "Cannot add other services to a package appointment during booking"
    }
    return ""
  }

  return (
    <div className="mt-4">
      {practiceServices && (
        <>
          <Label>Add services</Label>

          {services.map((service, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center gap-2">
                <div className="flex-1">
                  <ReactSelect
                    id={`service-select-${index}`}
                    isDisabled={!isServicesEditable}
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    placeholder={services.length > 1 ? "-- select an add-on --" : "-- select a service --"}
                    onChange={(option) => {
                      const [serviceId, variationId] = option.value.split("-")
                      handleServiceSelect(serviceId, variationId, index)
                    }}
                    styles={selectStyling()}
                    value={
                      service.service
                        ? serviceOptionsToUse.find(
                            (option) => option.value === `${service.service.serviceId}-${service.service.id}`
                          )
                        : null
                    }
                    components={{ Option: CustomOptionComponent }}
                  />
                </div>

                {service.service && (
                  <div className="w-1/4">
                    <CurrencyInput
                      disabled={isBookingOpenPackage || !isServicesEditable}
                      placeholder="Price"
                      id={`price-input-${index}`}
                      value={formatPrice(service.amountCents) || ""}
                      onChange={(e) => handlePriceChange(index, e.target.value)}
                      style={service.priceChanged ? { color: "#0D9DA4" } : { color: "#0B3954" }}
                    />
                  </div>
                )}

                {services.length > 1 && isServicesEditable && (
                  <div className="flex h-full items-center">
                    <TrashIcon
                      className="h-6 w-6 cursor-pointer"
                      onClick={() => {
                        const updatedServices = services.filter((_, i) => i !== index)
                        setServices(updatedServices)
                        updateEndsAtWithServicesDuration(updatedServices)
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Only show the checkbox if the price has changed and the client is set */}
              {client && service.priceChanged && isServicesEditable && (
                <div className="mt-2 flex items-center gap-2">
                  <CheckBox
                    id={`save-price-${index}`}
                    checked={service.saveCustomPrice}
                    onChange={() => handleSaveCustomPriceChange(index)}
                  />
                  <label htmlFor={`save-price-${index}`} className="text-sm">
                    Remember this price for this client
                  </label>
                </div>
              )}
            </div>
          ))}

          {isServicesEditable && (
            <div className="relative mt-2 flex items-center gap-2">
              <Button type="secondary" onClick={() => setServices([...services, {}])} disabled={addServiceDisabled}>
                + Add-ons
              </Button>
              {addServiceDisabled && (
                <>
                  <InformationCircleIcon className="h-5 w-5" data-tooltip-id="add-ons-tooltip" />
                  <HealMeTooltip id="add-ons-tooltip" render={() => <div>{getAddOnsTooltip()}</div>} />
                </>
              )}
            </div>
          )}

          {services.some((s) => s.service) && (
            <div className="my-4 space-y-2">
              {(appointment?.tipAmountCents > 0 || appointment?.discountCodeRedemptions?.length > 0) && (
                <div className="flex justify-between">
                  <span>Subtotal:</span>
                  <span>{formatPrice(totalPrice)}</span>
                </div>
              )}

              {appointment?.discountCodeRedemptions?.map((discount, index) => (
                <div key={index} className="flex justify-between">
                  <span>Discount ({discount.code}):</span>
                  <span>-{formatPrice(discount.amountCents)}</span>
                </div>
              ))}

              {appointment?.tipAmountCents > 0 && (
                <div className="flex justify-between">
                  <span>Tip:</span>
                  <span>{formatPrice(appointment.tipAmountCents)}</span>
                </div>
              )}

              <Typography variant="h5" className="flex justify-between">
                <span>Total:</span>
                <span>
                  {formatPrice(
                    totalPrice +
                      (appointment?.tipAmountCents || 0) -
                      (appointment?.discountCodeRedemptions?.reduce((sum, discount) => sum + discount.amountCents, 0) ||
                        0)
                  )}
                </span>
              </Typography>
            </div>
          )}

          {!appointment && services.length > 0 && practice.recurringBookingsEnabled && totalPrice > 0 && (
            <RecurrenceSelect className="mt-4" />
          )}

          {appointment?.recurrenceFrequency && !appointment?.package && (
            <RecurrenceSelect disabled={true} className={upcoming ? "mb-4" : ""} />
          )}
        </>
      )}
    </div>
  )
}

import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/react/24/outline"
import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

import { FeatureFlagProvider } from "../contexts/FeatureFlagContext"
import { getSideNavigationItems } from "../utils/sideNavigationUtils"

import { CounterBadge } from "./shared/Badge"
import { Link } from "./shared/Links"
import { AnimatedModal } from "./shared/Modal"
import Typography from "./shared/Typography"

const NavigationItem = ({ item, activeHref, badgeCount }) => {
  const hasChildren = item.children && item.children.length > 0
  const hasChildrenActive = hasChildren && item.children.some((child) => activeHref.startsWith(child.href))

  const [isOpen, setIsOpen] = useState(hasChildrenActive)

  let isActive = (activeHref.startsWith(item.href) && !activeHref.includes("personal")) || hasChildrenActive

  // remove this after fully enabling flag "side_navigation_v2"
  if (item.name === "Settings" && activeHref.includes("intake_forms")) {
    isActive = false
  }

  const icon = isActive ? item.solidIcon : item.outlineIcon

  const handleClick = (e) => {
    if (hasChildren) {
      e.preventDefault()
      setIsOpen(!isOpen)
    }
  }

  return (
    <>
      <div className={twMerge("mx-3 rounded-lg", isOpen ? "bg-gray/10" : "")}>
        <a
          href={item.href}
          style={{ textDecoration: "none" }}
          onClick={handleClick}
          {...(item.id ? { id: item.id } : {})}>
          <div
            className={twMerge(
              "my-3 flex cursor-pointer items-center justify-start rounded-lg py-1 pl-4 pr-2 hover:text-teal",
              isActive ? "font-bold" : "",
              isActive && !hasChildren ? "bg-gray" : "",
              hasChildren && isOpen ? "mb-0 rounded-b-none font-bold" : ""
            )}>
            {icon}
            <span className="ml-3">{item.name}</span>
            {badgeCount > 0 && <CounterBadge className="ml-8">{badgeCount}</CounterBadge>}
            {hasChildren && (
              <span className="ml-auto">
                {isOpen ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-dark" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-dark" />
                )}
              </span>
            )}
          </div>
        </a>
        {isOpen && hasChildren && (
          <div className="mt-2 flex flex-col gap-2">
            {item.children.map((child, index) => (
              <NavigationItemChild
                key={child.name}
                item={child}
                activeHref={activeHref}
                isLast={index === item.children.length - 1}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

const NavigationItemChild = ({ item, activeHref, isLast }) => {
  const isActive = activeHref === item.href

  return (
    <a
      href={item.href}
      className={twMerge(
        "flex cursor-pointer items-center justify-start py-1 pl-[52px] pr-2 hover:text-teal",
        isActive ? "bg-gray/25 font-bold" : "",
        isLast ? "rounded-b-lg" : ""
      )}>
      {item.icon}
      <span>{item.name}</span>
    </a>
  )
}

const SideNavigationComponent = ({
  activeHref,
  completionCount,
  verificationCount,
  googleConnected,
  cronofyConnected,
  websitePlan
}) => {
  const [showNotice, setShowNotice] = useState(false)
  const [cronofyModalVisible, setCronofyModalVisible] = useState(false)

  const profileBadgeCount = completionCount > 0 ? completionCount : verificationCount
  const syncCalendarCopy = "Sync Heal.me with your calendar"

  const items = getSideNavigationItems({ websitePlan })

  useEffect(() => {
    setShowNotice(!cronofyConnected && localStorage.getItem("sideNavNoticeDismissed") !== "true")

    const cronofyModalShown = localStorage.getItem("cronofyModalShownV2")
    if (!cronofyModalShown) {
      setCronofyModalVisible(true)
      localStorage.setItem("cronofyModalShownV2", "true")
    }
  }, [])

  const handleDismiss = () => {
    localStorage.setItem("sideNavNoticeDismissed", "true")
    setShowNotice(false)
  }

  return (
    <>
      <div className="fixed z-30 h-screen min-h-100 w-[200px] overflow-y-auto border-r border-gray-light bg-gray-ultralight pb-125 scrollbar-none">
        <div className="w-full bg-gray-ultralight pb-3 pl-8 pt-6">
          <a href="/" className="">
            <img src="/images/healme_logo.svg" alt="Logo" />
          </a>
        </div>
        <div className="mt-6">
          {items.map((item) => (
            <NavigationItem
              key={item.name}
              item={item}
              activeHref={activeHref}
              badgeCount={item.name === "Profile" ? profileBadgeCount : 0}
            />
          ))}

          {showNotice && (
            <div className="relative mx-4 my-6 ml-6 rounded-lg border border-gray bg-white p-4">
              <div className="flex flex-col">
                <Typography variant="micro">{syncCalendarCopy}</Typography>
                <Link href="/portal/settings/calendar" className="mt-1 text-sm font-semibold">
                  Connect now
                </Link>
              </div>
              <XMarkIcon
                onClick={handleDismiss}
                className="absolute right-1 top-2 h-7 w-7 cursor-pointer p-1 font-semibold text-black"
              />
            </div>
          )}

          {!cronofyConnected && googleConnected && (
            <AnimatedModal
              visible={cronofyModalVisible}
              hideModal={() => (window.location = "/portal/settings/calendar")}
              showFooter={true}
              actionButtonCopy="Connect calendar"
              hideCancelButton={true}
              onSave={() => (window.location = "/portal/settings/calendar")}
              header="Reconnect your calendar">
              <p>We&apos;ve made big improvements to our calendar integration!</p>
              <br />
              <p>To continue syncing with your Google Calendar, please reconnect your calendar now.</p>
              <br />
              <p>We apologize for any inconvenience and appreciate your understanding.</p>
            </AnimatedModal>
          )}
        </div>
      </div>
    </>
  )
}

export default function SideNavigation(props) {
  return (
    <FeatureFlagProvider flags={props.featureFlags}>
      <SideNavigationComponent {...props} />
    </FeatureFlagProvider>
  )
}

import NiceModal from "@ebay/nice-modal-react"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "urql"

import { PAYMENT_STATUS_OPTIONS } from "../../../../@core/appointment/appointment.model"
import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { usePractice } from "../../../../contexts/PracticeContext"
import { useToast } from "../../../../contexts/ToastContext"
import { DEFAULT_PAYMENT_METHOD_QUERY } from "../../../../utils/mutations"
import { formatShortDate } from "../../../../utils/utils"
import Badge from "../../../shared/Badge"
import { Button } from "../../../shared/Buttons"
import { InputWithLabel } from "../../../shared/Inputs"
import LoadingSpinner from "../../../shared/LoadingSpinner"
import { NewModal } from "../../../shared/Modal"
import { RadioWithLabel } from "../../../shared/RadioButtons"
import Typography from "../../../shared/Typography"
import AddOrRetryButton from "../AddOrRetryButton"
import { MARK_AS_PAID, UNDO_MARK_PAID, RETRY_PAYMENT_MUTATION } from "../appointment_graphql"

export default function AppointmentStatus() {
  const { appointment } = useManualBooking()

  const [, markPaid] = useMutation(MARK_AS_PAID)
  const [, undoMarkPaid] = useMutation(UNDO_MARK_PAID)
  const [, retryPayment] = useMutation(RETRY_PAYMENT_MUTATION)

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("cash")
  const [otherPaymentOption, setOtherPaymentOption] = useState("")

  const options = [
    { label: "Cash", value: "cash" },
    { label: "Venmo", value: "venmo" },
    { label: "PayPal", value: "paypal" },
    { label: "Other", value: "other" }
  ]

  const handleChange = (event) => {
    setSelectedPaymentOption(event.target.value)
  }

  const [appointmentCopy, setAppointmentCopy] = useState(appointment)
  const [retryingPayment, setRetryingPayment] = useState(false)
  const { showToast } = useToast()
  const { practice } = usePractice()

  const markAsPaid = (id) => {
    markPaid({
      appointmentId: id,
      paidBy: selectedPaymentOption === "other" ? otherPaymentOption : selectedPaymentOption
    }).then((res) => {
      NiceModal.hide("markPaidModal")
      if (res.data.markPaid.result === "success") {
        setAppointmentCopy({ ...appointment, markPaid: true })
      }
    })
  }

  const undoMarkAsPaid = (id) => {
    undoMarkPaid({ appointmentId: id }).then((res) => {
      if (res.data.undoMarkPaid.result === "success") {
        setAppointmentCopy({ ...appointment, markPaid: false, paymentStatus: "Unpaid" })
      }
    })
  }

  const handleRetryPayment = () => {
    setRetryingPayment(true)
    retryPayment({ appointmentId: appointment?.id }).then((res) => {
      if (res.data?.retryPayment?.result === "success") {
        showToast("Success! Payment processed.")
        setAppointmentCopy({ ...appointment, paymentError: false, hasPaymentMethod: true, paymentStatus: "Paid" })
      } else {
        showToast({
          content: `Payment failed: ${res.data?.retryPayment?.errors[0] || "Internal server error"}`,
          type: "error"
        })
        setAppointmentCopy({ ...appointment, paymentError: res.data?.retryPayment?.errors[0] })
      }
      setRetryingPayment(false)
    })
  }

  const [{ data, fetching, error }] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: {
      clientId: appointment?.client?.id,
      userId: appointment?.user?.id,
      practiceId: practice.id
    },
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (!appointmentCopy.hasPaymentMethod && data?.defaultPaymentMethodPractice) {
      setAppointmentCopy({
        ...appointmentCopy,
        hasPaymentMethod: true,
        client: { ...appointmentCopy.client, defaultPaymentMethod: data.defaultPaymentMethodPractice }
      })
    }
  }, [data, appointmentCopy.hasPaymentMethod])

  const upcoming = new Date(appointment.startsAt) >= new Date()
  const canShowWarning =
    !appointmentCopy.markPaid && appointmentCopy.amountCents > 0 && appointmentCopy.paymentStatus !== "Paid"
  const missingPaymentMethod = !appointmentCopy.paymentError && !appointmentCopy.hasPaymentMethod
  const overduePayment = canShowWarning && missingPaymentMethod && !upcoming
  const unprocessedPayment =
    canShowWarning &&
    !upcoming &&
    appointmentCopy.hasPaymentMethod &&
    appointmentCopy.paymentStatus === PAYMENT_STATUS_OPTIONS.UNPAID
  const failedPayment = canShowWarning && appointmentCopy.paymentError
  const client = appointmentCopy.user || appointmentCopy.client
  const isPackage = !!appointmentCopy.packageSessionNumber

  if (fetching) return <LoadingSpinner />
  if (error)
    return (
      <Typography variant="body" className="mb-4 text-red">
        Error fetching payment method, please try again later.
      </Typography>
    )

  return (
    <div className="relative flex flex-col">
      <div className="mb-2 flex items-center justify-between">
        {!upcoming && (overduePayment || failedPayment || unprocessedPayment) ? (
          <Badge type="error">Unpaid</Badge>
        ) : (
          (appointmentCopy.paymentStatus === "Paid" || appointmentCopy.markPaid) && <Badge>Paid</Badge>
        )}
        {appointmentCopy.amountCents > 0 && (appointmentCopy.paymentStatus !== "Paid" || appointmentCopy.markPaid) && (
          <>
            {appointmentCopy.markPaid ? (
              <div>
                <span className="text-sm text-teal">Marked as Paid</span>
                <Button
                  type="link"
                  className="ml-2 text-sm text-gray-dark"
                  onClick={() => {
                    undoMarkAsPaid(appointment.id)
                  }}>
                  Undo
                </Button>
              </div>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  NiceModal.show("markPaidModal")
                }}>
                Mark as Paid
              </Button>
            )}
          </>
        )}
      </div>
      <Typography variant="small" className="mb-4 text-gray-dark">
        Booked by {appointment.bookedBy === "client" ? client.firstName : "me"} on{" "}
        {formatShortDate(appointment.createdAt)}
      </Typography>
      {(overduePayment || failedPayment || unprocessedPayment) && (
        <>
          <div className="mb-4 rounded-xl border border-orange bg-orange-light p-4">
            {retryingPayment && (
              <Typography variant="body" className="mb-0">
                Processing payment. Please wait...
              </Typography>
            )}

            {!overduePayment && !retryingPayment && (failedPayment || unprocessedPayment) && (
              <Typography variant="body">
                <div className="font-bold">Payment not processed</div>
                {practice.hasPaymentProvider ? (
                  <>
                    Try adding a new card or retry this payment method.{" "}
                    <AddOrRetryButton
                      appointment={appointmentCopy}
                      headerLabel={"Choose your payment method"}
                      label="Add or retry"
                      onSave={handleRetryPayment}
                      type={"link"}
                    />
                  </>
                ) : (
                  <>
                    <div>Payment provider not connected</div>
                    <a className="font-bold text-teal hover:underline" href="/portal/settings/banking">
                      Click here to connect to a payment provider
                    </a>
                  </>
                )}
              </Typography>
            )}

            {!retryingPayment && overduePayment && (
              <Typography variant="body">
                <div className="font-bold">Payment is overdue for this {isPackage ? "package" : "appointment"}</div>
                {practice.hasPaymentProvider ? (
                  <>
                    Please add a payment method for this client.{" "}
                    <AddOrRetryButton
                      appointment={appointmentCopy}
                      headerLabel={"Choose your payment method"}
                      label="Add or retry"
                      onSave={handleRetryPayment}
                      type={"link"}
                    />
                  </>
                ) : (
                  <>
                    <div>Payment provider not connected</div>
                    <a className="font-bold text-teal hover:underline" href="/portal/settings/banking">
                      Click here to connect to a payment provider
                    </a>
                  </>
                )}
              </Typography>
            )}
          </div>
        </>
      )}
      <NewModal
        id="markPaidModal"
        header="Mark as paid"
        showFooter={true}
        fullHeightInMobile={true}
        hideModal={() => {
          NiceModal.hide("markPaidModal")
        }}
        onSave={() => markAsPaid(appointment.id)}>
        <div className="sm:pb-12">
          <Typography variant="body" className="mb-6 text-gray-dark">
            You can note how an offline payment was made:
          </Typography>
          {options.map((option) => (
            <RadioWithLabel
              key={option.value}
              id={option.value}
              label={option.label}
              checked={selectedPaymentOption === option.value}
              onChange={handleChange}
              value={option.value}
              name="paymentMethod"
              className="mb-4"
            />
          ))}
          {selectedPaymentOption === "other" && (
            <InputWithLabel
              label="Note how the client paid:"
              value={otherPaymentOption}
              onChange={(e) => setOtherPaymentOption(e.target.value)}
            />
          )}
        </div>
      </NewModal>
    </div>
  )
}

import {
  ArrowPathRoundedSquareIcon,
  CreditCardIcon,
  ChatBubbleLeftEllipsisIcon,
  CalendarDaysIcon,
  NewspaperIcon
} from "@heroicons/react/24/outline"
import React from "react"

import { Button } from "../../components/shared/Buttons"
import Typography from "../../components/shared/Typography"

const MainSettings = ({ calendarConnected, showSubscriptionLink }) => (
  <div>
    <div className="mb-9 flex flex-col sm:mb-4">
      <Typography as="h1" variant="h3">
        Settings
      </Typography>
      <Typography variant="subtitle" className="sm:hidden">
        Manage your Heal.me practice
      </Typography>
    </div>
    <div className="grid grid-cols-3 gap-8 sm:grid-cols-1">
      <div className="flex flex-col rounded-xl border border-gray-light p-8">
        <div className="mb-8 w-fit rounded-xl bg-gray-ultralight p-4">
          <ArrowPathRoundedSquareIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <Typography variant="h5" className="mb-2">
          Calendar Integrations
        </Typography>
        <Typography variant="subtitle" className="mb-8">
          Sync your calendar across apps so wherever you work from, it happens here.
        </Typography>
        <a href="/portal/settings/calendar">
          <Button type={calendarConnected ? "tertiary" : "primary"} className="w-fit">
            {calendarConnected ? "Manage" : "Connect"}
          </Button>
        </a>
      </div>
      <div className="flex flex-col rounded-xl border border-gray-light p-8">
        <div className="mb-8 w-fit rounded-xl bg-green-light p-4">
          <CreditCardIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <Typography variant="h5" className="mb-2">
          Payments
        </Typography>
        <Typography variant="subtitle" className="mb-8 text-gray-dark">
          Accept online payments with Heal.me so you get paid on time, every time.
        </Typography>
        <a href="/portal/settings/banking">
          <Button type="tertiary" className="w-fit">
            Manage
          </Button>
        </a>
      </div>
      {/* <div className="flex flex-col rounded-xl border border-gray-light p-8">
        <div className="mb-8 w-fit rounded-xl bg-gray-light p-4">
          <CodeBracketSquareIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <Typography variant="h5" className="mb-2">
          Booking Widget
        </Typography>
        <Typography variant="subtitle" className="mb-8">
          A few lines of code, a whole lot of bookings: Add to your website now.
        </Typography>
        <a href="/portal/settings/banking">
          <Button type="primary" className="w-fit">
            Install
          </Button>
        </a>
      </div>*/}
      <div className="flex flex-col rounded-xl border border-gray-light p-8">
        <div className="mb-8 w-fit rounded-xl bg-red-light p-4">
          <ChatBubbleLeftEllipsisIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <Typography variant="h5" className="mb-2">
          Communications
        </Typography>
        <Typography variant="subtitle" className="mb-8">
          Automated appointment, payment, and follow-up booking reminders.
        </Typography>
        <a href="/portal/settings/communication">
          <Button type="tertiary" className="w-fit">
            Manage
          </Button>
        </a>
      </div>
      <div className="flex flex-col rounded-xl border border-gray-light p-8">
        <div className="mb-8 w-fit rounded-xl bg-orange-light p-4">
          <CalendarDaysIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <Typography variant="h5" className="mb-2">
          Booking
        </Typography>
        <Typography variant="subtitle" className="mb-8">
          Auto-accept new bookings, edit the time between appointments, and more.
        </Typography>
        <a href="/portal/settings/booking">
          <Button type="tertiary" className="w-fit">
            Manage
          </Button>
        </a>
      </div>
      {showSubscriptionLink && (
        <div className="flex flex-col rounded-xl border border-gray-light p-8">
          <div className="mb-8 w-fit rounded-xl bg-gray-light p-4">
            <img src="/images/icons/heal_me.svg" className="h-10 w-10" alt="Heal.me" />
          </div>
          <Typography variant="h5" className="mb-2">
            Subscription
          </Typography>
          <Typography variant="subtitle" className="mb-8">
            Manage your subscription to Heal.me.
          </Typography>
          <a href="/portal/subscriptions">
            <Button type="tertiary" className="w-fit">
              Manage
            </Button>
          </a>
        </div>
      )}
      <div className="flex flex-col rounded-xl border border-green-light p-8">
        <div className="mb-8 w-fit rounded-xl bg-green-light p-4">
          <NewspaperIcon className="h-10 w-10 text-gray-dark" />
        </div>
        <Typography variant="h5" className="mb-2">
          Intake Form
        </Typography>
        <Typography variant="subtitle" className="mb-8">
          Customize the intake form that you can send to your clients.
        </Typography>
        <a href="/portal/settings/intake_forms">
          <Button type="tertiary" className="w-fit">
            Manage
          </Button>
        </a>
      </div>
    </div>
  </div>
)

const ProMainSettings = (props) => <MainSettings {...props} />

export default ProMainSettings

import { APPOINTMENT_QUERY } from "../components/pro_portal/calendar/appointment_graphql"

import { createUrqlClient } from "./utils"

const urqlClient = createUrqlClient({ requestPolicy: "network-only" })

export const fetchAppointment = async (instantActionToken) => {
  const result = await urqlClient.query(APPOINTMENT_QUERY, { instantActionToken }).toPromise()
  if (result.data?.tokenAppointment) {
    result.data.tokenAppointment
    return result.data.tokenAppointment
  } else {
    console.error("Error fetching profile status data")
    return null
  }
}

import { CheckIcon } from "@heroicons/react/24/outline"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"

import { Button } from "../../components/shared/Buttons"
import { Box, Divider } from "../../components/shared/Layout"
import { ButtonLink, UnderlinedLink } from "../../components/shared/Links"
import { AnimatedModal } from "../../components/shared/Modal"
import ProgressBar from "../../components/shared/ProgressBar"
import Typography from "../../components/shared/Typography"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"
import { urqlClient } from "../../utils/utils"

const VERIFICATION_SESSION_QUERY = `
  query VerificationSession {
    verificationSession
  }
`

const stripePromise = typeof window !== "undefined" ? loadStripe(`${window.App.stripe_key}`) : null

const Step = ({ step, kind, number, onClick }) => {
  const Tag = step.link ? "a" : onClick ? "button" : "div"

  return (
    <li>
      <Tag href={step.link} className="flex items-center gap-3" onClick={onClick}>
        <div
          className={`flex h-6 w-6 flex-none items-center justify-center rounded-full ${
            kind === "completed" ? "bg-gray" : kind === "current" ? "bg-gray-dark" : "border border-gray-dark"
          }`}>
          {kind === "completed" ? (
            <CheckIcon className="h-4 w-4 stroke-[3px] text-gray-dark" />
          ) : (
            <span className={kind === "current" ? "font-bold text-white" : "text-gray-dark"}>{number}</span>
          )}
        </div>
        <span
          className={
            kind === "completed"
              ? "text-gray-dark line-through"
              : kind === "current"
              ? "font-bold text-black underline"
              : "text-gray-dark"
          }>
          {step.name}
        </span>
      </Tag>
    </li>
  )
}

const ProfileChecklist = ({ className }) => {
  const { profileStatusData } = useProfileStatus()
  const [showModal, setShowModal] = useState(false)
  const [modalShown, setModalShown] = useState(false)
  const profilePublished = profileStatusData.status === "Verified"
  const notVerified = profileStatusData.status === "Not Published"

  useEffect(() => {
    if (profileStatusData.showCompletedModal && !modalShown) {
      setShowModal(true)
      setModalShown(true)
    }
  })

  const steps =
    profileStatusData.status === "Incomplete - Not Published"
      ? profileStatusData.completionSteps
      : profileStatusData.verificationSteps
  const indexOfCurrentStep = steps.findIndex((step) => !step.completed)
  let progressPercentage =
    profileStatusData.completionSteps.map((step) => step.completed).filter((c) => c).length /
    profileStatusData.completionSteps.length
  progressPercentage = `${Math.round(progressPercentage * 100)}%`

  const startVerification = async () => {
    const stripe = await stripePromise

    if (!stripe) return

    const result = await urqlClient.query(VERIFICATION_SESSION_QUERY).toPromise()
    if (!result.data?.verificationSession) {
      console.error(result.error)
    }

    const { error } = await stripe.verifyIdentity(result.data.verificationSession)

    if (error) {
      console.error(error)
    } else {
      console.log("Verification submitted!")
    }
  }

  return (
    <>
      <Box className={className}>
        <div className="font-bold">
          Profile status:{" "}
          <span className={profilePublished ? "text-teal" : "text-red"}>{profileStatusData.status}</span>
        </div>
        <Typography variant="subtitle" className="mt-2">
          {notVerified ? (
            <>
              Verified profiles rank higher in search and get a profile badge.{" "}
              <UnderlinedLink
                href="https://help.heal.me/en/articles/8448233-what-is-profile-verification-and-why-should-i-do-it"
                target="_blank">
                Learn more
              </UnderlinedLink>
            </>
          ) : profilePublished ? (
            <>
              Well done {profileStatusData.practitionerFirstName}! Now that you&apos;re verified, check out your new
              badge.
            </>
          ) : (
            <>
              You must finish each step for your profile to be complete.{" "}
              <UnderlinedLink
                href="https://help.heal.me/en/articles/8545385-how-best-to-set-up-your-profile#h_3a7fc229ba"
                target="_blank">
                Learn more
              </UnderlinedLink>
            </>
          )}
        </Typography>
        {profileStatusData.status === "Incomplete - Not Published" && (
          <ProgressBar className="mt-6 sm:hidden" percentComplete={progressPercentage} />
        )}
        <ul className="mt-6 flex flex-col gap-3">
          {steps.map((step, i) => (
            <Step
              key={step.name}
              step={step}
              kind={step.completed ? "completed" : indexOfCurrentStep === i ? "current" : "incomplete"}
              onClick={step.name === "Verify your identity" ? startVerification : null}
              number={i + 1}
            />
          ))}
        </ul>
        {profileStatusData.status === "Not Published" ? (
          <div className="mt-6">
            {!profileStatusData.verificationSteps[1].completed ? (
              <Button onClick={startVerification}>Verify identity</Button>
            ) : (
              <ButtonLink
                href="https://help.heal.me/en/articles/8421792-how-can-i-get-reviews-from-existing-clients"
                target="_blank">
                Get client reviews
              </ButtonLink>
            )}
          </div>
        ) : profileStatusData.status === "Verified" ? (
          <div className="mt-6">
            <ButtonLink href={profileStatusData.profileUrl} target="_blank">
              View my profile
            </ButtonLink>
          </div>
        ) : null}
        {profileStatusData.status === "Incomplete - Not Published" && (
          <>
            <Divider className="-mx-8 box-content px-8" />
            <div className="flex items-start gap-3">
              <div className="flex h-6 w-6 flex-none">
                <QuestionMarkCircleIcon className="text-gray-dark" />
              </div>
              <div>
                <div className="mb-4">Are you curious about how best to complete your profile?</div>
                <UnderlinedLink
                  href="https://help.heal.me/en/articles/8545385-how-best-to-set-up-your-profile-to-get-clients"
                  target="_blank">
                  Watch a quick video
                </UnderlinedLink>
              </div>
            </div>
          </>
        )}
        {profileStatusData.status !== "Incomplete - Not Published" && notVerified && (
          <>
            <Divider className="-mx-8 box-content px-8" />
            <div className="flex items-start gap-3">
              <div className="flex h-6 w-6 flex-none">
                <QuestionMarkCircleIcon className="text-gray-dark" />
              </div>
              <div>
                <span className="font-bold">Please note:</span> To get published and show in search results, your
                profile needs to be verified.
              </div>
            </div>
          </>
        )}
      </Box>
      <AnimatedModal visible={showModal} hideModal={() => setShowModal(false)}>
        <div className="flex flex-col items-center">
          <div className="mt-6 text-[40px]">😁</div>
          <Typography variant="h3" as="h2">
            Great work setting up your profile!
          </Typography>
          <Typography variant="subtitle" className="mt-2 max-w-xs">
            Give yourself a high-five! The next step is getting your profile verified.{" "}
            <UnderlinedLink
              href="https://help.heal.me/en/articles/8448233-what-is-profile-verification-and-why-should-i-do-it"
              target="_blank">
              Learn more
            </UnderlinedLink>
          </Typography>
          <Button className="mb-4 mt-6" size="large" onClick={startVerification}>
            Verify my identity
          </Button>
        </div>
      </AnimatedModal>
    </>
  )
}

export default ProfileChecklist

import React, { useState } from "react"
import { useMutation } from "urql"

import { practitionerWebsiteUrl } from "../../@core/practitioner/practitioner.utils"
import { CheckBox } from "../../components/shared/Inputs"
import { ButtonLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"
import { ToastProvider, useToast } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

import { ReviewLayout } from "./ReviewLayout"

const TOGGLE_REVIEW_ANON_MUTATION = `
  mutation ToggleReviewAnon($reviewId: ID!, $isAnon: Boolean!) {
    toggleReviewAnon(reviewId: $reviewId, isAnon: $isAnon) {
      result
      errors
    }
  }
`

const BookNext = ({ review }) => {
  const { showToast } = useToast()
  const [isAnon, setIsAnon] = useState(review.isAnonymous)
  const [, toggleAnonReview] = useMutation(TOGGLE_REVIEW_ANON_MUTATION)

  const websiteUrl = practitionerWebsiteUrl(review.practitioner, review.practice)

  return (
    <ReviewLayout percentDone="100%" hideContinue={true} review={review}>
      <div className="mx-auto my-12 w-4/5 text-center">
        <Typography variant="h1">😁</Typography>
        <Typography variant="h3">Thank you for that {review?.client?.firstName}!</Typography>
        <Typography className="text-center" variant="subtitle">
          Your rating and review are now live on {review.practitioner.firstName} profile. Check them out!
        </Typography>
        <ButtonLink type="primary" size="large" className="mx-auto mt-6 py-3" href={websiteUrl}>
          Let&apos;s see it
        </ButtonLink>
      </div>
      <div className="mt-6 text-center">
        <CheckBox
          label="Leave review anonymously?"
          id="anonymous-reviews"
          onChange={() => {
            setIsAnon(!isAnon)
            toggleAnonReview({ reviewId: review.id, isAnon: !isAnon }).then((res) => {
              if (res.data.toggleReviewAnon.result === "success") {
                showToast("Review updated")
              } else {
                showToast({
                  content: `Error updating review: ${res.data.toggleReviewAnon.errors.join(" ")}`,
                  type: "error"
                })
              }
            })
          }}
          checked={isAnon}
        />

        <Typography variant="micro" as="p">
          Otherwise, will show as your first name and last initial.
        </Typography>
      </div>
    </ReviewLayout>
  )
}

export default function ReviewBookNext(props) {
  return (
    <ToastProvider>
      <GraphQLProvider>
        <BookNext {...props} />
      </GraphQLProvider>
    </ToastProvider>
  )
}

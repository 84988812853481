import React from "react"

import Badge from "../../../components/shared/Badge"
import Breadcrumbs from "../../../components/shared/Breadcrumbs"
import { Button } from "../../../components/shared/Buttons"
import { EditableValue } from "../../../components/shared/Inputs"
import Typography from "../../../components/shared/Typography"
import { ToastProvider, useToast } from "../../../contexts/ToastContext"
import GraphQLProvider from "../../../providers/GraphQLProvider"

const StripeSettings = ({ stripeInfo }) => {
  const { showToast } = useToast()

  if (stripeInfo.connectionError) {
    showToast(`Issue connecting to Stripe: ${stripeInfo.connectionError}`)
  }

  return (
    <div>
      <Breadcrumbs
        links={[
          { href: "/portal/settings", text: "Settings" },
          { href: "/portal/settings/banking", text: "Payments" }
        ]}
        activePage="Stripe"
      />
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          Stripe
        </Typography>
      </div>
      <div className="flex w-1/2 flex-row justify-between rounded-xl border border-gray-light p-8 sm:w-full">
        <div className="flex flex-col">
          <EditableValue
            value="Client payments will be made to this account."
            editable={false}
            name={stripeInfo.displayName}
          />
          {stripeInfo.requirementsDue ||
            (stripeInfo.requirementsPastDue && (
              <Badge type="warning" className="my-2">
                Check Stripe account for missing requirements
              </Badge>
            ))}
        </div>
        <a href="/portal/settings/banking/link">
          <Button type={stripeInfo.operational ? "tertiary" : "primary"} className="w-fit">
            {stripeInfo.operational ? "Manage" : "Connect"}
          </Button>
        </a>
      </div>
    </div>
  )
}

export default function StripePaymentsSettings(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <StripeSettings {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

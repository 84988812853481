import { useState, useEffect } from "react"

export default function useBrowserInfo() {
  const [browserInfo, setBrowserInfo] = useState(null)

  useEffect(() => {
    const userAgent = navigator.userAgent

    const browserData = {
      isChrome: /Chrome/.test(userAgent) && !/Chromium|Edge/.test(userAgent),
      isSafari: /Safari/.test(userAgent) && !/Chrome/.test(userAgent),
      isMobile: /Mobile|Android|iPhone|iPad|iPod/i.test(userAgent),
      isAndroid: /Android/i.test(userAgent),
      isIOS: /iPhone|iPad|iPod/i.test(userAgent)
    }

    browserData.isCompatibleBrowser =
      (browserData.isIOS && browserData.isSafari) || (browserData.isAndroid && browserData.isChrome)

    setBrowserInfo(browserData)
  }, [])

  return browserInfo
}

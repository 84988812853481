export const BOOKABLE_EVENT_REGISTRANTS_QUERY = `
  query BookableEvent($id: ID!) {
    bookableEvent(id: $id) {
      clients {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`

export const BOOKABLE_EVENT_REGISTRATION_QUERY = `
  query BookableEventRegistration($bookableEventId: ID!, $clientId: ID!) {
    bookableEventRegistration(bookableEventId: $bookableEventId, clientId: $clientId) {
      id
      amountCents
      createdAt
      paymentStatus
      discount {
        code
        amountCents
      }
    }
  }
`

export const DEFAULT_PAYMENT_METHOD_QUERY = `
  query DefaultPaymentMethod($clientId: ID!, $practiceId: ID!) {
    defaultPaymentMethodPractice(clientId: $clientId, practiceId: $practiceId) {
      brand
      last4
      expMonth
      expYear
    }
  }
`

import NiceModal from "@ebay/nice-modal-react"
import { XMarkIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import Badge from "../../components/shared/Badge"
import { Button } from "../../components/shared/Buttons"
import HealMeTable from "../../components/shared/HealMeTable"
import { NewModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { formatShortDate, formatPhone, copyToClipboard } from "../../utils/utils"

const RESEND_PRO_REVIEW_REQUEST = `
  mutation ResendProReviewRequest($clientId: ID, $appointmentId: ID) {
    resendProReviewRequest(clientId: $clientId, appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

const DISMISS_REVIEW_REQUEST = `
  mutation DismissReviewRequest($clientId: ID, $appointmentId: ID) {
    dismissReviewRequest(clientId: $clientId, appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

export default function ProReviewRequestsTable({ clientRequests }) {
  const handleCopy = async (text) => {
    await copyToClipboard(text, showToast)
  }

  const [requests, setRequests] = useState(clientRequests)
  const [reviewToDismiss, setReviewToDismiss] = useState()

  const [, dismissReviewRequest] = useMutation(DISMISS_REVIEW_REQUEST)
  const [, resendProReviewRequest] = useMutation(RESEND_PRO_REVIEW_REQUEST)
  const { showToast } = useToast()

  const requestColumns = [
    {
      accessor: "firstName",
      Header: "Client",
      className: "w-2/12 min-w-[200px] pr-6",
      Cell: (props) => (
        <>
          <span>{props.cell.value}</span>
          {props.cell.row.original.reviewRequireConfirmation && (
            <span className="mt-2 block">
              <Badge type="info">Imported</Badge>
            </span>
          )}
        </>
      )
    },
    {
      accessor: "email",
      Header: "Email/Phone",
      className: "w-1/6 min-w-[120px] pr-6",
      Cell: (props) => {
        const client = props.cell.row.original
        if (client.phone) {
          return <span className="text-gray-dark">{formatPhone(client.phone)}</span>
        } else {
          return <span className="text-gray-dark"> {client.email} </span>
        }
      }
    },
    {
      accessor: "reviewRequestedAt",
      Header: "Date requested",
      className: "w-2/5 max-w-[700px] min-w-[240px] pr-6",
      Cell: (props) => <span className="text-gray-dark">{formatShortDate(props.cell.value)}</span>
    },
    {
      accessor: "id",
      Header: "Action",
      className: "w-3/12 min-w-[80px]",
      Cell: (props) => (
        <div className="flex flex-row">
          <a
            className="mr-6 flex flex-row items-center font-semibold text-gray-dark sm:mb-4 sm:mr-0 sm:flex-col"
            onClick={() => handleCopy(props.cell.row.original.reviewLink)}>
            {" "}
            Copy link <DocumentDuplicateIcon className="ml-2 h-5 w-5 sm:ml-0" />
          </a>
          <a
            className="mr-4 font-bold text-teal"
            onClick={() => {
              resendProReviewRequest({
                clientId: props.cell.row.original.clientId,
                appointmentId: props.cell.row.original.appointmentId
              }).then((result) => {
                if (result.data.resendProReviewRequest.result === "success") {
                  showToast("Review request resent.")
                } else {
                  showToast({
                    content: `We weren't able to resend this request: ${result.data.resendProReviewRequest.errors.join()}`,
                    type: "error"
                  })
                }
              })
            }}>
            Resend
          </a>
          <a
            onClick={() => {
              NiceModal.show("dismiss-review-modal")
              setReviewToDismiss(props.cell.row.original)
            }}>
            <XMarkIcon className="h-6 w-6" />
          </a>
        </div>
      )
    }
  ]

  return (
    <>
      {clientRequests && (
        <HealMeTable
          data={requests?.sort((a, b) => {
            const dateA = new Date(a.reviewRequestedAt)
            const dateB = new Date(b.reviewRequestedAt)
            return dateB - dateA
          })}
          columns={requestColumns}
          searchType="review requests"
          header={() => <></>}
          rowClick={() => {}}
        />
      )}
      <NewModal id="dismiss-review-modal" fullHeightInMobile={true} header="Delete review request now">
        <div className="pb-24">
          <Typography variant="body">
            This will delete the review request from <span className="font-semibold">{reviewToDismiss?.firstName}</span>{" "}
            now. This action cannot be undone.
          </Typography>
          <div className="absolute inset-x-0 bottom-0 flex justify-end border-t border-gray-light bg-white p-8 sm:bottom-12">
            <Button
              type="tertiary"
              className="mr-4"
              onClick={() => {
                NiceModal.remove("dismiss-review-modal")
              }}>
              No, don&apos;t delete
            </Button>
            <Button
              type="destructive"
              onClick={() => {
                dismissReviewRequest({
                  clientId: reviewToDismiss.clientId,
                  appointmentId: reviewToDismiss.appointmentId
                }).then((result) => {
                  if (result.data.dismissReviewRequest.result === "success") {
                    setRequests((requests) =>
                      requests.filter(
                        (request) =>
                          request.clientId !== reviewToDismiss.clientId ||
                          request.appointmentId !== reviewToDismiss.appointmentId
                      )
                    )
                    NiceModal.remove("dismiss-review-modal")
                    showToast("Review request was deleted.")
                  } else {
                    showToast({
                      content: `We weren't able to delete this request: ${result.data.dismissReviewRequest.errors.join()}`,
                      type: "error"
                    })
                  }
                })
              }}>
              Yes, delete
            </Button>
          </div>
        </div>
      </NewModal>
    </>
  )
}

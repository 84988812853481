import React from "react"

import { CheckBox, TimePicker } from "../../components/shared/Inputs"
import { ORDERED_DAYS } from "../../utils/utils"

const AvailableTimes = ({ availableTimes, updateState }) => {
  const updateAvailableTimes = (day, key, value) => {
    updateState((state) => {
      const newState = JSON.parse(JSON.stringify(state))
      const newTimes = { ...newState.availableTimes }
      newTimes[day] ||= {}
      newTimes[day][key] = value
      return { ...newState, availableTimes: newTimes }
    })
  }

  return (
    <div className="flex flex-col gap-2">
      {ORDERED_DAYS.map((day) => {
        const availableTimesForDay = availableTimes[day]

        const enabled = !availableTimesForDay
          ? false
          : typeof availableTimesForDay.enabled === "undefined"
          ? !!(availableTimesForDay.startsAt && availableTimesForDay.endsAt)
          : availableTimesForDay.enabled
        return (
          <div key={day} className="flex items-center">
            <CheckBox
              id={`${day}-checkbox`}
              label={day}
              checked={enabled}
              onChange={() => {
                updateAvailableTimes(day, "enabled", !enabled)
                if (!enabled) {
                  if (!availableTimesForDay?.startsAt) updateAvailableTimes(day, "startsAt", "9:00 AM")
                  if (!availableTimesForDay?.endsAt) updateAvailableTimes(day, "endsAt", "5:00 PM")
                }
              }}
              className="mb-0 w-32 pr-3"
            />
            {enabled ? (
              <>
                <span>
                  <TimePicker
                    valueFormat="h:mm A"
                    disabled={!enabled}
                    value={availableTimesForDay?.startsAt || "9:00 AM"}
                    onChange={(e) => updateAvailableTimes(day, "startsAt", e.target.value)}
                  />
                </span>
                <span className="px-2"> - </span>
                <span>
                  <TimePicker
                    valueFormat="h:mm A"
                    value={availableTimesForDay?.endsAt || "5:00 PM"}
                    onChange={(e) => updateAvailableTimes(day, "endsAt", e.target.value)}
                  />
                </span>
              </>
            ) : (
              <span className="my-[7px] flex-1 text-gray-dark">Unavailable</span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AvailableTimes

import React, { useState, useEffect } from "react"
import { useMutation } from "urql"

import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import ReviewProvider from "../../providers/ReviewProvider"
import RatingStar from "../RatingStar"
import LoadingSpinner from "../shared/LoadingSpinner"

import { ReviewLayout } from "./ReviewLayout"

const REVIEW_RATING_MUTATION = `
  mutation ReviewAddRating($practiceId: ID!, $clientId: ID, $rating: Int!, $appointmentId: ID ) {
    reviewAddRating(practiceId: $practiceId, clientId: $clientId, rating: $rating, appointmentId: $appointmentId) {
      result
      token
      errors
    }
  }
`

const Rating = ({ review }) => {
  const { showToast } = useToast()
  const [rating, setRating] = useState(review?.rating)
  const [, addRating] = useMutation(REVIEW_RATING_MUTATION)

  const isFormValid = rating > 0

  const handleAddRating = () => {
    addRating({
      practiceId: review.practiceId,
      clientId: review.clientId,
      rating,
      appointmentId: review.appointmentId
    }).then((res) => {
      if (res.data.reviewAddRating.result === "success") {
        window.location.href = `/i/r/text_review/${res.data.reviewAddRating.token}`
      } else {
        showToast({ content: `Error creating client: ${res.data.reviewAddRating.errors.join(" ")}`, type: "error" })
      }
    })
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (review.rating) {
      handleAddRating()
    } else if (urlParams.get("stars")) {
      setRating(Number(urlParams.get("stars")))
    }
  }, [])

  return (
    <>
      {!review.rating ? (
        <ReviewLayout
          percentDone="50%"
          onContinue={handleAddRating}
          continueDisabled={!isFormValid}
          proPic={review.practitioner.filestackPhoto}
          showBackButton={false}>
          <Typography variant="h3">
            Out of 5 stars, how would you rate your most recent visit with {review.practitioner.firstName}?
          </Typography>
          <div className="mt-4 text-center">
            <RatingStar rating={rating} setRating={setRating} />
          </div>
        </ReviewLayout>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

export default function ReviewRating(props) {
  return (
    <ReviewProvider>
      <Rating {...props} />
    </ReviewProvider>
  )
}

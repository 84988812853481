import React, { useState, useEffect } from "react"
import { useMutation } from "urql"

import { sendAmplitudeData } from "../support/amplitude-react"

const ViewPhone = `
  mutation ViewPhone($id: ID!) {
    viewPhone (id: $id) {
      formattedPhoneNumber
      phoneNumber
    }
  }
`

const ProfilePhoneNumber = ({ practitionerId, className }) => {
  const [phoneNumberHref, setPhoneNumberHref] = useState()
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState()
  const [isDisabled, setIsDisabled] = useState()
  const [, viewPhone] = useMutation(ViewPhone)

  const onClick = () => {
    if (!formattedPhoneNumber) {
      setIsDisabled(true)
      viewPhone({ id: practitionerId }).then((result) => {
        if (result.error) {
          console.error(result.error)
        } else {
          setFormattedPhoneNumber(result.data.viewPhone.formattedPhoneNumber)
          setPhoneNumberHref(`tel:${result.data.viewPhone.phoneNumber}`)
          useEffect(() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: "view_phone",
              event_category: "Client Booking Flow",
              event_label: "Client View Pro Phone"
            })
          }, [onClick])
          setIsDisabled(false)
          sendAmplitudeData("phone number clicked")
        }
      })
    }
  }

  return (
    <div className={className} style={isDisabled ? { pointerEvents: "none" } : {}}>
      <span className="details button btn-tertiary small" data-icon="phone" onClick={onClick}>
        {!formattedPhoneNumber ? "Show phone number" : <a href={phoneNumberHref}>{formattedPhoneNumber}</a>}
      </span>
    </div>
  )
}

export default ProfilePhoneNumber

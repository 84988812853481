import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react"
import { MapPinIcon } from "@heroicons/react/24/solid"
import capitalize from "lodash/capitalize"
import React, { useState, useRef } from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { twMerge } from "tailwind-merge"

import { BASE_INPUT_CLASSNAMES, DROPDOWN_CLASSNAMES, DROPDOWN_OPTION_CLASSNAMES } from "../../components/shared/Inputs"
import { Divider } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { stripUSAFromString } from "../../utils/utils"

const LocationInput = ({ eventType, setEventType, previousLocations }) => {
  const [inputValue, setInputValue] = useState(eventType.location || "")
  const comboboxRef = useRef(null)

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    debounce: 300
  })

  const onChange = (location) => {
    setEventType((prev) => ({ ...prev, location }))
  }

  const value = eventType.location || ""

  return (
    <div>
      <Combobox
        id="location-input"
        as="div"
        className="relative sm:flex-1"
        ref={comboboxRef}
        value={value}
        onChange={(item) => {
          if (typeof item === "string") {
            onChange(item)
            setInputValue(item)
          } else if (item) {
            placesService.getDetails({ placeId: item.place_id }, (place) => {
              const newMapCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
              const event = new CustomEvent("newMapCenter", { detail: newMapCenter })
              document.dispatchEvent(event)
              onChange(item.description)
              setInputValue(item.description)
            })
          }
        }}>
        <div className="relative">
          <ComboboxInput
            onChange={(evt) => {
              const newValue = evt.target.value
              setInputValue(newValue)
              onChange(newValue)
              getPlacePredictions({ input: newValue })
            }}
            className={BASE_INPUT_CLASSNAMES}
            placeholder="Location"
            value={inputValue}
          />
          {isPlacePredictionsLoading ? (
            <LoadingSpinner className="absolute right-2 top-2 py-0" spinnerClassName="h-5 w-5" />
          ) : (
            // Button is used to trigger the dropdown
            <ComboboxButton className="absolute right-1.5 top-1.5 h-6 w-6 text-gray-dark opacity-[0.64] sm:top-[7px]">
              <MapPinIcon />
            </ComboboxButton>
          )}
        </div>

        <ComboboxOptions className={twMerge(DROPDOWN_CLASSNAMES, "absolute z-50 w-full max-w-[430px]")}>
          {previousLocations?.length > 0 && <div className="px-3 font-bold">My locations</div>}
          {previousLocations?.map((location) => (
            <ComboboxOption key={location} className={DROPDOWN_OPTION_CLASSNAMES} value={location}>
              {location}
            </ComboboxOption>
          ))}
          {previousLocations?.length > 0 && <Divider />}
          {placePredictions?.length > 0 &&
            !isPlacePredictionsLoading &&
            placePredictions.map((item) => (
              <ComboboxOption key={item.description} className={DROPDOWN_OPTION_CLASSNAMES} value={item}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: stripUSAFromString(item.description).replace(
                      new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"),
                      (match) => `<mark style='font-weight: bold'>${capitalize(match)}</mark>`
                    )
                  }}
                />
              </ComboboxOption>
            ))}
        </ComboboxOptions>
      </Combobox>
    </div>
  )
}

export default LocationInput

import React, { useState } from "react"
import { useMutation } from "urql"

import { useManualBooking } from "../../contexts/ManualBookingContext"
import { useToast } from "../../contexts/ToastContext"
import { formatShortDateTime } from "../../utils/utils"
import { UPDATE_APPOINTMENT_V2_MUTATION } from "../pro_portal/calendar/appointment_graphql"

import { TextArea } from "./Inputs"
import { AnimatedModal } from "./Modal"

export default function ConfirmRescheduleModal({ showReschedule, hideModal, onRescheduleSuccess }) {
  const [practitionerNote, setPractitionerNote] = useState()
  const [loading, setLoading] = useState(false)
  const { appointment, rescheduledStartsAt, rescheduledEndsAt, locationId } = useManualBooking()
  const [, updateAppointmentV2] = useMutation(UPDATE_APPOINTMENT_V2_MUTATION)
  const { showToast } = useToast()

  if (!appointment) {
    return null
  }

  const clientName = appointment.client?.firstName || appointment.user?.firstName || "your client"

  return (
    <AnimatedModal
      visible={showReschedule}
      hideModal={hideModal}
      header="Reschedule session now"
      actionButtonCopy={loading ? "Rescheduling..." : "Reschedule"}
      saveDisabled={loading}
      showFooter={true}
      onSave={() => {
        setLoading(true)
        updateAppointmentV2({
          appointmentId: appointment.id,
          locationId,
          startsAt: rescheduledStartsAt,
          endsAt: rescheduledEndsAt,
          practitionerNote
        }).then((result) => {
          setLoading(false)
          if (result.data?.updateAppointmentV2?.result === "success") {
            showToast("Appointment rescheduled successfully. ")
            if (result.data.updateAppointmentV2.appointment) {
              onRescheduleSuccess(appointment.id, result.data.updateAppointmentV2.appointment)
            }
          } else {
            console.error(result)
            const errorMessage = result?.data?.updateAppointmentV2?.errors?.join() ?? "unknown error"
            showToast({
              content: `We weren't able to reschedule this appointment: ${errorMessage}`,
              type: "error"
            })
          }
          hideModal()
        })
      }}>
      <p className="mb-4">
        This will reschedule {clientName}&apos;s session from:
        <br />
        {formatShortDateTime(appointment.startsAt)} - {formatShortDateTime(appointment.endsAt)}
        <br />
        to:
        <br />
        {formatShortDateTime(rescheduledStartsAt)} - {formatShortDateTime(rescheduledEndsAt)}.
        <br />
        <br />
        Add a message below if you want to send an email to {clientName}:
      </p>
      <TextArea
        placeholder="Type in your message here..."
        rows="4"
        onChange={(e) => setPractitionerNote(e.target.value)}
        value={practitionerNote}
      />
    </AnimatedModal>
  )
}

import dayjs from "dayjs"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { Label, TextArea } from "../../components/shared/Inputs"
import { Divider } from "../../components/shared/Layout"

import ClientPageView from "./ClientPageView"

const ADD_NOTE_MUTATION = `
  mutation($text: String!, $clientId: ID!) {
    addNote(text: $text, clientId: $clientId) {
      result
      errors
    }
  }
`

const ClientNotes = ({ client: clientProp }) => {
  const [client, setClient] = useState(clientProp)
  const [text, setText] = useState("")
  const [{ fetching }, addNote] = useMutation(ADD_NOTE_MUTATION)

  const addNoteToClient = () => {
    addNote({ text, clientId: client.id }).then((result) => {
      if (result.data?.addNote?.result === "success") {
        setText("")
        setClient((prev) => ({
          ...prev,
          notes: [
            ...prev.notes,
            {
              text,
              time: new Date().toISOString()
            }
          ]
        }))
        console.log("Note added successfully")
      } else {
        console.error(result)
      }
    })
  }

  return (
    <ClientPageView client={client}>
      <Label>Client notes</Label>
      <div className="mb-4 rounded-lg border border-gray p-4">
        {client.notes.map((note, i) => (
          <>
            <div key={note.time} className="flex gap-4">
              <div className="flex-none font-bold">{dayjs(note.time).format("MMM Do, YYYY, h:mm A")}</div>
              <div>{note.text}</div>
            </div>
            {i < client.notes.length - 1 && <Divider className="my-4" />}
          </>
        ))}
      </div>
      <Label htmlFor="note-textarea">Add a new note</Label>
      <TextArea id="note-textarea" onChange={(e) => setText(e.target.value)} value={text} />
      <Button onClick={addNoteToClient} disabled={fetching}>
        Submit
      </Button>
    </ClientPageView>
  )
}

export default ClientNotes

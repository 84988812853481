import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import React from "react"
import { twMerge } from "tailwind-merge"

import { Divider } from "../components/shared/Layout"
import GraphQLProvider from "../providers/GraphQLProvider"
import { formatPhone } from "../utils/utils"

dayjs.extend(utc)
dayjs.extend(timezone)

const PriceRow = ({ label, value, className }) => (
  <div className={twMerge("flex items-center justify-between", className)}>
    <span className="text-left">{label}</span>
    <span className="ml-2 font-bold">{value}</span>
  </div>
)

const PageContent = ({ eventRegistration }) => {
  const { event, practice, discount, finalAmountCents } = eventRegistration

  // Format dates
  const timeZone = event.timeZone || dayjs.tz.guess()
  const startsAt = dayjs(event.startsAt).tz(timeZone)
  const endsAt = dayjs(event.endsAt).tz(timeZone)

  // Check if the event is in the past
  const eventIsPast = dayjs().isAfter(endsAt)

  // Format location
  const isVirtual = event.kind === "virtual"
  const locationDisplay = isVirtual ? "Virtual" : event.location

  // Format price
  const eventAmount = `$${((event.amountCents || 0) / 100).toFixed(2)}`
  const discountAmount = discount ? `$${(discount.amountCents / 100).toFixed(2)}` : "$0.00"
  const finalAmount = `$${(finalAmountCents / 100).toFixed(2)}`

  // Format phone
  const formattedPhone = formatPhone(practice.user.phone)

  // Status message
  const statusMessage = eventIsPast
    ? "This event has already ended."
    : `We're looking forward to seeing you on ${startsAt.format("MMMM D")} at ${startsAt.format("h:mm a")}.`

  return (
    <div className="m-auto w-full py-4 text-center">
      <div className="mb-4 text-2xl font-bold">Event Registration</div>
      <p className="mb-4 text-gray-dark">{statusMessage}</p>

      <div className="m-auto max-w-lg border border-gray p-4 sm:border-none">
        {/* Practitioner Info */}
        <div className="mb-4 flex items-center justify-center">
          <img src={practice.user.defaultPhotoUrl} className="h-16 w-16 rounded-full" />
          <div className="inline-flex flex-col">
            <div className="ml-4 text-lg font-bold">{practice.user.name}</div>
            <a href={`tel:${formattedPhone}`} className="ml-4 text-sm font-bold text-teal">
              {formattedPhone}
            </a>
          </div>
        </div>

        {/* Event Info */}
        <div className="mb-6">
          <div className="text-xl font-bold">{event.name}</div>
          <div className="mb-2 font-bold">
            <div>{startsAt.format("dddd, MMMM D, YYYY")}</div>
            <div>
              {startsAt.format("h:mm a")} - {endsAt.format("h:mm a z")}
            </div>
            <div className="my-4">
              <span>{locationDisplay}</span>
              {isVirtual && <span> (Virtual)</span>}
            </div>
          </div>

          {/* Event Description */}
          {event.description && (
            <div className="mb-4 text-left">
              <div className="mb-2 font-bold">Description:</div>
              <p className="text-sm">{event.description}</p>
            </div>
          )}

          {/* Event Instructions */}
          {event.instructions && (
            <div className="mb-4 text-left">
              <div className="mb-2 font-bold">Instructions:</div>
              <p className="text-sm">{event.instructions}</p>
            </div>
          )}
        </div>

        {/* Price Info */}
        {event.amountCents > 0 && (
          <div className="m-auto w-full">
            <div className="my-4 border-y border-gray py-4">
              <PriceRow label="Event Fee" value={eventAmount} />

              {discount && (
                <>
                  <PriceRow label={`Discount (${discount.code})`} value={`-${discountAmount}`} className="text-teal" />
                  <Divider className="my-2" />
                </>
              )}

              <PriceRow label="Total" value={finalAmount} className="text-lg font-bold" />
            </div>
          </div>
        )}

        {/* Footer links */}
        <div className="mt-6 font-bold text-teal [&>*]:m-auto [&>*]:mt-2 [&>*]:block">
          <div>
            <a href={practice.url}>Book another appointment</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ManageEventRegistration(props) {
  return (
    <GraphQLProvider>
      <PageContent {...props} />
    </GraphQLProvider>
  )
}

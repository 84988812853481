import { gql } from "urql"

export const GENERATE_IMAGE_ALT_TEXT_MUTATION = gql`
  mutation GenerateImageAltText {
    generateImageAltText {
      result
      errors
    }
  }
`

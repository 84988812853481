import NiceModal from "@ebay/nice-modal-react"
import { BellIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { useQuery } from "urql"

import { PracticeProvider } from "../../contexts/PracticeContext"
import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import { CounterBadge } from "../shared/Badge"
import NotificationsFlyout from "../shared/NotificationsFlyout"

const USER_CLIENT_FIELDS = "id name firstName lastName"
const APPOINTMENT_FIELDS = `
  id startsAt endsAt state instantActionToken hasTransaction formattedStartsAt
  client { ${USER_CLIENT_FIELDS} }
`

const INBOX_QUERY = `
  query ProInbox {
    proInbox {
      failedPayments {
        paymentError
        ${APPOINTMENT_FIELDS}
      }
      missingPayments { ${APPOINTMENT_FIELDS} }
      pendingAppointments { ${APPOINTMENT_FIELDS} }
    }
  }
`

const Inbox = () => {
  const [showFlyout, setShowFlyout] = useState(false)

  const [{ data, fetching }, reexecuteQuery] = useQuery({
    query: INBOX_QUERY,
    requestPolicy: "cache-and-network"
  })

  let notificationAppointments = []
  if (data && data.proInbox) {
    let allNotifications = [
      ...(data.proInbox.failedPayments || []),
      ...(data.proInbox.missingPayments || []),
      ...(data.proInbox.pendingAppointments || [])
    ]
    notificationAppointments = allNotifications.sort((a, b) => {
      if (!a || !b) return 0
      if (a.state === "pending" && b.state !== "pending") {
        return -1 // "pending" items come first
      } else if (a.state !== "pending" && b.state === "pending") {
        return 1 // "pending" items come first
      } else {
        // If both items have the same state or non-pending states, sort by date
        const dateA = new Date(a.startsAt)
        const dateB = new Date(b.startsAt)
        return dateA - dateB
      }
    })
  }

  const badgeStyles =
    notificationAppointments.length > 9 ? "font-bold p-[3px] min-w-[16px] w-auto right-[12px]" : "right-4"

  return (
    <>
      <a className="relative" onClick={() => setShowFlyout(true)}>
        <BellIcon className="mr-4 h-8 w-8 text-gray-dark" />
        {notificationAppointments.length > 0 && (
          <CounterBadge className={`absolute ${badgeStyles} top-0 border-2 border-white bg-orange text-[10px]`}>
            {notificationAppointments.length}
          </CounterBadge>
        )}
      </a>
      <NotificationsFlyout
        visible={showFlyout}
        closeFlyout={() => setShowFlyout(false)}
        fetching={fetching}
        reexecuteQuery={reexecuteQuery}
        notificationAppointments={notificationAppointments}
      />
    </>
  )
}

export default function ProInbox() {
  return (
    <NiceModal.Provider>
      <ToastProvider>
        <GraphQLProvider>
          <PracticeProvider>
            <Inbox />
          </PracticeProvider>
        </GraphQLProvider>
      </ToastProvider>
    </NiceModal.Provider>
  )
}

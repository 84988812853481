import React, { useState } from "react"
import styled from "styled-components"

import RoundedButton from "../shared/RoundedButton"

import CsvReader from "./CsvReader"

const lightbulb = require("../../../assets/images/lightbulb.png")
const uploadClientListImage = require("../../../assets/images/upload-clientlist.png")

const UploadContainer = styled.div`
  border: 1px dashed #889fad;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: 50%;
  margin-bottom: 24px;

  i {
    margin-bottom: 24px;
    &:before {
      font-size: 33px;
    }
  }

  span {
    color: #55778a;
  }
`

const Divider = styled.div`
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const HR = styled.div`
  border-bottom: 1px solid back;
`

const Helper = styled.div`
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 4px;

  span {
    color: #55778a;
  }
`

const InlineFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const LinkComponent = styled.div`
  color: #0d9da4;
  text-decoration: underline;
  margin-left: 4px;

  &:hover {
    cursor: pointer;
  }
`

const InviteLater = styled.div`
  color: #55778a;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  &:hover {
    cursor: pointer;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const HintContainer = styled.div`
  background: #f3f8fb;
  position: absolute;
  top: 116px;
  left: 76%;
  width: 268px;
  text-align: left;
  padding: 32px 16px 16px 16px;
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
`

const UploadWrapper = styled.div`
  position: relative;
  width: 100vw;
  padding: auto 200px;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 32px;
  }
`

const Lightbulb = styled.div`
  position: absolute;
  top: -27px;
  left: 100px;
  width: 54px;
  height: 54px;
`

export default function ChooseMethod({ handleChoice, active, handleFileUpload, reviewInput }) {
  const [uploadClick, setUploadClick] = useState(false)

  return (
    <div className="columns is-centered is-multiline">
      <UploadWrapper>
        <h2>Step 1: Invite your clients to book with you.</h2>
        <p>Got a client list? Upload it here.</p>
        <UploadContainer>
          <i className="icon-upload-2" />
          {/* TODO: add back when implementing step2 */}
          {/* <Helper>Drag and drop your file here</Helper> */}
          <InlineFlex>
            {/* TODO: add back when implementing step2 */}
            {/* <span>or</span> */}
            <LinkComponent
              onClick={() => {
                setUploadClick(true)
              }}>
              Select file from your computer
            </LinkComponent>
          </InlineFlex>
        </UploadContainer>
        <div className="methods-form">
          <div>
            {active === "file" && (
              <div>
                <CsvReader handleFileUpload={handleFileUpload} reviewInput={reviewInput} upload={uploadClick} />
              </div>
            )}
          </div>
        </div>
        {/* Remove app references
          <HintContainer>
            <Lightbulb>
              <img src={lightbulb} />
            </Lightbulb>
            <h3>{`Don't have a client list?`}</h3>
            <h4>{`Don't worry, you'll be able to invite clients directly from your phone contacts after you download the app.`}</h4>
          </HintContainer>
        */}
        <Divider>
          <HR />
          <span>or</span>
          <HR />
        </Divider>
      </UploadWrapper>
      <ButtonWrapper>
        <RoundedButton
          type="link"
          label="Add clients manually"
          onClick={() => {
            handleChoice("copy-paste")
          }}
        />
        <a href="/portal/clients">
          <InviteLater>{`I'll do this later`}</InviteLater>
        </a>
      </ButtonWrapper>
      <div className="column is-full has-text-centered">
        <img src={uploadClientListImage} />
      </div>
    </div>
  )
}

export const GET_BOOKABLE_EVENT_TYPES_QUERY = `
  query GetBookableEventTypes {
    bookableEventTypes {
      id
      name
      description
      instructions
      capacity
      amountCents
      amountCurrency
      location
      kind
      photoUrl
      slug
      url
    }
  }
`

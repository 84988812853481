import { XMarkIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import { useManualBooking } from "../../contexts/ManualBookingContext"
import { useToast } from "../../contexts/ToastContext"
import { formatDate, formatPrice } from "../../utils/utils"

import AdjustmentModal from "./AdjustmentModal"
import { Label } from "./Inputs"
import { UnderlinedLink } from "./Links"
import { AnimatedModal } from "./Modal"
import Typography from "./Typography"

const REMOVE_ADJUSTMENT = `
  mutation RemoveAdjustment($adjustmentId: ID!) {
    removeAdjustment(adjustmentId: $adjustmentId) {
      result
      errors
    }
  }
`

export default function AppointmentAdjustments() {
  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false)
  const [showRemoveAdjustmentModal, setShowRemoveAdjustmentModal] = useState(false)
  const [adjustment, setAdjustment] = useState()
  const [loading, setLoading] = useState(false)
  const { showToast } = useToast()

  const { appointment, setAppointment } = useManualBooking()

  const isStripePayment = appointment?.paymentIntentId !== null

  const [, removeAdjustment] = useMutation(REMOVE_ADJUSTMENT)

  return (
    <>
      <div className="my-8 w-full border-t border-gray-light" />
      <Label>Refunds / Adjustment</Label>
      <Typography className="my-2 block" variant="small">
        Record any refunds or adjustments to this payment.
      </Typography>
      {appointment?.adjustments?.length > 0 && (
        <ul className="list-disc ">
          {appointment.adjustments.map((adjustment) => (
            <li className="relative mb-4 ml-4" key={adjustment.id}>
              <Typography variant="body" as="p" className="mb-0 text-sm">
                {adjustment.amountCents >= 0 ? "Refunded " : "Adjusted "} {formatPrice(adjustment.amountCents)} on{" "}
                {formatDate(adjustment.adjustedAt)}{" "}
              </Typography>
              <Typography variant="body" as="p" className="text-sm text-gray-dark">
                Note: {adjustment.note || "No note provided"}
              </Typography>
              {!isStripePayment && (
                <XMarkIcon
                  onClick={() => {
                    setAdjustment(adjustment)
                    setShowRemoveAdjustmentModal(true)
                  }}
                  className="absolute right-1 top-2 h-6 w-6 cursor-pointer p-1 font-bold text-black"
                />
              )}
            </li>
          ))}
        </ul>
      )}
      <UnderlinedLink onClick={() => setShowAdjustmentModal(true)} className="text-sm">
        Add refund/adjustment
      </UnderlinedLink>
      {showAdjustmentModal && (
        <AdjustmentModal showAdjustmentModal={showAdjustmentModal} hideModal={() => setShowAdjustmentModal(false)} />
      )}
      {showRemoveAdjustmentModal && (
        <AnimatedModal
          visible={showRemoveAdjustmentModal}
          hideModal={() => setShowRemoveAdjustmentModal(false)}
          header="Remove adjustment?"
          actionButtonCopy="Remove"
          actionButtonType="warning"
          saveDisabled={loading}
          showFooter={true}
          onSave={() => {
            setLoading(true)
            removeAdjustment({
              adjustmentId: adjustment.id
            }).then((result) => {
              setLoading(false)
              if (result.data?.removeAdjustment?.result === "success") {
                const updatedAdjustments = appointment.adjustments.filter((adj) => adj.id !== adjustment.id)
                setAppointment({ ...appointment, adjustments: updatedAdjustments })
                showToast("Adjustment removed ")
              } else {
                showToast({
                  content: `We weren't able to remove this adjustment: ${result.data.removeAdjustment.errors.join()}`,
                  type: "error"
                })
              }
              setShowRemoveAdjustmentModal(false)
            })
          }}>
          <div>
            <Typography variant="body" as="p" className="mb-0 text-sm">
              {adjustment.amountCents >= 0 ? "Refunded " : "Adjusted "} {formatPrice(adjustment.amountCents)} on{" "}
              {formatDate(adjustment.adjustedAt)}{" "}
            </Typography>
            <Typography variant="body" as="p" className="text-sm text-gray-dark">
              Note: {adjustment.note || "No note provided"}
            </Typography>
          </div>
        </AnimatedModal>
      )}
    </>
  )
}

import React from "react"

import { Label } from "../../components/shared/Inputs"
import PlacesAutocomplete from "../../components/shared/PlacesAutocomplete"
import { stripUSAFromString } from "../../utils/utils"

const LocationPlacesSelect = ({
  label,
  location,
  setLocation,
  placeholder = "Start typing the street address",
  types = [],
  validationError
}) => {
  const id = "location-select-" + label.toLowerCase().replace(" ", "-")

  const onPlaceSelected = (place) => {
    setLocation((location) => {
      const newLocation = {
        ...location,
        neighborhood: null,
        cityGeocoded: null,
        stateProvince: null,
        stateAbbreviation: null,
        country: null,
        countryCode: null,
        placeId: null,
        fullStreetAddress: null
      }
      place.address_components.map((address) => {
        if (address.types.includes("street_number")) {
          newLocation.address = address.long_name
        } else if (address.types.includes("route")) {
          newLocation.address = newLocation.address + " " + address.long_name
        } else if (address.types.includes("neighborhood")) {
          newLocation.neighborhood = address.long_name
        } else if (
          address.types.includes("locality") ||
          address.types.includes("sublocality") ||
          address.types.includes("postal_town")
        ) {
          newLocation.cityGeocoded = address.long_name
          newLocation.city = address.long_name
        } else if (address.types.includes("administrative_area_level_1")) {
          newLocation.stateProvince = address.long_name
          newLocation.stateAbbreviation = address.short_name
        } else if (address.types.includes("country")) {
          newLocation.country = address.long_name
          newLocation.countryCode = address.short_name
        } else if (address.types.includes("postal_code")) {
          newLocation.zip = address.long_name
        }
      })
      newLocation.latitude = place.geometry.location.lat()
      newLocation.longitude = place.geometry.location.lng()
      newLocation.fullStreetAddress = place.formatted_address
      newLocation.placeId = place.place_id
      return newLocation
    })
  }

  return (
    <div>
      <Label htmlFor={id}>{label}</Label>
      <PlacesAutocomplete
        apiKey={process.env.GOOGLE_MAPS_API_KEY}
        types={types}
        id={id}
        onPlaceSelected={onPlaceSelected}
        defaultValue={stripUSAFromString(location?.fullStreetAddress || location?.address || "")}
        placeholder={placeholder}
        validationError={validationError}
      />
    </div>
  )
}

export default LocationPlacesSelect

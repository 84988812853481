import React, { useState } from "react"

import Badge from "../../components/shared/Badge"
import HealMeTable from "../../components/shared/HealMeTable"
import { Select } from "../../components/shared/Inputs"
import { filterReviewsByDate, filterByRating } from "../../utils/setupFilters"
import { formatShortDate } from "../../utils/utils"
import ReviewFlyout from "../ProReviews/ReviewFlyout"

const dateRangeOptions = [
  { value: "alltime", label: "All time" },
  { value: "thisweek", label: "This week" },
  { value: "lastweek", label: "Last week" },
  { value: "thismonth", label: "This month" },
  { value: "lastmonth", label: "Last month" },
  { value: "thisyear", label: "This year" },
  { value: "lastyear", label: "Last year" }
]

const ratingOptions = [
  { value: "all", label: "All ratings" },
  { value: 5, label: "5" },
  { value: 4, label: "4" },
  { value: 3, label: "3" },
  { value: 2, label: "2" },
  { value: 1, label: "1" }
]

const TableHeader = ({ dateFilter, ratingFilter, handleFilter }) => (
  <div className="ml-4 flex w-full sm:ml-0 sm:mt-4">
    <Select
      className="mr-4"
      options={dateRangeOptions}
      value={dateFilter}
      onChange={(e) => handleFilter(e.target.value, ratingFilter)}
    />
    <Select options={ratingOptions} value={ratingFilter} onChange={(e) => handleFilter(dateFilter, e.target.value)} />
  </div>
)

export default function ProReviewsTable({ reviews }) {
  const allReviews = reviews
  const [flyoutKey, setFlyoutKey] = useState(0)
  const [showReviewDetail, setShowReviewDetail] = useState(false)
  const [selectedReview, setSelectedReview] = useState(null)

  const [filteredReviews, setFilteredReviews] = useState(reviews)
  const [dateFilter, setDateFilter] = useState(dateRangeOptions[0].value)
  const [ratingFilter, setRatingFilter] = useState(ratingOptions[0].value)

  const showReview = (review) => {
    setTimeout(() => setFlyoutKey(review.id), 500)
    setShowReviewDetail(true)
    setSelectedReview(review)
  }

  const handleFilter = (dateSelection, ratingSelection) => {
    setDateFilter(dateSelection || dateFilter)
    setRatingFilter(ratingSelection || ratingFilter)

    let combinedArray = allReviews

    combinedArray = filterReviewsByDate(combinedArray, dateSelection)
    combinedArray = filterByRating(combinedArray, ratingSelection)

    setFilteredReviews(combinedArray)
  }

  const reviewColumns = [
    {
      accessor: "id",
      Header: "Client",
      className: "w-1/5 min-w-[200px] pr-6",
      Cell: (props) => {
        const review = props.cell.row.original
        const clientOrUser = review.client || review.user

        let client_name = clientOrUser?.firstName

        if (clientOrUser?.lastName) {
          client_name += " " + clientOrUser.lastName
        }

        if (!client_name) {
          client_name = review.unverifiedName
        }

        return (
          <>
            <span className="mb-0 block"> {client_name}</span>
            {review.reviewReply && <Badge className="mt-2 inline-block">REPLIED</Badge>}
          </>
        )
      }
    },
    {
      accessor: "rating",
      Header: "Rating",
      className: "w-1/12 min-w-[80px] pr-6",
      Cell: (props) => (
        <>
          <span className="font-bold">{props.cell.value}</span>
          <span className="text-gray-dark">/5</span>
        </>
      )
    },
    {
      accessor: "review",
      Header: "Review",
      className: "w-2/5 max-w-[700px] min-w-[240px] pr-6",
      Cell: (props) => {
        const link = props.cell.row.original.reviewReply ? "View reply" : "Leave reply"

        return (
          <>
            {props.cell.value ? (
              <>
                <span className="mb-0 block">&ldquo;{props.cell.value}&rdquo;</span>
                <span className="mt-2 block">
                  <a className="font-bold text-teal">{link}</a>
                </span>
              </>
            ) : (
              <span className="text-gray-dark">No text review</span>
            )}
          </>
        )
      }
    },
    {
      accessor: "createdAt",
      Header: "Date",
      className: "w-1/6 min-w-[120px] pr-6",
      Cell: (props) => <span className="text-gray-dark">{formatShortDate(props.cell.value)}</span>
    },
    {
      accessor: "updatedAt",
      Header: "Action",
      className: "w-1/12 min-w-[80px]",
      Cell: () => <a className="font-bold text-teal">View</a>
    }
  ]

  return (
    <>
      {reviews && (
        <HealMeTable
          data={filteredReviews}
          columns={reviewColumns}
          searchType="reviews"
          rowClick={showReview}
          header={() => <TableHeader dateFilter={dateFilter} ratingFilter={ratingFilter} handleFilter={handleFilter} />}
        />
      )}

      <ReviewFlyout
        key={flyoutKey}
        review={selectedReview}
        closeFlyout={({ replied }) => {
          if (replied) {
            window.location.reload()
          } else {
            setShowReviewDetail(false)
            setTimeout(() => setFlyoutKey(selectedReview.id), 500)
          }
        }}
        visible={showReviewDetail}
      />
    </>
  )
}

import Honeybadger from "@honeybadger-io/js"
import { payments } from "@square/web-sdk"
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { createContext, useCallback, useContext, useEffect } from "react"
import { useMutation } from "urql"

import useStripeHook from "../../hooks/useStripeHook"
import { SQUARE_ADD_CARD_TO_CUSTOMER_MUTATION } from "../../utils/mutations"

export default function PaymentProviderWrapper({ practice, appointment, user, client, children, mode = "default" }) {
  return (
    <>
      {practice.paymentProvider === "stripe" && (
        <StripeWrapper practice={practice} appointment={appointment} user={user} client={client} mode={mode}>
          {children}
        </StripeWrapper>
      )}
      {practice.paymentProvider === "square" && (
        <SquareWrapper practice={practice} appointment={appointment} user={user} client={client} mode={mode}>
          {children}
        </SquareWrapper>
      )}
    </>
  )
}

function StripeWrapper({ children, practice, user, client, appointment, mode }) {
  const { stripeData, setupIntent, elementsOptions } = useStripeHook()

  const promise = loadStripe(`${window.App.stripe_key}`, {
    stripeAccount: practice.stripeAccountId || window.App.practice_stripe_account
  })

  useEffect(() => {
    // Only call setupIntent directly if not in setup mode
    // For setup mode, we'll handle it in handleCardSavedOnProvider
    if (mode !== "setup") {
      setupIntent({
        practiceId: practice.id,
        appointmentId: appointment?.id,
        userId: user?.id,
        clientId: client?.id
      }).then((res) => {
        if (res.data?.setupIntent?.result === "success") {
          // nothing
        } else {
          Honeybadger.notify(res.error)
          console.error(res.error) // eslint-disable-line no-console
        }
      })
    }
  }, [mode, practice.id, appointment?.id, user?.id, client?.id, setupIntent])

  return (
    (stripeData || mode === "setup") &&
    promise && (
      <Elements stripe={promise} options={elementsOptions}>
        <PaymentProviderContextProvider
          practice={practice}
          appointment={appointment}
          user={user}
          client={client}
          provider="stripe"
          mode={mode}>
          {children}
        </PaymentProviderContextProvider>
      </Elements>
    )
  )
}

function SquareWrapper({ practice, appointment, user, client, children, mode }) {
  return (
    <PaymentProviderContextProvider
      provider="square"
      appointment={appointment}
      practice={practice}
      user={user}
      client={client}
      mode={mode}>
      {children}
    </PaymentProviderContextProvider>
  )
}

export const PaymentProviderContext = createContext()

export const PaymentProviderContextProvider = ({
  provider,
  practice,
  appointment,
  user,
  client,
  children,
  mode = "default"
}) => {
  const [squareCard, setSquareCard] = React.useState(null)
  const [, squareAddCardToCustomer] = useMutation(SQUARE_ADD_CARD_TO_CUSTOMER_MUTATION)

  const stripe = provider === "stripe" ? useStripe() : null
  const elements = provider === "stripe" ? useElements() : null

  const { setupIntent } = provider === "stripe" ? useStripeHook() : { setupIntent: null }

  const [squarePayments, setSquarePayments] = React.useState(null)
  useEffect(() => {
    const initializePayment = async () => {
      let sp = await payments(window.App.square_application_id, "")
      setSquarePayments(sp)
    }
    initializePayment()
  }, [])

  const handleCardSavedOnProvider = useCallback(async () => {
    if (provider === "stripe") {
      if (!stripe || !elements) return

      // Use the mode prop instead of trying to detect it from elements
      const isSetupMode = mode === "setup"
      let clientSecret = null

      // Setup mode is to show the Stripe payment form without setup intent (logged out user)
      // This flow is not currently used, we only show the form, but we're not saving it
      // Test this before using.
      // If we're in setup mode, create a setupIntent
      if (isSetupMode) {
        const setupIntentResult = await setupIntent({
          practiceId: practice.id,
          appointmentId: appointment?.id,
          userId: user?.id,
          clientId: client?.id
        })

        if (setupIntentResult.data?.setupIntent?.result === "success") {
          clientSecret = setupIntentResult.data.setupIntent.setupIntentSecret
        } else {
          Honeybadger.notify(setupIntentResult.error)
          return { error: setupIntentResult.error?.message || "Failed to create setup intent" }
        }
      }

      // Now confirm the setup with the appropriate clientSecret
      const confirmParams = {
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: `${window.location.origin}${window.location.pathname}`
        }
      }

      // Add clientSecret if we're in setup mode and generated one
      if (isSetupMode && clientSecret) {
        confirmParams.clientSecret = clientSecret
      }

      const result = await stripe.confirmSetup(confirmParams)

      if (result.error) {
        return { error: result.error.message || "An error occurred" }
      }
      return { token: null }
    } else if (provider === "square") {
      try {
        const tokenizeResult = await squareCard.tokenize()
        if (tokenizeResult.status === "OK") {
          const addCardResult = await squareAddCardToCustomer({
            clientId: client?.id || appointment?.client?.id,
            practiceId: practice.id,
            sourceId: tokenizeResult.token,
            userId: user?.id || appointment?.user?.id
          })

          if (addCardResult.data?.squareAddCardToCustomer?.result === "success") {
            return { token: tokenizeResult.token }
          }
          return { error: addCardResult.data?.squareAddCardToCustomer?.errors.join(", ") || "An error occurred" }
        } else {
          return { error: JSON.stringify(tokenizeResult.errors) }
        }
      } catch (error) {
        return { error: error.message || "An error occurred" }
      }
    }
  }, [provider, squareCard, stripe, elements, setupIntent, practice, appointment, user, client, mode])

  return (
    <PaymentProviderContext.Provider
      value={{
        handleCardSavedOnProvider,
        stripe,
        elements,
        squarePayments,
        squareCard,
        setSquareCard,
        setupIntent,
        mode
      }}>
      {children}
    </PaymentProviderContext.Provider>
  )
}

export const usePaymentProvider = () => useContext(PaymentProviderContext)

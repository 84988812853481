import React from "react"

const Photo = ({ filestack, imgSrc, onClick, className, imgClassName }) => (
  <div className={`photo ${className}`} onClick={onClick}>
    <picture>
      {filestack?.x_large?.webp && (
        <source type="image/webp" srcSet={`https://cdn.filestackcontent.com/${filestack.x_large.webp} 2x`} />
      )}
      {filestack?.x_large?.jpeg && (
        <source type="image/jpeg" srcSet={`https://cdn.filestackcontent.com/${filestack.x_large.jpeg} 2x`} />
      )}
      {filestack?.large?.webp && (
        <source type="image/webp" srcSet={`https://cdn.filestackcontent.com/${filestack.large.webp}`} />
      )}
      {filestack?.large?.jpeg && (
        <source type="image/jpeg" srcSet={`https://cdn.filestackcontent.com/${filestack.large.jpeg}`} />
      )}
      {filestack?.square?.jpeg && (
        <source type="image/jpeg" srcSet={`https://cdn.filestackcontent.com/${filestack.square.jpeg}`} />
      )}
      {filestack?.medium?.jpeg && (
        <source type="image/jpeg" srcSet={`https://cdn.filestackcontent.com/${filestack.medium.jpeg}`} />
      )}
      <img className={imgClassName} type="image/jpeg" src={imgSrc} />
    </picture>
  </div>
)

export default Photo

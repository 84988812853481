import { StarIcon } from "@heroicons/react/24/solid"
import React from "react"

const Star = ({ filled }) => {
  const colorClass = filled ? "text-orange" : "text-gray"
  return <StarIcon className={`${colorClass} h-5 w-5`} />
}

const RatingStarRow = ({ total }) => {
  let stars = []

  for (let i = 0; i < 5; i++) {
    stars.push(<Star key={i} filled={i < total} />)
  }

  return <div className="flex">{stars}</div>
}

export default RatingStarRow

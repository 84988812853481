import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useQuery } from "urql"

import DefaultProvider from "../../providers/DefaultProvider"

import BookingCard from "./BookingCard"
import TransactionCard from "./TransactionCard"

const APPOINTMENT_FIELDS = `
  id
  state
  startsAt
  endsAt
  amountCents
  feeCents
  platformFeeCents
  instantActionToken
  appointmentTimeLength
`

const QUERY = `
  query ClientBookings {
    upcomingAppointments {
      ${APPOINTMENT_FIELDS}
      transaction {
        id
        amountCents
        state
        type
      }
    }
    pastAppointments {
      ${APPOINTMENT_FIELDS}
      transaction {
        id
        amountCents
        state
        type
      }
    }
    expiredAppointments {
      ${APPOINTMENT_FIELDS}
    }
  }
  `

const Container = styled.div`
  display: flex;
  flex-flow: column;
  color: #0b3954;
`
const Title = styled.div`
  font-family: "Manrope", serif;
  font-size: 48px;
  font-weight: 700;
  color: #0b3954;
`
const SubTitle = styled.div`
  color: #0b3954;
  font-size: 18px;
`
const TabsContainer = styled.div`
  display: flex;
  gap: 18px;
  margin-top: 24px;
  overflow-x: scroll;
`
const Tab = styled.div`
  color: #7a7a7a;
  font-size: 18px;
  padding-bottom: 8px;
  white-space: nowrap;

  ${(props) =>
    props.active &&
    `
    color: #0B3954;
    border-bottom: solid 2px #0b3954;
  `}
  &:hover {
    cursor: pointer;
    ${(props) =>
      !props.active &&
      `
      color: #0d9da4;
      border-bottom: solid 2px #0d9da4;
    `}
  }
`

const BookingsContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 24px;
  margin-top: 24px;
  width: 50%;

  @media (max-width: 769px) {
    width: 100%;
  }
`
export const CardTitle = styled.div`
  color: #0b3954;
  font-size: 16px;
  font-weight: 500;
  margin-top: 18px;
`
const UPCOMING = "upcoming"
const PAST = "past"
const TRANSACTIONS = "transactions"
const EXPIRED = "expired"

const Bookings = () => {
  const [activeTab, setActiveTab] = useState(UPCOMING)
  const [upcoming, setUpcoming] = useState()
  const [past, setPast] = useState()
  const [expired, setExpired] = useState()

  const [{ data }] = useQuery({
    query: QUERY,
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (data) {
      setUpcoming(data.upcomingAppointments)
      setPast(data.pastAppointments)
      setExpired(data.expiredAppointments)
    }
  }, [data])

  return (
    <Container>
      <Title>Your Bookings</Title>
      <SubTitle>All of your upcoming and past bookings.</SubTitle>
      <TabsContainer>
        <Tab active={activeTab === UPCOMING} onClick={() => setActiveTab(UPCOMING)}>
          Upcoming Bookings
        </Tab>
        <Tab active={activeTab === PAST} onClick={() => setActiveTab(PAST)}>
          Past Bookings
        </Tab>
        <Tab active={activeTab === TRANSACTIONS} onClick={() => setActiveTab(TRANSACTIONS)}>
          Transactions
        </Tab>
        <Tab active={activeTab === EXPIRED} onClick={() => setActiveTab(EXPIRED)}>
          Expired Requests
        </Tab>
      </TabsContainer>
      <BookingsContainer>
        {activeTab === UPCOMING &&
          upcoming &&
          upcoming.map((booking) => <BookingCard booking={booking} key={`upcoming-${booking.id}`} />)}
        {activeTab === UPCOMING && (!upcoming || upcoming?.length === 0) && (
          <div>You do not have any upcoming booking requests.</div>
        )}

        {activeTab === PAST &&
          past &&
          past.map((booking) => <BookingCard booking={booking} key={`past-${booking.id}`} />)}
        {activeTab === PAST && (!past || past?.length === 0) && <div>You do not have any past booking requests.</div>}

        {activeTab === TRANSACTIONS &&
          upcoming &&
          upcoming.map((booking) => <TransactionCard booking={booking} key={`transaction-${booking.id}`} />)}
        {activeTab === TRANSACTIONS && (!upcoming || upcoming?.length === 0) && (
          <div>Your transactions will appear here after you book your first sessions.</div>
        )}

        {activeTab === EXPIRED &&
          expired &&
          expired.map((booking) => <BookingCard booking={booking} key={`expired-${booking.id}`} />)}
        {activeTab === EXPIRED && (!expired || expired?.length === 0) && (
          <div>You do not have any expired booking requests.</div>
        )}
      </BookingsContainer>
    </Container>
  )
}

export default function ClientBookings() {
  return (
    <DefaultProvider>
      <Bookings />
    </DefaultProvider>
  )
}

import React from "react"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { usePractice } from "../../../../contexts/PracticeContext"
import { formatPrice } from "../../../../utils/utils"
import ConfirmRescheduleModal from "../../../shared/ConfirmRescheduleModal"
import { Label, TextArea } from "../../../shared/Inputs"
import { AnimatedModal } from "../../../shared/Modal"
import { RadioWithLabel } from "../../../shared/RadioButtons"
import Typography from "../../../shared/Typography"
import ConfirmPackageModal from "../ConfirmPackageModal"

const ConfirmModals = ({
  showReschedule,
  showCancelModal,
  showPackageConfirmModal,
  showPastAppointmentModal,
  onRescheduleSuccess,
  onCancelSuccess,
  hideRescheduleModal,
  hideCancelModal,
  hidePackageConfirmModal,
  hidePastAppointmentModal,
  onPackageConfirm,
  onPastAppointmentConfirm,
  practitionerNote,
  setPractitionerNote,
  cancelRecurringSeries,
  setCancelRecurringSeries
}) => {
  const { appointment, client, services } = useManualBooking()
  const { practice } = usePractice()

  const serviceAmount = formatPrice(services?.[0]?.amountCents)

  return (
    <>
      {showReschedule && (
        <ConfirmRescheduleModal
          showReschedule={showReschedule}
          onRescheduleSuccess={onRescheduleSuccess}
          hideModal={hideRescheduleModal}
        />
      )}
      {showCancelModal && (
        <AnimatedModal
          visible={showCancelModal}
          hideModal={hideCancelModal}
          header="Cancel session now"
          showFooter={true}
          cancelButtonCopy="No, don't cancel"
          actionButtonCopy="Yes, cancel"
          actionButtonType="destructive"
          onSave={onCancelSuccess}>
          <Typography variant="subtitle">You can add an optional message to the cancellation email.</Typography>
          <TextArea
            placeholder="Message to client (optional)"
            onChange={(e) => setPractitionerNote(e.target.value)}
            value={practitionerNote}
          />
          {appointment.recurrenceFrequency && (
            <div className="mt-4">
              <Label>Cancel recurring session</Label>
              <RadioWithLabel
                value={!cancelRecurringSeries}
                label="This session only"
                checked={!cancelRecurringSeries}
                onChange={() => setCancelRecurringSeries(!cancelRecurringSeries)}
              />
              <RadioWithLabel
                className="mt-1.5"
                value={cancelRecurringSeries}
                label="This and all following sessions"
                checked={cancelRecurringSeries}
                onChange={() => setCancelRecurringSeries(!cancelRecurringSeries)}
              />
            </div>
          )}{" "}
        </AnimatedModal>
      )}
      <ConfirmPackageModal
        showPackageConfirmModal={showPackageConfirmModal}
        client={client}
        hideModal={hidePackageConfirmModal}
        practice={practice}
        onSave={onPackageConfirm}
        amount={serviceAmount}
      />
      {showPastAppointmentModal && (
        <AnimatedModal
          visible={showPastAppointmentModal}
          hideModal={hidePastAppointmentModal}
          header="Create Past Appointment"
          showFooter={true}
          cancelButtonCopy="No, cancel"
          actionButtonCopy="Yes, continue"
          onSave={onPastAppointmentConfirm}>
          <p>
            It looks like you&apos;re creating an appointment in the past, are you sure this is what you want to do?
          </p>
          <br />
          {practice.hasPaymentProvider && <p>If the client has a card on file, it will be charged right away.</p>}
        </AnimatedModal>
      )}
    </>
  )
}

export default ConfirmModals

import { PencilIcon } from "@heroicons/react/24/outline"
import React, { useMemo, useState } from "react"
import { useQuery, useMutation } from "urql"

import ClientSelector from "../../components/pro_portal/calendar/ClientSelector"
import { Button } from "../../components/shared/Buttons"
import { IconButton } from "../../components/shared/Buttons"
import { Banner } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { AnimatedModal } from "../../components/shared/Modal"

import { useBookableEvents } from "./BookableEventsContext"
import ClientDetailsFlyout from "./ClientDetailsFlyout"
import { ADD_CLIENT_TO_BOOKABLE_EVENT_MUTATION } from "./mutations"
import { BOOKABLE_EVENT_REGISTRANTS_QUERY } from "./queries"

export default function BookableEventRegistrants() {
  const { bookableEvent } = useBookableEvents()
  const [showAddClientModal, setShowAddClientModal] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [clientDetailsFlyoutVisible, setClientDetailsFlyoutVisible] = useState(false)

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: BOOKABLE_EVENT_REGISTRANTS_QUERY,
    variables: { id: bookableEvent.id }
  })

  const atCapacity = useMemo(
    () => bookableEvent.capacity && data?.bookableEvent?.clients?.length >= bookableEvent.capacity,
    [bookableEvent.capacity, data?.bookableEvent?.clients?.length]
  )

  const handleAddClient = () => {
    setShowAddClientModal(true)
  }

  const handleAddClientSuccess = () => {
    // Explicitly refetch the registrants data
    reexecuteQuery({ requestPolicy: "network-only" })
    setShowAddClientModal(false)
  }

  const handleEditClient = (client) => {
    setSelectedClient(client)
    setClientDetailsFlyoutVisible(true)
  }

  const handleClientRemoved = () => {
    reexecuteQuery({ requestPolicy: "network-only" })
  }

  return (
    <div>
      {fetching ? (
        <LoadingSpinner />
      ) : error ? (
        <p>Error loading registrants</p>
      ) : (
        <>
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-semibold">Event Registrants</h3>
            <Button type="primary" onClick={handleAddClient}>
              Add Client
            </Button>
          </div>
          {/* Show the capacity */}
          <div className="mb-4 text-sm text-gray-dark">
            Capacity: {data?.bookableEvent?.clients?.length} taken / {bookableEvent?.capacity} max
          </div>
          <div className="border-t border-gray-light">
            {data?.bookableEvent?.clients?.length > 0 ? (
              data.bookableEvent.clients.map((client) => (
                <div key={client.id} className="flex justify-between border-b border-gray-light py-2">
                  <div>
                    {client.firstName} {client.lastName}
                  </div>
                  <div className="flex items-center">
                    <div className="mr-4 text-right">
                      <div>{client.email}</div>
                      <div>{client.phone}</div>
                    </div>
                    <IconButton
                      Icon={PencilIcon}
                      onClick={() => handleEditClient(client)}
                      tooltip="Edit registration"
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="py-4 text-center text-gray-dark">No registrants yet for this event.</div>
            )}
          </div>

          {/* Add client modal */}
          <AnimatedModal
            visible={showAddClientModal}
            hideModal={() => setShowAddClientModal(false)}
            header="Add Client to Event">
            <AddClientToEvent
              bookableEventId={bookableEvent.id}
              onSuccess={handleAddClientSuccess}
              onCancel={() => setShowAddClientModal(false)}
              reexecuteQuery={reexecuteQuery}
              atCapacity={atCapacity}
            />
          </AnimatedModal>

          {/* Client Details Flyout */}
          {selectedClient && (
            <ClientDetailsFlyout
              visible={clientDetailsFlyoutVisible}
              closeFlyout={() => setClientDetailsFlyoutVisible(false)}
              client={selectedClient}
              bookableEventId={bookableEvent.id}
              onClientRemoved={handleClientRemoved}
            />
          )}
        </>
      )}
    </div>
  )
}

// This component will handle adding a client to the event
const AddClientToEvent = ({ bookableEventId, onSuccess, onCancel, reexecuteQuery, atCapacity }) => {
  const [selectedClient, setSelectedClient] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [{ fetching }, addClientToEvent] = useMutation(ADD_CLIENT_TO_BOOKABLE_EVENT_MUTATION)

  const handleClientSelect = (client) => {
    setSelectedClient(client)
  }

  const handleAddClient = async () => {
    if (!selectedClient) {
      setError("Please select a client first")
      return
    }

    setLoading(true)
    setError(null)

    try {
      // Determine if this is an existing client or a new one
      const variables = { bookableEventId }

      if (selectedClient.id) {
        // Existing client case
        variables.clientId = selectedClient.id
      } else {
        // New client case - we need to prepare the client params
        variables.clientParams = {
          firstName: selectedClient.firstName,
          lastName: selectedClient.lastName,
          email: selectedClient.email,
          phone: selectedClient.phone
        }
      }

      const result = await addClientToEvent(variables)

      if (result.data?.addClientToBookableEvent?.result === "success") {
        // First, explicitly refetch the registrants query to ensure fresh data
        if (reexecuteQuery) {
          reexecuteQuery({ requestPolicy: "network-only" })
        }
        onSuccess()
      } else {
        const errorMessage = result.data?.addClientToBookableEvent?.errors?.[0] || "Failed to add client to event"
        setError(errorMessage)
      }
    } catch (err) {
      setError(err.message || "Failed to add client to event")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="py-4">
      {/* Ensure dropdown appears above modal content with a higher z-index */}
      <div style={{ position: "relative", zIndex: 20000 }}>
        <ClientSelector defaultClient={null} onSelect={handleClientSelect} />
      </div>

      {atCapacity && (
        <Banner type="warning">
          Just a reminder: this event is at capacity, but you can add more clients if you want.
        </Banner>
      )}

      {error && <div className="mt-2 text-red">{error}</div>}

      <div className="mt-4 flex justify-end space-x-2">
        <Button type="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleAddClient} disabled={loading || !selectedClient || fetching}>
          {loading || fetching ? "Adding..." : "Add Client"}
        </Button>
      </div>
    </div>
  )
}

document.addEventListener("turbolinks:load", function () {
  // Quiz Completion
  if ($("body.quizzes").length && $(".computation-state").length) {
    const element = $(".computation-state")

    setTimeout(function () {
      element.find(".computing").fadeOut(function () {
        const resultsElement = element.find(".results")
        $(".image.hero-img img").replaceWith(resultsElement.data("image-tag"))
        resultsElement.fadeIn(1000)
      })
    }, 3000)
  }
})

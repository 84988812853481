import dayjs from "dayjs"
import React from "react"
import { useQuery } from "urql"

import { Label } from "../../shared/Inputs"
import { Link } from "../../shared/Links"
import Typography from "../../shared/Typography"

import AppointmentSummaryRow from "./AppointmentSummaryRow"

const UPCOMING_APPOINTMENTS_QUERY = `
  query UpcomingAppointments($clientId: ID, $userId: ID){
    upcomingAppointments(clientId: $clientId, userId: $userId) {
      id
      isVirtual
      startsAt
      endsAt
      amountCents
      timeZone
      instantActionToken
      isReviewed
      state
      hasEndorsements
      hasTextReview
      user {
        id
        name
        phone
        email
      }
      client {
        id
        firstName
        userId
        email
        phone
      }
      practice {
        id
        isStripeApproved
        user {
          id
          name
          profilePhotos {
            xLarge {
              jpeg
              webp
            }
            large {
              jpeg
              webp
            }
            medium {
              jpeg
              webp
            }
            small {
              jpeg
              webp
            }
            original {
              jpeg
              webp
            }
          }
        }
      }
      service {
        id
        name
        timeLength
        description
        photoUrl
        parent {
          id
          name
          description
          photoUrl
          timeLength
        }
        variations {
          id
          timeLength
          amountCents
        }
      }
      location {
        id
        name
        latitude
        longitude
        singleLine
        virtualLink
        virtualInstructions
        shortSingleLine
      }
      review {
        id
        review
        rating
        endorsedSkillIds
        videoUrl
        skillsForEndorsement {
          id
          name
        }
      }
    }
  }
`

export default function UpcomingAppointments({ client }) {
  const [{ data, fetching, error }] = useQuery({
    query: UPCOMING_APPOINTMENTS_QUERY,
    variables: { clientId: client.id },
    requestPolicy: "cache-and-network"
  })

  const upcomingAppointments = data?.upcomingAppointments

  return (
    <div>
      <Label>Upcoming appointments</Label>
      {error ? (
        <div>Error fetching upcoming appointments</div>
      ) : fetching ? (
        <img className="loading" src="/loading-spinner.png" width="40px" />
      ) : (
        <div>
          {upcomingAppointments.length > 0 ? (
            <div>
              {upcomingAppointments.map((upcomingAppointment, index) => (
                <div key={index}>
                  <p>{dayjs(upcomingAppointment.startsAt).format("dddd, MMMM DD, h:mm a")}</p>
                  <AppointmentSummaryRow
                    appointment={upcomingAppointment}
                    hideDivider={index === upcomingAppointments.length - 1}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <Typography variant="body">None</Typography>
              <Link href={`/portal/calendar?clientId=${client.id}#create-appointment`}>+ New appointment</Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

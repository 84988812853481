import React from "react"

import { ToastProvider } from "../../../contexts/ToastContext"
import GraphQLProvider from "../../../providers/GraphQLProvider"

const ProfileEditProvider = ({ children }) => (
  <ToastProvider>
    <GraphQLProvider>{children}</GraphQLProvider>
  </ToastProvider>
)

export default ProfileEditProvider

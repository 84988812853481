import React from "react"

import { ORDERED_DAYS } from "../../utils/utils"

const DisplayAvailableTimes = ({ locationOrService, className }) => (
  <div className={className}>
    {ORDERED_DAYS.map((day) => {
      const availableTimesForDay = locationOrService.availableTimes[day]
      if (!availableTimesForDay) return null
      if (availableTimesForDay.enabled === false) return null
      if (!availableTimesForDay.startsAt || !availableTimesForDay.endsAt) return null
      return (
        <div key={`location-${locationOrService.id}-${day}`} className="flex items-center justify-between">
          <span>{day}</span>
          <span>
            {availableTimesForDay.startsAt.replace(/ /g, "").toLowerCase()} -{" "}
            {availableTimesForDay.endsAt.replace(/ /g, "").toLowerCase()}
          </span>
        </div>
      )
    })}
  </div>
)

export default DisplayAvailableTimes

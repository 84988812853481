import NiceModal from "@ebay/nice-modal-react"
import React, { useState } from "react"
import { useMutation } from "urql"

import { useToast } from "../../contexts/ToastContext"

import { Button } from "./Buttons"
import { NewModal } from "./Modal"
import Typography from "./Typography"

const DELETE_CLIENT_MUTATION = `
  mutation DeleteClient($clientId: ID!) {
    deleteClient(clientId: $clientId) {
      result
      errors
    }
  }
`

export default function DeleteClientModal({ client, deleteModalId, onSuccess }) {
  const { showToast } = useToast()
  const [, deleteClient] = useMutation(DELETE_CLIENT_MUTATION)

  const [loading, setLoading] = useState(false)

  return (
    <NewModal id={deleteModalId} header="Delete client now" showFooter={false}>
      <div className="pb-24">
        <Typography variant="body" as="p">
          This will remove the client{" "}
          <span className="font-semibold">
            {client?.firstName} {client?.lastName}
          </span>{" "}
          from your account now. This action can&apos;t be undone.
        </Typography>
        <div className="absolute inset-x-0 bottom-0 flex justify-end border-t border-gray-light bg-white p-8 sm:bottom-12">
          <Button
            type="tertiary"
            className="mr-4"
            onClick={() => {
              NiceModal.remove(deleteModalId)
            }}>
            No, don&apos;t delete
          </Button>
          <Button
            type="destructive"
            loading={loading}
            onClick={() => {
              setLoading(true)
              deleteClient({ clientId: client.id }).then((result) => {
                if (result.error || result.data.deleteClient.errors) {
                  showToast({
                    content: `Payment could not be processed: ${result.error || result.data.editClient.errors}`,
                    type: "error"
                  })
                  setLoading(false)
                } else {
                  setLoading(false)
                  NiceModal.remove(deleteModalId)
                  showToast("Client deleted successfully. ")
                  onSuccess()
                }
              })
            }}>
            Yes, delete
          </Button>
        </div>
      </div>
    </NewModal>
  )
}

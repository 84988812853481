import React from "react"

import { Label, Select } from "../../components/shared/Inputs"

import LocationPlacesSelect from "./LocationPlacesSelect"

const SELECT_OPTIONS = []
for (let i = 1; i <= 50; i++) {
  SELECT_OPTIONS.push({ value: i.toString(), label: i === 1 ? `1 mile` : `${i} miles` })
}

const HomeLocationFields = ({ location, setLocation, validationErrors }) => (
  <>
    <LocationPlacesSelect
      setLocation={setLocation}
      location={location}
      label="Your home address"
      validationError={validationErrors.fullStreetAddress}
    />
    <div>
      <Label htmlFor="travel-distance">How far are you willing to commute to a clients’ home?</Label>
      <Select
        id="travel-distance"
        value={location.travelDistance || ""}
        onChange={(e) => setLocation({ ...location, travelDistance: e.target.value })}
        options={SELECT_OPTIONS}
        defaultOption="Select distance"
        validationError={validationErrors.travelDistance}
      />
    </div>
  </>
)

export default HomeLocationFields

import React from "react"

import GraphQLProvider from "../providers/GraphQLProvider"

import ClientUpload from "./shared/ClientUpload"

export default function ClientUploadButton(props) {
  return (
    <GraphQLProvider>
      <ClientUpload {...props} />
    </GraphQLProvider>
  )
}

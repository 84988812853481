import React from "react"

const PaymentStatusBox = ({ appointment }) => {
  const { clientPaymentStatus: status, defaultPaymentMethodData: paymentMethod } = appointment
  if (status === "Free") return <></>

  let statusMessage = {
    Paid: "Your payment has been processed successfully.",
    Failed: "Your payment has failed. Please retry or add a new payment method.",
    Pending: "Your payment method will be charged after your appointment.",
    "Card needed": "Please add a payment method before your appointment.",
    "Card needed past due": "Your payment is past due. Please add a credit card to pay for your appointment.",
    "Past due": "Your payment is past due. Please add a credit card or retry the card on file"
  }[status]

  if ((status === "Past due" || status === "Pending") && paymentMethod) {
    statusMessage += ` (${paymentMethod.brand} ending in ${paymentMethod.last4})`
  }

  return (
    <div className={`my-4 rounded p-4 ${status !== "Paid" && status !== "Pending" ? "bg-red-light" : "bg-offwhite"}`}>
      {status !== "Paid" && status !== "Pending" && <i className="icon-warning-icon mr-1" />}
      {statusMessage}
    </div>
  )
}

export default PaymentStatusBox

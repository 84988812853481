import React from "react"

import PersonalInformationPractice from "./PersonalInformationPractice"
import ProfileEditProvider from "./ProfileEditProvider"

export default function PracticeInformationPage({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <PersonalInformationPractice {...props} />
    </ProfileEditProvider>
  )
}

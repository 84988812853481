import clsx from "clsx"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const InputField = (
  {
    kind = "form",
    label,
    id,
    type,
    placeholder,
    value,
    defaultValue,
    onChange,
    onKeyUp,
    autoComplete,
    style,
    disabled
  },
  ref
) => (
  <>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      ref={ref}
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onKeyUp={onKeyUp}
      autoComplete={autoComplete}
      className={clsx(
        "m-0 w-full rounded border border-gray bg-white shadow-none outline-0 focus:border-teal active:border-teal",
        { "border-none px-0 py-0": disabled },
        { "px-4 py-3.5": kind === "form" && !disabled },
        { "px-8 py-4": kind === "search" && !disabled }
      )}
      style={style}
      disabled={disabled}
    />
  </>
)

InputField.propTypes = {
  kind: PropTypes.oneOf(["form", "search"]),
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  autoComplete: PropTypes.string,
  style: PropTypes.object
}

export default forwardRef(InputField)

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useMutation } from "urql"

const appStore = require("../../../../../public/apple-store.png")
const googlePlaystore = require("../../../../../public/google-playstore.png")
const iphone13 = require("../../../../../public/iPhone-13.png")
const mobileAppStore = require("../../../../assets/images/app-store-badge.png")
const mobileConsumerIphone = require("../../../../assets/images/consumer-iphone-mobile.png")
const consumerIphone = require("../../../../assets/images/consumer-iphone.png")
const mobileIphone13 = require("../../../../assets/images/iphone-screenshot.png")
const mobileGooglePlaystore = require("../../../../assets/images/playstore.png")

const SEND_TEXT = `
  mutation DownloadMobileApp($type: String!) {
    sendText (type: $type) {
      result
      errors
    }
  }
`

const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    margin-top: 0;
    height: 90vh;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
`
const TextWrapper = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  display: block;
  margin: auto;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  color: #0b3954;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
  }
`

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 21px;
  color: #0b3954;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 24px;
  }
`

const SubHeader = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: #55778a;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media only screen and (max-width: 768px) {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 24px;
  }
`

const Helper = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #55778a;
  margin-top: 8px;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 12px;
  }
`

const HelperLink = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #55778a;
  margin-top: 8px;
  margin-bottom: 24px;
  text-decoration: underline;
  color: #0d9da4;

  &:hover {
    cursor: pointer;
  }
`

const IconWrapper = styled.div`
  display: flex;

  a {
    height: 100%;
  }
`

const Logo = styled.div`
  max-width: 48px;
  max-height: 52px;
  margin-right: 16px;

  @media only screen and (max-width: 768px) {
    max-width: 142px;
    max-height: 40px;
  }
`

const ImageWrapper = styled.div`
  display: block;
  margin: auto;

  @media only screen and (max-width: 768px) {
    margin: 0;
    margin-top: 50px;
  }
`

export default function DownloadMobileApp({ isPro = true, contextHeader }) {
  const [, sendText] = useMutation(SEND_TEXT)
  const [loading, setLoading] = useState(false)
  const [dimension, updateDimention] = useState({ width: 0, height: 0 })

  const sendTextMessage = () => {
    setLoading(true)
    sendText({ type: "download_mobile_app" }).then((res) => {
      if (res.data.sendText.result === "success") {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    updateDimention({
      ...dimension,
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [window.innerWidth])

  return (
    <>
      {dimension?.width < 768 ? (
        <Wrapper>
          <TextWrapper>
            {contextHeader && <Subtitle>{contextHeader}</Subtitle>}
            {isPro ? (
              <div>
                <Subtitle>Your profile is live!</Subtitle>
                <Helper>What's next?</Helper>
                <Subtitle>Get it now and discover over 3000+ trusted wellness practitioners.</Subtitle>
                <p>Install the practitioner app so you can manage your bookings.</p>
              </div>
            ) : (
              <div>
                <SubHeader>Next Step</SubHeader>
                <Subtitle>Download the Heal.me App</Subtitle>
                <p>Get it now and discover over 3000+ trusted wellness practitioners.</p>
              </div>
            )}

            <Helper>Available on iPhone and Android.</Helper>
            <IconWrapper>
              <Logo>
                <a href="https://apps.apple.com/app/id1599744549">
                  <img src={mobileAppStore} />
                </a>
              </Logo>
              <Logo>
                <a href="https://play.google.com/store/apps/details?id=me.heal.mobile">
                  <img src={mobileGooglePlaystore} />
                </a>
              </Logo>
            </IconWrapper>
          </TextWrapper>
          <ImageWrapper>{isPro ? <img src={mobileIphone13} /> : <img src={mobileConsumerIphone} />}</ImageWrapper>
        </Wrapper>
      ) : (
        <Wrapper>
          <TextWrapper>
            {contextHeader && <Subtitle>{contextHeader}</Subtitle>}
            <SubHeader>Next Step</SubHeader>
            <Title>Check your phone.</Title>
            <Subtitle>We've sent you a text message with a link to download the mobile app.</Subtitle>
            {isPro ? (
              <Subtitle>Get it now and start managing your bookings.</Subtitle>
            ) : (
              <Subtitle>Get it now and discover over 3000+ trusted wellness practitioners.</Subtitle>
            )}

            <Helper>Available on iPhone and Android.</Helper>
            <IconWrapper>
              <Logo>
                <img src={appStore} />
              </Logo>
              <Logo>
                <img src={googlePlaystore} />
              </Logo>
            </IconWrapper>
            {isPro ? (
              <HelperLink onClick={() => sendTextMessage()}>
                {loading ? "Sending..." : "Didn't get it? Send again."}
              </HelperLink>
            ) : (
              <a href="/practitioner-search">Or continue with the web version</a>
            )}
          </TextWrapper>
          <ImageWrapper>{isPro ? <img src={iphone13} /> : <img src={consumerIphone} />}</ImageWrapper>
        </Wrapper>
      )}
    </>
  )
}

import { InformationCircleIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import React, { useEffect, useMemo, useState } from "react"
import { twMerge } from "tailwind-merge"

import { useManualBooking } from "../../../contexts/ManualBookingContext"
import { usePractice } from "../../../contexts/PracticeContext"
import { networkOnlyUrqlClient } from "../../../utils/utils"
import DatePicker from "../../shared/DatePicker"
import HealMeTooltip from "../../shared/HealMeTooltip"
import { BASE_INPUT_CLASSNAMES, CheckBox, Label, Select } from "../../shared/Inputs"
import { Banner } from "../../shared/Layout"

const RECURRING_TIMES_QUERY = `
  query RecurringTimes($practiceId: ID!, $startsAt: String!, $recurrencePattern: String!, $duration: Int!) {
    recurringTimes(practiceId: $practiceId, startsAt: $startsAt, recurrencePattern: $recurrencePattern, duration: $duration) {
      startsAt
      displayTime
      conflicts { id name type timeRange }
    }
  }
`

const seriesEndOptions = [
  { value: "after", label: "Series ends after" },
  { value: "on", label: "Series ends on" }
]

const getDateInNMonths = (n) => {
  const now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + n
  const day = now.getDate()

  if (month > 11) {
    month = month % 12
    year++
  }

  let oneMonthLater = new Date(year, month, day, 23, 59, 59)

  // Checking if the month has wrapped, which indicates that the day was invalid for the next month
  // For example, if the current date is January 31st, then one month later will be February 28th/29th
  if (oneMonthLater.getMonth() !== month) {
    oneMonthLater = new Date(year, month, 0, 23, 59, 59) // The 0th day of a month will be the last day of the previous month
  }

  return oneMonthLater
}

const ordinals = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "last"
}

const getDayOfWeekAndDayInMonth = (dateObj) => {
  const dayOfWeek = dateObj.toLocaleDateString("en-US", { weekday: "short" }).toUpperCase().substring(0, 2)
  const dayInMonth = dateObj.getDate()
  let weekOfMonth = Math.ceil(dayInMonth / 7)
  weekOfMonth = weekOfMonth === 5 ? -1 : weekOfMonth

  return `${weekOfMonth}${dayOfWeek}`
}

const getDayOfWeekAndDayInMonthOrdinal = (dateObj) => {
  const dayOfWeek = dateObj.toLocaleDateString("en-US", { weekday: "long" })
  const dayInMonth = dateObj.getDate()
  const weekOfMonth = Math.ceil(dayInMonth / 7)

  return `${ordinals[weekOfMonth]} ${dayOfWeek}`
}

const RecurrenceSelect = ({ className, disabled }) => {
  const {
    startsAt,
    endsAt,
    setRecurringStartTimes,
    setRecurrencePattern,
    services,
    recurringAppointmentError,
    setRecurringAppointmentError,
    appointment,
    client
  } = useManualBooking()
  const { practice } = usePractice()

  const dayOfWeek = new Date(startsAt).toLocaleString("default", { weekday: "long" })
  const dayOfWeekWithOrdinal = getDayOfWeekAndDayInMonthOrdinal(new Date(startsAt))

  const options = [
    { value: "", label: "Does not repeat" },
    { value: "FREQ=DAILY", label: "Daily" },
    { value: "FREQ=WEEKLY", label: `Weekly on ${dayOfWeek}` },
    { value: "FREQ=WEEKLY;INTERVAL=2", label: `Every 2 weeks on ${dayOfWeek}` },
    {
      value: `FREQ=MONTHLY;BYMONTHDAY=${new Date(startsAt).getDate()}`,
      label: `Monthly on the ${new Date(startsAt).getDate()}th`
    },
    {
      value: `FREQ=MONTHLY;BYDAY=${getDayOfWeekAndDayInMonth(new Date(startsAt)).replace("4", "-1")}`,
      label: dayOfWeekWithOrdinal.startsWith("fourth")
        ? `Monthly on the last ${dayOfWeek}`
        : `Monthly on the ${dayOfWeekWithOrdinal}`
    },
    { value: "custom", label: "Custom..." }
  ]

  const currentServiceOpenPackage = client?.openPackages?.find((p) => p.serviceId === services?.[0]?.service.id)
  const openPackage = !!currentServiceOpenPackage
  const isNewPackage = services?.[0]?.service?.package && !appointment && !openPackage
  let defaultFrequency = appointment ? appointment.recurrenceFrequency : isNewPackage ? "FREQ=WEEKLY" : ""
  // if not one of the "options" var, set to custom
  if (options.map((option) => option.value).indexOf(defaultFrequency) === -1) {
    defaultFrequency = "custom"
  }

  const packageData = currentServiceOpenPackage || appointment?.package
  const maxOccurrences = isNewPackage
    ? services?.[0]?.service?.numberOfSessions
    : packageData?.sessionsRemaining
    ? Math.min(25, packageData.sessionsRemaining)
    : 25
  const numberOfOccurrenceOptions = Array(maxOccurrences ? maxOccurrences - 1 : 0)
    .fill(0)
    .map((_, i) => ({ value: i + 2, label: (i + 2).toString() }))

  const getNumberOfSessions = (appointment, isNewPackage, service, openPackage, packageData) => {
    if (appointment) {
      return appointment.recurrenceCount
    } else if (isNewPackage) {
      return service.service.numberOfSessions
    } else if (openPackage) {
      return packageData?.sessionsRemaining || 10
    } else {
      return 10
    }
  }

  const removedRecurringTimes = useMemo(() => [], [])

  const [frequency, setFrequency] = useState(defaultFrequency)
  const [seriesEnd, setSeriesEnd] = useState(appointment?.recurrenceUntil ? "on" : "after")
  const [numberOfOccurrences, setNumberOfOccurrences] = useState(
    getNumberOfSessions(appointment, isNewPackage, services?.[0], openPackage, packageData)
  )
  const [endsOnDate, setEndsOnDate] = useState(
    appointment?.recurrenceUntil ? new Date(appointment.recurrenceUntil) : getDateInNMonths(1)
  )
  const [recurringTimes, setRecurringTimes] = useState([])

  const [customRecurrence, setCustomRecurrence] = useState({
    repeatEveryValue: 1,
    repeatEveryUnit: "weekly",
    repeatOn: {
      SU: dayOfWeek === "Sunday",
      MO: dayOfWeek === "Monday",
      TU: dayOfWeek === "Tuesday",
      WE: dayOfWeek === "Wednesday",
      TH: dayOfWeek === "Thursday",
      FR: dayOfWeek === "Friday",
      SA: dayOfWeek === "Saturday"
    }
  })

  const handleCustomDayChange = (day) => {
    setCustomRecurrence((prevState) => {
      const newRepeatOn = {
        ...prevState.repeatOn,
        [day]: !prevState.repeatOn[day]
      }
      const newCustomRecurrence = { ...prevState, repeatOn: newRepeatOn }
      return newCustomRecurrence
    })
  }

  const handleCustomRecurrenceChange = (key, value) => {
    setCustomRecurrence((prevState) => {
      let newCustomRecurrence = { ...prevState, [key]: value }
      return newCustomRecurrence
    })
  }

  const getPattern = (frequency, seriesEnd, numberOfOccurrences, endsOnDate) => {
    if (frequency === "custom") {
      if (!customRecurrence.repeatEveryValue || !customRecurrence.repeatEveryUnit) {
        return null
      }
      let customPattern = `FREQ=${customRecurrence.repeatEveryUnit.toUpperCase()};INTERVAL=${
        customRecurrence.repeatEveryValue
      }`
      if (customRecurrence.repeatEveryUnit === "weekly") {
        const daysActive = Object.entries(customRecurrence.repeatOn)
          .filter(([, isChecked]) => isChecked)
          .map(([day]) => day)
          .join(",")
        if (daysActive) {
          customPattern += `;BYDAY=${daysActive}`
        }
      }
      customPattern += seriesEnd === "after" ? `;COUNT=${numberOfOccurrences};` : `;UNTIL=${endsOnDate.toISOString()};`
      return customPattern
    }
    return frequency
      ? [frequency, seriesEnd === "after" ? `COUNT=${numberOfOccurrences}` : `UNTIL=${endsOnDate.toISOString()}`].join(
          ";"
        )
      : null
  }

  useEffect(() => {
    onChange(frequency, seriesEnd, numberOfOccurrences, endsOnDate)
  }, [startsAt, customRecurrence])
  useEffect(() => {
    const newNumberOfSessions = getNumberOfSessions(appointment, isNewPackage, services?.[0], openPackage, packageData)
    setNumberOfOccurrences(newNumberOfSessions)
    onChange(frequency, seriesEnd, newNumberOfSessions, endsOnDate)
  }, [services])

  const conflictsCount = recurringTimes.filter((recurringTime) => recurringTime.conflicts.length > 0).length

  const onChange = (frequency, seriesEnd, numberOfOccurrences, endsOnDate) => {
    const pattern = getPattern(frequency, seriesEnd, numberOfOccurrences, endsOnDate)
    fetchRecurringTimes(pattern)
    setRecurrencePattern(pattern)
  }

  const fetchRecurringTimes = (recurrencePattern) => {
    if (!recurrencePattern) {
      setRecurringStartTimes(null)
      setRecurringTimes([])
      return
    }

    const duration = dayjs(endsAt).diff(dayjs(startsAt), "minutes")

    if (!duration || !recurrencePattern || appointment) return

    networkOnlyUrqlClient
      .query(RECURRING_TIMES_QUERY, {
        practiceId: practice.id,
        startsAt: startsAt,
        recurrencePattern,
        duration
      })
      .toPromise()
      .then((res) => {
        if (res.data?.recurringTimes) {
          const recurringTimes = res.data.recurringTimes.map((recurringTime) => ({
            ...recurringTime,
            shouldBook: recurringTime.conflicts.length === 0
          }))
          updateState(recurringTimes)
          const lastRecurringTime = recurringTimes[recurringTimes.length - 1]
          const nonConflictingLength = recurringTimes.filter((time) => time.conflicts.length === 0).length

          let error =
            nonConflictingLength > maxOccurrences
              ? `You can create at most ${maxOccurrences} appointments at once. You are currently attempting to create ${nonConflictingLength} appointments.`
              : lastRecurringTime && new Date(lastRecurringTime.startsAt) > getDateInNMonths(12)
              ? "You can create appointments at most one year into the future."
              : null
          if (error) {
            error += " Please select a different frequency or end date."
            setRecurringAppointmentError(error)
          } else {
            setRecurringAppointmentError(null)
          }
        } else {
          console.error("Error fetching recurring times", res)
        }
      })
  }

  const updateState = (newRecurringTimes) => {
    setRecurringTimes(newRecurringTimes)
    setRecurringStartTimes(newRecurringTimes.filter((time) => time.shouldBook).map((time) => time.startsAt))
  }

  return (
    <div className={twMerge("flex flex-col gap-4", disabled ? "pointer-events-none" : "", className)}>
      <div>
        <Label htmlFor="recurring-select">Recurring</Label>
        <Select
          disabled={disabled || maxOccurrences < 2}
          id="recurring-select"
          options={options}
          value={frequency}
          onChange={(e) => {
            setFrequency(e.target.value)
            onChange(e.target.value, seriesEnd, numberOfOccurrences, endsOnDate)
          }}
        />
      </div>
      {frequency === "custom" && !disabled && (
        <div className="flex flex-col">
          <div>
            <Label>Repeat every</Label>
            <div className="flex flex-row gap-2">
              <input
                className={twMerge("w-1/2", BASE_INPUT_CLASSNAMES)}
                type="number"
                min="1"
                value={customRecurrence.repeatEveryValue}
                onChange={(e) => handleCustomRecurrenceChange("repeatEveryValue", parseInt(e.target.value))}
              />
              <Select
                className="w-1/2"
                options={[
                  { value: "daily", label: "day(s)" },
                  { value: "weekly", label: "week(s)" }
                ]}
                value={customRecurrence.repeatEveryUnit}
                onChange={(e) => handleCustomRecurrenceChange("repeatEveryUnit", e.target.value)}
              />
            </div>
          </div>
          {customRecurrence.repeatEveryUnit === "weekly" && (
            <div className="mt-4 flex justify-between">
              {["SU", "MO", "TU", "WE", "TH", "FR", "SA"].map((day) => (
                <CheckBox
                  key={day}
                  label={day}
                  className={"gap-1.5"}
                  checked={customRecurrence.repeatOn[day]}
                  onChange={() => handleCustomDayChange(day)}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {frequency && (
        <div className="flex items-center gap-2">
          <Select
            disabled={disabled || maxOccurrences < 2}
            name="series-end"
            className="w-1/2"
            options={seriesEndOptions}
            value={seriesEnd}
            onChange={(e) => {
              setSeriesEnd(e.target.value)
              onChange(frequency, e.target.value, numberOfOccurrences, endsOnDate)
            }}
          />
          {seriesEnd === "after" ? (
            <>
              <Select
                disabled={disabled || maxOccurrences < 2}
                name="number-of-occurrences"
                options={numberOfOccurrenceOptions}
                value={numberOfOccurrences}
                onChange={(e) => {
                  setNumberOfOccurrences(e.target.value)
                  onChange(frequency, seriesEnd, e.target.value, endsOnDate)
                }}
              />
              <span>sessions</span>
            </>
          ) : (
            <DatePicker
              disabled={disabled}
              selected={endsOnDate}
              onChange={(date) => {
                date.setHours(23, 59, 59)
                setEndsOnDate(date)
                onChange(frequency, seriesEnd, numberOfOccurrences, date)
              }}
            />
          )}
        </div>
      )}

      {recurringTimes.length > 0 && frequency && (
        <>
          {recurringAppointmentError ? (
            <Banner type="error">{recurringAppointmentError}</Banner>
          ) : (
            <Banner type={conflictsCount > 0 ? "warning" : "info"}>
              {conflictsCount > 0 ? (
                <>
                  <div className="font-bold">
                    We found <span className="underline">{conflictsCount} times</span> that are already booked/filled.
                  </div>
                  <div>We skipped these, but you can include them.</div>
                </>
              ) : (
                <div className="font-bold">Here are the dates and times included in this recurring series:</div>
              )}
              <ul className="mt-4 flex flex-col gap-2">
                {recurringTimes.map((recurringTime) => {
                  const hasConflicts = recurringTime.conflicts.length > 0

                  return (
                    <li key={recurringTime.startsAt} className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <div
                          className={[
                            hasConflicts && "text-red",
                            !recurringTime.shouldBook && "font-bold line-through"
                          ].join(" ")}>
                          {dayjs(recurringTime.startsAt).format("MM/DD/YYYY [@] h:mma")}
                        </div>
                        {hasConflicts && (
                          <>
                            <InformationCircleIcon className="h-5 w-5" data-tooltip-id={recurringTime.startsAt} />
                            <HealMeTooltip
                              id={recurringTime.startsAt}
                              render={() => (
                                <div className="flex flex-col gap-4">
                                  {recurringTime.conflicts.map((conflict) => (
                                    <div key={`${recurringTime.startsAt}-${conflict.id}`}>
                                      <div className="font-bold">{conflict.name}</div>
                                      <div>{conflict.timeRange}</div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </div>
                      {hasConflicts && (
                        <button
                          className="underline"
                          onClick={() => {
                            const newRecurringTimes = [...recurringTimes]
                            const time = newRecurringTimes.find((time) => time.startsAt === recurringTime.startsAt)
                            if (!time.shouldBook) {
                              const finalTime = newRecurringTimes.pop()
                              removedRecurringTimes.push(finalTime)
                            } else {
                              const nextTime = removedRecurringTimes.pop()
                              newRecurringTimes.push(nextTime)
                            }
                            time.shouldBook = !time.shouldBook
                            updateState(newRecurringTimes)
                          }}>
                          {recurringTime.shouldBook ? "Remove" : "Include"}
                        </button>
                      )}
                    </li>
                  )
                })}
              </ul>
            </Banner>
          )}
        </>
      )}
    </div>
  )
}

export default RecurrenceSelect

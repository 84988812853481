import { LinkIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import { CheckBox, EditableValue, Input, InputWithLabel } from "../../components/shared/Inputs"
import { Box, Divider } from "../../components/shared/Layout"
import PlacesAutocomplete from "../../components/shared/PlacesAutocomplete"
import { useToast } from "../../contexts/ToastContext"
import { UPDATE_PRACTICE_DETAILS_MUTATION } from "../../utils/mutations"
import { stripUSAFromString } from "../../utils/utils"

export default function PersonalInformationPractice({
  practice,
  allLanguages,
  allWorkWiths,
  allAgeWorkWiths,
  userIsWebsitePlan
}) {
  const [practiceDetails, setPracticeDetails] = useState(practice)
  const [openField, setOpenField] = useState(null)
  const [lastSavedDetailsValues, setLastSavedDetailsValues] = useState(practiceDetails)
  const [{ fetching }, updatePracticeDetails] = useMutation(UPDATE_PRACTICE_DETAILS_MUTATION)

  const { showToast } = useToast()

  const languageIds = practiceDetails.languages.map((l) => Number(l.id))
  const ageWorkWithIds = practiceDetails.ageWorkWiths.map((a) => Number(a.id))
  const workWithIds = practiceDetails.workWiths.map((w) => Number(w.id))
  const location = practiceDetails.location

  const onOpened = (fieldName) => {
    setOpenField(fieldName)
  }

  const onSave = () => {
    const variables = {
      locationId: practiceDetails.locationId,
      languageIds: practiceDetails.languages?.map((l) => l.id),
      ageWorkWithIds: practiceDetails.ageWorkWiths?.map((a) => a.id),
      workWithIds: practiceDetails.workWiths?.map((w) => w.id),
      website: practiceDetails.website,
      facebook: practiceDetails.facebook,
      instagram: practiceDetails.instagram,
      twitter: practiceDetails.twitter,
      linkedin: practiceDetails.linkedin,
      tiktok: practiceDetails.tiktok,
      youtube: practiceDetails.youtube
    }
    const setVariables = Object.fromEntries(Object.entries(variables).filter(([, v]) => v !== null))
    updatePracticeDetails(setVariables).then((result) => {
      if (result.data?.updatePracticeDetails) {
        setPracticeDetails(result.data.updatePracticeDetails.practice)
        setLastSavedDetailsValues(result.data.updatePracticeDetails.practice)
        setOpenField(null)
        showToast("Changes saved")
      } else {
        console.error(result) // eslint-disable-line no-console
        showToast({ type: "error", content: `Error saving changes: ${result.error}` })
      }
    })
  }

  const socialsValue = [
    practiceDetails.facebook,
    practiceDetails.instagram,
    practiceDetails.twitter,
    practiceDetails.linkedin,
    practiceDetails.tiktok,
    practiceDetails.youtube
  ]
    .filter((link) => link)
    .map((link) => link.replace(/(^\w+:|^)\/\//, ""))
    .join(", ")

  return (
    <>
      <div className="w-[600px] md:w-full">
        <Box className="rounded-t-none border-t-0">
          <EditableValue
            name="Location"
            hint="Where is your practice located?"
            value={location}
            disabled={fetching || (openField && openField !== "Location")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setPracticeDetails(lastSavedDetailsValues)
            }}>
            <PlacesAutocomplete
              apiKey={process.env.GOOGLE_MAPS_API_KEY}
              name="Location"
              types={["locality", "administrative_area_level_3"]}
              onPlaceSelected={(place) => {
                setPracticeDetails((values) => ({
                  ...values,
                  location: stripUSAFromString(place.formatted_address),
                  locationId: place.place_id
                }))
              }}
              defaultValue={location ? stripUSAFromString(location) : ""}
            />
          </EditableValue>
          <Divider />
          <EditableValue
            name="Languages I speak"
            hint="Set which languages you speak."
            saveDisabled={practiceDetails.languages === lastSavedDetailsValues.languages}
            value={practiceDetails.languages.map((l) => l.name).join(", ")}
            disabled={fetching || (openField && openField !== "Languages I speak")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setPracticeDetails(lastSavedDetailsValues)
            }}>
            <div className="grid grid-cols-2">
              {allLanguages.map((language) => (
                <CheckBox
                  key={language.id}
                  label={language.name}
                  checked={languageIds.includes(language.id)}
                  onChange={() =>
                    languageIds.includes(language.id)
                      ? setPracticeDetails({
                          ...practiceDetails,
                          languages: practiceDetails.languages.filter((l) => Number(l.id) !== Number(language.id))
                        })
                      : setPracticeDetails({
                          ...practiceDetails,
                          languages: [...practiceDetails.languages, language]
                        })
                  }
                />
              ))}
            </div>
          </EditableValue>
          <Divider />
          <EditableValue
            name="Client ages I work with"
            hint="Set which age groups you work with."
            saveDisabled={practiceDetails.ageWorkWiths === lastSavedDetailsValues.ageWorkWiths}
            value={practiceDetails.ageWorkWiths.map((a) => a.name).join(", ")}
            disabled={fetching || (openField && openField !== "Client ages I work with")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setPracticeDetails(lastSavedDetailsValues)
            }}>
            <div className="flex flex-col">
              {allAgeWorkWiths.map((ageWorkWith) => (
                <CheckBox
                  key={ageWorkWith.id}
                  label={ageWorkWith.name}
                  checked={ageWorkWithIds.includes(ageWorkWith.id)}
                  onChange={() =>
                    ageWorkWithIds.includes(ageWorkWith.id)
                      ? setPracticeDetails({
                          ...practiceDetails,
                          ageWorkWiths: practiceDetails.ageWorkWiths.filter(
                            (a) => Number(a.id) !== Number(ageWorkWith.id)
                          )
                        })
                      : setPracticeDetails({
                          ...practiceDetails,
                          ageWorkWiths: [...practiceDetails.ageWorkWiths, ageWorkWith]
                        })
                  }
                />
              ))}
            </div>
          </EditableValue>
          <Divider />
          <EditableValue
            name="Client types I work with"
            hint="Set which types of clients you work with."
            saveDisabled={practiceDetails.workWiths === lastSavedDetailsValues.workWiths}
            value={practiceDetails.workWiths.map((w) => w.name).join(", ")}
            disabled={fetching || (openField && openField !== "Client types I work with")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setPracticeDetails(lastSavedDetailsValues)
            }}>
            <div className="flex flex-col">
              {allWorkWiths.map((workWith) => (
                <CheckBox
                  key={workWith.id}
                  label={workWith.name}
                  checked={workWithIds.includes(workWith.id)}
                  onChange={() =>
                    workWithIds.includes(workWith.id)
                      ? setPracticeDetails({
                          ...practiceDetails,
                          workWiths: practiceDetails.workWiths.filter((w) => Number(w.id) !== Number(workWith.id))
                        })
                      : setPracticeDetails({
                          ...practiceDetails,
                          workWiths: [...practiceDetails.workWiths, workWith]
                        })
                  }
                />
              ))}
            </div>
          </EditableValue>
          <Divider />
          {userIsWebsitePlan && (
            <>
              <EditableValue
                name="Website"
                hint="Optional: Add your website for clients to learn more about you."
                value={practiceDetails.website?.replace(/(^\w+:|^)\/\//, "")}
                saveDisabled={practiceDetails.website === lastSavedDetailsValues.website}
                disabled={fetching || (openField && openField !== "Website")}
                onOpened={onOpened}
                onSave={onSave}
                onCancel={() => {
                  setOpenField(null)
                  setPracticeDetails(lastSavedDetailsValues)
                }}>
                <div className="relative">
                  <Input
                    className="pl-10"
                    name="Website"
                    explanatorySubtext="Copy and paste your website link"
                    value={practiceDetails.website || ""}
                    onChange={(e) => setPracticeDetails((values) => ({ ...values, website: e.target.value }))}
                  />
                  <LinkIcon className="absolute left-2.5 top-2 h-6 w-6 text-gray-dark" />
                </div>
              </EditableValue>
              <Divider />
            </>
          )}
          <EditableValue
            name="Social accounts"
            hint="Optional: Add Instagram, Facebook, TikTok, LinkedIn, Youtube or Twitter/X account(s)."
            value={socialsValue}
            saveDisabled={
              practiceDetails.facebook === lastSavedDetailsValues.facebook &&
              practiceDetails.instagram === lastSavedDetailsValues.instagram &&
              practiceDetails.tiktok === lastSavedDetailsValues.tiktok &&
              practiceDetails.linkedin === lastSavedDetailsValues.linkedin &&
              practiceDetails.twitter === lastSavedDetailsValues.twitter &&
              practiceDetails.youtube === lastSavedDetailsValues.youtube
            }
            disabled={fetching || (openField && openField !== "Social accounts")}
            onOpened={onOpened}
            onSave={onSave}
            onCancel={() => {
              setOpenField(null)
              setPracticeDetails(lastSavedDetailsValues)
            }}>
            <div className="flex flex-col gap-4">
              <InputWithLabel
                label="Instagram"
                name="Instagram"
                explanatorySubtext="Copy and paste your Instagram profile link"
                value={practiceDetails.instagram || ""}
                onChange={(e) => setPracticeDetails((values) => ({ ...values, instagram: e.target.value }))}
              />
              <InputWithLabel
                label="Facebook"
                name="Facebook"
                explanatorySubtext="Copy and paste your Facebook profile link"
                value={practiceDetails.facebook || ""}
                onChange={(e) => setPracticeDetails((values) => ({ ...values, facebook: e.target.value }))}
              />
              <InputWithLabel
                label="TiKTok"
                name="TikTok"
                explanatorySubtext="Copy and paste your TikTok profile link"
                value={practiceDetails.tiktok || ""}
                onChange={(e) => setPracticeDetails((values) => ({ ...values, tiktok: e.target.value }))}
              />
              <InputWithLabel
                label="LinkedIn"
                name="LinkedIn"
                explanatorySubtext="Copy and paste your LinkedIn profile link"
                value={practiceDetails.linkedin || ""}
                onChange={(e) => setPracticeDetails((values) => ({ ...values, linkedin: e.target.value }))}
              />
              <InputWithLabel
                label="Twitter / X"
                name="Twitter"
                explanatorySubtext="Copy and paste your Twitter profile link"
                value={practiceDetails.twitter || ""}
                onChange={(e) => setPracticeDetails((values) => ({ ...values, twitter: e.target.value }))}
              />
              <InputWithLabel
                label="YouTube"
                name="YouTube"
                explanatorySubtext="Copy and paste your YouTube profile link"
                value={practiceDetails.youtube || ""}
                onChange={(e) => setPracticeDetails((values) => ({ ...values, youtube: e.target.value }))}
              />
            </div>
          </EditableValue>
        </Box>
      </div>
    </>
  )
}

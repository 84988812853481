import React from "react"
import { useQuery } from "urql"

import { Link } from "../../components/shared/Links"
import LoadingSpinner from "../../components/shared/LoadingSpinner"

import SendIntakeFormButton from "./SendIntakeFormButton"

const CLIENT_HAS_INTAKE_FORM_QUERY = `
  query IntakeForm($clientId: ID!) {
    client(clientId: $clientId) {
      id
      intakeFormSubmitted
      intakeLastSentAt
    }
  }
`

const IntakeFormSection = ({ clientId }) => {
  const [{ data, fetching }] = useQuery({
    query: CLIENT_HAS_INTAKE_FORM_QUERY,
    variables: { clientId },
    requestPolicy: "network-only"
  })

  if (fetching) return <LoadingSpinner />

  const hasIntakeForm = data?.client?.intakeFormSubmitted

  return (
    <div>
      {hasIntakeForm ? (
        <Link href={`/portal/clients/${clientId}/intake_form`}>View intake form</Link>
      ) : (
        <SendIntakeFormButton clientId={clientId} intakeLastSentAt={data?.client?.intakeLastSentAt} />
      )}
    </div>
  )
}

export default IntakeFormSection

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"
import React from "react"
import { Fragment } from "react"
import { twMerge } from "tailwind-merge"

import { DROPDOWN_CLASSNAMES, DROPDOWN_OPTION_CLASSNAMES } from "./Inputs"

const DropdownButton = ({ label, items, className }) => (
  <Menu as="div" className={twMerge("relative inline-block text-left", className)}>
    <div>
      <MenuButton className="flex h-8 items-center justify-center gap-2 rounded border border-teal bg-teal px-4 font-bold text-white hover:shadow focus:outline-none focus:ring-2 focus:ring-blue active:bg-teal-dark">
        {label}
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <MenuItems className={twMerge("absolute right-0 mt-2 w-56 origin-top-right", DROPDOWN_CLASSNAMES)}>
        <div className="py-1">
          {items.map((item, index) => (
            <MenuItem key={index}>
              {({ active, selected }) => (
                <button
                  className={twMerge(
                    DROPDOWN_OPTION_CLASSNAMES({ active, selected }),
                    "mb-0 block w-full pr-3 text-left"
                  )}
                  onClick={item.onClick}>
                  {item.label}
                </button>
              )}
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Transition>
  </Menu>
)

DropdownButton.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired,
  className: PropTypes.string
}

export default DropdownButton

import React, { useState } from "react"
import { useMutation } from "urql"

import { sendAmplitudeData } from "../../support/amplitude-react"
import LoadingSpinner from "../shared/LoadingSpinner"

import ChooseMethod from "./ChooseMethod"
import Confirmation from "./Confirmation"
import CopyPaste from "./CopyPaste"
import Review from "./Review"

const InviteClientsMutation = `
  mutation InviteClients($clients: [ClientInput!]!) {
    inviteClients (clients: $clients) {
      erroredClients {
        email
      }
    }
  }
`

export default function InviteClients() {
  const [active, setActive] = useState("file")
  const [step, setStep] = useState(1)
  const [clients, setClients] = useState([])
  const [existingClients, setExistingClients] = useState([])
  const [field, setField] = useState("")
  const [, inviteClients] = useMutation(InviteClientsMutation)
  const [errorMessage, setErrorMessage] = useState("")

  const handleChoiceUpdate = (newVal) => {
    setActive(newVal)
  }

  const handleChoice = (choice) => {
    if (choice === "file") {
      setStep(2)
      sendAmplitudeData("import method selected", { "Method Selected": "CSV" })
    } else if (choice === "single") {
      window.location.href = "/portal/clients?show_add_client_form=1"
    } else {
      setStep(3)
      sendAmplitudeData("import method selected", { "Method Selected": "Copy Paste" })
    }
  }

  const handleFieldChange = (val) => {
    sendAmplitudeData("import row edited")
    setField(val)
  }

  const handleFileUpload = () => {
    setStep(4)
    sendAmplitudeData("csv uploaded")
  }

  const review = (clients) => {
    setClients(clients)
    setStep(4)
  }

  const editContacts = () => {
    setStep(3)
  }

  const submitContacts = () => {
    setStep(6)
    inviteClients({ clients: clients }).then((result) => {
      if (result.error) {
        console.error(result.error)
      } else {
        sendAmplitudeData("import clients submitted", {
          "Total Rows": clients.length,
          "Errored Invites": result.data.inviteClients.erroredClients.length
        })
        setExistingClients(result.data.inviteClients.erroredClients)
        setStep(5)
      }
    })
  }

  const reviewInput = (userInput, invitationType) => {
    const clients = []
    // split input on linebreaks
    const array = userInput.split(/\r?\n/)
    array.forEach((row) => {
      // split row on commas
      const rowArray = row.split(/[,\t]+/)
      if (validateEmail(rowArray[0])) {
        clients.push({
          email: rowArray[0],
          firstName: rowArray[1],
          lastName: rowArray[2],
          phone: rowArray[3],
          clientType: "invited",
          invitedFrom: invitationType
        })
      }
    })
    if (clients.length) {
      if (invitationType == "csv_upload") {
        sendAmplitudeData("csv upload sucess", { "Valid Rows": clients.length })
      } else {
        sendAmplitudeData("copy paste sucess", { "Valid Rows": clients.length })
      }

      review(clients)
    } else {
      if (invitationType == "csv_upload") {
        sendAmplitudeData("csv upload failed")
      } else {
        sendAmplitudeData("copy paste failed")
      }

      setErrorMessage(
        invitationType == "csv_upload"
          ? "Please make sure the file contains at least one correct email and the formatting of your file is in this order: Email | First name | Last name | Phone number"
          : "Please make sure you entered at least one correct email and you entered the information in this order: Email | First name | Last name | Phone number"
      )
    }
    handleFieldChange(userInput)
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const SwitchCase = ({ value }) => {
    switch (value) {
      case 1:
        return (
          <ChooseMethod
            handleChoice={handleChoice}
            active={active}
            handleChoiceUpdate={handleChoiceUpdate}
            handleFileUpload={handleFileUpload}
            reviewInput={reviewInput}
          />
        )
      case 2:
        return <div />
      case 3:
        return <CopyPaste field={field} reviewInput={reviewInput} errorMessage={errorMessage} />
      case 4:
        return <Review clients={clients} submitContacts={submitContacts} editContacts={editContacts} />
      case 5:
        return <Confirmation clients={clients} existingClients={existingClients} />
      case 6:
        return <LoadingSpinner />
    }
  }

  return (
    <div>
      <SwitchCase value={step} />
    </div>
  )
}

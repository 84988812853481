import React from "react"
import styled from "styled-components"

import { sendAmplitudeData } from "../../support/amplitude-react"
import Vector from "../shared/Vector"

const TherapyCategoriesContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  flex-flow: column wrap;
  background-color: #ffffff;
  max-height: 450px;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 30px;
  flex-grow: 1;
  overflow-x: auto;
`
const TherapyCategory = styled.a`
  margin: 5px;
  color: #0b3954;
  font-size: 14px;
  &:hover {
    color: #0d9da4;
  }
`
const BrowseTherapiesContainer = styled.div`
  padding-top: 13px;
  margin-left: 15px;
  height: 75px;

  @media (max-width: 1299px) {
    margin-left: 0;
  }

  & > a > .browse-therapies-toggle {
    margin-left: 10px;
    margin-top: 2px;
  }

  &:hover {
    ${TherapyCategoriesContainer} {
      display: flex;
    }
    & > a {
      background-color: transparent;
      cursor: pointer;
      color: #0d9da4 !important;

      & > .browse-therapies-toggle {
        transition: transform linear 0.1s;
        transform: rotate(180deg);
      }
    }
  }
`
const TherapyCategories = ({ categories }) => (
  <TherapyCategoriesContainer id="therapy-categories-container">
    {categories.map((c, index) => (
      <TherapyCategory
        onClick={() => sendAmplitudeData("browse therapy link clicked", { Therapy: c })}
        href={`/practitioner-search?query=${encodeURIComponent(c)}`}
        key={`${c}-${index}`}>
        {c}
      </TherapyCategory>
    ))}
  </TherapyCategoriesContainer>
)

const BrowseCategoriesCTA = ({ categories, classname, styling }) => (
  <BrowseTherapiesContainer className="browse-category-link">
    <a className={classname} style={styling}>
      Browse Services <Vector className="browse-therapies-toggle" />
    </a>
    <TherapyCategories categories={categories} />
  </BrowseTherapiesContainer>
)

export default BrowseCategoriesCTA

import { ExclamationCircleIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import Breadcrumbs from "../../components/shared/Breadcrumbs"
import { Banner } from "../../components/shared/Layout"
import { ButtonLink } from "../../components/shared/Links"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { ToastProvider, useToast } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

const DEACTIVATE_VIDEO_CONFERENCING = `
  mutation DeactivateVideoConferencing($provider: String!) {
    deactivateVideoConferencing(provider: $provider) {
      result
      errors
    }
  }
`

const ConferencingSettings = ({ zoom }) => {
  const [, deactivateVideoConferencing] = useMutation(DEACTIVATE_VIDEO_CONFERENCING)
  const { showToast } = useToast()
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)

  const handleDeactivate = async () => {
    const result = await deactivateVideoConferencing({ provider: "zoom" })

    if (result.data?.deactivateVideoConferencing?.result === "success") {
      showToast("Successfully disconnected from Zoom")
      window.location.reload()
    } else {
      const error = result.data?.deactivateVideoConferencing?.errors?.[0] || "Something went wrong"
      showToast({ type: "error", content: error })
    }
  }

  return (
    <div>
      <Breadcrumbs links={[{ href: "/portal/settings", text: "Settings" }]} activePage="Video Conferencing" />
      <div className="mb-9 flex flex-col sm:mb-4">
        <Typography as="h1" variant="h3">
          Video Conferencing
        </Typography>
        <Typography variant="body" className="text-gray-dark sm:hidden">
          Connect your video conferencing account to automatically create meeting links for virtual appointments.
        </Typography>
      </div>

      <div className="w-full max-w-[750px]">
        <div className="flex flex-col rounded-xl border border-gray-light p-8">
          <div className="mb-8 w-fit rounded-xl bg-gray-ultralight p-4">
            <VideoCameraIcon className="h-12 w-12" />
          </div>

          {zoom && zoom.connected ? (
            <>
              <Typography variant="h5" className="mb-2">
                {zoom.erroredAt ? (
                  <div className="flex items-center gap-2">
                    <span>Disconnected from Zoom</span>
                    <ExclamationCircleIcon className="h-6 w-6 text-red" />
                  </div>
                ) : (
                  <span className="text-green">Connected to Zoom</span>
                )}
              </Typography>
              <Typography variant="body" className="mb-4 text-gray-dark">
                Account: {zoom.accountName} ({zoom.accountEmail})
              </Typography>
              {zoom.erroredAt && (
                <Banner type="error" className="mb-2">
                  Heal.me no longer has access to your Zoom account. Please reconnect to continue using the Zoom
                  integration.
                </Banner>
              )}
              {zoom.erroredAt ? (
                <ButtonLink href="/portal/settings/conferencing/zoom/authorize" className="mt-4 w-fit">
                  Connect Zoom
                </ButtonLink>
              ) : (
                <ButtonLink onClick={() => setShowDeactivateModal(true)} variant="secondary" className="mt-4 w-fit">
                  Disconnect Zoom
                </ButtonLink>
              )}
            </>
          ) : (
            <>
              <Typography variant="h5" className="mb-2">
                Connect to Zoom
              </Typography>
              <Typography variant="body" className="mb-4 text-gray-dark">
                Connect your Zoom account to automatically create meeting links.
              </Typography>
              <ButtonLink href="/portal/settings/conferencing/zoom/authorize" className="mt-4 w-fit">
                Connect Zoom
              </ButtonLink>
            </>
          )}
        </div>
      </div>

      <AnimatedModal
        visible={showDeactivateModal}
        hideModal={() => setShowDeactivateModal(false)}
        header="Disconnect Zoom"
        showFooter={true}
        actionButtonCopy="Yes, disconnect"
        actionButtonType="destructive"
        onSave={handleDeactivate}>
        <Typography variant="body">
          Are you sure you want to disconnect your Zoom account? This will remove automatic meeting creation for virtual
          appointments.
        </Typography>
      </AnimatedModal>
    </div>
  )
}

export default function ConferencingSettingsPage(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <ConferencingSettings {...props} />
      </ToastProvider>
    </GraphQLProvider>
  )
}

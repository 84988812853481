import { EllipsisVerticalIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { useMutation } from "urql"

import { Button } from "../components/shared/Buttons"
import HealMeTooltip from "../components/shared/HealMeTooltip"
import { Box, Divider, ProfilePageEmptyStateBox } from "../components/shared/Layout"
import { UnderlinedLink } from "../components/shared/Links"
import Typography from "../components/shared/Typography"
import { PracticeProvider } from "../contexts/PracticeContext"
import { useProfileStatus } from "../contexts/ProfileStatusContext"
import { useToast } from "../contexts/ToastContext"

import ServiceCard from "./PortalServices/ServiceCard"
import ProfileEditProvider from "./ProfileEdit/ProfileEditProvider"
import ProfileEditView from "./ProfileEdit/ProfileEditView"
import ServiceFlyout from "./ProfileEdit/ServiceFlyout"

const REORDER_SERVICES_MUTATION = `
  mutation ReorderServices($servicesArray: [String!]!) {
    reorderServices(servicesArray: $servicesArray) {
      result
      errors
    }
  }
`

const maxNumberOfServices = 25

const Services = ({ path, services, activeLocations, defaultAvailableTimes }) => {
  const { profileStatusData } = useProfileStatus()
  const newServiceDefaults = { locationIds: [], amountCents: 0, variations: [], name: "", description: "" }
  const newPackageDefaults = {
    package: true,
    numberOfSessions: 5,
    locationIds: [],
    amountCents: 0,
    variations: [],
    name: "",
    description: ""
  }
  const [servicesState, setServicesState] = useState(services)
  const [service, setService] = useState(newServiceDefaults)
  const [flyoutVisible, setFlyoutVisible] = useState(false)
  const { showToast } = useToast()

  const [, reorderServices] = useMutation(REORDER_SERVICES_MUTATION)

  const addNewService = () => {
    setFlyoutVisible(true)
    setService(newServiceDefaults)
  }

  const addNewPackage = () => {
    setFlyoutVisible(true)
    setService(newPackageDefaults)
  }

  const editService = (service) => {
    setService(service)
    setFlyoutVisible(true)
  }

  const freeServices = servicesState.filter((service) => service.amountCents === 0)
  const paidServices = servicesState.filter((service) => service.amountCents > 0)

  return (
    <>
      <ProfileEditView path={path} profileStatusData={profileStatusData}>
        {activeLocations.length === 0 ? (
          <ProfilePageEmptyStateBox title="Services">
            You need to <UnderlinedLink href="/portal/profile/locations">add a location</UnderlinedLink> first in order
            to add services.
          </ProfilePageEmptyStateBox>
        ) : servicesState.length === 0 ? (
          <ProfilePageEmptyStateBox title="Services" onButtonClick={addNewService}>
            Add a free service and any paid services you offer.
          </ProfilePageEmptyStateBox>
        ) : (
          <Box data-test-id="services">
            <div className="flex items-center justify-between border-b border-gray-light pb-6 sm:hidden">
              <Typography variant="capitalHeading">Services</Typography>
              <div className="flex flex-row" data-tooltip-id="disabled-tooltip">
                <Button
                  floatingInMobile={true}
                  disabled={services.length >= maxNumberOfServices}
                  onClick={addNewService}
                  className="mr-4">
                  Add service
                </Button>
                <Button
                  floatingInMobile={true}
                  disabled={services.length >= maxNumberOfServices}
                  onClick={addNewPackage}
                  className="sm:hidden">
                  Add package
                </Button>
              </div>
              {services.length >= maxNumberOfServices && (
                <HealMeTooltip
                  id="disabled-tooltip"
                  place="bottom"
                  content={`Note: You can add a maximum of ${maxNumberOfServices} services.`}
                />
              )}
            </div>
            {services.length < maxNumberOfServices && (
              <Button floatingInMobile={true} onClick={addNewService} className="hidden sm:block">
                Add new
              </Button>
            )}
            <div className="mt-6 flex flex-col gap-4 sm:mt-0">
              {freeServices.map((service) => (
                <ServiceCard key={service.id} service={service} onClick={() => editService(service)} />
              ))}
              {paidServices.length > 0 && (
                <>
                  {freeServices.length > 0 ? <Divider className="sm:my-4" /> : null}
                  <ReactSortable
                    className="flex flex-col gap-4"
                    list={paidServices}
                    setList={(newPositions) => {
                      const newServices = freeServices.concat(newPositions)
                      if (JSON.stringify(newServices) !== JSON.stringify(servicesState)) {
                        const servicesArray = newServices.map((service, i) =>
                          JSON.stringify({ id: service.id, position: i + 1 })
                        )
                        reorderServices({ servicesArray }).then((result) => {
                          if (result?.data?.reorderServices?.result !== "success") {
                            console.error(result)
                            showToast({
                              type: "error",
                              content:
                                "There was error reordering your credentials. Please try again later or contact support if the error persists."
                            })
                          }
                        })
                        setServicesState(newServices)
                      }
                    }}>
                    {paidServices.map((service) => (
                      <div key={service.id} className="flex cursor-grab items-center">
                        <EllipsisVerticalIcon className="mr-4 h-6 w-6 text-gray-dark" />
                        <ServiceCard service={service} onClick={() => editService(service)} />
                      </div>
                    ))}
                  </ReactSortable>
                </>
              )}
            </div>
          </Box>
        )}
      </ProfileEditView>
      <ServiceFlyout
        visible={flyoutVisible}
        closeFlyout={() => setFlyoutVisible(false)}
        service={service}
        setService={setService}
        services={servicesState}
        setServices={setServicesState}
        activeLocations={activeLocations}
        defaultAvailableTimes={defaultAvailableTimes}
      />
    </>
  )
}

export default function ProfileEditServices({ profileStatusData, ...props }) {
  return (
    <PracticeProvider>
      <ProfileEditProvider profileStatusData={profileStatusData}>
        <Services {...props} />
      </ProfileEditProvider>
    </PracticeProvider>
  )
}

import capitalize from "lodash/capitalize"
import React, { useState } from "react"

import Badge from "../../components/shared/Badge"
import { Button } from "../../components/shared/Buttons"
import { Box, ProfilePageEmptyStateBox } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import DisplayAvailableTimes from "../ProfileEdit/DisplayAvailableTimes"
import LocationFlyout from "../ProfileEdit/LocationFlyout"
import ProfileEditProvider from "../ProfileEdit/ProfileEditProvider"

const Locations = ({ locations, defaultAvailableTimes }) => {
  const newLocationDefaults = {
    availableTimes: defaultAvailableTimes,
    kind: "office",
    virtualType: "video",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Get user's browser timezone as default
  }
  const [locationsState, setLocationsState] = useState(locations)
  const [location, setLocation] = useState(newLocationDefaults)
  const [flyoutVisible, setFlyoutVisible] = useState(false)

  const addNewLocation = () => {
    setFlyoutVisible(true)
    setLocation(newLocationDefaults)
  }

  return (
    <>
      <div className="w-[600px] md:w-full">
        <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
          <div className="flex flex-col">
            <Typography variant="h3" as="h1">
              Locations & Hours
            </Typography>
            <Typography variant="subtitle">Add or edit your locations and hours.</Typography>
          </div>
        </div>

        {locationsState.length === 0 ? (
          <ProfilePageEmptyStateBox title="Locations and hours" onButtonClick={addNewLocation}>
            Add any locations you offer and set your availability for each.
          </ProfilePageEmptyStateBox>
        ) : (
          <Box data-test-id="locations">
            <div className="flex items-center justify-between border-b border-gray-light pb-6 sm:hidden">
              <Typography variant="capitalHeading">Locations</Typography>
              <Button onClick={addNewLocation}>Add new</Button>
            </div>
            <Button onClick={addNewLocation} floatingInMobile={true} className="hidden sm:block">
              Add new
            </Button>
            <div className="mt-6 flex flex-col gap-4 sm:mt-0">
              {locationsState.map((location) => {
                const title =
                  location.kind === "virtual"
                    ? "Virtual office"
                    : location.kind === "home"
                    ? "In-home"
                    : location.address
                const subTitle =
                  location.kind === "virtual"
                    ? `${capitalize(location.virtualType)} calls`
                    : location.kind === "home"
                    ? ""
                    : `${location.city}, ${location.stateAbbreviation}`
                return (
                  <Box key={location.id}>
                    {location.state === "disabled" && (
                      <div className="mb-4">
                        <Badge type="error">Disabled</Badge>
                      </div>
                    )}
                    <div className="flex items-center justify-between">
                      <Typography variant="title">{title}</Typography>
                      <Button
                        type="link"
                        onClick={() => {
                          setLocation(location)
                          setFlyoutVisible(true)
                        }}>
                        Manage
                      </Button>
                    </div>
                    <div className="flex items-center justify-between">
                      <Typography variant="smSubtitle">{subTitle}</Typography>
                      {location.kind === "virtual" && (
                        <Typography variant="smSubtitle">Timezone: {location.timeZone}</Typography>
                      )}
                    </div>

                    <DisplayAvailableTimes locationOrService={location} className="mt-4" />
                  </Box>
                )
              })}
            </div>
          </Box>
        )}
      </div>

      <LocationFlyout
        key={location.id}
        visible={flyoutVisible}
        closeFlyout={() => setFlyoutVisible(false)}
        location={location}
        setLocation={setLocation}
        locations={locationsState}
        setLocations={setLocationsState}
      />
    </>
  )
}

export default function PortalServicesLocations({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <Locations {...props} />
    </ProfileEditProvider>
  )
}

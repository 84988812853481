import React from "react"
import Autocomplete from "react-google-autocomplete"

import "./PlacesAutocomplete.sass"
import { VALIDATION_ERROR_INPUT_CLASSNAMES, ValidationError } from "./Inputs"

const PlacesAutocomplete = ({ types = [], validationError, ...props }) => (
  <div>
    <Autocomplete
      {...props}
      options={{ types }}
      className={validationError ? `w-full rounded-lg border px-3 py-1.5 ${VALIDATION_ERROR_INPUT_CLASSNAMES}` : ""}
    />
    {validationError && <ValidationError>{validationError}</ValidationError>}
  </div>
)

export default PlacesAutocomplete

export const ADD_TIP_TO_APPOINTMENT_MUTATION = `
mutation AddTipToAppointment($appointmentId: ID!, $tipAmount: Int!) {
  addTipToAppointment(appointmentId: $appointmentId, tipAmount: $tipAmount) {
    result
    errors
  }
}
`
export const CHARGE_TIP_ONLY_MUTATION = `
mutation ChargeTipOnly($appointmentId: ID!, $tipAmount: Int!) {
  chargeTipOnly(appointmentId: $appointmentId, tipAmount: $tipAmount) {
    result
    errors
  }
}
`

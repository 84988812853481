import React from "react"
import { useMutation } from "urql"

import { capitalize } from "../../../utils/utils"
import { Link, UnderlinedLink } from "../../shared/Links"
import { AnimatedModal } from "../../shared/Modal"
import Typography from "../../shared/Typography"

// receive either square or stripe
const UPDATE_PRACTICE_PAYMENT_PROVIDER = `
  mutation UpdatePracticePaymentProvider($provider: String!) {
    updatePracticePaymentProvider(provider: $provider) {
      result
      errors
    }
  }
`

export default function PaymentIntegrations({
  stripeConnected,
  stripeDisplayName,
  squareConnected,
  squareDisplayName,
  paymentProvider,
  paypalConnected,
  paypalEnabled
}) {
  const [changePaymentProviderModalVisible, setChangePaymentProviderModalVisible] = React.useState(false)
  const [newPaymentProvider, setNewPaymentProvider] = React.useState("stripe")

  const [, updatePaymentProvider] = useMutation(UPDATE_PRACTICE_PAYMENT_PROVIDER)

  const handleUpdatePaymentProvider = async (p) => {
    const { data } = await updatePaymentProvider({ provider: p })
    if (data) {
      window.location.reload()
    }
  }

  const PaymentProviderCard = ({ provider, connected, displayName, imageUrl, manageUrl, connectUrl }) => {
    const [noticeModalVisible, setNoticeModalVisible] = React.useState(false)

    const hasAnyConnected = stripeConnected || squareConnected

    const isCurrentProvider = provider === paymentProvider && connected

    return (
      <div className="flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
        <div className="flex flex-row justify-items-start">
          <div className="mr-4 flex w-fit items-center justify-center rounded-xl bg-gray-ultralight p-4">
            <img src={imageUrl} width="32px" />
          </div>
          <div className="flex flex-col">
            <Typography variant="h5" className="mb-2">
              {capitalize(provider)}
            </Typography>
            <Typography variant="body" className="text-gray-dark">
              {connected ? `Connected to: ${displayName}` : `Sync Heal.me and ${capitalize(provider)}`}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-end text-end">
          {isCurrentProvider && <div className="font-bold">Active payment method</div>}
          {!isCurrentProvider && (
            <div>
              <span>Inactive</span>
              {connected && (
                <span
                  className="ml-1 cursor-pointer hover:underline"
                  onClick={() => {
                    setNewPaymentProvider(provider)
                    setChangePaymentProviderModalVisible(true)
                  }}>
                  (activate)
                </span>
              )}
            </div>
          )}
          {connected ? (
            <UnderlinedLink href={manageUrl}>Manage</UnderlinedLink>
          ) : hasAnyConnected ? (
            <>
              <div
                className="cursor-pointer font-bold text-teal underline"
                onClick={() => {
                  setNoticeModalVisible(true)
                }}>
                Connect
              </div>
              <AnimatedModal
                visible={noticeModalVisible}
                showFooter={true}
                hideModal={() => setNoticeModalVisible(false)}
                actionButtonCopy={`I understand, switch to ${capitalize(provider)} Payments`}
                onSave={() => {
                  window.location.href = connectUrl
                }}
                header={"Please Note:"}>
                <p>
                  When switching from one payment provider to another, your clients will need to re-enter their payment
                  information.
                </p>
              </AnimatedModal>
            </>
          ) : (
            <UnderlinedLink href={connectUrl}>Connect</UnderlinedLink>
          )}
        </div>
      </div>
    )
  }

  const providers = [
    {
      provider: "stripe",
      connected: stripeConnected,
      displayName: stripeDisplayName,
      imageUrl: "/images/payments-icon-stripe.png",
      manageUrl: "/portal/settings/banking/stripe",
      connectUrl: "/portal/settings/banking/link"
    },
    {
      provider: "square",
      connected: squareConnected,
      displayName: squareDisplayName,
      imageUrl: "/images/payments-icon-square.png",
      manageUrl: "/portal/settings/banking/square",
      connectUrl: "/portal/settings/banking/square/authorize"
    }
  ]

  const sortedProviders = providers.sort((a) => (a.provider === paymentProvider ? -1 : 1))

  return (
    <>
      <Typography as="h2" variant="h5" className="mb-2">
        Integrations
      </Typography>
      <div className="space-y-4">
        {sortedProviders.map((provider) => (
          <PaymentProviderCard
            key={provider.provider}
            provider={provider.provider}
            connected={provider.connected}
            displayName={provider.displayName}
            imageUrl={provider.imageUrl}
            manageUrl={provider.manageUrl}
            connectUrl={provider.connectUrl}
          />
        ))}
        {/* PayPal section */}
        {paypalEnabled && (
          <div className="flex flex-row items-center justify-between rounded-xl border border-gray-light p-8">
            <div className="flex flex-row justify-items-start">
              <div className="mr-4 flex w-fit items-center justify-center rounded-xl bg-gray-ultralight p-4">
                <img src="/images/payments-icon-paypal.png" width="32px" />
              </div>
              <div className="flex flex-col">
                <Typography variant="h5" className="mb-2">
                  PayPal / Venmo (BETA)
                </Typography>
                <Typography variant="body" className="text-gray-dark">
                  Accept payments from clients using PayPal or Venmo.
                </Typography>
                <Typography variant="small" className="text-gray-dark">
                  You need to have a{" "}
                  <Link href="https://www.paypal.com/us/business" target="_blank" rel="noopener noreferrer">
                    PayPal Business account
                  </Link>{" "}
                  to use this feature.
                </Typography>
              </div>
            </div>
            <div className="flex flex-col items-end text-end">
              {paypalConnected ? (
                <UnderlinedLink href="/portal/settings/banking/paypal">Manage</UnderlinedLink>
              ) : (
                <UnderlinedLink href="/portal/settings/banking/paypal/link">Connect</UnderlinedLink>
              )}
            </div>
          </div>
        )}
      </div>
      <AnimatedModal
        visible={changePaymentProviderModalVisible}
        showFooter={true}
        hideModal={() => setChangePaymentProviderModalVisible(false)}
        actionButtonCopy={`Change to ${capitalize(newPaymentProvider)}`}
        onSave={() => handleUpdatePaymentProvider(newPaymentProvider)}
        header={`Confirm change to ${capitalize(newPaymentProvider)}`}>
        <p>
          By making this change, all future payments will be processed through {capitalize(newPaymentProvider)}. Are you
          sure you want to make this change?
        </p>
      </AnimatedModal>
    </>
  )
}

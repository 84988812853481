import React, { useEffect, useState } from "react"
import { useQuery } from "urql"

import { useToast } from "../../../contexts/ToastContext"
import { DEFAULT_PAYMENT_METHOD_QUERY } from "../../../utils/mutations"
import { capitalize } from "../../../utils/utils"
import PaymentProviderCardForm from "../../payments/PaymentProviderCardForm"
import { usePaymentProvider } from "../../payments/PaymentProviderWrapper"
import LoadingSpinner from "../../shared/LoadingSpinner"
import { AnimatedModal } from "../../shared/Modal"
import { RadioWithLabel } from "../../shared/RadioButtons"
import Typography from "../../shared/Typography"

const AddOrRetryModal = ({ headerLabel, open, closeModal, onSave, appointment, practice, client }) => {
  const { showToast } = useToast()

  const [loading, setLoading] = useState(false)
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(
    client?.defaultPaymentMethod || appointment?.client?.defaultPaymentMethod
  )
  const [paymentMethod, setPaymentMethod] = useState(!defaultPaymentMethod ? "new" : "default")

  const [{ data, fetching, error }] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: {
      clientId: appointment?.client?.id,
      userId: appointment?.user?.id,
      practiceId: practice.id
    },
    pause: !!defaultPaymentMethod,
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (!defaultPaymentMethod && data?.defaultPaymentMethodPractice) {
      setPaymentMethod("default")
      setDefaultPaymentMethod(data.defaultPaymentMethodPractice)
    }
  }, [data])

  const { handleCardSavedOnProvider } = usePaymentProvider()

  const handleCardSaved = async () => {
    const showErrorAndClose = (message) => {
      showToast({ content: `There was an error: ${message}`, type: "error" })
      setLoading(false)
      closeModal()
    }

    setLoading(true)

    if (paymentMethod === "new") {
      const paymentProviderResult = await handleCardSavedOnProvider()
      if (paymentProviderResult.error) {
        showErrorAndClose(paymentProviderResult.error || "An error occurred")
        return
      }
    }

    setLoading(false)
    onSave()
    closeModal()
  }

  const getDefaultPaymentMethodText = (paymentMethod) => {
    if (!paymentMethod) return "No payment method selected"
    return `${capitalize(paymentMethod.brand)} ending in ${paymentMethod.last4} • Expires ${paymentMethod.expMonth}/${
      paymentMethod.expYear
    }`
  }

  return (
    <AnimatedModal
      visible={open}
      hideModal={closeModal}
      header={headerLabel}
      showFooter={true}
      onSave={handleCardSaved}
      saveDisabled={loading}
      actionButtonCopy="Charge card">
      <div className="mt-4">
        <Typography variant="h5" className="mb-4">
          Payment method
        </Typography>

        {fetching ? (
          <LoadingSpinner />
        ) : error ? (
          <Typography variant="body" className="text-red">
            An error occurred while fetching payment methods
          </Typography>
        ) : (
          <div className="flex flex-col gap-2">
            {defaultPaymentMethod && (
              <RadioWithLabel
                value="default"
                name="payment-method"
                label={getDefaultPaymentMethodText(defaultPaymentMethod)}
                checked={paymentMethod === "default"}
                onChange={() => {
                  setPaymentMethod("default")
                }}
              />
            )}
            <RadioWithLabel
              value="new"
              name="payment-method"
              label="New Credit card"
              checked={paymentMethod === "new"}
              onChange={() => {
                setPaymentMethod("new")
              }}
            />
            {paymentMethod === "new" && <PaymentProviderCardForm practice={practice} />}
          </div>
        )}
      </div>
    </AnimatedModal>
  )
}

export default AddOrRetryModal

import React from "react"

import Typography from "../../components/shared/Typography"
import { PracticeProvider } from "../../contexts/PracticeContext"
import DefaultProvider from "../../providers/DefaultProvider"

import ProImportReviews from "./ProImportReviews"

const Reviews = () => (
  <>
    <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
      <div className="flex flex-col">
        <Typography as="h1" variant="h3">
          Import Reviews
        </Typography>
        <Typography variant="subtitle">Import your past reviews from Google, Facebook, Yelp, and more.</Typography>
      </div>
    </div>

    <ProImportReviews />
  </>
)

export default function ProReviewsImportPage(props) {
  return (
    <DefaultProvider>
      <PracticeProvider>
        <Reviews {...props} />
      </PracticeProvider>
    </DefaultProvider>
  )
}

import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { Label } from "../../components/shared/Inputs"
import { Banner, Flyout } from "../../components/shared/Layout"
import { UnderlinedLink } from "../../components/shared/Links"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"
import { useToast } from "../../contexts/ToastContext"
import { formatAvailableTimes } from "../../utils/utils"

import AvailableTimes from "./AvailableTimes"
import HomeLocationFields from "./HomeLocationFields"
import OfficeLocationFields from "./OfficeLocationFields"
import VirtualLocationFields from "./VirtualLocationFields"

const UPSERT_LOCATION = `
  mutation UpsertLocation(
    $id: ID,
    $address: String,
    $secondaryAddress: String,
    $neighborhood: String,
    $cityGeocoded: String,
    $city: String,
    $stateProvince: String,
    $stateAbbreviation: String,
    $country: String,
    $countryCode: String,
    $zip: String,
    $latitude: Float,
    $longitude: Float,
    $timeZone: String,
    $placeId: String,
    $fullStreetAddress: String,
    $hideAddress: Boolean,
    $kind: String
    $virtualType: String,
    $virtualLink: String,
    $virtualInstructions: String,
    $travelDistance: String
    $availableTimes: String
    $countryIds: [String!]
    $stateIds: [String!]) {
    upsertLocation(
      id: $id,
      address: $address,
      secondaryAddress: $secondaryAddress,
      neighborhood: $neighborhood,
      cityGeocoded: $cityGeocoded,
      city: $city,
      stateProvince: $stateProvince,
      stateAbbreviation: $stateAbbreviation,
      country: $country,
      countryCode: $countryCode,
      zip: $zip,
      latitude: $latitude,
      longitude: $longitude,
      timeZone: $timeZone,
      placeId: $placeId,
      fullStreetAddress: $fullStreetAddress,
      hideAddress: $hideAddress,
      kind: $kind,
      virtualType: $virtualType,
      virtualLink: $virtualLink,
      virtualInstructions: $virtualInstructions,
      travelDistance: $travelDistance
      availableTimes: $availableTimes
      countryIds: $countryIds
      stateIds: $stateIds) {
      result
      location {
        id
        name
        latitude
        longitude
        singleLine
        fullStreetAddress
        cityState
        city
        stateAbbreviation
        virtualType
        virtualLink
        virtualInstructions
        kind
        zip
        address
        secondaryAddress
        availableTimes
        hideAddress
        timeZone
        travelDistance
        state
        canDelete
      }
      errors
    }
  }
`
const DISABLE_LOCATION = `
  mutation DisableLocation ($id: ID!){
    disableLocation (id: $id){
      result
      errors
    }
  }
`
const ENABLE_LOCATION = `
  mutation EnableLocation ($id: ID!){
    enableLocation (id: $id){
      result
      errors
    }
  }
`
const DELETE_LOCATION = `
  mutation DeleteLocation ($id: ID!){
    deleteLocation (id: $id){
      result
      errors
    }
  }
`

const LocationFlyout = ({ location, setLocation, locations, setLocations, visible, closeFlyout }) => {
  const { syncProfileStatus } = useProfileStatus()
  const [formState, setFormState] = useState(JSON.parse(JSON.stringify(location)))
  const [validationErrors, setValidationErrors] = useState({})

  const [{ fetching: upsertLocationFetching }, upsertLocation] = useMutation(UPSERT_LOCATION)
  const [{ fetching: disableLocationFetching }, disableLocation] = useMutation(DISABLE_LOCATION)
  const [{ fetching: enableLocationFetching }, enableLocation] = useMutation(ENABLE_LOCATION)
  const [{ fetching: deleteLocationFetching }, deleteLocation] = useMutation(DELETE_LOCATION)

  const { showToast } = useToast()

  const editingExistingLocation = !!formState.id
  const lastActiveLocation = locations.filter((l) => l.state === "active").length <= 1
  const fetching = upsertLocationFetching || disableLocationFetching || enableLocationFetching || deleteLocationFetching
  const title = editingExistingLocation ? `Edit ${location.kind} location` : "Add location"

  const handleLocationTypeChange = (e) => {
    setFormState((state) => ({ ...state, kind: e.target.value }))
  }

  const updateLocationsState = (newLocation) => {
    const locationsCopy = [...locations]
    const index = locationsCopy.findIndex((location) => Number(location.id) === Number(newLocation.id))
    if (index !== -1) {
      locationsCopy[index] = newLocation
    } else {
      locationsCopy.push(newLocation)
    }
    setLocations(locationsCopy)
  }

  const onSave = () => {
    const errors = {}

    if (formState.kind === "office") {
      if (!formState.fullStreetAddress) {
        errors.fullStreetAddress = "Address is required"
      }
    } else if (formState.kind === "virtual") {
      if (!formState.virtualLink && !formState.virtualInstructions) {
        errors.virtualLink = "Either a link or instructions are required"
        errors.virtualInstructions = "Either a link or instructions are required"
      }
    } else {
      if (!formState.travelDistance) {
        errors.travelDistance = "Travel distance is required"
      }
      if (!formState.fullStreetAddress) {
        errors.fullStreetAddress = "Address is required"
      }
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return
    } else {
      setValidationErrors({})
    }

    upsertLocation({ ...formState, availableTimes: JSON.stringify(formState.availableTimes) }).then((result) => {
      if (result?.data?.upsertLocation?.result === "success") {
        const newLocation = result.data.upsertLocation.location
        newLocation.availableTimes = formatAvailableTimes(newLocation.availableTimes)
        setLocation(newLocation)
        updateLocationsState(newLocation)

        showToast("Location has been saved")
        syncProfileStatus()
        closeFlyout()
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your location"
        if (result.data?.upsertLocation?.errors) errorMessage += `: ${result.data.upsertLocation.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  const onDisable = () => {
    disableLocation({ id: location.id }).then((result) => {
      if (result?.data?.disableLocation?.result === "success") {
        const newLocation = { ...location, state: "disabled" }
        setLocation(newLocation)
        updateLocationsState(newLocation)
        showToast("Location has been disabled")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error disabling your location"
        if (result.data?.disableLocation?.errors) errorMessage += `: ${result.data.disableLocation.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  const onEnable = () => {
    enableLocation({ id: location.id }).then((result) => {
      if (result?.data?.enableLocation?.result === "success") {
        const newLocation = { ...location, state: "enabled" }
        setLocation(newLocation)
        updateLocationsState(newLocation)
        closeFlyout()
        showToast("Location has been enabled")
      } else {
        console.error(result)
        let errorMessage = "There was an error enabling your location"
        if (result.data?.enableLocation?.errors) errorMessage += `: ${result.data.enableLocation.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  const onDelete = () => {
    deleteLocation({ id: location.id }).then((result) => {
      if (result?.data?.deleteLocation?.result === "success") {
        setLocations(locations.filter((l) => l.id !== location.id))
        closeFlyout()
        showToast("Location has been deleted")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error deleting your location"
        if (result.data?.deleteLocation?.errors) errorMessage += `: ${result.data.deleteLocation.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  return (
    <Flyout
      visible={visible}
      closeFlyout={() => {
        closeFlyout()
        setValidationErrors({})
      }}
      header={title}
      footer={
        <div className={`flex w-full items-center ${editingExistingLocation ? "justify-between" : "justify-end"}`}>
          {editingExistingLocation && (
            <>
              {location.state === "disabled" ? (
                <Button type="destructive" onClick={onDelete} disabled={fetching}>
                  Delete
                </Button>
              ) : (
                <Button
                  type="destructive"
                  onClick={onDisable}
                  disabled={fetching || location.onlyLocationForService || lastActiveLocation}>
                  Disable
                </Button>
              )}
            </>
          )}
          <div className="flex gap-4">
            <Button
              type="tertiary"
              onClick={() => {
                closeFlyout()
                setValidationErrors({})
              }}
              disabled={fetching}>
              Cancel
            </Button>
            {location.state === "disabled" ? (
              <Button type="primary" onClick={onEnable} disabled={fetching}>
                Enable
              </Button>
            ) : (
              <Button type="primary" onClick={onSave} disabled={fetching}>
                Save
              </Button>
            )}
          </div>
        </div>
      }>
      <div
        className={`flex flex-col gap-6 overflow-y-scroll ${
          location.state === "disabled" ? "pointer-events-none opacity-25" : ""
        }`}>
        {!editingExistingLocation && (
          <div>
            <Label htmlFor="location-type" className="mb-2">
              What type of location is this?
            </Label>
            <div className="flex gap-4">
              <RadioWithLabel
                value="office"
                name="location-type"
                label="Office"
                checked={formState.kind === "office"}
                onChange={handleLocationTypeChange}
              />
              <RadioWithLabel
                value="virtual"
                name="location-type"
                label="Virtual"
                disabled={locations.filter((location) => location.kind === "virtual").length > 0}
                disabledTooltipMessage="Note: You already have a virtual location set up."
                checked={formState.kind === "virtual"}
                onChange={handleLocationTypeChange}
              />
              <RadioWithLabel
                id="in-home-radio"
                value="home"
                name="location-type"
                label="At client's home"
                disabled={locations.filter((location) => location.kind === "home").length > 0}
                disabledTooltipMessage="Note: You already have an in-home location set up."
                checked={formState.kind === "home"}
                onChange={handleLocationTypeChange}
              />
            </div>
          </div>
        )}
        {formState.kind === "office" ? (
          <OfficeLocationFields location={formState} setLocation={setFormState} validationErrors={validationErrors} />
        ) : formState.kind === "home" ? (
          <HomeLocationFields location={formState} setLocation={setFormState} validationErrors={validationErrors} />
        ) : (
          <VirtualLocationFields location={formState} setLocation={setFormState} validationErrors={validationErrors} />
        )}
        <AvailableTimes availableTimes={formState.availableTimes} updateState={setFormState} />
      </div>
      {location.onlyLocationForService && (
        <Banner type="warning" className="mt-4">
          <b>Note:</b> This location is attached to a service(s) and cannot be disabled yet.{" "}
          <UnderlinedLink
            href="https://help.heal.me/en/articles/8467013-i-can-t-disable-or-delete-a-location-why-not"
            target="_blank">
            Learn more
          </UnderlinedLink>
        </Banner>
      )}
    </Flyout>
  )
}

export default LocationFlyout

import React from "react"

import { useCalendarFlyout } from "../../../contexts/CalendarFlyoutContext"
import { useManualBooking } from "../../../contexts/ManualBookingContext"
import { usePractice } from "../../../contexts/PracticeContext"
import BookableEventForm from "../../../pages/BookableEvents/BookableEventForm"
import BookableEventRegistrants from "../../../pages/BookableEvents/BookableEventRegistrants"
import { useBookableEvents } from "../../../pages/BookableEvents/BookableEventsContext"
import { BookableEventTypesProvider } from "../../../pages/BookableEventTypes/BookableEventTypesContext"
import { ToggleButtonGroup } from "../../shared/Inputs"
import { Flyout } from "../../shared/Layout"
import { RadioWithLabel } from "../../shared/RadioButtons"

import CalendarEvent from "./CalendarEvent"
import ClientEventForm from "./ClientEventForm"
import PersonalEventForm from "./PersonalEventForm"

export default function AppointmentFlyout({
  personalEvent: selectedPersonalEvent,
  calendarEvent: selectedCalendarEvent,
  clickedTime,
  onAppointmentChanged,
  onCreatePersonal,
  onDeletePersonal,
  onRescheduleSuccess,
  onAppointmentCancelled,
  onRecurringAppointmentsChanged,
  onBookableEventChanged,
  onDeleteBookableEvent,
  visible,
  closeFlyout
}) {
  const { appointment } = useManualBooking()
  const { eventType, setEventType } = useCalendarFlyout()
  const { bookableEvent, bookableEventTab, setBookableEventTab } = useBookableEvents()
  const { practice } = usePractice()

  const sidebarHeader = () => {
    if (selectedCalendarEvent) return selectedCalendarEvent.title

    if (eventType === "personal") {
      return selectedPersonalEvent ? "Edit personal event" : "Create personal event"
    }

    if (eventType === "bookable") {
      return bookableEvent?.id ? "Edit class/event" : "Create class/event"
    }

    if (!appointment) return "Create appointment"

    let upcoming = false
    let pending = false

    if (appointment) {
      upcoming = new Date(appointment.startsAt) >= new Date()
      pending = appointment.state === "pending"
    } else if (selectedPersonalEvent) {
      upcoming = new Date(selectedPersonalEvent.startDate) >= new Date()
    }

    if (pending && upcoming) return "New appointment request"
    if (upcoming) return "Upcoming appointment"
    return "Past appointment"
  }

  const handlePersonalEventCreate = (personalEvent) => {
    setEventType("appointment")
    onCreatePersonal(personalEvent)
  }

  const handleCloseFlyout = () => {
    closeFlyout()
  }
  const showRadioButtons = !appointment && !selectedPersonalEvent && !selectedCalendarEvent && !bookableEvent?.id

  return (
    <Flyout header={sidebarHeader()} visible={visible} closeFlyout={handleCloseFlyout}>
      <div className="relative">
        {showRadioButtons && (
          <>
            <div className="mb-4 flex flex-row gap-4">
              <RadioWithLabel
                value="client"
                name="event-type"
                label="Client"
                checked={eventType === "client"}
                onChange={(e) => setEventType(e.target.value)}
              />
              <RadioWithLabel
                value="personal"
                name="event-type"
                label="Personal"
                checked={eventType === "personal"}
                onChange={(e) => setEventType(e.target.value)}
              />
              {practice?.bookableEventsEnabled && (
                <RadioWithLabel
                  value="bookable"
                  name="event-type"
                  label="Class/Event"
                  checked={eventType === "bookable"}
                  onChange={(e) => setEventType(e.target.value)}
                />
              )}
            </div>
          </>
        )}

        {eventType === "personal" ? (
          <PersonalEventForm
            onCreate={handlePersonalEventCreate}
            onDelete={onDeletePersonal}
            personalEvent={selectedPersonalEvent}
            clickedTime={clickedTime}
          />
        ) : eventType === "client" ? (
          <ClientEventForm
            onAppointmentChanged={onAppointmentChanged}
            onRescheduleSuccess={onRescheduleSuccess}
            onAppointmentCancelled={onAppointmentCancelled}
            onRecurringAppointmentsChanged={onRecurringAppointmentsChanged}
          />
        ) : eventType === "bookable" ? (
          <BookableEventTypesProvider>
            {bookableEvent?.id && (
              <>
                <ToggleButtonGroup
                  className="mb-4"
                  value={bookableEventTab}
                  onChange={(value) => setBookableEventTab(value)}
                  options={[
                    { label: "Details", value: "details" },
                    { label: "Registrants", value: "registrants" }
                  ]}
                />
              </>
            )}
            {bookableEventTab === "details" ? (
              <BookableEventForm
                onBookableEventChanged={onBookableEventChanged}
                onDeleteBookableEvent={onDeleteBookableEvent}
              />
            ) : bookableEventTab === "registrants" ? (
              <BookableEventRegistrants />
            ) : null}
          </BookableEventTypesProvider>
        ) : eventType === "calendar" ? (
          <CalendarEvent calendarEvent={selectedCalendarEvent} />
        ) : null}
      </div>
    </Flyout>
  )
}

import React from "react"

import CurrencyInput from "../../components/shared/CurrencyInput"
import { CheckBox, Input, Label, TextArea } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"
import { formatPrice, unformatPrice } from "../../utils/utils"

import { useBookableEventTypes } from "./BookableEventTypesContext"
import LocationInput from "./LocationInput"
import PhotoUpload from "./PhotoUpload"

const BookableEventTypeForm = () => {
  const { bookableEventType, setBookableEventType, googlePlacesLoaded } = useBookableEventTypes()

  const handleChange = (field, value) => {
    setBookableEventType((prev) => ({ ...prev, [field]: value }))
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Label>Name</Label>
        <Input
          value={bookableEventType.name || ""}
          onChange={(e) => handleChange("name", e.target.value)}
          placeholder="e.g., Yoga Class, Meditation Session, etc."
          className="mt-1 w-full"
        />
      </div>

      <div>
        <Label>URL</Label>
        <Input
          value={bookableEventType.slug || ""}
          onChange={(e) => handleChange("slug", e.target.value)}
          placeholder="e.g., yoga-class, meditation-session"
          className="mt-1 w-full"
        />
        <Typography variant="micro" className="" as="div">
          This is the final part of the link, for example: yoursite.com/yoga-class. Use lowercase letters, numbers, and
          hyphens only.
        </Typography>
      </div>

      <div>
        <Label>Description</Label>
        <TextArea
          value={bookableEventType.description || ""}
          onChange={(e) => handleChange("description", e.target.value)}
          placeholder="Describe your event type to help clients understand what they're booking"
          className="mt-1 w-full"
          rows={3}
        />
      </div>

      <div>
        <Label>Instructions</Label>
        <TextArea
          value={bookableEventType.instructions || ""}
          onChange={(e) => handleChange("instructions", e.target.value)}
          placeholder="Provide any instructions or information clients need before attending"
          className="mt-1 w-full"
          rows={3}
        />
        <Typography variant="micro" className="-mt-1" as="div">
          Instructions for attendees. We&apos;ll email these to them after they book.
        </Typography>
      </div>

      <div>
        <div className="flex justify-between">
          <Label htmlFor="price-input">Price</Label>
          <div className="text-sm text-gray-dark">Optional</div>
        </div>
        <CurrencyInput
          value={bookableEventType.amountCents ? formatPrice(bookableEventType.amountCents) : ""}
          onChange={(input) => {
            const newAmount = unformatPrice(input.target.value)
            handleChange("amountCents", newAmount)
          }}
          className="mt-1 w-full"
          placeholder="$0.00 (leave blank for free)"
        />
      </div>

      <div>
        <div className="flex justify-between">
          <Label>Capacity</Label>
          <div className="text-sm text-gray-dark">Optional</div>
        </div>
        <Input
          type="number"
          value={bookableEventType.capacity || ""}
          onChange={(e) => handleChange("capacity", e.target.value === "" ? null : parseInt(e.target.value, 10))}
          placeholder="Leave blank for unlimited capacity"
          className="mt-1 w-full"
          min={1}
        />
      </div>

      <div>
        <Label>Event Location</Label>
        <div className="mt-4">
          <CheckBox
            id="virtual-event-checkbox"
            label="Virtual Event"
            name="Virtual Event"
            checked={bookableEventType.kind === "virtual"}
            onChange={() => {
              const newKind = bookableEventType.kind === "in_person" ? "virtual" : "in_person"
              setBookableEventType((event) => ({ ...event, kind: newKind }))
            }}
          />
        </div>
        {googlePlacesLoaded && (
          <div className="mt-2">
            {bookableEventType.kind === "in_person" ? (
              <>
                <LocationInput eventType={bookableEventType} setEventType={setBookableEventType} />
                <Typography variant="micro">Enter an address for in-person event</Typography>
              </>
            ) : (
              <>
                {/* <Label htmlFor="virtual-url">Virtual Meeting URL</Label> */}
                <Input
                  id="virtual-url"
                  value={bookableEventType.location || ""}
                  placeholder="https://zoom.us/j/123456789"
                  onChange={(e) => setBookableEventType((event) => ({ ...event, location: e.target.value }))}
                />
                <Typography variant="micro">Enter a URL for virtual events (Zoom, Google Meet, etc.)</Typography>
              </>
            )}
          </div>
        )}
      </div>

      {/* <div>
        <Label>Image</Label>
        <div className="mt-1">Image upload coming soon</div>
      </div> */}

      <div className="mt-4">
        <PhotoUpload />
      </div>
    </div>
  )
}

export default BookableEventTypeForm

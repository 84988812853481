import React from "react"

import Typography from "../../components/shared/Typography"
import { PracticeProvider } from "../../contexts/PracticeContext"
import DefaultProvider from "../../providers/DefaultProvider"

import ProReviewsTable from "./ProReviewsTable"

const Reviews = ({ reviews }) => (
  <>
    <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
      <div className="flex flex-col">
        <Typography as="h1" variant="h3">
          Completed Reviews
        </Typography>
        <Typography variant="subtitle">
          {reviews.length === 0 ? <>No reviews </> : <>{reviews?.length} completed </>}
        </Typography>
      </div>
    </div>

    <ProReviewsTable reviews={reviews} />
  </>
)

export default function ProReviewsCompletedPage(props) {
  return (
    <DefaultProvider>
      <PracticeProvider>
        <Reviews {...props} />
      </PracticeProvider>
    </DefaultProvider>
  )
}

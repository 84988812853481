import React, { useState } from "react"

import { usePractice } from "../../../contexts/PracticeContext"
import PaymentProviderWrapper from "../../payments/PaymentProviderWrapper"
import { Button } from "../../shared/Buttons"

import AddOrRetryModal from "./AddOrRetryModal"

const AddOrRetryButton = ({ appointment, client, user, headerLabel, label, onSave, type = "button" }) => {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false)
  const { practice } = usePractice()

  const onClick = () => {
    setPaymentModalOpen(true)
  }

  return (
    <>
      {type === "button" && <Button onClick={onClick}>{label || "Add credit card"}</Button>}
      {type === "link" && (
        <span className="cursor-pointer font-bold text-teal underline" onClick={onClick}>
          {label || "Add credit card"}
        </span>
      )}

      {paymentModalOpen && (
        <PaymentProviderWrapper practice={practice} appointment={appointment} client={client} user={user}>
          <AddOrRetryModal
            headerLabel={headerLabel || "Add credit card"}
            open={paymentModalOpen}
            closeModal={() => setPaymentModalOpen(false)}
            onSave={onSave}
            appointment={appointment}
            practice={practice}
            client={client}
          />
        </PaymentProviderWrapper>
      )}
    </>
  )
}

export default AddOrRetryButton

import React, { useState } from "react"

export default function RatingStar({ rating, setRating }) {
  const [hover, setHover] = useState(null)
  const keywords = ["1", "2", "3", "4", "5"]

  return (
    <div className="flex flex-row justify-evenly">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1

        return (
          <div className="star-radiobutton" key={ratingValue}>
            <label className="mb-0">
              <input type="radio" name="rating" value={ratingValue} onClick={() => setRating(ratingValue)} />
              <i
                className={`icon-star-filled star h-[44px] w-[44px] ${
                  ratingValue <= (hover || rating) ? "star-filled" : "star"
                }`}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
            <span className="text-[20px] font-bold">{keywords[i]}</span>
          </div>
        )
      })}
    </div>
  )
}

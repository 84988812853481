import React, { useState } from "react"
import { useMutation } from "urql"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { useToast } from "../../../../contexts/ToastContext"
import { Button } from "../../../shared/Buttons"
import { AnimatedModal } from "../../../shared/Modal"
import { RadioWithLabel } from "../../../shared/RadioButtons"
import Typography from "../../../shared/Typography"

const SEND_INVOICE = `
  mutation SendClientInvoiceMutation($appointmentId: ID!, $sendTo: String!) {
    sendClientInvoiceMutation(appointmentId: $appointmentId, sendTo: $sendTo) {
      result
      errors
    }
  }
`

export default function ManualPaymentInvoiceSection() {
  const { appointment } = useManualBooking()

  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [sendTo, setSendTo] = useState("email")

  const [, sendInvoice] = useMutation(SEND_INVOICE)
  const { showToast } = useToast()

  const sendInvoiceHandler = ({ appointmentId, sendTo }) => {
    sendInvoice({
      appointmentId,
      sendTo
    }).then((res) => {
      if (res?.data?.sendClientInvoiceMutation?.result === "success") {
        showToast("Invoice sent successfully")
      } else {
        if (res?.data?.sendClientInvoiceMutation?.errors?.length > 0) {
          showToast({ content: res.data.sendClientInvoiceMutation.errors.join(", "), type: "error" })
          return
        }
        showToast({ content: "Failed to send invoice", type: "error" })
      }
    })
  }

  return (
    <div className="mb-4 flex justify-between">
      <Button type="secondary" onClick={() => window.open("/m/c/" + appointment.instantActionToken, "_blank")}>
        In Person Payment
      </Button>
      <Button type="secondary" onClick={() => setShowInvoiceModal(true)}>
        Send invoice
      </Button>

      <AnimatedModal
        visible={showInvoiceModal}
        hideModal={() => setShowInvoiceModal(false)}
        header={"Send invoice notification"}
        showFooter={true}
        actionButtonCopy="Send now"
        onSave={() => {
          sendInvoiceHandler({
            appointmentId: appointment.id,
            sendTo
          })
          setShowInvoiceModal(false)
        }}>
        <Typography variant="subtitle">Where do you want to send?</Typography>
        <div className="mt-4 flex flex-col gap-2">
          <RadioWithLabel
            label="Email"
            name="sendTo"
            value="email"
            checked={sendTo === "email"}
            onChange={() => setSendTo("email")}
          />
          <RadioWithLabel
            label="SMS"
            name="sendTo"
            value="sms"
            checked={sendTo === "sms"}
            onChange={() => setSendTo("sms")}
          />
          <RadioWithLabel
            label="Both"
            name="sendTo"
            value="both"
            checked={sendTo === "both"}
            onChange={() => setSendTo("both")}
          />
        </div>
      </AnimatedModal>
    </div>
  )
}

import React, { useState } from "react"
import { useMutation } from "urql"

import AcceptTermsModal from "../../components/shared/AcceptTermsModal"
import DatePicker from "../../components/shared/DatePicker"
import { EditableValue, Input } from "../../components/shared/Inputs"
import { Box, Divider } from "../../components/shared/Layout"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"
import { useToast } from "../../contexts/ToastContext"

const UPDATE_INFO_MUTATION = `
  mutation UpdatePersonalSettings($name: String, $title: String, $gender: String, $dob: String, $customGender: String) {
    updatePersonalSettings(name: $name, title: $title, gender: $gender, dob: $dob, customGender: $customGender) {
      result
      user { id name gender dob customGender }
    }
  }
`

export default function PersonalInformationUser({ user }) {
  const [userState, setUserState] = useState(user)
  const [lastSavedUserValue, setLastSavedUserValue] = useState(user)
  const [openField, setOpenField] = useState(null)
  const { profileStatusData } = useProfileStatus()

  const [{ fetching }, updatePersonalSettings] = useMutation(UPDATE_INFO_MUTATION)

  const { showToast } = useToast()

  const onSave = () => {
    updatePersonalSettings(Object.fromEntries(Object.entries(userState).filter(([, v]) => !!v))).then((result) => {
      setOpenField(null)
      if (result.error) {
        console.error(result) // eslint-disable-line no-console
        setUserState(lastSavedUserValue)
        showToast({
          type: "error",
          content: result.error.message.replace("[GraphQL]", "")
        })
      } else {
        setLastSavedUserValue(result.data.updatePersonalSettings.user)
        showToast("Your settings have been saved.")
      }
    })
  }

  const onOpened = (fieldName) => {
    setOpenField(fieldName)
  }

  return (
    <div className="mt-4 w-[600px] md:w-full">
      <Box className="rounded-b-none">
        <EditableValue
          name="Name"
          value={userState.name}
          saveDisabled={userState.name === lastSavedUserValue.name || userState.name?.length < 2}
          disabled={fetching || (openField && openField !== "Name")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <Input
            name="Name"
            value={userState.name || ""}
            onChange={(e) => setUserState((values) => ({ ...values, name: e.target.value }))}
          />
        </EditableValue>
        <Divider />
        <EditableValue
          name="Title"
          hint="Set what your professional title is. Pro-tip: Keep this as short and clear as possible so it's easily understood by clients."
          value={userState.title}
          saveDisabled={userState.title === lastSavedUserValue.title || userState.title?.length < 2}
          disabled={fetching || (openField && openField !== "Title")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <Input
            name="Title"
            placeholder="Ex: Licensed Massage Therapist"
            value={userState.title || ""}
            onChange={(e) => setUserState((values) => ({ ...values, title: e.target.value }))}
          />
        </EditableValue>
        <Divider />
        <EditableValue
          name="Gender"
          value={(userState.gender === "Custom" ? userState.customGender : userState.gender) || ""}
          saveDisabled={userState.gender === lastSavedUserValue.gender}
          disabled={fetching || (openField && openField !== "Gender")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <div className="flex gap-4">
            <RadioWithLabel
              value="Female"
              name="gender"
              label="Female"
              checked={userState.gender === "Female"}
              onChange={(e) => setUserState({ ...userState, gender: e.target.value })}
            />
            <RadioWithLabel
              value="Male"
              name="gender"
              label="Male"
              checked={userState.gender === "Male"}
              onChange={(e) => setUserState({ ...userState, gender: e.target.value })}
            />
            <RadioWithLabel
              value="Custom"
              name="gender"
              label="Custom"
              checked={userState.gender === "Custom"}
              onChange={(e) => setUserState({ ...userState, gender: e.target.value })}
            />
          </div>
          {userState.gender === "Custom" && (
            <Input
              className="mt-4"
              name="Custom gender"
              value={userState.customGender || ""}
              onChange={(e) => setUserState((values) => ({ ...values, customGender: e.target.value }))}
            />
          )}
        </EditableValue>
        <Divider />
        <EditableValue
          name="Date of birth"
          value={userState.dob ? new Date(userState.dob).toDateString() : null}
          saveDisabled={userState.dob === lastSavedUserValue.dob}
          disabled={fetching || (openField && openField !== "Date of birth")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <DatePicker
            selected={userState.dob ? new Date(userState.dob) : ""}
            onChange={(date) => {
              setUserState((values) => ({ ...values, dob: date }))
            }}
          />
        </EditableValue>
      </Box>
      <AcceptTermsModal showModal={profileStatusData.showTermsModal} />
    </div>
  )
}

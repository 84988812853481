import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react"
import React from "react"

const honeybadger = Honeybadger.configure({
  apiKey: "hbp_6m6vaWJX6KdTdPYxfqptlzIhk7PiF103F3K5",
  environment: window.App.environment,
  projectRoot: "webpack:///./app/javascript",
  revision: window.App.heroku_slug_commit,
  enableUncaught: true
})

export const wrapWithErrorBoundary = (WrappedComponent) =>
  function WithErrorBoundary(props) {
    return (
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        <WrappedComponent {...props} />
      </HoneybadgerErrorBoundary>
    )
  }

import NiceModal from "@ebay/nice-modal-react"
import React from "react"

import { ToastProvider } from "../contexts/ToastContext"

import GraphQLProvider from "./GraphQLProvider"

const ReviewProvider = ({ children }) => (
  <NiceModal.Provider>
    <ToastProvider>
      <GraphQLProvider>{children}</GraphQLProvider>
    </ToastProvider>
  </NiceModal.Provider>
)

export default ReviewProvider

import React, { useState } from "react"

import { practitionerWebsiteUrl } from "../../@core/practitioner/practitioner.utils"
import { UnderlinedLink, ButtonLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"

import { ReviewLayout } from "./ReviewLayout"

const ReviewIntro = ({ review }) => {
  const [showWarning, setShowWarning] = useState(false)

  const websiteUrl = practitionerWebsiteUrl(review.practitioner, review.practice)

  return (
    <ReviewLayout
      percentDone="20%"
      showProgressBar={!showWarning}
      hideContinue={true}
      proPic={showWarning ? "" : review.practitioner.filestackPhoto}>
      {showWarning ? (
        <>
          <Typography variant="h3">
            Thank you for that. Unfortunately we can&apos;t accept a review from you.
          </Typography>
          <Typography variant="body">
            {review.practitioner.firstName} is using Heal.me for bookings, and we only show reviews from clients who’ve
            worked with {review.practitioner.firstName}.
          </Typography>
          <ButtonLink
            className="mt-4 w-full border border-gray bg-white text-center text-black active:bg-gray-light"
            href={websiteUrl}>
            View {review.practitioner.firstName}&apos;s profile
          </ButtonLink>
          <UnderlinedLink
            onClick={() => setShowWarning(false)}
            className="mx-auto mt-4 block w-full text-center text-gray-dark">
            Oops, I made a mistake
          </UnderlinedLink>
        </>
      ) : (
        <>
          <Typography variant="h3">
            Hi {review.client ? review.client.firstName : "there"}! Have you worked with {review.practitioner.name}{" "}
            before?
          </Typography>
          <Typography variant="body" className="mt-1 text-gray-dark">
            {review.practitioner.firstName} is using Heal.me for bookings, and requested a review from you based on your
            previous experience.
          </Typography>
          <ButtonLink
            className="mb-0 mt-6 w-full border border-gray bg-white text-center text-black"
            href={
              review.client
                ? `/i/r/rating?referral_code=${review.referralCode}&client_id=${review.clientId}`
                : `/i/r/client_info/${review.referralCode}`
            }
            onClick={(e) => {
              if (review.client) {
                e.preventDefault()
                window.location.href = `/i/r/rating?referral_code=${review.referralCode}&client_id=${review.clientId}`
              }
            }}>
            Yes, I have!
          </ButtonLink>

          <ButtonLink
            onClick={() => setShowWarning(true)}
            className="mt-4 w-full border border-gray bg-white text-center text-black">
            No, I haven&apos;t
          </ButtonLink>
        </>
      )}
    </ReviewLayout>
  )
}

export default ReviewIntro

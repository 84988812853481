import React, { useEffect, useState } from "react"
import { useQuery } from "urql"

import { usePractice } from "../../contexts/PracticeContext"
import IntakeFormSection from "../../pages/IntakeForms/IntakeFormSection"
import AddCreditCardModal from "../../pages/ManagePaymentMethods/AddCreditCardModal"
import { DEFAULT_PAYMENT_METHOD_QUERY } from "../../utils/mutations"
import { capitalize, formatPhone } from "../../utils/utils"
import PaymentProviderWrapper from "../payments/PaymentProviderWrapper"
import AppointmentHistory from "../pro_portal/calendar/AppointmentHistory"
import UpcomingAppointments from "../pro_portal/calendar/UpcomingAppointments"

import { Button } from "./Buttons"
import ClientNotes from "./ClientNotes"
import { Label } from "./Inputs"
import { Flyout, Divider } from "./Layout"
import { ButtonLink } from "./Links"
import Typography from "./Typography"

export default function ClientFlyout({ client, closeFlyout, visible }) {
  const [paymentMethod, setPaymentMethod] = useState(client?.defaultPaymentMethod)

  const [creditCardModalOpen, setCreditCardModalOpen] = React.useState(false)

  const { practice } = usePractice()

  const [{ data, fetching }, reexecuteQuery] = useQuery({
    query: DEFAULT_PAYMENT_METHOD_QUERY,
    variables: {
      clientId: client?.id,
      practiceId: practice.id
    },
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (client) {
      setPaymentMethod(null)
    }
  }, [client])

  useEffect(() => {
    if (data?.defaultPaymentMethodPractice) {
      setPaymentMethod(data.defaultPaymentMethodPractice)
    }
  }, [data])

  return (
    <Flyout
      header="Client Details"
      visible={visible}
      closeFlyout={closeFlyout}
      footer={
        <div className="flex items-center justify-end gap-4">
          <Button type="tertiary" onClick={closeFlyout}>
            Close
          </Button>
          <ButtonLink href={`/portal/clients/${client?.id}`}>View</ButtonLink>
        </div>
      }>
      {client && (
        <div>
          <div className="flex items-center justify-between">
            <div>
              <Label className="mb-1">Full name</Label>
              <div>{client.name ? client.name : client.firstName}</div>
            </div>
            {practice.clientNotesEnabled && <IntakeFormSection clientId={client.id} showData={false} />}
          </div>

          {formatPhone(client.phone) && (
            <>
              <Divider />
              <Label className="mb-1 mt-6">Phone</Label>
              <Typography className="mb-6" variant="body">
                {formatPhone(client.phone) ? formatPhone(client.phone) : ""}
              </Typography>
            </>
          )}
          {client.email && (
            <>
              <Divider />
              <Label className="mb-1 mt-6">Email</Label>
              <Typography className="mb-6" variant="body">
                {client.email}
              </Typography>
            </>
          )}

          <Divider className="mb-4" />
          {practice.clientNotesEnabled && (
            <>
              <ClientNotes client={client} />
              <Divider className="mb-4" />
            </>
          )}

          <Label className="mb-1 mt-6">Card on file</Label>

          {!practice.hasPaymentProvider ? (
            <a className="font-bold text-teal hover:underline" href={`/portal/settings/banking`}>
              Click here to connect to a payment provider
            </a>
          ) : fetching ? (
            <img className="loading" src="/loading-spinner.png" width="40px" />
          ) : paymentMethod ? (
            <div className="flex items-center justify-between">
              <Typography variant="body">
                {capitalize(paymentMethod.brand)} ending in {paymentMethod.last4} • Expires {paymentMethod.expMonth}/
                {paymentMethod.expYear}
              </Typography>
              <button
                className="font-bold text-teal hover:underline"
                onClick={() => {
                  setCreditCardModalOpen(true)
                }}>
                Change
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <Typography variant="body">None</Typography>
              <button
                className="font-bold text-teal hover:underline"
                onClick={() => {
                  setCreditCardModalOpen(true)
                }}>
                Add
              </button>
            </div>
          )}

          {practice.hasPaymentProvider && creditCardModalOpen && (
            <PaymentProviderWrapper practice={practice} client={client}>
              <AddCreditCardModal
                headerLabel={"Add a credit card"}
                open={creditCardModalOpen}
                closeModal={() => setCreditCardModalOpen(false)}
                practice={practice}
                onSave={() => reexecuteQuery()}
              />
            </PaymentProviderWrapper>
          )}

          <Divider className="mb-6 mt-4" />
          <UpcomingAppointments client={client} />
          <Divider />
          <AppointmentHistory client={client} limit={2} />
        </div>
      )}
    </Flyout>
  )
}

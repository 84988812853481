import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { UnderlinedLink } from "../../components/shared/Links"
import RatingStarRow from "../../components/shared/RatingStarRow"
import Typography from "../../components/shared/Typography"
import ReviewProvider from "../../providers/ReviewProvider"
import { formatShortDate } from "../../utils/utils"

import { ReviewLayout } from "./ReviewLayout"

const CONFIRM_REVIEW_MUTATION = `
  mutation ConfirmReview($reviewId: ID!) {
    confirmReview(reviewId: $reviewId) {
      result
      errors
    }
  }
`

const Confirm = ({ review }) => {
  const [showWarning, setShowWarning] = useState(false)
  const [{ fetching }, confirmReview] = useMutation(CONFIRM_REVIEW_MUTATION)

  const handleConfirm = () => {
    confirmReview({
      reviewId: review.id
    }).then((res) => {
      if (res.data.confirmReview.result === "success") {
        window.location.href = `/i/r/text_review/${review.token}`
      } else {
        console.error(res)
      }
    })
  }

  return (
    <ReviewLayout
      percentDone="40%"
      showProgressBar={!showWarning}
      hideContinue={true}
      proPic={showWarning ? "" : review.practitioner.filestackPhoto}>
      {showWarning ? (
        <>
          <Typography variant="h3">Thank you for that.</Typography>
          <Typography variant="body">
            Please email us at <UnderlinedLink href="mailto:support@heal.me">support@heal.me</UnderlinedLink> with the
            issue and we can coordinate with {review.practitioner.firstName}.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">
            {review.practitioner.name} wants to import your review. Does this look correct?
          </Typography>
          <Typography variant="body" className="mt-1 text-gray-dark">
            {review.practitioner.firstName} is using Heal.me for bookings, and requested you confirm this review is
            correct before we import it.
          </Typography>
          <div className="mt-6 rounded-xl border border-gray-light p-6 shadow">
            <Typography variant="body" as="p">
              &ldquo;{review.review}&rdquo;
            </Typography>
            <div className="mt-2 flex flex-row items-center sm:flex-col">
              <span className="mr-2 text-gray-dark sm:mb-2">
                {" "}
                {review.client.firstName} • {formatShortDate(review.createdAt)}
              </span>
              <RatingStarRow total={review.rating} />
            </div>
          </div>
          <div className="mt-8 flex w-full items-center justify-end border-t border-gray-light bg-white p-0 sm:fixed sm:bottom-0 sm:left-0 sm:px-8 sm:py-4">
            <Button type="tertiary" className="float-right mr-4 mt-8 sm:mt-0" onClick={() => setShowWarning(true)}>
              <span className="hidden sm:block">No, it&apos;s not</span>
              <span className="block sm:hidden">No, this is wrong</span>
            </Button>
            <Button type="primary" className="float-right mt-8 sm:mt-0" onClick={handleConfirm} disabled={fetching}>
              <span className="hidden sm:block">Yes, this is</span>
              <span className="block sm:hidden">Yes, this is correct</span>
            </Button>
          </div>
        </>
      )}
    </ReviewLayout>
  )
}

export default function ReviewConfirm(props) {
  return (
    <ReviewProvider>
      <Confirm {...props} />
    </ReviewProvider>
  )
}

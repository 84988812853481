import React from "react"

import { Button } from "../components/shared/Buttons"
import Typography from "../components/shared/Typography"
import { CalendarFlyoutProvider } from "../contexts/CalendarFlyoutContext"
import { ToastProvider } from "../contexts/ToastContext"
import GraphQLProvider from "../providers/GraphQLProvider"

import BookableEventCard from "./BookableEvents/BookableEventCard"
import { BookableEventsProvider, useBookableEvents } from "./BookableEvents/BookableEventsContext"
import BookableEventsFlyout from "./BookableEvents/BookableEventsFlyout"

const BookableEventsPage = () => {
  const { setShowFlyout, bookableEvents, setBookableEvent, defaultNewEvent, defaultTimeZone } = useBookableEvents()

  return (
    <>
      <div className="mb-8 flex justify-between align-bottom sm:mb-4">
        <div className="flex flex-col">
          <Typography as="h1" variant="h3">
            Events
          </Typography>
          <Typography variant="subtitle">Schedule events that multiple people can sign up for</Typography>
        </div>
        <div className="flex">
          <Button
            type="primary"
            size="small"
            floatingInMobile={true}
            onClick={() => {
              setShowFlyout(true)
              setBookableEvent({ ...defaultNewEvent, timeZone: defaultTimeZone })
            }}>
            Create event
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        {bookableEvents.map((event) => (
          <BookableEventCard
            event={event}
            key={event.id}
            onClick={() => {
              setShowFlyout(true)
              setBookableEvent(event)
            }}
          />
        ))}
      </div>
      <BookableEventsFlyout />
    </>
  )
}

// TODO: CalendarFlyoutProvider should not be here, but BookableEventForm is using it now
export default function BookableEvents(props) {
  return (
    <GraphQLProvider>
      <ToastProvider>
        <CalendarFlyoutProvider>
          <BookableEventsProvider {...props}>
            <BookableEventsPage />
          </BookableEventsProvider>
        </CalendarFlyoutProvider>
      </ToastProvider>
    </GraphQLProvider>
  )
}

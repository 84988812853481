import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../../components/shared/Buttons"
import { CheckBox } from "../../../components/shared/Inputs"
import { Divider } from "../../../components/shared/Layout"
import { useToast } from "../../../contexts/ToastContext"

import CalIcon from "./CalIcon"

const UPDATE_CALENDARS_MUTATION = `
  mutation UpdateCronofyCalendars($calendars: [CronofyCalendarInput!]!) {
    updateCronofyCalendars(calendars: $calendars) {
      result
    }
  }
`

const ReadCalendarSettings = ({ selectedProvider, onSave }) => {
  const [newProvider, setNewProvider] = useState(selectedProvider)
  const [, updateCalendars] = useMutation(UPDATE_CALENDARS_MUTATION)

  const { showToast } = useToast()

  return (
    <div>
      <div className="flex items-center gap-4">
        <CalIcon provider={selectedProvider.providerName} />
        <div>{newProvider.profileName}</div>
      </div>
      <Divider />
      <div className="mb-4 font-bold">Check these places for conflicts</div>
      <ul>
        {newProvider.calendars.map((cal) => (
          <li key={cal.id}>
            <CheckBox
              label={cal.calendarName}
              checked={cal.active}
              onChange={() => {
                setNewProvider((prevProvider) => ({
                  ...prevProvider,
                  calendars: prevProvider.calendars.map((calendar) => {
                    if (calendar.id === cal.id) {
                      return { ...calendar, active: !calendar.active }
                    }
                    return calendar
                  })
                }))
              }}
            />
          </li>
        ))}
      </ul>
      <div className="mt-4 flex justify-end">
        <Button
          onClick={() => {
            updateCalendars({
              calendars: newProvider.calendars.map((cal) => ({
                id: cal.id,
                active: cal.active
              }))
            }).then((result) => {
              if (result.data?.updateCronofyCalendars?.result === "success") {
                onSave(newProvider.calendars)
                showToast("Calendars updated successfully")
              } else {
                console.error(result)
                showToast({ content: "There was an error updating calendars", type: "error" })
              }
            })
          }}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default ReadCalendarSettings

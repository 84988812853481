import React from "react"
import styled from "styled-components"

const VectorImg = styled.div`
  background-image: url("/images/Vector.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 8px;
  width: 14px;
`

const Vector = ({ className }) => <VectorImg className={className} />

export default Vector

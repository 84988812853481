import React from "react"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { useToast } from "../../../../contexts/ToastContext"
import { copyToClipboard } from "../../../../utils/utils"
import { CopyLinkButton } from "../../../shared/Buttons"
import { Label } from "../../../shared/Inputs"
import Typography from "../../../shared/Typography"

const AppointmentReview = () => {
  const { appointment, client } = useManualBooking()
  const { showToast } = useToast()

  if (!appointment || new Date(appointment.startsAt) >= new Date()) return null

  const handleCopy = async () => {
    await copyToClipboard(appointment.reviewLink, showToast)
  }

  return (
    <>
      <div className="my-8 w-full border-t border-gray-light" />
      <Label>Review</Label>
      <CopyLinkButton value={appointment.reviewLink} className="w-[325px]" onClick={handleCopy} />
      <Typography className="mt-2 block" variant="small">
        You can send this link to {client?.firstName} to complete a review.
      </Typography>
    </>
  )
}

export default AppointmentReview

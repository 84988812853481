import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayjs from "dayjs"
import React, { useRef, useEffect, useState } from "react"
import LoadingBar from "react-top-loading-bar"

import { CalendarFlyoutProvider } from "../../../contexts/CalendarFlyoutContext"
import { FeatureFlagProvider } from "../../../contexts/FeatureFlagContext"
import { ManualBookingProvider } from "../../../contexts/ManualBookingContext"
import { PracticeProvider } from "../../../contexts/PracticeContext"
import { ProCalendarProvider, useProCalendar } from "../../../contexts/ProCalendarContext"
import { BookableEventsProvider } from "../../../pages/BookableEvents/BookableEventsContext"
import DefaultProvider from "../../../providers/DefaultProvider"
import { handleDayHeaderContent, renderEventContent } from "../../../utils/calendarUtils"
import AcceptTermsModal from "../../shared/AcceptTermsModal"
import ConfirmRescheduleModal from "../../shared/ConfirmRescheduleModal"

import AppointmentFlyout from "./AppointmentFlyout"

const initialIsMobile = () => window.innerWidth < 768

const CalendarComponent = () => {
  const {
    calendarEvents,
    isLoading,
    handleEventClick,
    handleDateClick,
    handleDragReschedule,
    isFlyoutVisible,
    selectedPersonalEvent,
    selectedExternalEvent,
    closeFlyout,
    calendarData,
    selectedDateTime,
    handleCreatePersonalEvent,
    handleDeletePersonalEvent,
    handleAppointmentChange,
    handleRecurringAppointmentsChange,
    handleAppointmentCancellation,
    handleRescheduleSuccess,
    showRescheduleConfirm,
    hideRescheduleConfirm,
    handleCreateAppointmentButtonClick,
    handleDatesSet,
    handleBookableEventChange,
    handleDeleteBookableEvent
  } = useProCalendar()

  const [isMobile, setIsMobile] = useState(initialIsMobile())
  const loadingBarRef = useRef(null)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (isLoading) {
      loadingBarRef.current.continuousStart()
    } else {
      loadingBarRef.current.complete()
    }
  }, [isLoading])

  return (
    <>
      <LoadingBar color={"rgb(var(--color-teal))"} height={3} ref={loadingBarRef} />
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
        headerToolbar={{
          left: isMobile ? "prev,title,next" : "today prev,next,title timeGridDay,timeGridWeek,dayGridMonth",
          center: "",
          right: "createAppointment"
        }}
        customButtons={{
          createAppointment: {
            text: "Create appointment",
            click: handleCreateAppointmentButtonClick
          }
        }}
        datesSet={handleDatesSet}
        editable={true}
        events={calendarEvents}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        eventDrop={handleDragReschedule}
        eventResize={handleDragReschedule}
        views={{
          timeGridWeek: {
            titleFormat: { year: "numeric", month: "long" },
            dayHeaderContent: handleDayHeaderContent
          },
          timeGridDay: {
            dayHeaderContent: handleDayHeaderContent
          }
        }}
        slotDuration="00:15"
        slotLabelInterval="01:00"
        nowIndicator={true}
        contentHeight={isMobile ? 200 : 1600}
        expandRows={!isMobile}
        slotMinTime={"00:00:00"}
        scrollTime={dayjs().format("HH:mm:ss") || "07:00:00"}
      />

      <AppointmentFlyout
        personalEvent={selectedPersonalEvent}
        calendarEvent={selectedExternalEvent}
        clickedTime={selectedDateTime}
        onAppointmentChanged={handleAppointmentChange}
        onCreatePersonal={handleCreatePersonalEvent}
        onDeletePersonal={handleDeletePersonalEvent}
        onRescheduleSuccess={handleRescheduleSuccess}
        onAppointmentCancelled={handleAppointmentCancellation}
        onRecurringAppointmentsChanged={handleRecurringAppointmentsChange}
        visible={isFlyoutVisible}
        closeFlyout={closeFlyout}
        onBookableEventChanged={handleBookableEventChange}
        onDeleteBookableEvent={handleDeleteBookableEvent}
      />

      {showRescheduleConfirm && (
        <ConfirmRescheduleModal
          showReschedule={showRescheduleConfirm}
          onRescheduleSuccess={handleRescheduleSuccess}
          hideModal={hideRescheduleConfirm}
        />
      )}

      {calendarData?.currentUser?.practice && (
        <AcceptTermsModal showModal={calendarData.currentUser.practice.showTermsModal} />
      )}
    </>
  )
}

const ProCalendar = ({ featureFlags }) => (
  <DefaultProvider>
    <FeatureFlagProvider flags={featureFlags}>
      <PracticeProvider>
        <ManualBookingProvider>
          <BookableEventsProvider>
            <CalendarFlyoutProvider>
              <ProCalendarProvider>
                <CalendarComponent />
              </ProCalendarProvider>
            </CalendarFlyoutProvider>
          </BookableEventsProvider>
        </ManualBookingProvider>
      </PracticeProvider>
    </FeatureFlagProvider>
  </DefaultProvider>
)

export default ProCalendar

import dayjs from "dayjs"
import React, { createContext, useContext, useEffect, useReducer } from "react"
import { useMutation } from "urql"

import { CREATE_APPOINTMENTS_MUTATION } from "../components/pro_portal/calendar/appointment_graphql"

import { useToast } from "./ToastContext"

const initialState = {
  services: [{}],
  locationId: null,
  startsAt: null,
  endsAt: null,
  customerId: null,
  clientId: null,
  client: null,
  bookedFrom: "web",
  bookedBy: "pro",
  recurringStartTimes: null,
  recurrencePattern: null,
  recurringAppointmentError: null,
  appointment: null,
  rescheduledStartsAt: null,
  rescheduledEndsAt: null,
  rescheduleRecurringSeries: false,
  appointmentErrors: false,
  initialAppointmentValues: null // Used to check if the appointment has changed during edit
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SERVICES":
      return { ...state, services: action.payload }
    case "SET_LOCATION_ID":
      return { ...state, locationId: action.payload }
    case "SET_STARTS_AT":
      return { ...state, startsAt: action.payload }
    case "SET_ENDS_AT":
      return { ...state, endsAt: action.payload }
    case "SET_CUSTOMER_ID":
      return { ...state, customerId: action.payload }
    case "SET_CLIENT_ID":
      return { ...state, clientId: action.payload }
    case "SET_CLIENT":
      return { ...state, client: action.payload }
    case "SET_BOOKED_FROM":
      return { ...state, bookedFrom: action.payload }
    case "SET_BOOKED_BY":
      return { ...state, bookedBy: action.payload }
    case "SET_RECURRING_START_TIMES":
      return { ...state, recurringStartTimes: action.payload }
    case "SET_RECURRENCE_PATTERN":
      return { ...state, recurrencePattern: action.payload }
    case "SET_RECURRING_APPOINTMENT_ERROR":
      return { ...state, recurringAppointmentError: action.payload }
    case "SET_APPOINTMENT":
      return { ...state, appointment: action.payload }
    case "SET_RESCHEDULED_STARTS_AT":
      return { ...state, rescheduledStartsAt: action.payload }
    case "SET_RESCHEDULED_ENDS_AT":
      return { ...state, rescheduledEndsAt: action.payload }
    case "SET_RESCHEDULE_RECURRING_SERIES":
      return { ...state, rescheduleRecurringSeries: action.payload }
    case "SET_APPOINTMENT_ERRORS":
      return { ...state, appointmentErrors: action.payload }
    case "RESET":
      return initialState
    case "SET_INITIAL_APPOINTMENT_VALUES":
      return { ...state, initialAppointmentValues: action.payload }
    default:
      throw new Error(`Invalid action type: ${action.type}`)
  }
}

export const ManualBookingContext = createContext()

export const ManualBookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [{ fetching: createRecurringAppointmentsFetching }, createAppointments] =
    useMutation(CREATE_APPOINTMENTS_MUTATION)
  const { showToast } = useToast()

  const createRecurringAppointments = (onSuccess) => {
    let clientId = null
    let newClient = null

    if (state.client.id) {
      clientId = state.client.id
    } else {
      newClient = state.client
    }

    const variables = {
      appointmentServices: state.services
        .filter((service) => service.service?.id)
        .map((service) => ({
          amountCents: service.amountCents,
          serviceId: service.service.id,
          saveCustomPrice: service.saveCustomPrice
        })),
      locationId: state.locationId,
      clientId,
      client: newClient,
      bookedFrom: state.bookedFrom,
      bookedBy: state.bookedBy,
      recurringStartTimes: state.recurringStartTimes,
      recurrencePattern: state.recurrencePattern,
      duration: dayjs(state.endsAt).diff(state.startsAt, "minutes")
    }
    createAppointments(variables).then((result) => {
      if (result.error) {
        console.error(result.error)
        showToast({ type: "error", content: `Error creating appointments: ${result.error}` })
      } else if (result.data.createAppointments?.result === "success") {
        showToast("Your recurring series was created successfully.")
        onSuccess(result.data.createAppointments.appointments)
      } else {
        console.error(result)
        showToast({
          type: "error",
          content: `Error creating appointments: ${result.data.createAppointments.errors.join(" ")}`
        })
      }
    })
  }

  useEffect(() => {
    if (state.appointment) {
      // Add priceChanged and saveCustomPrice to services for the edit form
      const updatedServices = state.appointment.appointmentServices.map((service) => {
        const customPrice = state.appointment.client.customPrices?.find((cp) => cp.serviceId === service.service.id)
        return {
          ...service,
          priceChanged: service.amountCents !== service.service.amountCents,
          saveCustomPrice: customPrice?.amountCents === service.amountCents
        }
      })

      const initialValues = {
        client: state.appointment.client || state.appointment.user,
        services: updatedServices,
        locationId: state.appointment.location.id,
        startsAt: state.appointment.startsAt,
        endsAt: state.appointment.endsAt
      }

      dispatch({ type: "SET_INITIAL_APPOINTMENT_VALUES", payload: initialValues })
      dispatch({ type: "SET_CLIENT", payload: initialValues.client })
      dispatch({ type: "SET_SERVICES", payload: initialValues.services })
      dispatch({ type: "SET_LOCATION_ID", payload: initialValues.locationId })
      dispatch({ type: "SET_STARTS_AT", payload: initialValues.startsAt })
      dispatch({ type: "SET_ENDS_AT", payload: initialValues.endsAt })
    }
  }, [state.appointment])

  const updateEndsAtWithServicesDuration = (servicesObj) => {
    const totalTimeLength = servicesObj
      .filter((service) => service.service?.timeLength)
      .reduce((sum, service) => sum + service.service.timeLength, 0)
    dispatch({
      type: "SET_ENDS_AT",
      payload: dayjs(state.startsAt).add(totalTimeLength, "minutes").toISOString()
    })
  }

  const value = {
    setServices: (services) => dispatch({ type: "SET_SERVICES", payload: services }),
    setLocationId: (locationId) => dispatch({ type: "SET_LOCATION_ID", payload: locationId }),
    setStartsAt: (startsAt) => dispatch({ type: "SET_STARTS_AT", payload: startsAt }),
    setEndsAt: (endsAt) => dispatch({ type: "SET_ENDS_AT", payload: endsAt }),
    setCustomerId: (customerId) => dispatch({ type: "SET_CUSTOMER_ID", payload: customerId }),
    setClientId: (clientId) => dispatch({ type: "SET_CLIENT_ID", payload: clientId }),
    setClient: (client) => dispatch({ type: "SET_CLIENT", payload: client }),
    setRecurringStartTimes: (recurringStartTimes) =>
      dispatch({ type: "SET_RECURRING_START_TIMES", payload: recurringStartTimes }),
    setRecurrencePattern: (recurrencePattern) =>
      dispatch({ type: "SET_RECURRENCE_PATTERN", payload: recurrencePattern }),
    setRecurringAppointmentError: (recurringAppointmentError) =>
      dispatch({ type: "SET_RECURRING_APPOINTMENT_ERROR", payload: recurringAppointmentError }),
    resetBookingState: () => dispatch({ type: "RESET" }),
    setAppointment: (appointment) => dispatch({ type: "SET_APPOINTMENT", payload: appointment }),
    setRescheduledStartsAt: (startsAt) => dispatch({ type: "SET_RESCHEDULED_STARTS_AT", payload: startsAt }),
    setRescheduledEndsAt: (endsAt) => dispatch({ type: "SET_RESCHEDULED_ENDS_AT", payload: endsAt }),
    setRescheduleRecurringSeries: (rescheduleRecurringSeries) =>
      dispatch({ type: "SET_RESCHEDULE_RECURRING_SERIES", payload: rescheduleRecurringSeries }),
    setAppointmentErrors: (appointmentErrors) =>
      dispatch({ type: "SET_APPOINTMENT_ERRORS", payload: appointmentErrors }),
    createRecurringAppointments,
    createRecurringAppointmentsFetching,
    updateEndsAtWithServicesDuration,
    ...state
  }

  return <ManualBookingContext.Provider value={value}>{children}</ManualBookingContext.Provider>
}

export const useManualBooking = () => useContext(ManualBookingContext)

import { ChevronRightIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import ClientFlyout from "../../components/shared/ClientFlyout"
import { Label, TextArea } from "../../components/shared/Inputs"
import { Flyout, Divider } from "../../components/shared/Layout"
import { Link } from "../../components/shared/Links"
import RatingStarRow from "../../components/shared/RatingStarRow"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { formatPhone } from "../../utils/utils"

const REVIEW_REPLY_MUTATION = `
  mutation AddEditReviewReply($reviewId: ID!, $message: String!) {
    addEditReviewReply(reviewId: $reviewId, message: $message) {
      result
      errors
    }
  }
`

export default function ReviewFlyout({ review, closeFlyout, visible }) {
  const [showClientDetail, setShowClientDetail] = useState(false)
  const [showReplyEdit, setShowReplyEdit] = useState(false)

  const [reviewReply, setReviewReply] = useState(review?.reviewReply?.message || "")

  const client = review?.client ? review?.client : review?.user
  const { showToast } = useToast()
  const [, addEditReply] = useMutation(REVIEW_REPLY_MUTATION)

  const handleReviewReply = () => {
    addEditReply({ reviewId: review.id, message: reviewReply }).then((res) => {
      closeFlyout({ replied: true })
      if (res.data.addEditReviewReply.result === "success") {
        showToast("Your reply has been posted")
      } else {
        showToast({
          content: `Error replying to review: ${res.data.addEditReviewReply.errors.join(" ")}`,
          type: "error"
        })
      }
    })
  }

  let saveEnabled = reviewReply?.length > 5

  return (
    <>
      <Flyout
        visible={visible}
        closeFlyout={closeFlyout}
        header="Review details"
        footer={
          <div className="flex w-full items-center justify-end">
            <div className="flex gap-4">
              <Button type="tertiary" onClick={closeFlyout}>
                Close
              </Button>
              {(showReplyEdit || !review?.reviewReply) && (
                <Button type="primary" onClick={handleReviewReply} disabled={!saveEnabled}>
                  {review?.reviewReply ? "Edit reply" : "Leave reply"}
                </Button>
              )}
            </div>
          </div>
        }>
        {review && (
          <>
            <div className="relative rounded-xl border border-gray-light px-4 py-2">
              {client ? (
                <a onClick={() => setShowClientDetail(true)}>
                  <Typography variant="h5">
                    {client.firstName} {client.lastName}
                  </Typography>
                  <Typography variant="small">
                    {client.email && `${client.email}`} {formatPhone(client.phone) && `${formatPhone(client.phone)}`}{" "}
                  </Typography>

                  <button className="absolute right-4 top-4">
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </a>
              ) : (
                <Typography variant="h5">{review.unverifiedName}</Typography>
              )}
            </div>
            <div className="mt-6 flex flex-row justify-items-start">
              <Typography variant="h1" as="p" className="font-bold">
                {review.rating}
              </Typography>
              <Typography variant="h1" as="p" className="font-normal text-gray-dark">
                /{review.rating}
              </Typography>
            </div>
            <RatingStarRow total={review.rating} />
            {review.endorsedSkills?.length > 0 && (
              <>
                <Label className="mt-6">Endorsements</Label>
                <div className="flex flex-row flex-wrap">
                  {review.endorsedSkills.map((skill, i) => (
                    <div
                      key={i}
                      className="mb-2 mr-2 flex items-center justify-center rounded-lg bg-gray-light p-2 leading-none">
                      {skill}
                    </div>
                  ))}
                </div>
              </>
            )}
            <Divider />
            <Label>Review</Label>
            <Typography variant="body" as="p" className="font-normal text-black">
              <div className="flex">
                {review.review ? (
                  <>
                    &ldquo;<span className="pl-1"> {review.review}&rdquo;</span>
                  </>
                ) : (
                  <Typography className="text-gray-dark">No text review</Typography>
                )}
              </div>
            </Typography>
            {review.review && (
              <>
                <Divider />
                <Label>Your reply</Label>
                {review.reviewReply && !showReplyEdit ? (
                  <>
                    <div className="flex">
                      &ldquo;<span className="pl-1"> {review.reviewReply.message}&rdquo;</span>
                    </div>
                    <Link className="float-right mt-4" onClick={() => setShowReplyEdit(true)}>
                      Edit
                    </Link>
                  </>
                ) : (
                  <>
                    {!review.reviewReply && (
                      <Typography variant="body" className="text-gray-dark">
                        Letting your clients know how their review makes you feel is a nice touch.
                      </Typography>
                    )}
                  </>
                )}
                {(showReplyEdit || !review.reviewReply) && (
                  <TextArea
                    className="mt-4"
                    placeholder="Ex: Thank you for such a nice review!"
                    value={reviewReply}
                    onChange={(e) => setReviewReply(e.target.value)}
                  />
                )}
              </>
            )}
          </>
        )}
      </Flyout>
      <ClientFlyout
        closeFlyout={() => {
          setShowClientDetail(false)
        }}
        visible={showClientDetail}
        refreshClient={() => {}}
        client={client}
        clientEdited={() => {
          showToast("Client edited successfully. ")
        }}
      />
    </>
  )
}

import { PayPalButtons } from "@paypal/react-paypal-js"
import React, { useState, useCallback } from "react"
import { useMutation } from "urql"

const CREATE_PAYPAL_ORDER_MUTATION = `
  mutation($appointmentId: ID!, $tipAmountCents: Int!, $tipOnly: Boolean!) {
    createPaypalOrder(appointmentId: $appointmentId, tipAmountCents: $tipAmountCents, tipOnly: $tipOnly) {
      result
      orderId
      errors
    }
  }
`

const CAPTURE_PAYPAL_PAYMENT_MUTATION = `
  mutation($appointmentId: ID!, $orderId: String!) {
    capturePaypalPayment(appointmentId: $appointmentId, orderId: $orderId) {
      result
      errors
      appointment {
        id
        clientPaymentStatus
        tipAmountCents
      }
    }
  }
`

const PaypalPaymentButton = ({
  appointment,
  tipAmountCents = 0,
  tipOnly = false,
  fundingSource = "paypal",
  onSuccess,
  onError
}) => {
  const [loading, setLoading] = useState(false)
  const [, createOrder] = useMutation(CREATE_PAYPAL_ORDER_MUTATION)
  const [, capturePayment] = useMutation(CAPTURE_PAYPAL_PAYMENT_MUTATION)

  const createOrderCallback = useCallback(async () => {
    setLoading(true)
    const response = await createOrder({
      appointmentId: appointment.id,
      tipAmountCents,
      tipOnly
    })
    setLoading(false)

    if (response.error || response.data?.createPaypalOrder?.result === "failure") {
      onError(response.error?.message || response.data?.createPaypalOrder?.errors?.[0])
      return null
    }

    return response.data?.createPaypalOrder?.orderId
  }, [appointment.id, tipAmountCents, createOrder, onError])

  return (
    <PayPalButtons
      forceReRender={[tipAmountCents]}
      fundingSource={fundingSource}
      disabled={loading}
      createOrder={createOrderCallback}
      onApprove={async (data) => {
        setLoading(true)
        const response = await capturePayment({
          appointmentId: appointment.id,
          orderId: data.orderID
        })
        setLoading(false)

        if (response.error || response.data?.capturePaypalPayment?.result === "failure") {
          onError(response.error?.message || response.data?.capturePaypalPayment?.errors?.[0])
          return
        }

        onSuccess(response.data?.capturePaypalPayment?.appointment)
      }}
      onError={() => {
        setLoading(false)
      }}
      style={{
        layout: "horizontal",
        color: fundingSource === "venmo" ? "blue" : "gold",
        shape: "rect",
        label: "pay"
      }}
    />
  )
}

export default PaypalPaymentButton

import { useMutation } from "urql"

export const SETUP_INTENT_MUTATION = `
  mutation SetupIntent($appointmentId: ID, $practiceId: ID!, $userId: ID, $clientId: ID) {
    setupIntent(appointmentId: $appointmentId, practiceId: $practiceId, userId: $userId, clientId: $clientId) {
      result
      setupIntentSecret
    }
  }
`

export default function useStripeHook() {
  const [{ data }, setupIntent] = useMutation(SETUP_INTENT_MUTATION)

  // Set elementsOptions based on what data we have
  const elementsOptions = data?.setupIntent?.setupIntentSecret
    ? { clientSecret: data.setupIntent.setupIntentSecret }
    : { mode: "setup", currency: "usd" }

  return { stripeData: data, setupIntent, elementsOptions }
}

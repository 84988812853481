import dayjs from "dayjs"

const dateFormat = "YYYY-MM-DDTHH:mm:ssZ"

export const filterByDate = (data, dateSelection) => {
  if (!dateSelection || dateSelection.value === "alltime") {
    return data
  }

  switch (dateSelection) {
    case "thisweek":
      return data.filter(
        (appt) =>
          appt.startsAt >= dayjs().startOf("week").format(dateFormat) &&
          appt.startsAt < dayjs().endOf("week").format(dateFormat)
      )
    case "thismonth":
      return data.filter(
        (appt) =>
          appt.startsAt >= dayjs().startOf("month").format(dateFormat) &&
          appt.startsAt < dayjs().endOf("month").format(dateFormat)
      )
    case "lastweek":
      return data.filter(
        (appt) =>
          appt.startsAt < dayjs().startOf("week").format(dateFormat) &&
          appt.startsAt >= dayjs().startOf("week").subtract(1, "weeks").format(dateFormat)
      )
    case "lastmonth":
      return data.filter(
        (appt) =>
          appt.startsAt < dayjs().startOf("month").format(dateFormat) &&
          appt.startsAt >= dayjs().startOf("month").subtract(1, "months").format(dateFormat)
      )
    case "thisyear":
      return data.filter(
        (appt) =>
          appt.startsAt >= dayjs().startOf("year").format(dateFormat) &&
          appt.startsAt < dayjs().endOf("year").format(dateFormat)
      )
    case "lastyear":
      return data.filter(
        (appt) =>
          appt.startsAt < dayjs().startOf("year").format(dateFormat) &&
          appt.startsAt >= dayjs().startOf("year").subtract(1, "years").format(dateFormat)
      )
    default:
      return data // return the original data if there's no match
  }
}

export const filterByState = (data, stateSelection) => {
  if (!stateSelection || stateSelection === "allpayments") {
    return filterOutFutureUnpaid(data)
  }

  switch (stateSelection) {
    case "paid":
      return data.filter((appt) => appt.paymentStatus === "Paid")
    case "unpaid":
      return data.filter((appt) => appt.paymentStatus === "Unpaid")
    case "free":
      return data.filter((appt) => appt.paymentStatus === "Free")
    case "upcoming":
      return data.filter((appt) => dayjs(appt.startsAt).isAfter(dayjs()))
    default:
      return data
  }
}

export const filterReviewsByDate = (data, dateSelection) => {
  if (!dateSelection || dateSelection.value === "alltime") {
    return data
  }

  switch (dateSelection) {
    case "thisweek":
      return data.filter((review) => review.createdAt >= dayjs().startOf("week").format(dateFormat))
    case "thismonth":
      return data.filter((review) => review.createdAt >= dayjs().startOf("month").format(dateFormat))
    case "lastweek":
      return data.filter(
        (review) =>
          review.createdAt < dayjs().startOf("week").format(dateFormat) &&
          review.createdAt >= dayjs().startOf("week").subtract(1, "weeks").format(dateFormat)
      )
    case "lastmonth":
      return data.filter(
        (review) =>
          review.createdAt < dayjs().startOf("month").format(dateFormat) &&
          review.createdAt >= dayjs().startOf("month").subtract(1, "months").format(dateFormat)
      )
    case "thisyear":
      return data.filter((review) => review.createdAt >= dayjs().startOf("year").format(dateFormat))
    case "lastyear":
      return data.filter(
        (review) =>
          review.createdAt < dayjs().startOf("year").format(dateFormat) &&
          review.createdAt >= dayjs().startOf("year").subtract(1, "years").format(dateFormat)
      )
    default:
      return data
  }
}

export const filterByRating = (data, ratingSelection) => {
  if (!ratingSelection || ratingSelection === "all") {
    return data
  }

  return data.filter((review) => review.rating == ratingSelection)
}

export const filterOutFutureUnpaid = (data) =>
  data.filter(
    (appt) =>
      dayjs(appt.startsAt).isBefore(dayjs()) ||
      (dayjs(appt.startsAt).isAfter(dayjs()) && appt.paymentStatus !== "Unpaid")
  )

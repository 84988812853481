import NiceModal from "@ebay/nice-modal-react"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import isEmpty from "lodash/isEmpty"
import uniqBy from "lodash/uniqBy"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"
import { v4 as uuidv4 } from "uuid"

import { Button, CloseButton } from "../../components/shared/Buttons"
import { FilePicker } from "../../components/shared/FileStackWidgets"
import HealMeTooltip from "../../components/shared/HealMeTooltip"
import {
  CheckBox,
  InputWithLabel,
  Label,
  CurrencyInput,
  BASE_INPUT_CLASSNAMES,
  ToggleButtonGroup,
  ValidationError
} from "../../components/shared/Inputs"
import { Banner, Flyout } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { NewModal } from "../../components/shared/Modal"
import QuillEditor from "../../components/shared/QuillEditor"
import { useProfileStatus } from "../../contexts/ProfileStatusContext"
import { useToast } from "../../contexts/ToastContext"
import { formatAvailableTimes, stripHTMLTags } from "../../utils/utils"

import AvailableTimes from "./AvailableTimes"

const UPSERT_SERVICE_MUTATION = `
  mutation UpsertService(
    $id: ID,
    $locationIds: [ID!],
    $name: String!,
    $description: String!,
    $timeLength: Int,
    $amountCents: Int!,
    $variationOf: ID,
    $variationsArray: [String!],
    $availableTimes: String,
    $availableTimesEnabled: Boolean,
    $package: Boolean,
    $numberOfSessions: Int,
    $addOn: Boolean,
    $allowedServiceIds: [ID!],
    $public: Boolean,
    $handle: String
  ) {
    upsertService(
      id: $id,
      locationIds: $locationIds,
      name: $name,
      description: $description,
      timeLength: $timeLength,
      amountCents: $amountCents,
      variationOf: $variationOf,
      variationsArray: $variationsArray,
      availableTimes: $availableTimes,
      availableTimesEnabled: $availableTimesEnabled
      package: $package,
      numberOfSessions: $numberOfSessions,
      addOn: $addOn,
      allowedServiceIds: $allowedServiceIds,
      public: $public,
      handle: $handle
    ) {
      result
      service {
        id
        name
        description
        timeLength
        amountCents
        locationIds
        availableTimes
        availableTimesEnabled
        package
        numberOfSessions
        addOn
        allowedServiceIds
        public
        variations { id timeLength amountCents }
        photoUrl
      }
      errors
    }
  }
`

const REMOVE_SERVICE_MUTATION = `
  mutation RemoveService($serviceId: ID!) {
    removeService(serviceId: $serviceId) {
      result
      errors
    }
  }
`

const UPDATE_SERVICE_PHOTO_MUTATION = `
  mutation UpdateServicePhoto($handle: String!, $serviceId: ID!) {
    updateServicePhoto(handle: $handle, serviceId: $serviceId) { result errors photoUrl }
  }
`

const REMOVE_SERVICE_PHOTO_MUTATION = `
  mutation RemoveServicePhoto($serviceId: ID!) {
    removeServicePhoto(serviceId: $serviceId) { result errors }
  }
`

const hasDuplicates = (array) => array.length !== new Set(array).size

const ServiceFlyout = ({
  service,
  setService,
  services,
  setServices,
  allServices,
  addOns,
  setAddOns,
  activeLocations,
  visible,
  closeFlyout,
  defaultAvailableTimes,
  setView,
  showToggle
}) => {
  const { syncProfileStatus } = useProfileStatus()
  const [{ fetching: upsertMutationFetching }, upsertService] = useMutation(UPSERT_SERVICE_MUTATION)
  const [{ fetching: removeMutationFetching }, removeService] = useMutation(REMOVE_SERVICE_MUTATION)
  const [{ fetching: photoMutationFetching }, updatePhoto] = useMutation(UPDATE_SERVICE_PHOTO_MUTATION)
  const [{ fetching: removePhotoMutationFetching }, removePhoto] = useMutation(REMOVE_SERVICE_PHOTO_MUTATION)
  const { showToast } = useToast()
  const [validationErrors, setValidationErrors] = useState({})

  const fetching = upsertMutationFetching || removeMutationFetching
  const editingExistingService = !!service.id
  const lastActiveService = services.length <= 1 && !service.addOn
  const title = editingExistingService
    ? service.addOn
      ? "Edit add-on"
      : service.package
      ? "Edit package"
      : "Edit service"
    : service.addOn
    ? "Create add-on"
    : service.package
    ? "Create package"
    : "Create service"
  const deleteModalId = "delete-service-modal"
  const allVariations = [
    { id: service.id, amountCents: service.amountCents, timeLength: service.timeLength },
    ...service.variations
  ]
  const hasMultipleLocationsInSameCity =
    activeLocations && uniqBy(activeLocations, (location) => location.name).length < activeLocations.length

  const updateServices = (newService) => {
    const newServices = newService.addOn ? [...addOns] : [...services]
    const index = newServices.findIndex((s) => s.id === newService.id)
    if (index !== -1) {
      newServices[index] = newService
    } else {
      newServices.push(newService)
    }

    if (newService.addOn) {
      setAddOns(newServices)
      setView("Add-ons")
    } else {
      setServices(newServices)
      setView("Services & Packages")
    }
  }

  const onSave = () => {
    const errors = {}

    if (service.name.length < 2) {
      errors.name = "Name is required"
    }
    if (stripHTMLTags(service.description).length < 2) {
      errors.description = "Description is required"
    }
    if (service.timeLength < 1 && !service.addOn) {
      errors.timeLength = "Session duration is required"
    }
    if (service.amountCents < 1 && service.package) {
      errors.amountCents = "Packages must have a price"
    }
    if (service.numberOfSessions < 1 && service.package) {
      errors.numberOfSessions = "Packages must have a number of sessions"
    }
    if (
      hasDuplicates(allVariations.map((v) => v.amountCents)) ||
      hasDuplicates(allVariations.map((v) => v.timeLength))
    ) {
      errors.variations = "Variations must be unique"
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return
    } else {
      setValidationErrors({})
    }

    upsertService({
      ...service,
      availableTimes: JSON.stringify(service.availableTimes),
      variationsArray: service.variations.map((v) => JSON.stringify(v))
    }).then((result) => {
      if (result?.data?.upsertService?.result === "success") {
        const newService = result.data.upsertService.service
        newService.variations = newService.variations.filter((v) => v.id !== newService.id) // variations returns the parent service as well
        newService.id = Number(newService.id)
        newService.locationIds = newService.locationIds.map((id) => Number(id))
        newService.allowedServiceIds = newService.allowedServiceIds.map((id) => Number(id))
        newService.availableTimes = formatAvailableTimes(newService.availableTimes)
        newService.smallWebpUrl = newService.photoUrl
        updateServices(newService)
        closeFlyout()
        syncProfileStatus()
        showToast(`Your ${service.package ? "package" : "service"} '${service.name}' was saved successfully`)
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your service"
        if (result.data?.upsertService?.errors) errorMessage += `: ${result.data.upsertService.errors}`
        showToast({ type: "error", content: errorMessage })
      }
      setValidationErrors({})
    })
  }

  const onDelete = () => {
    removeService({ serviceId: service.id }).then((result) => {
      if (result?.data?.removeService?.result === "success") {
        if (service.addOn) {
          setAddOns(addOns.filter((s) => s.id !== service.id))
        } else {
          setServices(services.filter((s) => s.id !== service.id))
        }
        closeFlyout()
        setValidationErrors({})
        NiceModal.remove(deleteModalId)
        syncProfileStatus()
        showToast("Service has been deleted")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error deleting your service"
        if (result.data?.removeService?.errors) errorMessage += `: ${result.data.removeService.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  const onUploadDone = (res) => {
    if (service.id) {
      updatePhoto({
        handle: res.filesUploaded[0].handle,
        serviceId: service.id
      }).then((result) => {
        if (result?.data?.updateServicePhoto?.result === "success") {
          const newService = { ...service, smallWebpUrl: result.data.updateServicePhoto.photoUrl }
          setService(newService)
          updateServices(newService)
          showToast("Your photo has been saved.")
        } else {
          console.error(result) // eslint-disable-line no-console
          let errorMessage = "There was an error saving your service photo. Please try again later or contact support."
          if (result.data?.updateServicePhoto?.errors) errorMessage += ` ${result.data.updateServicePhoto.errors}`
          showToast({
            type: "error",
            content: errorMessage
          })
        }
      })
    } else {
      setService({ ...service, handle: res.filesUploaded[0].handle, smallWebpUrl: res.filesUploaded[0].url })
    }
  }

  const onPhotoDelete = () => {
    removePhoto({ serviceId: service.id }).then((result) => {
      if (result?.data?.removeServicePhoto?.result === "success") {
        const newService = { ...service, smallWebpUrl: null }
        setService(newService)
        updateServices(newService)
        showToast("Your photo has been removed.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error removing your service photo. Please try again later or contact support."
        if (result.data?.removeServicePhoto?.errors) errorMessage += ` ${result.data.removeServicePhoto.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  return (
    <Flyout
      visible={visible}
      closeFlyout={() => {
        closeFlyout()
        setValidationErrors({})
      }}
      header={title}
      footer={
        <div className={`flex w-full items-center ${editingExistingService ? "justify-between" : "justify-end"}`}>
          {editingExistingService && (
            <Button
              type="destructive"
              onClick={() => NiceModal.show(deleteModalId)}
              disabled={fetching || lastActiveService}>
              Delete
            </Button>
          )}
          <div className="flex gap-4">
            <Button
              type="tertiary"
              onClick={() => {
                closeFlyout()
                setValidationErrors({})
              }}
              disabled={fetching}>
              Cancel
            </Button>
            <Button type="primary" onClick={onSave} disabled={fetching}>
              Save
            </Button>
          </div>
        </div>
      }>
      <div
        className={`flex flex-col gap-6 overflow-y-scroll pl-1 ${
          service.state === "disabled" ? "pointer-events-none opacity-25" : ""
        }`}>
        {showToggle && !editingExistingService && (
          <ToggleButtonGroup
            value={service.package ? "package" : service.addOn ? "add-on" : "service"}
            onChange={(value) => {
              setService({
                ...service,
                ...{
                  package: value === "package",
                  addOn: value === "add-on",
                  variations: value === "package" ? [] : service.variations,
                  numberOfSessions: value === "package" ? service?.numberOfSessions || 5 : null
                }
              })
            }}
            options={[
              { label: "Service", value: "service" },
              { label: "Package", value: "package" },
              { label: "Add-on", value: "add-on" }
            ]}
          />
        )}
        <div>
          <div className="flex justify-between">
            <Label htmlFor="photo">Photo</Label>
            {!service.smallWebpUrl && <div className="text-sm text-gray-dark">Optional</div>}
          </div>
          {photoMutationFetching || removePhotoMutationFetching ? (
            <LoadingSpinner className="py-0" />
          ) : service.smallWebpUrl ? (
            <div className="flex items-center gap-4">
              <img src={service.smallWebpUrl} alt="Service photo" className="h-16 w-16 rounded-lg" />
              <div>
                <FilePicker
                  as="button"
                  className="font-bold text-teal"
                  onUploadDone={onUploadDone}
                  disabled={photoMutationFetching}>
                  Edit photo
                </FilePicker>
              </div>
              <button className="font-bold text-red" onClick={onPhotoDelete}>
                Remove
              </button>
            </div>
          ) : (
            <FilePicker
              as="button"
              onUploadDone={onUploadDone}
              disabled={photoMutationFetching}
              className={twMerge(BASE_INPUT_CLASSNAMES, "text-teal")}>
              + Upload photo
            </FilePicker>
          )}
        </div>
        <InputWithLabel
          label="Name"
          value={service.name}
          onChange={(e) => setService({ ...service, name: e.target.value })}
          validationError={validationErrors.name}
        />
        <div>
          <Label htmlFor="description-input">Description</Label>
          <QuillEditor
            id="description-input"
            value={service.description}
            onChange={(content) => setService((prevService) => ({ ...prevService, description: content }))}
            validationError={validationErrors.description}
          />
        </div>
        {!service.addOn && (
          <>
            <div className="flex w-full gap-4">
              <div className="flex-1">
                <Label htmlFor="price-input">Price</Label>
                <CurrencyInput
                  id="price-input"
                  value={service.amountCents}
                  onChange={(amountCents) => setService({ ...service, amountCents })}
                  validationError={validationErrors.amountCents}
                />
              </div>
              <InputWithLabel
                className="flex-1"
                type="number"
                label="Session duration"
                placeholder="__ minutes"
                value={service.timeLength || ""}
                onChange={(e) => setService({ ...service, timeLength: Number(e.target.value) })}
                validationError={validationErrors.timeLength}
              />
              {service.variations.length > 0 && <CloseButton className="invisible flex-none" />}
            </div>
            {service.variations.map((variation) => {
              const otherVariations = allVariations.filter((v) => v.id !== variation.id)
              const priceExists = otherVariations.map((v) => v.amountCents).includes(variation.amountCents)
              const durationExists = otherVariations.map((v) => v.timeLength).includes(variation.timeLength)

              return (
                <div key={variation.id} className="flex w-full gap-4" data-test-id="adding-variation">
                  <div className="flex-1">
                    <Label htmlFor={`price-input-${variation.id}`}>Price</Label>
                    <CurrencyInput
                      validationError={priceExists ? "Must be different from other prices" : null}
                      id={`price-input-${variation.id}`}
                      value={variation.amountCents}
                      onChange={(amountCents) => {
                        const newVariations = [...service.variations]
                        const newVariation = { ...variation, amountCents }
                        const index = newVariations.findIndex((v) => v.id === newVariation.id)
                        if (index !== -1) {
                          newVariations[index] = newVariation
                        } else {
                          newVariations.push(newVariation)
                        }
                        setService({ ...service, variations: newVariations })
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          variations: null
                        }))
                      }}
                    />
                  </div>
                  <InputWithLabel
                    className="flex-1"
                    label="Session duration"
                    type="number"
                    placeholder="__ minutes"
                    value={variation.timeLength || ""}
                    validationError={durationExists ? "Must be different from other durations" : null}
                    onChange={(e) => {
                      const newVariations = [...service.variations]
                      const newVariation = { ...variation, timeLength: Number(e.target.value) }
                      const index = newVariations.findIndex((v) => v.id === newVariation.id)
                      if (index !== -1) {
                        newVariations[index] = newVariation
                      } else {
                        newVariations.push(newVariation)
                      }
                      setService({ ...service, variations: newVariations })
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        variations: null
                      }))
                    }}
                  />
                  <CloseButton
                    className="mt-[26px] flex-none"
                    onClick={() => {
                      const newVariations = service.variations.filter((v) => v.id !== variation.id)
                      setService({ ...service, variations: newVariations })
                    }}
                  />
                </div>
              )
            })}
            {validationErrors.variations && <ValidationError>{validationErrors.variations}</ValidationError>}
            {service.package ? (
              <InputWithLabel
                label="Number of sessions"
                type="number"
                value={service.numberOfSessions}
                className="w-1/2"
                onChange={(e) => setService({ ...service, numberOfSessions: Number(e.target.value) })}
                validationError={validationErrors.numberOfSessions}
              />
            ) : (
              <Button
                type="secondary"
                size="large"
                disabled={
                  service.variations.filter(
                    (variation) =>
                      typeof variation.id === "string" &&
                      (variation.amountCents === null || variation.timeLength === null)
                  ).length > 0
                }
                onClick={() => {
                  setService({
                    ...service,
                    variations: [...service.variations, { id: uuidv4(), amountCents: null, timeLength: null }]
                  })
                }}>
                Add another price and duration
              </Button>
            )}
            <div className="flex flex-col">
              <CheckBox
                label="List this service publicly on my website"
                checked={service.public}
                onChange={() => setService({ ...service, public: !service.public })}
              />
            </div>
            <div className="flex flex-col">
              <Label>Locations where this is offered</Label>
              {activeLocations.map((location) => {
                var label =
                  hasMultipleLocationsInSameCity && location.kind === "office" ? location.address : location.name

                if (!label) {
                  label = location.name
                }
                return (
                  <CheckBox
                    key={location.id}
                    label={label}
                    checked={service.locationIds.includes(location.id)}
                    onChange={() =>
                      service.locationIds.includes(location.id)
                        ? setService({
                            ...service,
                            locationIds: service.locationIds.filter((id) => id !== location.id)
                          })
                        : setService({ ...service, locationIds: [...service.locationIds, location.id] })
                    }
                  />
                )
              })}
            </div>
            <div className="flex flex-col">
              <Label>Availability</Label>
              <div className="flex gap-1">
                <CheckBox
                  label="I only offer this on certain days/times"
                  checked={service.availableTimesEnabled}
                  onChange={() =>
                    setService((service) => ({
                      ...service,
                      availableTimesEnabled: !service.availableTimesEnabled,
                      availableTimes: isEmpty(service.availableTimes) ? defaultAvailableTimes : service.availableTimes
                    }))
                  }
                />
                <QuestionMarkCircleIcon
                  className="h-6 w-6 text-gray-dark"
                  data-tooltip-id="custom-availability-tooltip"
                />
                <HealMeTooltip
                  id="custom-availability-tooltip"
                  place="bottom"
                  content="Service availability is based on the location(s) you offer a service from, but you can customize this for each service."
                />
              </div>
              {service.availableTimesEnabled && (
                <div className="mt-4">
                  <AvailableTimes availableTimes={service.availableTimes} updateState={setService} />
                </div>
              )}
            </div>
          </>
        )}
        {service.addOn && (
          <>
            <div className="flex flex-col">
              <CheckBox
                label="List this add-on publicly on my website"
                checked={service.public}
                onChange={() => setService({ ...service, public: !service.public })}
              />
            </div>
            <div className="flex-1">
              <Label htmlFor="price-input">Price</Label>
              <CurrencyInput
                id="price-input"
                value={service.amountCents}
                onChange={(amountCents) => setService({ ...service, amountCents })}
              />
            </div>
            <div className="flex flex-col">
              <Label>Services this add-on applies to</Label>
              {allServices
                .filter((s) => !s.addOn && !s.package)
                .map((s) => (
                  <CheckBox
                    key={s.id}
                    label={s.name}
                    checked={service.allowedServiceIds.includes(s.id)}
                    onChange={() =>
                      service.allowedServiceIds.includes(s.id)
                        ? setService({
                            ...service,
                            allowedServiceIds: service.allowedServiceIds.filter((id) => id !== s.id)
                          })
                        : setService({
                            ...service,
                            allowedServiceIds: [...service.allowedServiceIds, s.id]
                          })
                    }
                  />
                ))}
            </div>
          </>
        )}
      </div>
      {editingExistingService && lastActiveService && (
        <Banner type="warning">
          <b>Note:</b> This service can&apos;t be deleted since it is the only service you have.
        </Banner>
      )}
      <NewModal
        id={deleteModalId}
        header="Delete service now"
        showFooter={true}
        onSave={onDelete}
        cancelButtonCopy="No, don't delete"
        actionButtonType="destructive"
        actionButtonCopy="Yes, delete">
        <p>
          This will remove the service <b>{service.name}</b> from your profile.
        </p>
      </NewModal>
    </Flyout>
  )
}

export default ServiceFlyout

import React from "react"

import Row from "./Row"

export default function Confirmation({ clients, existingClients }) {
  return (
    <div className="columns is-centered">
      <div
        className={`column is-half ${
          existingClients.length === clients.length || existingClients.length === 0 ? "box" : ""
        }`}>
        {existingClients.length === clients.length ? (
          <div className="copy-paste-headline">Uh oh!</div>
        ) : (
          <div className="copy-paste-headline" style={{ marginBottom: "4px" }}>
            Import was completed!
          </div>
        )}
        <div>
          {existingClients.length ? (
            <div>
              {existingClients.length === clients.length ? (
                <div className="method-helper" style={{ marginBottom: "0" }}>
                  Looks like all of the clients you tried to add already exist in your directory.
                </div>
              ) : (
                <div className="method-helper">
                  An invitation was sent to {clients.length - existingClients.length}{" "}
                  {clients.length - existingClients.length > 1 ? "people" : "person"}.
                  <br />
                  <br />
                  <div style={{ fontWeight: "500", color: "#0B3954" }}>
                    These clients already exist in your directory
                  </div>
                  <div className="scrollable-area">
                    <table className="client-list table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Email</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {existingClients.map((row, index) => (
                          <Row
                            index={index + 1}
                            email={row.email}
                            first_name={row.first_name}
                            last_name={row.last_name}
                            phone={row.phone}
                            key={index}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="method-helper" style={{ marginBottom: "0", marginTop: "28px" }}>
              An invitation email was sent to {clients.length} {clients.length > 1 ? "people" : "person"}!
            </div>
          )}
        </div>
        <a href="/portal/clients" className="button btn-primary" style={{ marginTop: "32px", width: "100%" }}>
          See Clients
        </a>
      </div>
    </div>
  )
}

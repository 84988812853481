import dayjs from "dayjs"
import React, { useState } from "react"
import styled from "styled-components"
import { useMutation, useQuery } from "urql"

import DesktopH5 from "../shared/DesktopH5"
import DesktopTitle3 from "../shared/DesktopTitle3"
import HealMeSquareButton from "../shared/HealMeSquareButton"
import LoadingSpinner from "../shared/LoadingSpinner"
import Modal from "../shared/Modal"
import Photo from "../shared/Photo"

import { CardTitle } from "./ClientBookings"

const QUERY = `
  query BookingCard($id: ID!) {
    userAppointment(id: $id) {
      id
      practice {
        user {
          id
          commsName
          name
          profilePhotos {
            xLarge {
              jpeg
              webp
            }
            large {
              jpeg
              webp
            }
            medium {
              jpeg
              webp
            }
            small {
              jpeg
              webp
            }
            original {
              jpeg
              webp
            }
          }
        }
      }
      services {
        id
        name
        description
        timeLength
        amountCents
        photoUrl
        state
        addOn
      }
      location {
        id
        name
        address
        cityState
        zip
        kind
        timeZone
        formattedTimeZone
        timeZoneAbbr
        hideAddress
        country
        virtualLink
        virtualInstructions
      }
    }
  }
  `

const CANCEL_APPOINTMENT_MUTATION = `
  mutation CancelAppointmentNoUser($appointmentId: ID!) {
    cancelAppointmentNoUser(appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

const Card = styled.a`
  display: flex;
  gap: 10px;
  background: #fff;
  padding: 15px 15px 15px 15px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 0.5em 1em -0.125em rgba(11, 57, 84, 0.1), 0 0px 0 1px rgba(11, 57, 84, 0.02);
  color: #0b3954;
  font-size: 14px;
  margin-top: -15px;

  .booking-photo {
    height: 64px;
    min-height: 64px;
    width: 64px;
    min-width: 64px;
  }

  .booking-pro-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    position: relative;

    @media (max-width: 769px) {
      margin-bottom: 10px;
    }
  }

  .booking-state {
    ${(props) =>
      props.status === "CONFIRMED" &&
      `
      background-color: #48c774;
    `}

    ${(props) =>
      props.status === "PENDING" &&
      `
      background-color: #ffbe57;
    `}

    ${(props) =>
      props.status === "UNAVAILABLE" &&
      `
      background-color: #f75959;
    `}
    font-size: 10px;
    font-weight: 600;
    border-radius: 0;
    padding: 6px 6px 6px 6px;
    color: #fff;
    position: absolute;
    left: 87.5%;

    @media (max-width: 769px) {
      left: 72.5%;
    }
  }
  .booking-info {
    width: 100%;
    a {
      word-wrap: break-word;
    }
    @media (max-width: 769px) {
      max-width: 75%;
    }
  }
  .booking-time {
    font-size: 12px;
    color: #55778a;
  }

  .booking-time-price {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .booking-align-center {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`

const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  background: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #dcdfe6;
  border-style: solid;
  box-shadow: 0 0.5em 1em -0.125em rgba(11, 57, 84, 0.1), 0 0px 0 1px rgba(11, 57, 84, 0.02);
  margin-top: -24px;
  font-weight: 700;

  i {
    font-size: 18px;
    margin-right: 20px;
    font-weight: 700;
  }

  &:hover {
    cursor: pointer;
  }
`
const getStatus = (state) => {
  switch (state) {
    case "approved":
      return "CONFIRMED"
    case "pending":
      return "PENDING"
    case "unavailable":
      return "UNAVAILABLE"
  }
}

const BookingCard = ({ booking }) => {
  const [showConfirmCancel, setShowConfirmCancel] = useState(false)

  const [{ data, fetching, error }] = useQuery({
    query: QUERY,
    variables: { id: booking.id },
    requestPolicy: "cache-and-network"
  })

  const [{ fetching: cancelAppointmentFetching }, cancelAppointmentNoUser] = useMutation(CANCEL_APPOINTMENT_MUTATION)

  if (fetching) return <LoadingSpinner />
  if (error || !data) return <div>Error: {error.message}</div>

  const location = data.userAppointment.location
  const user = data.userAppointment.practice.user
  const services = data.userAppointment.services

  const processingFees = (booking.feeCents + booking.platformFeeCents) / 100
  const status = getStatus(booking.state)
  let timeZoneDisplay = new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]

  return (
    <>
      <CardTitle>{dayjs(booking.startsAt).format("dddd, MMM Do, YYYY")}</CardTitle>
      <Card status={status} href={`/m/c/${booking.instantActionToken}`}>
        <Photo filestack={user.profilePhotos} className="booking-photo" />

        <div className="booking-info">
          <div className="booking-pro-name">
            <div>{user.name}</div>
            <div className="booking-state">{status}</div>
          </div>

          <div className="mb-2">
            {services.map((service) => (
              <div key={service.id}>
                {service.name}
                {service.addOn ? " (Add-on)" : ""}
              </div>
            ))}
          </div>

          <div className="booking-time">
            {dayjs(booking.startsAt).format("MMMM DD YYYY, h:mm A")} - {dayjs(booking.endsAt).format("h:mm A")}{" "}
            {location.kind === "virtual" ? timeZoneDisplay : location.timeZoneAbbr}
          </div>

          <div className="booking-time-price">
            <div className="booking-align-center">
              <i className="icon-price" />{" "}
              {booking.amountCents > 0
                ? `${(booking.amountCents / 100 + processingFees).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD"
                  })}`
                : "Free"}
            </div>

            <div className="booking-align-center">
              <i className="icon-clock" />
              {booking.appointmentTimeLength} minutes
            </div>
          </div>

          <div className="booking-align-center">
            <i className="icon-location" />
            {location.kind === "virtual"
              ? "Virtual"
              : `${location.address}, ${location.cityState} ${location.zip}, ${location.country}`}
          </div>

          {location.kind === "virtual" && (
            <>
              {location.virtualLink && (
                <div>
                  Virtual link: <a href={location.virtualLink}>{location.virtualLink}</a>
                </div>
              )}
              {location.virtualInstructions && <div>Join instructions: {location.virtualInstructions}</div>}
            </>
          )}
        </div>
      </Card>

      {(status === "PENDING" || status === "UNAVAILABLE") && (
        <>
          <CancelContainer onClick={() => setShowConfirmCancel(true)}>
            <i className="icon-close" /> Cancel
          </CancelContainer>
          <Modal
            open={showConfirmCancel}
            closeModal={() => setShowConfirmCancel(false)}
            size="small"
            header={
              <div>
                <DesktopH5 content="Cancel booking" />
              </div>
            }
            footer={
              <>
                <HealMeSquareButton type="tertiary" onClick={() => setShowConfirmCancel(false)} label="Close" />
                <HealMeSquareButton
                  type="warning"
                  label="Cancel booking"
                  loading={cancelAppointmentFetching}
                  onClick={() =>
                    cancelAppointmentNoUser({ appointmentId: booking.id }).then(() => {
                      setShowConfirmCancel(false)
                      location.reload()
                    })
                  }
                />
              </>
            }>
            <DesktopTitle3 content={`Are you sure you want to cancel this booking?`} />
          </Modal>
        </>
      )}
    </>
  )
}

export default BookingCard

import React, { useEffect, useRef } from "react"

export default function CsvReader({ handleFileUpload, reviewInput, upload }) {
  const uploadFile = useRef(null)

  useEffect(() => {
    if (upload) {
      uploadFile.current.click()
    }
  }, [upload])

  const submit = (uploadedFile) => {
    const file = uploadedFile
    const reader = new FileReader()

    reader.onload = function (e) {
      const text = e.target.result
      const invitationType = "csv_import"
      reviewInput(text, invitationType)
      handleFileUpload()
    }

    reader.readAsText(file)
  }

  return (
    <div>
      <div className="file has-name is-fullwidth">
        <label className="file-label">
          <input
            className="button btn-primary"
            type="file"
            accept=".csv"
            onChange={(e) => {
              submit(e.target.files[0])
            }}
            style={{ display: "none" }}
            ref={uploadFile}
          />
        </label>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { useMutation } from "urql"

import DatePicker from "../components/shared/DatePicker"
import { EditableValue, Input, InputWithLabel } from "../components/shared/Inputs"
import { Box, Divider } from "../components/shared/Layout"
import { RadioWithLabel } from "../components/shared/RadioButtons"
import Typography from "../components/shared/Typography"
import { useToast } from "../contexts/ToastContext"

import ProfileEditProvider from "./ProfileEdit/ProfileEditProvider"

const UPDATE_INFO_MUTATION = `
  mutation UpdatePersonalSettings($name: String, $email: String, $phone: String, $gender: String, $dob: String, $customGender: String, $password: String, $passwordConfirmation: String) {
    updatePersonalSettings(name: $name, email: $email, phone: $phone, gender: $gender, dob: $dob, customGender: $customGender, password: $password, passwordConfirmation: $passwordConfirmation) {
      result
      user { id name email phone gender dob customGender }
    }
  }
`

const Details = ({ user }) => {
  const [userState, setUserState] = useState(user)
  const [lastSavedUserValue, setLastSavedUserValue] = useState(user)
  const [openField, setOpenField] = useState(null)

  const [{ fetching }, updatePersonalSettings] = useMutation(UPDATE_INFO_MUTATION)

  const { showToast } = useToast()

  const onSave = () => {
    if (
      !!userState.password &&
      !!userState.passwordConfirmation &&
      userState.password !== userState.passwordConfirmation
    ) {
      showToast({
        type: "error",
        content: "Your password and password confirmation do not match."
      })
      return
    }
    updatePersonalSettings(Object.fromEntries(Object.entries(userState).filter(([, v]) => !!v))).then((result) => {
      setOpenField(null)
      if (result.error) {
        console.error(result)
        setUserState(lastSavedUserValue)
        showToast({
          type: "error",
          content: result.error.message.replace("[GraphQL]", "")
        })
      } else {
        setLastSavedUserValue(result.data.updatePersonalSettings.user)
        showToast("Your settings have been saved.")
      }
    })
  }

  const onOpened = (fieldName) => {
    setOpenField(fieldName)
  }

  return (
    <div>
      <Typography variant="h3" as="h1" className="mb-6">
        My account
      </Typography>
      <Box>
        <EditableValue
          name="Name"
          value={userState.name}
          saveDisabled={userState.name === lastSavedUserValue.name || userState.name?.length < 2}
          disabled={fetching || (openField && openField !== "Name")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <Input
            name="Name"
            value={userState.name || ""}
            onChange={(e) => setUserState((values) => ({ ...values, name: e.target.value }))}
          />
        </EditableValue>
        <Divider />
        <EditableValue
          name="Email"
          value={userState.email}
          saveDisabled={userState.email === lastSavedUserValue.email}
          disabled={fetching || (openField && openField !== "Email")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <Input
            name="Email"
            value={userState.email || ""}
            onChange={(e) => setUserState((values) => ({ ...values, email: e.target.value }))}
          />
        </EditableValue>
        <Divider />
        <EditableValue
          name="Phone"
          value={userState.phone}
          saveDisabled={userState.phone === lastSavedUserValue.phone}
          disabled={fetching || (openField && openField !== "Phone")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <Input
            name="Phone"
            value={userState.phone || ""}
            onChange={(e) => setUserState((values) => ({ ...values, phone: e.target.value }))}
          />
        </EditableValue>
        <Divider />
        <EditableValue
          name="Gender"
          value={(userState.gender === "Custom" ? userState.customGender : userState.gender) || ""}
          saveDisabled={userState.gender === lastSavedUserValue.gender}
          disabled={fetching || (openField && openField !== "Gender")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <div className="flex gap-4">
            <RadioWithLabel
              value="Female"
              name="gender"
              label="Female"
              checked={userState.gender === "Female"}
              onChange={(e) => setUserState({ ...userState, gender: e.target.value })}
            />
            <RadioWithLabel
              value="Male"
              name="gender"
              label="Male"
              checked={userState.gender === "Male"}
              onChange={(e) => setUserState({ ...userState, gender: e.target.value })}
            />
            <RadioWithLabel
              value="Custom"
              name="gender"
              label="Custom"
              checked={userState.gender === "Custom"}
              onChange={(e) => setUserState({ ...userState, gender: e.target.value })}
            />
          </div>
          {userState.gender === "Custom" && (
            <Input
              className="mt-4"
              name="Custom gender"
              value={userState.customGender || ""}
              onChange={(e) => setUserState((values) => ({ ...values, customGender: e.target.value }))}
            />
          )}
        </EditableValue>
        <Divider />
        <EditableValue
          name="Date of birth"
          value={userState.dob ? new Date(userState.dob).toDateString() : null}
          saveDisabled={userState.dob === lastSavedUserValue.dob}
          disabled={fetching || (openField && openField !== "Date of birth")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <DatePicker
            selected={userState.dob ? new Date(userState.dob) : ""}
            onChange={(date) => {
              setUserState((values) => ({ ...values, dob: date }))
            }}
          />
        </EditableValue>
        <Divider />
        <EditableValue
          name="Password"
          value="Reset your password"
          editButtonCopy="Update"
          disabled={fetching || (openField && openField !== "Password")}
          onOpened={onOpened}
          onSave={onSave}
          onCancel={() => {
            setOpenField(null)
            setUserState(lastSavedUserValue)
          }}>
          <InputWithLabel
            className="mb-4"
            label="New password"
            type="password"
            value={userState.password || ""}
            onChange={(e) => setUserState((values) => ({ ...values, password: e.target.value }))}
          />
          <InputWithLabel
            label="Confirm password"
            type="password"
            value={userState.passwordConfirmation || ""}
            onChange={(e) => setUserState((values) => ({ ...values, passwordConfirmation: e.target.value }))}
          />
        </EditableValue>
      </Box>
    </div>
  )
}

export default function PersonalSettings({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <Details {...props} />
    </ProfileEditProvider>
  )
}

import React from "react"
import MaskedInput from "react-text-mask"
import styled from "styled-components"
import createNumberMask from "text-mask-addons/dist/createNumberMask"

import { BASE_INPUT_CLASSNAMES } from "./Inputs"

const InputWrapper = styled.div`
  input {
    border: 1px solid rgb(var(--color-gray));
    background: white;
    padding: 6px 12px;
    width: 100%;
    margin: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0.5rem;
  }

  input:disabled {
    background-color: rgb(var(--color-ultra-light-gray));
    color: rgb(var(--color-dark-gray));
    cursor: not-allowed;
  }

  input:focus {
    border-color: rgb(var(--color-black));
    box-shadow: 0 0 0 4px rgb(var(--color-blue));
  }

  input::placeholder {
    color: rgb(var(--color-dark-gray));
  }
`

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
}

const CurrencyInput = ({ ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions
  })

  return (
    <InputWrapper>
      <MaskedInput className={BASE_INPUT_CLASSNAMES} mask={currencyMask} {...inputProps} />
    </InputWrapper>
  )
}

export default CurrencyInput

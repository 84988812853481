import React, { useState } from "react"
import { useMutation } from "urql"

import { EndorsementPill } from "../../components/shared/Pill"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import ReviewProvider from "../../providers/ReviewProvider"

import { ReviewLayout } from "./ReviewLayout"

const REVIEW_ENDORSEMENTS_MUTATION = `
  mutation ReviewAddEndorsements($reviewId: ID!, $endorsements: [String!]) {
    reviewAddEndorsements(reviewId: $reviewId, endorsements: $endorsements) {
      result
      errors
    }
  }
`

const EndorsementButton = ({ specialty, active, onClick, onCloseButtonClick }) => (
  <EndorsementPill
    value={specialty.id.toString()}
    active={active}
    className="mb-0 block"
    onClick={onClick}
    onCloseButtonClick={onCloseButtonClick}>
    {specialty.name}
  </EndorsementPill>
)

const Endorsements = ({ review }) => {
  const { showToast } = useToast()
  const [endorsements, setEndorsements] = useState([])
  const [, addEndorsements] = useMutation(REVIEW_ENDORSEMENTS_MUTATION)

  const isFormValid = endorsements.length > 0

  const handleAddEndorsements = () => {
    addEndorsements({ reviewId: review.id, endorsements: Array.from(endorsements) }).then((res) => {
      if (res.data.reviewAddEndorsements.result === "success") {
        window.location.href = `/i/r/text_review/${review.id}`
      } else {
        showToast({
          content: `Error creating client: ${res.data.reviewAddEndorsements.errors.join(" ")}`,
          type: "error"
        })
      }
    })
  }

  const onSpecialtyClick = (e) => {
    setEndorsements((endorsements) => {
      const newEndorsements = [...endorsements]
      const specialtyId = e.target.value

      if (newEndorsements.includes(specialtyId)) {
        const index = newEndorsements.indexOf(specialtyId)
        newEndorsements.splice(index, 1)
      } else {
        newEndorsements.push(specialtyId)
      }
      return newEndorsements
    })
  }

  return (
    <ReviewLayout
      percentDone="70%"
      onContinue={handleAddEndorsements}
      continueDisabled={!isFormValid}
      proPic={review.practitioner.filestackPhoto}
      skipStep={() => (window.location.href = `/i/r/text_review/${review.id}?skip_step=true`)}
      mobileOverflow={true}>
      <Typography variant="h3">
        Thank you for that great rating! What is {review.practitioner.firstName} good at helping with?
      </Typography>
      <Typography variant="subtitle" className="mb-6">
        This is optional, but it lets us know how to match {review.practitioner.firstName} with ideal clients.
      </Typography>
      <div className="-mb-10 flex max-h-[300px] flex-wrap gap-2 overflow-y-scroll sm:mb-20 sm:h-auto sm:max-h-none">
        {review.skills.map((specialty) => (
          <EndorsementButton
            specialty={specialty}
            key={specialty.id}
            active={endorsements.includes(specialty.id.toString())}
            onClick={onSpecialtyClick}
          />
        ))}
      </div>
    </ReviewLayout>
  )
}

export default function ReviewEndorsements(props) {
  return (
    <ReviewProvider>
      <Endorsements {...props} />
    </ReviewProvider>
  )
}

import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useQuery } from "urql"

import { CardTitle } from "./ClientBookings"

const QUERY = `
  query TransactionCard($practiceId: ID!, $serviceId: ID!) {
    practice(id: $practiceId) {
      id
      user {
        id
        commsName
        name
      }
    }
    service(id: $serviceId) {
      id
      name
      amountCents
    }
  }
  `

const Card = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
  background: #fff;
  padding: 15px 15px 15px 15px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 0.5em 1em -0.125em rgba(11, 57, 84, 0.1), 0 0px 0 1px rgba(11, 57, 84, 0.02);
  color: #0b3954;
  font-size: 14px;

  .transaction-type-row {
    color: #0b3954;
    font-size: 18px;
    font-weight: 300;
  }

  .transaction-state {
    background-color: #f75959;
    ${(props) =>
      props.status === "PROCESSED" &&
      `
      background-color: #48c774;
    `}
    ${(props) =>
      props.status === "PENDING" &&
      `
      background-color: #ffbe57;
    `}

    font-size: 10px;
    font-weight: 600;
    border-radius: 0;
    padding: 6px 8px 6px 8px;
    color: #fff;
    position: absolute;
    left: 585px;
  }

  .transaction-info {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 15px;

    div {
      display: flex;
      align-items: center;
      gap: 4px;

      i {
        font-size: 16px;
      }
    }
  }

  .transaction-amount {
    position: absolute;
    left: 585px;
  }
`
const getStatus = (type, state) => {
  if (type === "Hold" && state === 1) {
    return "PENDING"
  } else if (state === 0) {
    return "PENDING"
  } else if (state === 1) {
    return "PROCESSED"
  } else if (state === 2) {
    return "FAILURE"
  } else {
    return "CANCELLED"
  }
}

const TransactionCard = ({ booking }) => {
  if (!booking.transaction) return null
  const [transaction] = useState(booking.transaction)
  const [practice, setPractice] = useState()
  const [service, setService] = useState()
  const processingFees = (booking.feeCents + booking.platformFeeCents) / 100
  const status = getStatus(transaction.type, transaction.state)

  const [{ data }] = useQuery({
    query: QUERY,
    variables: {
      practiceId: booking.practiceId,
      serviceId: booking.serviceId
    },
    requestPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (data) {
      setPractice(data.practice)
      setService(data.service)
    }
  }, [data])

  return (
    <>
      <CardTitle>{dayjs(booking.startsAt).format("dddd, MMM Do, YYYY")}</CardTitle>
      <Card status={status}>
        <div className="transaction-type-row">
          {transaction.type === "Charge" && <span>Payment</span>}
          {transaction.type === "Hold" && <span>Your card will be charged the day of the appointment</span>}
          {transaction.type !== "Charge" && transaction.type !== "Hold" && <span>{transaction.type}</span>}
        </div>
        <div className="transaction-state">{status}</div>
        <div className="transaction-info">
          <div>
            <i className="icon-practitioner-search" />
            {practice?.user.name}
          </div>
          <div>
            <i className="icon-match" />
            {service?.name}
          </div>
          <div className="transaction-amount">
            {`${(service?.amountCents / 100 + processingFees).toLocaleString("en-US", {
              style: "currency",
              currency: "USD"
            })}`}
          </div>
        </div>
      </Card>
    </>
  )
}

export default TransactionCard

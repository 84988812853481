import { XMarkIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { useMutation } from "urql"

import { Button } from "../components/shared/Buttons"
import { FilePicker } from "../components/shared/FileStackWidgets"
import { Box, ProfilePageEmptyStateBox } from "../components/shared/Layout"
import Typography from "../components/shared/Typography"
import { useProfileStatus } from "../contexts/ProfileStatusContext"
import { useToast } from "../contexts/ToastContext"

import ProfileEditProvider from "./ProfileEdit/ProfileEditProvider"
import ProfileEditView from "./ProfileEdit/ProfileEditView"

const REMOVE_GALLERY_IMAGE_MUTATION = `
  mutation RemoveGalleryImage($galleryId: ID!) {
    removeGalleryImage(galleryId: $galleryId) {
      result
      errors
    }
  }
`

const ADD_GALLERY_IMAGE_MUTATION = `
  mutation AddGalleryImage($handles: [String!]!) {
    addGalleryImage(handles: $handles) {
      result
      errors
      galleryImages {
        id
        defaultPhotoUrl
      }
    }
  }
`

const REORDER_GALLERY_IMAGES_MUTATION = `
  mutation ReorderGalleryImages($photosArray: [String!]!) {
    reorderGalleryImages(photosArray: $photosArray) {
      result
    }
  }
`

const Photos = ({ path, galleryImages }) => {
  const { profileStatusData, syncProfileStatus } = useProfileStatus()
  const [photos, setPhotos] = useState(galleryImages)
  const [{ fetching: removeImageFetching }, removeGalleryImage] = useMutation(REMOVE_GALLERY_IMAGE_MUTATION)
  const [{ fetching: addImagesFetching }, addGalleryImage] = useMutation(ADD_GALLERY_IMAGE_MUTATION)
  const [, reorderGalleryImages] = useMutation(REORDER_GALLERY_IMAGES_MUTATION)

  const { showToast } = useToast()

  const loading = removeImageFetching || addImagesFetching
  const disabled = loading

  const onUploadDone = (res) => {
    addGalleryImage({
      handles: res.filesUploaded.map((file) => file.handle)
    }).then((result) => {
      if (result?.data?.addGalleryImage?.result === "success") {
        setPhotos([...photos, ...result.data.addGalleryImage.galleryImages])
        showToast("Your photo has been saved.")
        syncProfileStatus()
      } else {
        console.error(result)
        let errorMessage = "There was an error saving your profile photo. Please try again later or contact support."
        if (result.data?.addGalleryImage?.errors) errorMessage += ` ${result.data.addGalleryImage.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const removePhoto = (galleryId) => {
    removeGalleryImage({ galleryId }).then((result) => {
      if (result?.data?.removeGalleryImage?.result === "success") {
        setPhotos(photos.filter((photo) => photo.id !== galleryId))
        showToast("Successfully deleted photo")
        syncProfileStatus()
      } else {
        showToast({ type: "error", content: "Failed to delete photo" })
        console.error(result)
      }
    })
  }

  return (
    <ProfileEditView path={path} profileStatusData={profileStatusData}>
      <div className={loading ? "pointer-events-none opacity-25" : ""}>
        {photos.length === 0 ? (
          <ProfilePageEmptyStateBox title="Photos">
            Add photos that help clients see what working with you is like.
            <FilePicker maxFiles={10} onUploadDone={onUploadDone} disabled={disabled}>
              <Button className="mt-4" size="small" disabled={disabled}>
                Add now
              </Button>
            </FilePicker>
          </ProfilePageEmptyStateBox>
        ) : (
          <Box>
            <div className="flex items-center justify-between border-b border-gray-light pb-6">
              <Typography variant="capitalHeading">Photos</Typography>
              <div data-tooltip-id="disabled-tooltip">
                <FilePicker maxFiles={10} onUploadDone={onUploadDone} disabled={disabled}>
                  <Button size="small" disabled={disabled}>
                    Add new
                  </Button>
                </FilePicker>
              </div>
            </div>
            <div className="my-4" />
            <Typography className="my-4" variant="subtitle">
              Drag to re-order your photos.
            </Typography>
            <ReactSortable
              className="grid grid-cols-2 gap-6"
              list={photos}
              setList={(newPositions) => {
                if (JSON.stringify(newPositions) !== JSON.stringify(photos)) {
                  const newOrder = newPositions.map((photo, i) => JSON.stringify({ id: photo.id, position: i + 1 }))
                  reorderGalleryImages({ photosArray: newOrder }).then((result) => {
                    if (result?.data?.reorderGalleryImages?.result !== "success") {
                      console.error(result)
                      showToast({
                        type: "error",
                        content:
                          "There was error reordering your credentials. Please try again later or contact support if the error persists."
                      })
                    }
                  })
                  setPhotos(newPositions)
                }
              }}>
              {photos.map((photo) => (
                <div key={photo.id} className="relative aspect-square cursor-grab">
                  <button
                    className="absolute right-4 top-4 flex h-10 w-10 items-center justify-center rounded-full border border-gray bg-white sm:right-2 sm:top-2 sm:h-6 sm:w-6"
                    onClick={() => removePhoto(photo.id)}>
                    <XMarkIcon className="h-6 w-6 stroke-2 text-gray-dark sm:h-5 sm:w-5" />
                  </button>
                  <img className="h-full w-full" src={photo.defaultPhotoUrl} alt="" />
                </div>
              ))}
            </ReactSortable>
          </Box>
        )}
      </div>
    </ProfileEditView>
  )
}

export default function ProfileEditPhotos({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <Photos {...props} />
    </ProfileEditProvider>
  )
}

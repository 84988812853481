import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "urql"

import {
  DELETE_BOOKABLE_EVENT_TYPE_MUTATION,
  REMOVE_BOOKABLE_EVENT_TYPE_PHOTO_MUTATION,
  UPDATE_BOOKABLE_EVENT_TYPE_PHOTO_MUTATION,
  UPSERT_BOOKABLE_EVENT_TYPE_MUTATION
} from "./mutations"
import { GET_BOOKABLE_EVENT_TYPES_QUERY } from "./queries"

const initialEventType = {
  name: "",
  description: "",
  instructions: "",
  amount: { cents: 0, currency: "USD" },
  capacity: null,
  kind: "in_person",
  location: "",
  slug: ""
}

export const BookableEventTypesContext = createContext()

export const BookableEventTypesProvider = ({ children, bookableEventTypes: bookableEventTypesProp }) => {
  const [showFlyout, setShowFlyout] = useState(false)
  const [bookableEventType, setBookableEventType] = useState({ ...initialEventType })
  const [bookableEventTypes, setBookableEventTypes] = useState(bookableEventTypesProp || [])
  const [googlePlacesLoaded, setGooglePlacesLoaded] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  // Only fetch event types if they are not provided - useful in the AppointmentFlyout
  const [{ data: fetchedEventTypesData, fetching: fetchingEventTypes }] = useQuery({
    query: GET_BOOKABLE_EVENT_TYPES_QUERY,
    pause: !!bookableEventTypesProp
  })

  const [{ fetching: upsertMutationFetching }, upsertBookableEventType] = useMutation(
    UPSERT_BOOKABLE_EVENT_TYPE_MUTATION
  )
  const [{ fetching: photoMutationFetching }, updatePhoto] = useMutation(UPDATE_BOOKABLE_EVENT_TYPE_PHOTO_MUTATION)
  const [{ fetching: deleteMutationFetching }, deleteBookableEventType] = useMutation(
    DELETE_BOOKABLE_EVENT_TYPE_MUTATION
  )
  const [{ fetching: removePhotoMutationFetching }, removePhoto] = useMutation(
    REMOVE_BOOKABLE_EVENT_TYPE_PHOTO_MUTATION
  )

  const fetching =
    upsertMutationFetching ||
    photoMutationFetching ||
    deleteMutationFetching ||
    removePhotoMutationFetching ||
    fetchingEventTypes

  // Set the bookableEventTypes state if it was fetched
  useEffect(() => {
    if (fetchedEventTypesData?.bookableEventTypes && !bookableEventTypesProp) {
      setBookableEventTypes(fetchedEventTypesData.bookableEventTypes)
    }
  }, [fetchedEventTypesData, bookableEventTypesProp])

  // Initialize the Google Places API for the location field
  useEffect(() => {
    async function initPlaces() {
      await window.google.maps.importLibrary("places")
      setGooglePlacesLoaded(true)
    }

    initPlaces()
  }, [])

  const updateBookableEventTypes = (newEventType) => {
    if (!bookableEventTypes) return

    setBookableEventTypes((prevEventTypes) => {
      const newEventTypes = [...prevEventTypes]
      const index = newEventTypes.findIndex((s) => s.id.toString() === newEventType.id.toString())

      if (index !== -1) {
        newEventTypes[index] = newEventType
      } else {
        newEventTypes.push(newEventType)
      }

      return newEventTypes
    })
  }

  const removeBookableEventTypeFromList = (id) => {
    setBookableEventTypes((prevEventTypes) => prevEventTypes.filter((eventType) => eventType.id !== id))
  }

  const resetEventType = useCallback(() => {
    setBookableEventType({ ...initialEventType })
  }, [])

  const editEventType = useCallback((eventType) => {
    setBookableEventType({ ...eventType })
    setShowFlyout(true)
  }, [])

  const addEventType = useCallback(() => {
    resetEventType()
    setShowFlyout(true)
  }, [resetEventType])

  const saveEventType = useCallback(async () => {
    const result = await upsertBookableEventType({ ...bookableEventType })

    // If success, update the bookableEventTypes state
    if (result?.data?.upsertBookableEventType?.result === "success") {
      updateBookableEventTypes(result.data.upsertBookableEventType.bookableEventType)
    }

    return result
  }, [upsertBookableEventType, bookableEventType])

  const value = {
    bookableEventTypes,
    setBookableEventTypes,
    showFlyout,
    setShowFlyout,
    bookableEventType,
    setBookableEventType,
    editEventType,
    addEventType,
    saveEventType,
    googlePlacesLoaded,
    fetching,
    upsertBookableEventType,
    updatePhoto,
    deleteBookableEventType,
    removePhoto,
    updateBookableEventTypes,
    deleteModalVisible,
    setDeleteModalVisible,
    removeBookableEventTypeFromList
  }

  return <BookableEventTypesContext.Provider value={value}>{children}</BookableEventTypesContext.Provider>
}

export const useBookableEventTypes = () => useContext(BookableEventTypesContext)

import capitalize from "lodash/capitalize"
import React, { useState } from "react"
import { useMutation } from "urql"

import { Button } from "../../../components/shared/Buttons"
import { Select } from "../../../components/shared/Inputs"
import { Divider } from "../../../components/shared/Layout"
import { RadioWithLabel } from "../../../components/shared/RadioButtons"
import { useToast } from "../../../contexts/ToastContext"

const UPDATE_CRONOFY_CLIENT_MUTATION = `
  mutation UpdateCronofyClient($writeCalendarId: ID!) {
    updateCronofyClient(writeCalendarId: $writeCalendarId) {
      result
      errors
    }
  }
`

const WriteCalendarSettings = ({ connectedCalendars, writeCalendar, setWriteCalendar, onSave }) => {
  const [writeProvider, setWriteProvider] = useState(
    connectedCalendars.find((cal) =>
      cal.calendars.map((calendar) => calendar.calendarId).includes(writeCalendar?.calendarId)
    )
  )

  const [, updateCronofyClient] = useMutation(UPDATE_CRONOFY_CLIENT_MUTATION)
  const { showToast } = useToast()

  return (
    <div>
      <div className="my-4 flex flex-col gap-2">
        {connectedCalendars.map((provider) => (
          <RadioWithLabel
            key={provider.id}
            id={`write-provider-${provider.id}`}
            name="write-provider"
            label={`${provider.profileName} (${capitalize(provider.providerName)})`}
            checked={!writeProvider ? false : writeProvider.id === provider.id}
            onChange={() => {
              setWriteProvider(provider)
              if (!writeProvider) setWriteCalendar(provider.calendars[0])
            }}
          />
        ))}
        <RadioWithLabel
          name="write-provider"
          label="Do not add new events to a calendar"
          checked={!writeProvider}
          onChange={() => {
            setWriteProvider(null)
            setWriteCalendar(null)
          }}
        />
      </div>
      {writeProvider && (
        <>
          <Divider />
          <div className="mb-4 font-bold">Add to calendar</div>
          <Select
            options={writeProvider.calendars.map((cal) => ({ value: cal.calendarId, label: cal.calendarName }))}
            value={writeCalendar?.calendarId || writeProvider.calendars[0].calendarId}
            onChange={(e) => setWriteCalendar(writeProvider.calendars.find((cal) => cal.calendarId === e.target.value))}
          />
        </>
      )}
      <div className="mt-4 flex justify-end">
        <Button
          onClick={() => {
            updateCronofyClient({ writeCalendarId: !writeCalendar ? "" : writeCalendar.calendarId }).then((result) => {
              if (result.data?.updateCronofyClient?.result === "success") {
                setWriteCalendar(
                  writeProvider
                    ? writeProvider.calendars.find((cal) => cal.calendarId === writeCalendar.calendarId)
                    : null
                )
                showToast("Write calendar updated successfully")
                onSave()
              } else {
                showToast({ content: "Failed to update write calendar", type: "error" })
              }
            })
          }}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default WriteCalendarSettings

import React from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "../../components/shared/Buttons"
import Photo from "../../components/shared/Photo"
import ProgressBar from "../../components/shared/ProgressBar"

const logo = require("../../../assets/images/healme_logo.svg")

export const ReviewLayout = ({
  children,
  percentDone,
  showProgressBar = true,
  proPic,
  onContinue,
  continueDisabled,
  hideContinue = false,
  finalStep = false,
  skipStep,
  mobileOverflow = false,
  showBackButton = true,
  ...rest
}) => (
  <div
    {...rest}
    className="fixed left-0 top-0 z-9998 flex h-screen w-screen  justify-center overflow-y-scroll bg-gray-ultralight">
    <div className="mt-4 flex w-[560px] flex-col items-center sm:w-full">
      <img src={logo} width="20" />
      <ProgressBar
        className={twMerge("mt-4 block h-[6px] w-[200px] rounded sm:w-[300px]", !showProgressBar && "opacity-0")}
        percentComplete={percentDone}
      />
      <div
        className={twMerge(
          "my-4 mb-24 flex h-fit w-full flex-col rounded-xl border border-gray-light bg-white sm:my-0 sm:rounded-none",
          mobileOverflow ? "sm:h-auto" : "sm:h-screen"
        )}>
        <div className="p-12 sm:p-[25px] sm:pb-32">
          {proPic && (
            <Photo
              imgClassName="rounded-full mb-8 mx-auto sm:ml-0 sm:mb-6 block h-[80px] w-[80px]"
              filestack={proPic}
            />
          )}
          {children}
        </div>
        {!hideContinue && (
          <div className="flex w-full items-center justify-end border-t border-gray-light bg-white px-12 py-8 sm:fixed sm:bottom-0 sm:px-8">
            {skipStep && (
              <Button type="tertiary" className="mr-auto" onClick={skipStep}>
                Skip <span className="sm:hidden">step</span>
              </Button>
            )}
            {showBackButton && (
              <Button type="tertiary" className="float-right mr-4" onClick={() => window.history.back()}>
                Back
              </Button>
            )}
            <Button type="primary" className="float-right" onClick={onContinue} disabled={continueDisabled}>
              {finalStep ? "Share review" : "Continue"}
            </Button>
          </div>
        )}
      </div>
    </div>
  </div>
)

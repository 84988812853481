import NiceModal from "@ebay/nice-modal-react"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { PAYMENT_STATUS_OPTIONS } from "../@core/appointment/appointment.model"
import InfoParagraph from "../components/shared/InfoParagraph"
import { Divider } from "../components/shared/Layout"
import Toast from "../components/shared/Toast"
import { AppointmentProvider, useAppointment } from "../contexts/AppointmentContext"
import { ToastProvider, useToast } from "../contexts/ToastContext"
import GraphQLProvider from "../providers/GraphQLProvider"
import { CHARGE_CARD_MUTATION } from "../utils/mutations"
import { formatPhone } from "../utils/utils"

import AddPaymentButton from "./ManageAppointmentsConsumer/AddPaymentButton"
import CancelAppointmentButton from "./ManageAppointmentsConsumer/CancelAppointmentButton"
import OfflinePaymentMethods from "./ManageAppointmentsConsumer/OfflinePaymentMethods"
import PaymentStatusBox from "./ManageAppointmentsConsumer/PaymentStatusBox"

// import RescheduleAppointmentButton from "./ManageAppointmentsConsumer/RescheduleAppointmentButton"

const PriceRow = ({ label, value, className }) => (
  <div className={twMerge("flex items-center justify-between", className)}>
    <span className="text-left">{label}</span>
    <span className="ml-2 font-bold">{value}</span>
  </div>
)

const PageContent = ({ appointmentData }) => {
  const { showToast } = useToast()

  const { setAppointment: setContextAppointment } = useAppointment()
  useEffect(() => {
    setContextAppointment(appointmentData)
  }, [appointmentData])

  const [appointment, setAppointment] = useState(appointmentData)
  const [alertContent, setAlertContent] = useState("")
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertWarning, setAlertWarning] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  const timeZone =
    !appointment.ianaTimeZone || appointment.location.kind === "virtual" ? dayjs.tz.guess() : appointment.ianaTimeZone
  const startsAt = dayjs(appointment.startsAt).tz(timeZone)
  const endsAt = dayjs(appointment.endsAt).tz(timeZone)
  const appointmentIsPast = appointment.past
  const status = appointment.clientPaymentStatus
  const shouldPayNow =
    status === PAYMENT_STATUS_OPTIONS.CARD_NEEDED_PAST_DUE ||
    status === PAYMENT_STATUS_OPTIONS.PAST_DUE ||
    status === PAYMENT_STATUS_OPTIONS.FAILED
  const showAddCardButton = [
    PAYMENT_STATUS_OPTIONS.PAST_DUE,
    PAYMENT_STATUS_OPTIONS.FAILED,
    PAYMENT_STATUS_OPTIONS.CARD_NEEDED,
    PAYMENT_STATUS_OPTIONS.CARD_NEEDED_PAST_DUE
  ].some((s) => s === status)
  const showAddTipButton =
    status === PAYMENT_STATUS_OPTIONS.PAID &&
    appointmentIsPast &&
    appointment.practice.acceptsTips &&
    appointment.tipAmountCents === 0
  const titleStatus = appointmentIsPast ? "passed" : appointment.state === "pending" ? "pending" : "accepted!"
  const subTitle =
    appointment.state === "cancelled"
      ? "This appointment has been cancelled."
      : appointment.state === "pending"
      ? "This appointment is pending confirmation from your practitioner."
      : appointmentIsPast
      ? "This appointment has already ended."
      : `We're looking forward to seeing you on ${startsAt.format("MMMM D")} at ${startsAt.format("h:mm a")}.`
  const amount = `$${(appointment.amountCents / 100).toFixed(2)}`
  const formattedTip = `$${(appointment.tipAmountCents / 100).toFixed(2)}`
  const formattedTotal = `$${((appointment.amountCents + appointment.tipAmountCents) / 100).toFixed(2)}`
  const formattedPhone = formatPhone(appointment.practice.user.phone)
  const isVirtual = appointment.location.kind === "virtual"
  const virtualLink = appointment.virtualMeetingLinkWithFallback
  const isPhoneCall = appointment.location.virtualType === "phone"

  const [, chargeCard] = useMutation(CHARGE_CARD_MUTATION)

  const showAlert = (message, type) => {
    setAlertVisible(true)
    setAlertWarning(type === "error")
    setAlertContent(message)
  }

  const chargeAndAlert = (tip) => {
    chargeCard({ appointmentId: appointment.id }).then((res) => {
      setButtonLoading(false)
      if (res.data?.chargeCard?.result === "success") {
        showAlert("Your payment has been processed successfully.")
        setAppointment({
          ...appointment,
          ...{
            clientPaymentStatus: res.data.chargeCard.appointment.clientPaymentStatus,
            tipAmountCents: tip || appointment.tipAmountCents
          }
        })
      } else {
        if (res.error) console.error(res.error) // eslint-disable-line no-console
        showToast({
          content: `There was an error processing your payment: ${
            res.data?.chargeCard?.errors || "Internal server error"
          }`,
          type: "error"
        })
      }
    })
  }

  const onPaypalSuccess = (appointmentResponse) => {
    setAppointment({
      ...appointment,
      ...{
        clientPaymentStatus: appointmentResponse.clientPaymentStatus,
        tipAmountCents: appointmentResponse.tipAmountCents
      }
    })
    showAlert("Your payment has been processed successfully.")
  }

  const onCreditCardSave = (tip) => {
    if (shouldPayNow) {
      setButtonLoading(true)
      chargeAndAlert(tip)
    } else {
      setAppointment({ ...appointment, ...{ clientPaymentStatus: PAYMENT_STATUS_OPTIONS.PENDING } })
      showAlert("Your payment method has been saved.")
    }
  }

  const onTipSave = (tip) => {
    setAppointment({ ...appointment, ...{ tipAmountCents: tip } })
    showToast({ content: `Tip added successfully. Thank you!`, type: "success" })
  }

  return (
    <div className="m-auto w-full py-4 text-center">
      {appointment.state !== "cancelled" && <div className="mb-4 text-2xl font-bold">Appointment {titleStatus}</div>}
      <p className="mb-4 text-gray-dark">{subTitle}</p>
      <div className="m-auto max-w-lg border border-gray p-4 sm:border-none">
        <div className="mb-4 flex items-center justify-center">
          <img src={appointment.practice.user.defaultPhotoUrl} className="h-16 w-16 rounded-full" />
          <div className="inline-flex flex-col">
            <div className="ml-4 text-lg font-bold">{appointment.practice.user.name}</div>
            <a href={`tel:${formattedPhone}`} className="ml-4 text-sm font-bold text-teal">
              {formattedPhone}
            </a>
          </div>
        </div>

        <div className="mb-4 font-bold">
          <div>{startsAt.format("dddd, MMMM D, YYYY")}</div>
          <div>
            {startsAt.format("h:mm a")} - {endsAt.format("h:mm a z")}
          </div>
          <div className={twMerge("my-4", isVirtual && "mb-0")}>
            <span>{appointment.locationName}</span>
            {isVirtual && isPhoneCall && <span> (Phone Call)</span>}
            {isVirtual && !isPhoneCall && <span> (Video)</span>}
          </div>
          {isVirtual && appointment.location.virtualInstructions && (
            <div className="font-normal">{appointment.location.virtualInstructions}</div>
          )}
          {isVirtual && !isPhoneCall && virtualLink && (
            <a href={virtualLink} className="text-sm text-teal">
              Link to Join
            </a>
          )}
        </div>

        {appointment.amountCents === 0 && (
          <div>
            {appointment.appointmentServices.map((as) => (
              <div key={as.id}>{as.service.name}</div>
            ))}
          </div>
        )}

        {appointment.amountCents > 0 && (
          <div className="m-auto w-full">
            <div className="my-4 border-y border-gray py-4">
              {appointment.appointmentServices.map((as) => (
                <PriceRow key={as.id} label={as.service.name} value={`$${(as.amountCents / 100).toFixed(2)}`} />
              ))}

              <Divider className="my-2" />
              <PriceRow label="Subtotal" value={amount} className="font-semibold" />

              <PriceRow
                label="Tip"
                value={formattedTip}
                className={appointment.tipAmountCents === 0 ? "text-gray" : ""}
              />

              <Divider className="my-2" />
              <PriceRow label="Total" value={formattedTotal} className="text-lg font-bold" />
            </div>
          </div>
        )}

        {appointment.state === "approved" && appointment.practice.hasPaymentProvider && (
          <>
            <PaymentStatusBox appointment={appointment} />
            {appointmentIsPast && showAddCardButton && (
              <AddPaymentButton
                label="Pay now"
                modalHeaderLabel="Pay for your session"
                loading={buttonLoading}
                onSave={onCreditCardSave}
                onPaypalSuccess={onPaypalSuccess}
                modalActionButtonCopy={shouldPayNow ? "Pay now" : "Save credit card"}
              />
            )}
            {!appointmentIsPast && showAddCardButton && (
              <AddPaymentButton
                label="Add credit card"
                modalHeaderLabel="Add debit or credit card"
                loading={buttonLoading}
                onPaypalSuccess={onPaypalSuccess}
                onSave={onCreditCardSave}
              />
            )}
            {showAddTipButton && (
              <AddPaymentButton
                label="Add a tip"
                modalHeaderLabel="Add a tip to your session"
                loading={buttonLoading}
                onSave={onTipSave}
                onPaypalSuccess={onPaypalSuccess}
                tipOnly={true}
              />
            )}
          </>
        )}

        {appointment.practice.offlinePaymentMethod && <OfflinePaymentMethods appointment={appointment} />}
        {appointment.state === "approved" && appointmentIsPast && (
          <div className="mt-4">
            <InfoParagraph>
              The appointment start time has past so this appointment can&apos;t be cancelled or rescheduled.
            </InfoParagraph>
          </div>
        )}
        <div className="mt-4 font-bold text-teal [&>*]:m-auto [&>*]:mt-2 [&>*]:block">
          {!appointmentIsPast && appointment.state !== "cancelled" && (
            <>
              {/* <RescheduleAppointmentButton appointmentId={appointment.id} showAlert={showAlert} /> */}
              <CancelAppointmentButton
                appointmentId={appointment.id}
                onCancel={() => {
                  setAppointment({ ...appointment, ...{ state: "cancelled" } })
                  showAlert("Your appointment has been cancelled.")
                }}
                onError={(error) => {
                  console.error(error) // eslint-disable-line no-console
                  showToast({ content: "There was an error cancelling your appointment.", type: "error" })
                }}
                showAlert={showAlert}
              />
            </>
          )}
          <div>
            <a href={appointment.practice.url}>Book another appointment</a>
          </div>
        </div>
      </div>
      <Toast visible={alertVisible} setVisible={setAlertVisible} warning={alertWarning}>
        {alertContent}
      </Toast>
    </div>
  )
}

export default function ManageAppointmentsConsumer(props) {
  return (
    <NiceModal.Provider>
      <ToastProvider>
        <GraphQLProvider>
          <AppointmentProvider>
            <PageContent {...props} />
          </AppointmentProvider>
        </GraphQLProvider>
      </ToastProvider>
    </NiceModal.Provider>
  )
}

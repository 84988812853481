import React from "react"
import styled from "styled-components"

const Button = styled.button`
  border: none;
  padding: 24px 48px;
  background: linear-gradient(215.91deg, #f4b965 12.73%, #f58c72 85.52%);
  border-radius: 100px;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.03em;
  height: fit-content;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #c8d6df;

    &:hover {
      cursor: not-allowed;
    }
  }
  ${({ fullWidth }) => fullWidth && `width: 100%;`}

  ${({ size }) => size === "slim" && `padding-top: 19px; padding-bottom: 19px;`}

  ${({ type }) =>
    type === "primary" && `&:hover { background: linear-gradient(215.91deg, #EDB364 12.73%, #E96D74 85.52%) }`}
`
const customStyling = {
  secondary: {},
  tertiary: {
    background: "transparent",
    color: "#0B3954",
    border: "1px solid #C8D6DF"
  },
  link: {
    color: "#0D9DA4",
    background: "transparent",
    border: "1px solid #C8D6DF"
  },
  quaternary: {
    background: "#fff",
    color: "#0B3954",
    border: "1px solid #C8D6DF",
    padding: "8px 24px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px"
  },
  warning: {
    background: "#EB5757",
    color: "#FFF",
    padding: "24px 48px",
    fontSize: "14px",
    fontWeight: "700",
    fontSize: "17px",
    letterSpacing: "3%",
    lineHeight: "16px",
    border: "1px solid #EB5757"
  },
  square: {
    borderRadius: "4px"
  },
  text: {
    marginTop: "12px",
    border: "none"
  }
}

export default function RoundedButton({ type, label, disabled, onClick, fullWidth, size = "full", loading }) {
  if (disabled) {
    onClick = undefined
  }

  return (
    <Button
      style={customStyling[type]}
      onClick={onClick}
      disabled={disabled ? true : false}
      fullWidth={fullWidth}
      size={size}
      type={type}>
      {loading ? <img src="/images/button-spinner.gif" width="20px" /> : <>{label}</>}
    </Button>
  )
}

import NiceModal from "@ebay/nice-modal-react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import React, { useState, useEffect, useRef } from "react"
import { components } from "react-select"
import Creatable from "react-select/creatable"
import { useQuery } from "urql"

import { usePractice } from "../../../contexts/PracticeContext"
import { selectStyling, formatPhone, urqlClient } from "../../../utils/utils"
import EditClientModal from "../../shared/EditClientModal"
import { InputWithLabel } from "../../shared/Inputs"
import { Banner } from "../../shared/Layout"
import { Link } from "../../shared/Links"
import LoadingSpinner from "../../shared/LoadingSpinner"

const CLIENTS_QUERY = `
  query clientsQuery {
    clients {
      id
      practiceId
      userId
      email
      phone
      firstName
      lastName
      clientType
      invitedFrom
    }
  }
`

const CLIENT_DETAILS_QUERY = `
  query clientDetailsQuery($clientId: ID!) {
    client(clientId: $clientId) {
      id
      openPackages { 
        id 
        createdAt 
        name 
        sessionsRemaining 
        serviceId 
      }
      lastBookedService { 
        id 
      }
      customPrices { 
        id 
        amountCents 
        serviceId 
      }
    }
  }
`

const CustomOption = ({ data, setClientToEdit, ...restProps }) => {
  const { name, label, value, contactInfo, preventBooking, __isNew__ } = data

  return (
    <components.Option {...restProps}>
      <div>
        {__isNew__ ? <>{label}</> : <>{name}</>}

        <span className="block text-sm text-gray-dark">
          {preventBooking ? (
            <span>
              No email or phone on file{" "}
              <Link
                className="font-semibold"
                onClick={() => {
                  const [firstName, lastName] = name.split(" ")

                  setClientToEdit({
                    id: value,
                    firstName: firstName,
                    lastName: lastName
                  })
                  NiceModal.show("edit-client-appointment")
                }}>
                Add either to book a session
              </Link>
            </span>
          ) : (
            contactInfo
          )}
        </span>
      </div>
    </components.Option>
  )
}

export default function ClientSelector({ defaultClient, onSelect }) {
  const [{ data, error }, reexecuteQuery] = useQuery({
    query: CLIENTS_QUERY,
    requestPolicy: "cache-and-network"
  })

  const [clientList, setClientList] = useState([])
  const [newClient, setNewClient] = useState(false)
  const [selectedClient, setSelectedClient] = useState(defaultClient)
  const [listValue, setListValue] = useState()
  const [clientToEdit, setClientToEdit] = useState()
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)

  const { practicePhone, practiceEmail } = usePractice()

  let clientPhone = formatPhone(selectedClient?.phone)
  if (clientPhone) {
    clientPhone = clientPhone.startsWith("+1") || clientPhone.startsWith("1") ? clientPhone : "+1 " + clientPhone
  }

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="h-5 w-5 text-gray-dark" aria-hidden="true" />
    </components.DropdownIndicator>
  )

  const WrappedCustomOption = (props) => <CustomOption {...props} setClientToEdit={setClientToEdit} />

  useEffect(() => {
    if (data && data.clients) {
      setClientList(data.clients)

      if (defaultClient) {
        setListValue({
          value: defaultClient.id,
          name: `${defaultClient.firstName} ${defaultClient.lastName}`,
          label: `${defaultClient.firstName} ${defaultClient.lastName}
          <span class='block text-sm text-gray-dark'>${
            formatPhone(defaultClient.phone) ? formatPhone(defaultClient.phone) : defaultClient.email
          } </span>`
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (clientList.length > 0) {
      const params = new URLSearchParams(window.location.search)
      const clientId = params.get("clientId")
      if (clientId) {
        onSelect(clientList.find((client) => client.id === clientId))
        window.history.replaceState({}, "", window.location.pathname)
      }
    }
  }, [clientList])

  if (error) {
    console.log(error)
  }

  const ref = useRef(null)

  const enrichClientWithDetails = (client) => {
    if (!client) return

    setIsLoadingDetails(true)
    urqlClient
      .query(CLIENT_DETAILS_QUERY, { clientId: client.id })
      .toPromise()
      .then((result) => {
        const clientDetailsData = result.data
        if (clientDetailsData?.client) {
          const enrichedClient = {
            ...client,
            ...clientDetailsData.client
          }
          onSelect(enrichedClient)
        }
      })
      .finally(() => {
        setIsLoadingDetails(false)
      })
  }

  return (
    <div className="mb-4">
      {newClient ? (
        <div className="grid grid-cols-2 gap-2">
          <InputWithLabel
            label="First name"
            value={selectedClient.firstName}
            onChange={(e) => setSelectedClient({ ...selectedClient, firstName: e.target.value })}
            required
          />
          <InputWithLabel
            label="Last name"
            value={selectedClient.lastName}
            onChange={(e) => setSelectedClient({ ...selectedClient, lastName: e.target.value })}
            required
          />
          <InputWithLabel
            label="Email"
            value={selectedClient?.email ? selectedClient.email : ""}
            className="mt-2"
            onChange={(e) => {
              setSelectedClient({ ...selectedClient, email: e.target.value })
              onSelect({ ...selectedClient, email: e.target.value })
            }}
          />
          <InputWithLabel
            label="Phone"
            className="mt-2"
            onChange={(e) => {
              setSelectedClient({ ...selectedClient, phone: e.target.value })
              onSelect({ ...selectedClient, phone: e.target.value })
            }}
            value={selectedClient?.phone ? formatPhone(selectedClient.phone) : ""}
            disabled={!newClient}
            required
          />
        </div>
      ) : (
        <div className="relative">
          <Creatable
            id="client-select"
            placeholder="Type to select or create client"
            formatCreateLabel={(inputValue) => `Create new client "${inputValue}"`}
            components={{ DropdownIndicator, Option: WrappedCustomOption }}
            options={clientList.map((client) => {
              let name = client.firstName
              if (client.lastName) name += ` ${client.lastName}`
              const preventBooking = !client.phone && !client.email
              const contactInfo = formatPhone(client.phone) ? formatPhone(client.phone) : client.email

              return {
                value: client.id,
                name: name,
                label: name,
                contactInfo,
                preventBooking,
                isDisabled: preventBooking
              }
            })}
            onChange={(clientFromSelect) => {
              const fullClient = clientList.find((client) => client.id === clientFromSelect.value)
              setSelectedClient(fullClient)
              setListValue(clientFromSelect)
              enrichClientWithDetails(fullClient)
            }}
            value={listValue}
            menuPortalTarget={document.body}
            formatOptionLabel={(option, { context }) => (
              <div className="flex items-center justify-between">
                {context === "menu" ? (
                  <span className="block font-manrope text-base" dangerouslySetInnerHTML={{ __html: option.label }} />
                ) : (
                  <>
                    <span className="font-manrope text-base" dangerouslySetInnerHTML={{ __html: option.name }} />
                    {isLoadingDetails && <LoadingSpinner className="p-0" spinnerClassName="h-4 w-4" />}
                  </>
                )}
              </div>
            )}
            styles={selectStyling()}
            onCreateOption={(e) => {
              const client = {
                firstName: e.split(" ")[0],
                lastName: e.split(" ")[1],
                email: "",
                phone: "",
                clientType: "invited",
                invitedFrom: "web_appointment_invite"
              }
              setNewClient(true)
              setSelectedClient(client)
              onSelect(client)
            }}
            ref={ref}
          />
        </div>
      )}

      {clientPhone === practicePhone && !!practicePhone && (
        <Banner type="error">
          Cannot use your phone number for a client.
          {newClient ? (
            <> Please leave blank and add email if client phone is unknown.</>
          ) : (
            <>
              {" "}
              Please edit this client in the{" "}
              <a className="cursor-pointer underline hover:text-gray" href="/portal/clients">
                client directory
              </a>{" "}
              before booking.
            </>
          )}
        </Banner>
      )}
      {selectedClient?.email?.trim() === practiceEmail && (
        <Banner type="error">
          Cannot use your email for a client.
          {newClient ? (
            <> Please add phone for client or use a placeholder email.</>
          ) : (
            <>
              {" "}
              Please edit this client in the{" "}
              <a className="underline hover:text-gray" href="/portal/clients">
                client directory
              </a>{" "}
              before booking.
            </>
          )}
        </Banner>
      )}
      <EditClientModal
        client={clientToEdit}
        onSuccess={reexecuteQuery}
        key={clientToEdit?.id}
        modalId="edit-client-appointment"
        deleteModalId="delete-client-appointment"
      />
    </div>
  )
}
